import API from "../axios/api"

export const getClient = async (val)=>{
    const {data} = await API.post('api/client/get',val)    
    return data
}
export const searchClient = async (val,page=null)=>{
    const {data} = await API.post('api/client/search?page='+page,val)    
    return data
}
export const searchClientAll = async (val)=>{
    const {data} = await API.post('api/client/search-all',val)    
    return data
}
export const getClientLocation = async (val)=>{
    const {data} = await API.post('api/client/locations',val)    
    return data
}
export const listClient = async (page)=>{
    const {data} = await API.get('api/client/list?page='+page)    
    return data
}
export const newClient = async (val)=>{
    const {data} = await API.post('api/client/create',val)    
    return data
}
export const setCotizacion = async (val)=>{
    const {data} = await API.post('api/cotizacion/create',val)    
    return data
}
export const clientKardex = async (val)=>{
    const {data} = await API.post('api/client/kardex',val)    
    return data
}
export const getReciboPrint = async (val)=>{
    const {data} = await API.post('api/recibo/get',val)    
    return data
}
export const getReciboAllPrint = async (val)=>{
    const {data} = await API.post('api/recibo/getAll',val)    
    return data
}
export const generateCode = async (val)=>{
    const {data} = await API.post('api/anticipo/code',val)    
    return data
}
export const verifyCode = async (val)=>{
    const {data} = await API.post('api/anticipo/code',val)    
    return data
}
export const dowloadExcel = async ()=>{
    const {data} = await API.get('api/client/download',{responseType:'blob'})
    return data
}