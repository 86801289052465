import API from "../axios/api"

export const getDebtsAccountReceivable = async (page)=>{
    const {data} = await API.get('api/account-receivable?page='+page)
    
    return data
}
export const newAccount = async (val)=>{
    const {data} = await API.post('api/new-account',val)    
    return data
}

export const listarCuentas = async (val,page)=>{
    const {data} = await API.post('api/account-list?page='+page,val)    
    return data
}
export const listarCuentasPagar = async (val,page)=>{
    const {data} = await API.post('api/list-to-pay?page='+page,val)    
    return data
}
export const cobrarCuenta = async (val)=>{
    const {data} = await API.post('api/account-collect',val)    
    return data
}
export const pagarCuenta = async (val)=>{
    const {data} = await API.post('api/account-pay',val)    
    return data
}
export const getCuenta = async (val)=>{
    const {data} = await API.post('api/account',val)    
    return data
}
export const getPCuenta = async (val)=>{
    const {data} = await API.post('api/account-cxp',val)    
    return data
}
export const newAnticipo = async (val)=>{
    const {data} = await API.post('api/anticipo/create',val)    
    return data
}
export const listAnticipo = async (page)=>{
    const {data} = await API.get('api/anticipo/list?page='+page)    
    return data
}
export const usAnticipo = async (val)=>{
    const {data} = await API.post('api/anticipo/use',val)    
    return data
}
export const verificarAnticipo = async (val)=>{
    const {data} = await API.post('api/anticipo/verify',val)    
    return data
}