import { Button, Paper, TableContainer } from "@mui/material"
import { size, sumBy } from "lodash"
import { useRef, useState } from "react"
import { useReactToPrint } from "react-to-print"
import FiltersReport from "../../components/FiltersReport"
import Logo from "../../components/Logo"
import PrintFooter from "../../components/PrintFooter"
import Page from "../../layout/Pagev1"
import { dateConfig } from "../../utils/dateconfig"
import { fNumber } from "../../utils/formatNumber"
import GetAppIcon from "@mui/icons-material/GetApp";

import CashDetailV1 from "./CashDetailV1"
import CashDetailV2 from "./CashDetailV2"
import { downloadDocument } from "../../services/document"
import ModalComponent from "../../components/ModalComponent"
import CashboxV2 from "./CashboxV2"
const CashBoxList = () => {
    const [option,setOption] = useState();
    const [data,setData]=useState([]);  
    const componentRef = useRef();
    const componentRef1 = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const loadData =()=> {
      componentRef1.current.reportGenerate()
    }
    const downloadResp=(id)=>{
      downloadDocument({id:id}).then(res=>{        
        const url =  window.URL.createObjectURL(res)
        const link = document.createElement('a')
        link.href =url
        link.setAttribute('download',"respaldo")
        document.body.appendChild(link)
        link.click();
      })
     }

     function calculateTotal(data, getter) {
      return data.reduce((total, o) => {
        if (o.final_amount) {
          return total + parseFloat(getter(o));
        }
        return total;
      }, 0);
    }
    const sumEfectivo = calculateTotal(data, o => o.efectivo);
    const sumQR = calculateTotal(data, o => o.qr);
    const sumTransBanco = calculateTotal(data, o => o.trans_banco);
    const sumTarjeta = calculateTotal(data, o => o.tarjeta);
    const sumDeposito = calculateTotal(data, o => o.deposito);
    const sumCheque = calculateTotal(data, o => o.cheque);
   const sumEfectivosus = calculateTotal(data,o=>o.efectivo_sus);
   const sumTtransbancosus = calculateTotal(data,o=>o.trans_banco_sus);

// Calcular la suma total de todas las sumas individuales
const totalFinal = sumEfectivo + sumQR + sumTransBanco + sumTarjeta + sumDeposito + sumCheque;
const totalFinalSus = sumEfectivosus +sumTtransbancosus;


  return (
    <Page appBar={true} title="Cajas">
        <Paper className="p-3">
        <FiltersReport
              state={true}
              states={["cerrados","sin cerrar"]}
              setOption={setOption}
              provider={false}
              store={true}
              roles={["caja-almacen","caja-movil","jefe-almacen","jefe-sala"]}
              user={true}
              report="cash-box"
              loadData={setData}
              handlePrint={handlePrint}
              ref={componentRef1}
              exportExcel={true}
            />
        </Paper>
        <Paper className="p-3 mt-3">
          {
            option && 
            <ModalComponent btn="Ver caja general" title={"Caja General"} >
              <CashDetailV2 box={1} edit={false} options={option} reloadData={loadData} downloadRes={downloadResp} />  
            </ModalComponent>
          }
            <TableContainer>
             <div ref={componentRef}>
             <div className="grid grid-cols-8">
        <div className="col-span-2 ">
          <Logo />
        </div>
        <div className="col-span-4 w-full flex items-center">
          <h2 className="uppercase text-center w-full font-bold text-2xl">
           Historial de Cajas
          </h2>
        
        </div>
   
        
        <div className="col-span-4 text-xs"></div>
      </div>
             <table className="table-print">
                    <thead>
                       <tr>
                       
                       <th>#</th>
                        <th>Sucursal/Sala</th>
                        <th>Fecha de Apertura</th>
                        <th> apertura Bs</th>
                        <th> efectivo Bs</th>
                        <th> QR Bs</th>
                        <th>Transferencia Bancaria Bs</th>
                        <th>Tarjeta  Bs</th>
                        <th>Deposito Bs</th>
                        <th>Cheque Bs</th>
                        <th> efectivo Sus</th>
                        <th>Transferencia Bancaria Sus</th>
                        <th>Fecha de Cierre</th>
                        <th>Monto Cierre Bs</th>
                        <th>Monto Cierre Sus</th>
                        <th style={{width:"20%"}}>Observacion</th>
                        <th>Respaldo</th>
                        <th>Usuario</th>
                        <th className="no-print"></th>
                       </tr>
                    </thead>
                    <tbody>
                        {
                            size(data)> 0 && 
                            data.map((row,key)=>
                            <tr key={key}>                                
                                <td>{key+1}</td>
                                <td>{row.store.name}</td>
                                <td>{dateConfig(row.created_at)}</td>
                                <td className="text-right">{row.initial_amount}</td>
                                <td className="text-right">{row.efectivo}</td>
                                <td className="text-right">{row.qr}</td>
                                <td className="text-right">{row.trans_banco}</td>
                                <td className="text-right">
                                  <p>{row.tarjeta}</p>
                                  <p className="text-gray-600"> <span className="text-xs">Red enlace</span>{fNumber(parseFloat(row.tarjeta)*0.018)}</p>
                                </td>
                                <td className="text-right">{row.deposito}</td>
                                <td className="text-right">{row.cheque}</td>
                                <td className="text-right">{row.efectivo_sus}</td>
                                <td className="text-right">{row.trans_banco_sus}</td>
                                <td>{ row.close_date !== null && dateConfig(row.close_date)}</td>
                                <td className="text-right">{
                                  fNumber(
                                    parseFloat(row.initial_amount)+
                                    parseFloat(row.efectivo)+
                                    parseFloat(row.qr)+
                                    parseFloat(row.trans_banco)+
                                    parseFloat(row.tarjeta)+
                                    parseFloat(row.deposito)+
                                    parseFloat(row.cheque)
                                  )
                                }</td>
                                <td className="text-right">{
                                  fNumber(
                                    parseFloat(row.efectivo_sus)+
                                    parseFloat(row.trans_banco_sus)
                                  )
                                }</td>
                                
                                <td>{row.observation?.observation}</td>
                                <td>
                                 {
                                  size(row.docs)>0?  
                                  row.docs.map((img,index)=>
                                  <Button size="small" onClick={()=>downloadResp(img.id)} key={index}>
                                  <GetAppIcon size="small"/>
                                  {img.title}
                                  </Button>
                                  ):"Sin Respaldo"
                                 }
                                </td>
                                <td>{row.user.name}</td>
                                <td className="no-print">
                                  { row.close_date === null ? <CashDetailV1 key={key+row.id} box={row} reloadData={loadData} downloadRes={downloadResp} />
                                :
                               <div className="inline-flex gap-1">
                                 <CashDetailV1 key={key+row.id} box={row} edit={false}  reloadData={loadData} downloadRes={downloadResp} />
                          
                                <ModalComponent btn={sumBy(row.comprobante,o=>{
                                    return parseFloat(o.total)
                                  }) >= fNumber(
                                    parseFloat(row.initial_amount)+
                                    parseFloat(row.efectivo)+
                                    parseFloat(row.qr)+
                                    parseFloat(row.trans_banco)+
                                    parseFloat(row.tarjeta)+
                                    parseFloat(row.deposito)+
                                    parseFloat(row.cheque)
                                  )?"💰":"🔴"} title={"Caja"}>
                                  <CashboxV2  box={row}/>
                                </ModalComponent>
                               </div>
                                }
                                </td>
                            </tr>
                            )
                        }
                        <tr>
                          <th colSpan={4} className="text-right"> Totales.</th>
                          <td className="text-right">{fNumber(sumEfectivo)}</td>
                          <td className="text-right">{fNumber(sumQR)}</td>
                          <td className="text-right">{fNumber(sumTransBanco)}</td>
                          <td className="text-right">{fNumber(sumTarjeta)}</td>
                          <td className="text-right">{fNumber(sumDeposito)}</td>
                          <td className="text-right">{fNumber(sumCheque)}</td>
                          <td className="text-right">{fNumber(sumEfectivosus)}</td>
                          <td className="text-right">{fNumber(sumTtransbancosus)}</td>
                          <td className="text-right"></td>
                          <td className="text-right bg-blue-200">{fNumber(totalFinal)}</td>
                          <td className="text-right bg-green-200">{fNumber(totalFinalSus)}</td>                         
                        </tr>
                    </tbody>
                </table>
                <PrintFooter/>
             </div>
            </TableContainer>
        </Paper>
    </Page>
  )
}
export default CashBoxList