import API from "../axios/api"

export const listBranchOffices = async (val,page)=>{
    const {data} = await API.post('api/branch-office/list?page='+page,val)    
    return data
}
export const searchBranchOffices = async (val,page)=>{
    const {data} = await API.post('api/branch-office/search?page='+page,val) 
    return data
}
export const createBranchOffices = async (val)=>{
    const {data} = await API.post('api/branch-office/create',val) 
    return data
}
export const deleteBranchOffices = async (val)=>{
    const {data} = await API.post('api/branch-office/delete',val) 
    return data
}
export const listBranchOfficesAll = async ()=>{
    const {data} = await API.get('api/branch-office/all')    
    return data
}
export const listBranchMovilAll = async ()=>{
    const {data} = await API.get('api/branch/store')    
    return data
}
export const changeBranchMovilAll = async (val)=>{
    const {data} = await API.post('api/branch/store-change',val)    
    return data
}