import { useEffect, useState } from "react"
import { postApiService } from "../../../services/crudServices"
import ModalComponent from "../../../components/ModalComponent"
import GenerarPlanilla from "./GenerarPlanilla"

const PlanillaMensual =({planilla, info})=>{
    const [data,setData] = useState([])
    const verificarPlanilla = ()=>{
        postApiService("rrhh/planilla/rev",{planilla_id: planilla}).then(res=>{
            setData(res)
        })
    }
    useEffect(()=>{
        verificarPlanilla()
    },[])
    return(
        <div>
            <p><span>Planilla </span>{info?.name}</p>
            <p><span>Empresa </span>{info.company.name}</p>
            <p><span>Sucursal </span>{info?.z_company_branch.sucursal}</p>
            <div>
                {
                    data.map((row,key)=>
                    <div key={key}>
                        <ul>
                            <li className="font-bold text-lg">{row.year}</li>
                            {
                                row.meses.map((ro,ke)=>
                                <li className="font-semibold px-2 capitalize border-b border-black " key={ke}> 
                                <div className="inline-flex w-full justify-between text-left gap-2">
                                    <p className="w-32">
                                        {ro?.mes}
                                    </p>
                                    <ModalComponent fullScreenx={true}  btn={ <p className={ro.registro?"text-green-500":"text-red-500"}>Planilla de sueldos</p>} >
                                        <GenerarPlanilla planilla={planilla} info={info} fecha={ro.fecha} mes={ro.mes} anio={row.year} id={ro.registro?.id} />
                                    </ModalComponent>
                                    <ModalComponent fullScreenx={true}  btn={ <p className={"text-red-500"}>Planilla de aportes patronales</p>} >
                                    <div>planilla de aportes patronales</div>
                                    </ModalComponent>

                                </div>
                                {
                                    ro?.mes === "diciembre" &&
                                    <ModalComponent fullScreenx={true}  btn={ <p className={"text-red-500"}>Planilla de aguinaldos</p>} >
                                    <div>planilla de aguinaldos</div>
                                    </ModalComponent>
                                }
                                </li>
                                )
                            }
                        </ul>
                    </div>
                    )
                }
            </div>
        </div>
    )
}

export default PlanillaMensual