import API from "../axios/api";
export const createOrder = async (val) => {
  const { data } = await API.post("api/order/create", val);
  return data;
};
export const listOrders = async (val) => {
  const { data } = await API.post("api/order/list", val);
  return data;
};
export const discountOrders = async (val) => {
  const { data } = await API.post("api/order/discount", val);
  return data;
};
export const discountAddOrder = async (val) => {
  const { data } = await API.post("api/order/add-discount", val);
  return data;
};
export const listOrdersCont = async (val) => {
  const { data } = await API.post("api/order/list-cont", val);
  return data;
};
export const setStateOrder = async (val) => {
  const { data } = await API.post("api/order/status", val);
  return data;
};
export const setConfirmProvider = async (val) => {
  const { data } = await API.post("api/order/provider-confirm", val);
  return data;
};
export const listOrdersReceipt = async (val) => {
  const { data } = await API.post("api/order/receipt-orders/list", val);
  return data;
};
export const getOrder = async (val) => {
  const { data } = await API.post("api/order/get-order", val);
  return data;
};
export const setOrderObservation = async (val) => {
  const { data } = await API.post("api/order/new-observation", val);
  return data;
};
export const uploadDocument = async (val) => {
  const { data } = await API.post("api/order/up-document", val, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return data;
};
export const generateReceipt = async (val) => {
  const { data } = await API.post("api/order/new-receipt", val);
  return data;
};
export const listReceiptOrders = async (val) => {
  const { data } = await API.post("api/reception/reception", val);
  return data;
};
export const saleOrdersPending = async (val) => {
  const { data } = await API.post("api/sales/orders", val);
  return data;
};
export const saleOrderPending = async () => {
  const { data } = await API.get("api/sales/order/pending");
  return data;
};
export const saleOrder = async (val) => {
  const { data } = await API.get("api/sales/order/sales", val);
  return data;
};
export const changeStateSaleOrder = async (val) => {
  const { data } = await API.post("api/sales/orders/status", val);
  return data;
};
export const changeStateSaleOrders = async (val) => {
  const { data } = await API.post("api/sales/orders/states", val);
  return data;
};
export const anulateSaleOrder = async (val) => {
  const { data } = await API.post("api/sales/orders/anulate", val);
  return data;
};
export const anulateSaleOrderAll = async (val) => {
  const { data } = await API.post("api/sales/orders/anulate-all", val);
  return data;
};
export const saleDelivery = async (val) => {
  const { data } = await API.post("api/sales/delivery", val);
  return data;
};
export const saleDeliveryNotes = async (val) => {
  const { data } = await API.post("api/sales/delivery-notes", val);
  return data;
};
export const saleDeliveryConfirm = async (val) => {
  const { data } = await API.post("api/sales/delivery/confirm", val);
  return data;
};
export const saleDeliveryList = async (val) => {
  const { data } = await API.post("api/sales/exit-list", val);
  return data;
};
export const saleDeliveryListAll = async (val) => {
  const { data } = await API.post("api/sales/exit-list-all", val);
  return data;
};
export const anulateDelivery = async (val) => {
  const { data } = await API.post("api/sales/exit-anulate", val);
  return data;
};
export const getReceibVoucher = async (val) => {
  const { data } = await API.post("api/conta/receipt", val);
  return data;
};
export const getReceibVoucherA = async (val) => {
  const { data } = await API.post("api/conta/receipt", val);
  return data;
};
export const getSaleVoucher = async (val) => {
  const { data } = await API.post("api/order/get-sales", val);
  return data;
};
export const getSaleVoucherPrint = async (val) => {
  const { data } = await API.post("api/sales/get-voucher", val);
  return data;
};
export const getOrderControls = async (val) => {
  const { data } = await API.post("api/order-controls", val);
  return data;
};
export const getOrderControlsTransfer = async (val) => {
  const { data } = await API.post("api/order-controls-transfer", val);
  return data;
};

export const newCustomOrder = async (val) => {
  const { data } = await API.post("api/custom-order/new", val);
  return data;
};
export const getCustomOrder = async (val) => {
  const { data } = await API.post("api/custom-order/get", val);
  return data;
};
export const updateCustomOrder = async (val) => {
  const { data } = await API.post("api/custom-order/up", val);
  return data;
};
export const getAllCustomOrder = async (val) => {
  const { data } = await API.post("api/custom-order/list", val);
  return data;
};
export const setstateCustomOrder = async (val) => {
  const { data } = await API.post("api/custom-order/set-state", val);
  return data;
};
export const generateShopOrder = async (val) => {
  const { data } = await API.post("api/custom-order/new-order", val);
  return data;
};
export const updateShopOrder = async (val) => {
  const { data } = await API.post("api/custom-order/update", val);
  return data;
};
export const listReceiptOrder = async () => {
  const { data } = await API.get("api/custom-order/listreceipt");
  return data;
};
export const getOrderCust = async (val) => {
  const { data } = await API.post("api/custom-order/get-order", val);
  return data;
};
export const addNewProvStock = async (val) => {
  const { data } = await API.post("api/custom-order/add-stock", val);
  return data;
};
export const addNewProvStockBon = async (val) => {
  const { data } = await API.post("api/custom-order/add-stock-bon", val);
  return data;
};
