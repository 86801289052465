import API from "../axios/api"

export const listUsers = async (page=null)=>{
    const {data} = await API.get('api/user/list?page='+page)
    return data
}
export const listAllUsers = async ()=>{
    const {data} = await API.get('api/user/list-all')
    return data
}
export const listRoleUsers = async (val)=>{
    const {data} = await API.post('api/user/list-rol',val)
    return data
}
export const listAlmacenUsers = async ()=>{ 
    const {data} = await API.get('api/user/list-almacen')
    return data
}
export const listMovilUsers = async ()=>{ 
    const {data} = await API.get('api/user/list-movil')
    return data
}
export const searchUsers = async (value,page=null)=>{
    const {data} = await API.post('api/user/search?page='+page,value)
    return data
}
export const createUser = async (values)=>{
    const {data} = await API.post('api/user/create',values)
    return data
}
export const updateUser = async (values)=>{
    const {data} = await API.post('api/user/update',values)
    return data
}
export const deleteUser = async (values)=>{
    const {data} = await API.post('api/user/delete',values)
    return data
}
export const restoreUser = async (values)=>{
    const {data} = await API.post('api/user/undelete',values)
    return data
}

export const setUserParamtro = async (val)=>{
    const {data} = await API.post('api/parametro/user/new',val)    
    return data
}
export const getUserParametro = async ()=>{
    const {data} = await API.get('api/parametro/user',)    
    return data
}
export const userConfirm = async (val)=>{
    const {data} = await API.post('api/password-confirm',val)    
    return data
}