import { dateConfig } from "../utils/dateconfig";

const Firma = ({ user, client, title, url=null, date=null }) => {
  return (
   <div>
      {
        url? <img src={url} alt="firma" className="mx-auto w-32 h-12" />: <div className="h-12"></div>
      }
     <div class="w-full border-t border-gray-200">
      <div className="w-full flex flex-col text-center justify-end  gap-2">
        {user || client}
        <span className="font-base text-md borderTop text-gray-500">
          {title}
        </span>
      {
      date &&  <span className="font-base text-xs text-gray-500">
          { dateConfig(date) }
        </span>
        }
      </div>
    </div>
    </div>
  );
};
export default Firma;
