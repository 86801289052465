import { Link, useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Menu, Close } from "@mui/icons-material";
import { Badge, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menues from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useMemo, useState } from "react";
import { logOut } from "../services/auth";
import { useDispatch, useSelector } from "react-redux";
import { setNoAuth } from "../redux/features/auth/authSlice";
import NotificationsIcon from '@mui/icons-material/Notifications';

import { storage } from "../store/storage";
import { size } from "lodash";

export default function AdminNavbar({
  showSidebar,
  toggleSidebar,
  title,
  pos,
}) {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [alert, setAlert] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);
  const [alerts,setAlerts]=useState({items_count:0,items:[]});
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleAlertMenu=()=>{
    setAlert(!alert)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useMemo(()=>{
    if(userData.alerts){
      setAlerts(
        { items_count:userData.alerts.items_count,
         items:userData.alerts.items,}
       )
    }
  },[userData])
  const logout = () => {
    logOut().then((res) => {
      storage.clear();
      dispatch(setNoAuth());
      navigate("/login");
    });
  };
  return (
    <nav className="bg-primary py-2 px-3 z-50">
      <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
        <div className="md:hidden ">
          <IconButton onClick={() => toggleSidebar()}>
            <Menu className="text-white" />
          </IconButton>

          <div
            className={`absolute top-2 md:hidden ${
              showSidebar === "left-0" ? "left-64" : "-left-64"
            } z-50 transition-all duration-300`}
          >
            <IconButton onClick={() => toggleSidebar()}>
              <Close className="text-white" />
            </IconButton>
          </div>
        </div>

        <div className="flex justify-between items-center w-full">
          <h4 className="uppercase text-white text-sm tracking-wider mt-1">
            {title}
          </h4>

          <div className="flex items-center">
            
            <div className="-mr-4 ml-6 text-white ">{userData?.user?.name}</div>
            <div className="-mr-4 ml-6">
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="cuenta">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/2.jpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menues
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem component={Link} to="/clientarea">
                    <Typography textAlign="center">Cuenta</Typography>
                  </MenuItem>
                  <MenuItem onClick={() => logout()}>
                    <Typography textAlign="center">Salir</Typography>
                  </MenuItem>
                </Menues>
              </Box>
            </div>
            <div className="mr-4 ml-6">
           {size(alerts.items)>0 && <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Productos con bajo stock">
                <Badge badgeContent={parseInt(alerts.items_count)}  color="secondary">
                  <IconButton color="inherit" onClick={handleAlertMenu} sx={{ p: 0 }}>
                   <NotificationsIcon className="text-white "/>
                  </IconButton>
                  </Badge>
               
                </Tooltip>
                <Menues
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={alert}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(alert)}
                  onClose={handleAlertMenu}
                >
                  
                 {

                  alerts.items.map((row,key)=>
                  <MenuItem key={key}>
                  <Typography textAlign="center" className="uppercase">{row.detail}</Typography>
                </MenuItem>
                  )
                 }
                 <MenuItem component={Link} to="/stock-status/store" className="bg-blue-300">
                    <Typography textAlign="center" className="text-blue-500 uppercase" >{"Ver "+alerts.items_count+" items con bajo stock..."}</Typography>
                  </MenuItem>
                </Menues>
              </Box>}
           
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
