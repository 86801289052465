import { createSlice } from "@reduxjs/toolkit";
import { findIndex } from "lodash";

export const initialState = {};
export const evaluationdetail = createSlice({
  name: "evalItems",
  initialState: initialState,
  reducers: {
    addEvaluationItemsState: (state, action) => {
      return action.payload;
    },
    upEvalItem: (state, action) => {
      const allData = state;
      const index = findIndex(allData, (o) => {
        return o.id === action.payload.id;
      });

      let data = allData[index];
      data = {
        ...data,
        [action.payload.name]: action.payload.value,
      };
      allData[index] = data;
      return allData;
    },
    resetEvalItem: () => {
      console.log("Si llega");
      return initialState;
    },
  },
});
export const { addEvaluationItemsState, upEvalItem, resetEvalItem } =
  evaluationdetail.actions;
export default evaluationdetail.reducer;
