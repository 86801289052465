import { Paper } from "@mui/material"
import Page from "../../layout/Pagev1"
import { listRoleUsers } from "../../services/users"
import { useEffect, useState } from "react"
import { listStores } from "../../services/store"
import { listStoreType } from "../../services/store"
import { listObjectives } from "../../services/objective"
import { groupBy, includes, size } from "lodash"
import { useSelector } from "react-redux"

const Objectives = () => {
  const userData = useSelector(state=>state.userData)
  const [users,setUsers]= useState([])
  const [stores,setStores]= useState([])
  const listObj = ()=>{
    listObjectives().then(res=>{
      setUsers(res.users)
      setStores(res.stores)      
    })
  }
  useEffect(()=>{
    listObj()
  },[])
  console.log(userData);
  return (
    <Page appBar={true} title={"objetivo de ventas"}>
    {includes(["super-admin", "gerencia"],
                    userData.role
                  ) ? (
                    <div className="md:grid grid-cols-3 gap-2">
      {
        stores.map((row,key)=>
      <Paper className="p-3">
        <div key={key}>
          <p className="text-center font-bold  uppercase text-primary">{row.name}</p>
          <p className="text-center text-xs">{size(row.objective)>0?row.objective[0].type:"Objetivos no configurados"}</p>
        </div>
      </Paper>
        )
      }
    </div>
) : (
  <div className="md:grid grid-cols-2 gap-2">
    <Paper className="p-3">
      
    </Paper>
    <Paper className="p-3">
      {/* Contenido para otros roles */}
    </Paper>
  </div>
)}
      
    </Page>
  )
}
export default Objectives