import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import Firma from "../../components/Firma"
import Logo from "../../components/Logo"
import { companyDirection, companyName, companyPhone } from "../../config/AppConfig"
import { dateConfig } from "../../utils/dateconfig"
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber"
const ComprobantePrint = ({data})=>{
    if(!data.recibo){
        return(
            <div className='uppercase text-xs'>
        <Box>
            <div  className='flex flex-wrap bg-white overflow-hidden relative'>
                <div className='w-full h-full gap-2 flex flex-col'>
                    <div className='logos print-heading overflow-hidden w-fulljustify-center content-center'>
                        <div className='flex flex-wrap  justify-between w-full gap-4'>
                            <div className='w-5/12 flex flex-col justify-center text-center'>
                            {/* <img className="box-border h-auto w-11/12 relative m-auto" 
                                src={logoComexa}
                                alt="Logo Comexa"
                                /> */}
                                <div className='box-border'>
                                <Logo/>
                                </div>
                            </div>
                            <div className='w-6/12 flex flex-col'>
                            <h3 className='font-bold text-printa uppercase'>{companyName}</h3>
                                    <Typography
                                        component="span"                                         
                                        color="text.secondary"
                                        variant="body3"
                                        className='font-semibold text-xs'
                                    >
                                {companyDirection}
                                    </Typography>
        
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                    <strong>TELEFONO: </strong> {companyPhone}
                                    </Typography>
                            </div>

                        </div>
                    
                    </div>
                    <div className='print-tittle  relative overflow-hidden w-full print-logos flex flex-wrap justify-center text-center items-center'>
                        <div className='bg-printa rounded-md'>
                        <h2 className='tittle px-11 text-center text-xl font-bold text-white py-2'> 
                    COMPROBANTE DE CUENTAS POR COBRAR
                        </h2>                            
                        </div>
                    </div>
                    <div className='print-qr relative overflow-hidden w-full bg-white flex flex-wrap justify-center text-center items-center gap-3'>
                        <div className='qr w-full border border-solid rounded-lg border-black'>
                            <div className='w-full flex flex-wrap justify-between'>
                                <div className='w-6/12 flex flex-col text-left gap-2 py-2 px-4'>
                                    <div className='w-full flex flex-row justify-between  text-left gap-2'>
                                    <h3 className='font-bold text-printa text-lg'> Cliente: {data.client.business_name}</h3>
                                    <span  className='font-base text-md text-gray-500'>
                                    <strong> NIT/CI:</strong>   {data.client.identification_number}
                                    </span>
                                    </div>
                                    <div className='w-full flex flex-row text-left gap-2'>
                                    <span  className='font-base text-md text-gray-500'>
                                    <strong> FECHA:</strong>  {dateConfig(data.created_at)}
                                    </span>
                                    </div>
                                </div>
                                <Divider orientation="vertical" flexItem />
                                <div className='w-5/12 flex flex-col text-center gap-2 p-2'>
                                <h3 className='font-bold text-printa text-lg'>  Número de orden: {fInvoiceNumber(data.voucher_number ??data.voucher_head.voucher_number )}</h3>
                            {/*  <span  className='font-base text-md text-gray-500'>
                                <strong> Número de orden</strong>  {fInvoiceNumber(data.voucher_number)}
                                    </span> */}
                                </div>
                                    
                            </div>
                        </div>
                        <div className='qr w-full border border-solid overflow-hidden rounded-lg border-black'>
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>DETALLE</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                    
                            <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                            { data.destail}
                            </TableCell>
                
                            </TableRow>
                    
                        </TableBody>
                    </Table>
                    </TableContainer>
                            <div className='w-full flex flex-wrap justify-between'>
                                <div className='w-6/12 flex flex-col text-left gap-2 p-4'>
                                    <div className='w-full flex flex-row justify-between  text-left gap-2'>
                                    <h3 className='font-bold text-printa text-lg'> Transacción en  {data.currency}</h3>
                                
                            
                                    </div>
                                    {data.currency==="sus" && <div className='w-full flex flex-row text-left gap-2'>
                                    <span  className='font-base text-lg text-gray-500'>
                                    <strong>TOTAL {data.currency}:</strong>  {fNumber(parseFloat(data.total)/ parseFloat(data.exchange_rate))}
                                    </span>
                                    <span  className='font-base text-lg text-gray-500'>
                                    <strong>TIPO DE CAMBIO Bs:</strong>  {fNumber(data.exchange_rate)}
                                    </span>
                                    </div>}
                                    <div className='w-full flex flex-row text-left justify-between gap-2'>
                                    <span  className='font-base text-lg text-gray-500'>
                                    <strong>TOTAL  {data.currency}:</strong>   {fNumber(data.total)}
                                    </span>
                                    <span  className='font-base text-lg text-gray-500'>
                                    <strong> A CUENTA Bs:</strong>   {fNumber(data.a_cuenta)}
                                    </span>
                                    </div>
                                </div>
                                <Divider orientation="vertical" flexItem />
                                <div className='w-5/12 flex flex-col text-center justify-end gap-2 p-2'>
                                <h3 className='font-bold text-red-400 text-lg'> SALDO Bs: {fNumber(parseFloat(data.total) - parseFloat(data.a_cuenta))}</h3>                                   
                                </div>                                
                            </div>
                        </div>
                        <div className='qr w-full borderRadius h-24 '>
                            <div className='w-full h-full flex flex-wrap justify-evenly align-bottom'>                       
                                <Firma title="Realizado por" user={data.user.name}   url={data.user?.firma?.firma} />                          
                                <Divider orientation="vertical" flexItem />
                                <Firma title="cliente" client={data.client.business_name}/>                                
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div> 
        </Box>      
    </div>
        )
    }
  return (
    <div className='uppercase text-xs'>
        <Box>
        <div  className='flex flex-wrap bg-white overflow-hidden relative'>
            <div className='w-full h-full gap-2 flex flex-col'>
                <div className='logos print-heading overflow-hidden w-fulljustify-center content-center'>
                    <div className='flex flex-wrap  justify-between w-full gap-4'>
                        <div className='w-5/12 flex flex-col justify-center text-center'>
                        {/* <img className="box-border h-auto w-11/12 relative m-auto" 
                            src={logoComexa}
                            alt="Logo Comexa"
                            /> */}
                            <div className='box-border'>
                            <Logo/>
                            </div>
                        </div>
                        <div className='w-6/12 flex flex-col'>
                        <h3 className='font-bold text-printa uppercase'>{companyName}</h3>
                                <Typography
                                    component="span"                                         
                                    color="text.secondary"
                                    variant="body3"
                                    className='font-semibold text-xs'
                                >
                               {companyDirection}
                                </Typography>
    
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                <strong>TELEFONO: </strong> {companyPhone}
                                </Typography>
                        </div>

                    </div>
                  
                </div>
                <div className='print-tittle  relative overflow-hidden w-full print-logos flex flex-wrap justify-center text-center items-center'>
                    <div className='bg-printa rounded-md'>
                    <h2 className='tittle px-11 text-center text-xl font-bold text-white py-2'> 
                   COMPROBANTE DE CUENTAS POR COBRAR
                    </h2>                            
                    </div>
                </div>
                <div className='print-qr relative overflow-hidden w-full bg-white flex flex-wrap justify-center text-center items-center gap-3'>
                    <div className='qr w-full border border-solid rounded-lg border-black'>
                        <div className='w-full flex flex-wrap justify-between'>
                            <div className='w-6/12 flex flex-col text-left gap-2 py-2 px-4'>
                                <div className='w-full flex flex-row justify-between  text-left gap-2'>
                                <h3 className='font-bold text-printa text-lg'> Cliente: {data.client.business_name}</h3>
                                <span  className='font-base text-md text-gray-500'>
                                <strong> NIT/CI:</strong>   {data.client.identification_number}
                                </span>
                                </div>
                                <div className='w-full flex flex-row text-left gap-2'>
                                <span  className='font-base text-md text-gray-500'>
                                <strong> FECHA:</strong>  {dateConfig(data.created_at)}
                                </span>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className='w-5/12 flex flex-col text-center gap-2 p-2'>
                            <h3 className='font-bold text-printa text-lg'>  Número de orden: {fInvoiceNumber(data.voucher_number)}</h3>
                           {/*  <span  className='font-base text-md text-gray-500'>
                            <strong> Número de orden</strong>  {fInvoiceNumber(data.voucher_number)}
                                </span> */}
                            </div>
                                
                        </div>
                    </div>
                    <div className='qr w-full border border-solid overflow-hidden rounded-lg border-black'>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>DETALLE</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                  
                        <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                        {data.recibo.destail}
                        </TableCell>
               
                        </TableRow>
                   
                    </TableBody>
                </Table>
                </TableContainer>
                        <div className='w-full flex flex-wrap justify-between'>
                            <div className='w-6/12 flex flex-col text-left gap-2 p-4'>
                                <div className='w-full flex flex-row justify-between  text-left gap-2'>
                                <h3 className='font-bold text-printa text-lg'> Transacción en  {data.recibo.currency}</h3>
                               
                          
                                </div>
                                {data.currency==="sus" && <div className='w-full flex flex-row text-left gap-2'>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong>TOTAL {data.recibo.currency}:</strong>  {fNumber(parseFloat(data.recibo.total)/ parseFloat(data.recibo.exchange_rate))}
                                </span>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong>TIPO DE CAMBIO Bs:</strong>  {fNumber(data.recibo.exchange_rate)}
                                </span>
                                </div>}
                                <div className='w-full flex flex-row text-left justify-between gap-2'>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong>TOTAL  {data.recibo.currency}:</strong>   {fNumber(data.recibo.total)}
                                </span>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong> A CUENTA Bs:</strong>   {fNumber(data.recibo.a_cuenta)}
                                </span>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className='w-5/12 flex flex-col text-center justify-end gap-2 p-2'>
                            <h3 className='font-bold text-red-400 text-lg'> SALDO Bs: {fNumber(parseFloat(data.recibo.total) - parseFloat(data.recibo.a_cuenta))}</h3>                                   
                            </div>                                
                        </div>
                    </div>
                    <div className='qr w-full borderRadius h-24 '>
                        <div className='w-full h-full flex flex-wrap justify-evenly align-bottom'>                       
                            <Firma title="Realizado por" user={data.recibo.user.name}   url={data.recibo.user?.firma?.firma} />                          
                            <Divider orientation="vertical" flexItem />
                            <Firma title="cliente" client={data.client.business_name}/>                                
                        </div>
                    </div>
                </div>

                
            </div>
        </div> 
        </Box>      
    </div>
  )
}

export default ComprobantePrint