import { Button, Divider, IconButton, List, ListItem, Paper } from "@mui/material";
import { size, uniqBy } from "lodash";
import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import ComprobanteInicial from "../../sections/comprobante/ComprobanteInicial";
import Print from "../../sections/recibo/Print";
import { facturate } from "../../services/cashBox";
import InvoicePrint from "../CashBox/InvoicePrint";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useDispatch } from "react-redux";
import { setLoad } from "../../redux/features/config/eventButton";
import InvoicePrintV2 from "../CashBox/InvoicePrintV2";
import { fNumber } from "../../utils/formatNumber";

const PrintConfig = ({ dataPrint,infoPay }) => {
  const [facturas, setFacturas] = useState([]);
  const [invoice,setInvoice] = useState([])
  const [voucher,setVoucher] = useState([])
  const [recibo,setRecibo] = useState([])
  const componentRef1 = useRef();
  const componentRef = useRef();
  const toastId = useRef(null)
  const dispatch = useDispatch();

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const resetData =()=>{
    setInvoice([])
    setVoucher([])
  }
  const facturar = async (facturar) => {
        resetData()
    toastId.current =  toast.loading("cargando...")
      await facturate({id:facturar.factu, voucher_id: facturar.voucher_id,head:dataPrint.head}).then(res=>{        
          setInvoice(res)
          setVoucher(res)
        }).finally(()=>{
          toastId.current =  toast.dismiss(toastId.current)
        })
        await sleep(1000);
        printInvoice()
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef1.current,
  });
  const setFactu = () => {
    setFacturas(() => {
      return uniqBy(dataPrint.data.detail, "factu");
    });
    
  };
  
  useEffect(() => {
    if (dataPrint?.invoice === "yes") {
      setFactu();
    }
    if (dataPrint?.invoice === "no") {
      setRecibo(dataPrint.data);
    }
    if (size(dataPrint?.recibo)>0) {
        setRecibo(dataPrint.recibo);
      }
  }, []);
  const printInvoice=useReactToPrint({
    content: () => componentRef1.current,
  })
  const handleChangePrint=()=>{
    dispatch(setLoad())
  }
  const reloadPage=()=>{
    window.location.reload();
  }
  return (
    <div className="md:flex p-3 max:h-screen text-gray-900 gap-3">
      <Paper className="rounded-md relative min-w-[20%] shadow-2xl p-3 bg-white">
      <ul className="space-y-3">
      <li>
        <div className="flex justify-between">
          <span className="font-semibold">Total:</span>
          <span className="font-semibold">{fNumber(parseFloat(infoPay.total ?? 0))}</span>
        </div>
      </li>
      <li>
        <div className="flex justify-between">
          <span className="font-semibold">Monto:</span>
          <span className="font-semibold">{fNumber(parseFloat(infoPay.monto ?? 0))}</span>
        </div>
      </li>
      <li className="">
        <div className="flex justify-between ">
          <span className="font-semibold">Cambio:</span>
          <span className="font-bold text-xl">{fNumber(parseFloat(infoPay.monto ?? 0) - parseFloat(infoPay.total ?? 0))}</span>
        </div>
      </li>
    
      <li>
        <div className="py-2 text-center">
          <p className="uppercase text-xs font-bold">Facturar</p>
        </div>
        <Divider />
      </li>
      {size(facturas) > 0 &&
        facturas.map((row, key) => (
          <li key={key}>
            <Button
              size="small"
              className="no-print"
              disabled={row.factu === null}
              fullWidth
              onClick={() => facturar(row)}
            >
              {row.provider}
            </Button>
            <Divider />
          </li>
          
        ))}
        
      <li>
        <div className="py-2 text-center">
          <p className="uppercase text-xs font-bold">Nota General</p>
        </div>
        <Divider />
      </li>
      <li>
        <div className="flex justify-center space-x-2">
          <Button variant="contained" fullWidth size="small" onClick={() => printInvoice()}>Imprimir</Button>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleChangePrint}
            aria-label="close"
          >
            <CompareArrowsIcon />
          </IconButton>
        </div>
      </li>
      <li>
        <Button variant="contained" fullWidth size="small" color="success" onClick={()=>reloadPage()} >Volver al carito de ventas</Button>
      </li>
    </ul>
      </Paper>
      <div className="rounded-md shadow-2xl p-3 h-full bg-white overflow-hidden hover:overflow-scroll">
        <div ref={componentRef1}>
            {
                size(invoice)> 0 &&
                <div>
                {invoice.version+"" ==='1' ? <InvoicePrint invoice={invoice} />:<InvoicePrintV2 invoice={invoice}/>}               
                </div>
            }
             
            {
                size(recibo) > 0 &&
                <div>
                  <div>
                    {
                      size(invoice)> 0 &&
                      <div className="page-break">
                 
                      </div>
                    }
                  </div>
                { dataPrint?.invoice === "no" &&  <div className="">
                  <Print data={recibo}/>
                  </div>
                  }
                <div className="page-break">
                 
                 </div>
                  {
                    recibo.recibo.account_id &&<div>
                     <ComprobanteInicial data={recibo} />
                      </div>
                  }
                </div>
            }
        </div>        
           
      </div>
    </div>
  );
};
export default PrintConfig;
