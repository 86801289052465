import API from "../axios/api";
import APIpublic from "../axios/apiPublic";

export const login = async (credentials) => {
  const csrf = await API.get("sanctum/csrf-cookie");
  const { data } = await API.post("api/login", credentials);
  return data;
};
export const logOut = async (credential) => {
  const { data } = await APIpublic.post("api/logout", credential);
  return data;
};
export const rolePermissions = async () => {
  const { data } = await API.get("api/role-permissions");
  return data;
};
