import API from "../axios/api"

export const addStock = async (val)=>{
    const {data} = await API.post('api/stock/add',val)
    return data
}
export const addTransferStock = async (val)=>{
    const {data} = await API.post('api/stock/add-transfer',val)
    return data
}
export const addTransferStockReturn = async (val)=>{
    const {data} = await API.post('api/stock/add-transfer-return',val)
    return data
}
export const getPrekardex = async (val)=>{
    const {data} = await API.post('api/stock/prekardex',val)
    return data
}
export const getKardex = async (val)=>{
    const {data} = await API.post('api/stock/kardex',val)
    return data
}
export const getKardexPro = async (val)=>{
    const {data} = await API.post('api/stock/kardexpro',val)
    return data
}

export const getLowStock = async (page)=>{
    const {data} = await API.get('api/low-stock?page='+page)
    return data
}
export const getLowStockSearch = async (val,page)=>{
    const {data} = await API.post('api/low-stock/search?page='+page,val)
    return data
}

export const getHighStock = async ()=>{
    const {data} = await API.get('api/product/stock')
    return data
}

export const getItemsFilter = async (values,page)=>{
    const {data} = await API.post('api/item-filter/list?page='+page,values)
    return data;
}
export const getItemsFilterLow = async (values,page)=>{
    const {data} = await API.post('api/item-filter/list-low?page='+page,values)
    return data;
}
export const dowloadExcelLowItems = async (val)=>{
    const {data} = await API.post('api/item-filter/list-low/excel',val,{responseType:'blob'})
    return data
}
export const getItemsStoreFilter = async (values,page)=>{
    const {data} = await API.post('api/item-filter/storelist?page='+page,values)
    return data;
}
export const getItemsStoreFilterAll = async (values,page)=>{
    const {data} = await API.post('api/item-filter/storelist-stock?page='+page,values)
    return data;
}
export const getItemsStoreFilterAllSala = async (values)=>{
    const {data} = await API.post('api/item-filter/storelist-stock-all',values)
    return data;
}
export const getItemsDiscount = async (values)=>{
    const {data} = await API.post('api/item-filter/storelist-stock-filter',values)
    return data;
}
export const discountAdd = async (values)=>{
    const {data} = await API.post('api/discount/add',values)
    return data;
}
export const discountAllList = async ()=>{
    const {data} = await API.get('api/discount/list')
    return data;
}
export const storeStockAdd = async (values)=>{
    const {data} = await API.post('api/store/stockadd',values)
    return data;
}
export const generarParte = async (values)=>{
    const {data} = await API.post('api/parte/generar',values)
    return data;
}
export const stockAddQty = async (values)=>{
    const {data} = await API.post('api/stock/stockadd',values)
    return data;
}
export const stockSalaAll = async (page)=>{
    const {data} = await API.get('api/stock/sala?page=',page)
    return data;
}

