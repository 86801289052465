import Logo from "../../../components/Logo"


const Encabezados = ({data=null,title,subtitle,branch,company,nit,nro=null}) => {
  return (
    <div>
         <div className='inline-flex w-full justify-between items-center'>
        <div className="logo flex-1">
       <Logo/>
        
        </div>
        <div className="flex-1">
        <h4  className="text-center text-red-500 uppercase">{title}</h4>
        <h3 className="text-center font-semibold uppercase">{subtitle}</h3>
    </div>
        <div className="flex-1 " >
            <table style={{ float:'right' }}>
               <tbody>
                <tr>
                    <td colSpan={2} className='font-semibold uppercase text-xs'>
                        {company}
                    </td>
                </tr>
               <tr>
                    <td className='font-semibold uppercase text-xs'>NIT:</td>
                    <td>{nit}</td>
                </tr>
              {nro&&  <tr >
                    <td className='font-semibold uppercase text-xs'>No:</td>
                    <td>{nro}</td>
                </tr>}
                <tr>
                    <td colspan="2" className='font-semibold uppercase text-xs'>{branch}</td>
                </tr>
               </tbody>
            </table>
        </div>
    </div>   
    
    </div>
  )
}
export default Encabezados