import API from "../axios/api"

export const postApiService = async (route,val)=>{
    const {data} = await API.post('api/'+route,val)    
    return data
}
export const listApiService = async (route)=>{
    const {data} = await API.get('api/'+route)    
    return data
}
