import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { searchProductProvider } from '../services/product';

export default function AutocompleteProduct({provider_id,setProduct,error, helperText}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    ( () => {      
      if (active) {
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);
  const handleChange =(event)=>{
    setOptions([]);
      searchProductProvider({search:event.target.value,provider_id: provider_id}).then(res=>{          
        setOptions(res);
      })
    
  }
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete      
      fullWidth
      sx={{ my: 1 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name +" | "+ option.presentation +" | "+ option.unit }
      filterOptions={(x)=>{return x}}
      options={options}
      loading={loading}
      onChange={(event, value) => {
        setProduct(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Buscar producto"
          onChange={handleChange}
          error={error}
            helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
