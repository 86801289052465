import API from "../axios/api"

export const listProducts = async (page=null,id)=>{
    const {data} = await API.post('api/product/list?page='+page,{id:id})
    return data
}
export const searchProduct = async (val,page=null)=>{    
    const {data} = await API.post('api/product/search?page='+page,val)
    return data
}
export const searchProductBon = async (val)=>{    
    const {data} = await API.post('api/product/search/barcode-bon',val)
    return data
}
export const searchProductProvider = async (val)=>{    
    const {data} = await API.post('api/product/search-prod',val)
    return data
}
export const deleteProduct = async (val)=>{    
    const {data} = await API.post('api/product/delete',val)
    return data
}
export const deleteImageProduct = async (val)=>{    
    const {data} = await API.post('api/file/delete',val)
    return data
}
export const setProduct = async (val)=>{    
    const {data} = await API.post('api/product/create',val)
    return data
}
export const getProduct = async (val)=>{    
    const {data} = await API.post('api/product/get',val)
    return data
}
export const setPrice = async (val)=>{    
    const {data} = await API.post('api/product/create/price',val)
    return data
}
export const uploadImage= async (val)=>{
    const {data} = await API.post('api/product/upload-image',val,{headers: {
        'content-type': 'multipart/form-data',
      }},)
    return data
}
export const dowloadItemsExcel = async ()=>{
    const {data} = await API.get('api/product-download',{responseType:'blob'})
    return data
}
export const deletePriceItem = async (val)=>{
    const {data} = await API.post('api/product/price-delete',val)
    return data
}
export const uploadItemsExcel= async (val)=>{
    const {data} = await API.post('api/product-upload',val,{headers: {
        'content-type': 'multipart/form-data',
    }},)
    return data
}

export const getItemSubclass = async (val)=>{
    const {data} = await API.post('api/item/list',val)
    return data
}

export const itemConfig = async (val,page)=>{
    const {data} = await API.post('api/item/config?page='+page,val)
    return data
}
export const setObjectives = async (val)=>{
    const {data} = await API.post('api/objective/create',val)
    return data
}
export const setComisionReg = async (val)=>{
    const {data} = await API.post('api/comision/create',val)
    return data
}
export const allProducts = async (val)=>{
    const {data} = await API.post('api/item/all',val)
    return data
}
