import { Divider } from "@mui/material";
import { size } from "lodash";
import { useState } from "react";
import { useEffect, useMemo } from "react";
import QRCode from "react-qr-code";
import { getDocumentPrint } from "../../services/report";
import { dateConfig } from "../../utils/dateconfig";
import { fNumber } from "../../utils/formatNumber";
import { numeroALetras } from "../../utils/NumeroALetras";

const InvoicePrint = ({ invoice, doc = {}, id = {} }) => {
  let head1 = invoice?.factura.cabecera;
  let detail1 = invoice?.factura.detalle?.descripcion
    ? [invoice.factura.detalle]
    : invoice?.factura.detalle;
  let status1 = invoice?.status;
  const [head, setHead] = useState(head1);
  const [detail, setDetail] = useState(detail1);
  const [status, setStatus] = useState(status1);
  const codigo = head?.cuf;
  const primeraParte = codigo?.substring(0, 34);
  const segundaParte = codigo?.substring(34);
  useEffect(() => {
    if (size(doc) > 0) {
      getDocumentPrint({ doc: doc, id: id }).then((res) => {
        setHead(res.cabecera);
        setStatus(res.status || "");
        if (res.detalle[0]) {
          setDetail(res.detalle);
        } else {
          setDetail([res.detalle]);
        }
      });
    }
  }, []);

  return (
    <div>
      {size(detail) > 0 && (
        <div>
          <div className="text-center font-bold" style={{ fontSize: "0.6rem" }}>
            <div className="text-center font-bold">FACTURA</div>
            <p className="font-semibold">CON DERECHO A CRÉDITO FISCAL</p>

            <div className="text-center">
            <p>{head.razonSocialEmisor}</p>
            <p>
              {head.codigoSucursal === "0"
                ? "Casa Matriz"
                : "Sucursal " + head.codigoSucursal}
            </p>
            <p>No. Punto de Venta {head.codigoPuntoVenta}</p>
            <p>
              DIRECCION: <span>{head.direccion}</span>
            </p>
            <p>
              TELEFONO: <span>{head.telefono}</span>
            </p>
            <p>
              <span>{head.ciudad||'LA PAZ'}</span>
            </p>

            </div>

            <div className="text-center text-xs font-bold mb-1">
              ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            </div>
            <p>
              <span className="font-bold">NIT: </span>
              {head.nitEmisor}
            </p>
            <p>
              <span className="font-bold">FACTURA No: </span>
              {head.numeroFactura}
            </p>
            <p>CÓD AUTORIZACIÓN: </p>
            <p>{primeraParte}</p>
            <p>{segundaParte}</p>
          </div>
          <Divider />
          <div style={{ fontSize: "0.6rem" }}>
            <p>
              <span className="font-semibold">NOMBRE/RAZÓN SOCIAL: </span>{" "}
              {head.nombreRazonSocial}{" "}
            </p>
            <p>
              <span className="font-semibold">NIT/CI/CEX: </span>{" "}
              {head.numeroDocumento}{" "}
            </p>
            <p>
              <span className="font-semibold">COD. CLIENTE: </span>{" "}
              {head.codigoCliente}{" "}
            </p>
            <p>
              <span className="font-semibold">FECHA DE EMISION: </span>{" "}
              {dateConfig(head.fechaEmision)}{" "}
            </p>
          </div>
          <Divider />
          <div className="text-xs" style={{ fontSize: "0.6rem" }}>           
              <table>
                <thead>
                  <tr>
                    <th>CANT.</th>
                    <th>P.UNIT.</th>
                    <th>DETALLE</th>
                    <th>DESCT.</th>
                    <th>SUBTOTAL</th>
                  </tr>
                </thead>
                <tbody>
                {size(detail) > 0 &&
              detail.map((row, key) => (
               <tr key={key}>
                  <td>{fNumber(row.cantidad)}</td>
                  <td>  {fNumber(
                        parseFloat(row.precioUnitario) * parseFloat(row.cantidad)
                      )}</td>
                  <td>{ row.descripcion}</td>
                  <td>{ row.montoDescuento}</td>
                  <td> {fNumber(
                        (parseFloat(row.precioUnitario) * parseFloat(row.cantidad)) - parseFloat(row.montoDescuento)
                      )}</td>
               </tr>                
                ))}
                </tbody>
              </table>
                <Divider />
            <div
              className="w-full grid grid-cols-2"
              style={{ fontSize: "0.6rem" }}
            >
              <p className="text-right">SUTOTAL Bs</p>
              <p className="text-right">
                {fNumber(
                  parseFloat(head.montoTotal) +
                    parseFloat(head.descuentoAdicional)
                )}
              </p>
              <p className="text-right">DESCUENTO Bs</p>
              <p className="text-right">{fNumber(head.descuentoAdicional)}</p>
              <p className="text-right">
                TOTAL Bs. <span></span>
              </p>
              <p className="text-right">
                {fNumber(parseFloat(head.montoTotal))}
              </p>
            </div>
            <div>Son: {numeroALetras(parseFloat(head.montoTotal))}</div>
            <Divider />
            <p
              className="font-semibold text-xs text-center"
              style={{ fontSize: "0.6rem" }}
            >
              ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS, EL USO ILÍCITO
              SERÁ SANCIONADO PENALMENTE DE ACUERDO A LEY.
            </p>
            <p
              className="font-semibold text-center"
              style={{ fontSize: "0.5rem" }}
            >
              Ley N° 453: El proveedor de productos debe habilitar medios e
              instrumentos para efectuar consultas y reclamaciones.
            </p>
            <p
              className="font-semibold text-center"
              style={{ fontSize: "0.6rem" }}
            >
              {status === "VALIDADA"
                ? "Este documento es la Representación Gráfica de un Documento Fiscal Digital emitido en una modalidad de facturación en línea"
                : "Este documento es la Representación Gráfica de un Documento Fiscal Digital emitido fuera de línea, verifique su envío con su proveedor o en la página web www.impuestos.gob.bo"}
            </p>

            <div>
              <QRCode
                className="mx-auto"
                size={100}
                value={
                  "https://siat.impuestos.gob.bo/consulta/QR?nit=" +
                  head.nitEmisor +
                  "&cuf=" +
                  head.cuf +
                  "&numero=" +
                  head.numeroFactura +
                  "&t=1"
                }
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default InvoicePrint;
