import { Button, Paper } from "@mui/material"
import Pagev1 from "../../layout/Pagev1"
import { useEffect, useState } from "react"
import { listReclamos } from "../../services/utility"
import { dateConfig } from "../../utils/dateconfig"
import ModalComponent from "../../components/ModalComponent"
import Formulario from "./Formulario"
import { downloadDocument } from "../../services/document"

const Reclamos  = ()=>{
    const [data,setData] = useState([])

    const loadData=()=>{
        listReclamos().then(res=>{
            setData(res)
        })
    }

    const downloadResp=(id)=>{
        downloadDocument({id:id}).then(res=>{        
          const url =  window.URL.createObjectURL(res)
          const link = document.createElement('a')
          link.href =url
          link.setAttribute('download',"respaldo")
          document.body.appendChild(link)
          link.click();
        })
       }


    useEffect(()=>{
        loadData()
    },[])
    return(
        <Pagev1 title="Ticket departamento de sistemas">
            <Paper className="p-3">
                <div className="py-3">
                    <ModalComponent title={"crear ticket"} btn={"Nuevo Ticket"} reload={loadData}>
                        <Formulario />
                    </ModalComponent>
                </div>
                <table className="table-print">
                    <thead>
                        <th>usuario</th>
                        <th>almacen/sala</th>
                        <th>motivo</th>
                        <th>descripcion</th>
                        <th>respaldo</th>
                        <th>Respuesta</th>
                        <th>fecha</th>
                        <th>estado</th>
                    </thead>
                    <tbody>
                        {
                            data.map((val,key)=>
                            <tr key={key}>
                                <td>{val.user.name}</td>
                                <td>{val.store.name}</td>
                                <td>{val.motivo}</td>
                                <td>{val.descripcion}</td>
                                <td>
                                    <Button onClick={()=>downloadResp(val.file.id)} size="small">respaldo</Button>
                                </td>
                                <td>{val.observacion}</td>
                                <td>{dateConfig(val.created_at)}</td>
                                <td>{parseFloat(val.revision)===1?"REVISADO":"PENDIENTE"}</td>
                            </tr>
                            )
                        }
                    </tbody>
                </table>
            </Paper>
        </Pagev1>
    )
}

export default Reclamos