import { Button, Divider, IconButton, Paper, Tooltip } from "@mui/material"
import { size } from "lodash"
import { useRef, useState } from "react"
import { useEffect } from "react"
import Page from "../../layout/Pagev1"
import { getStoreList } from "../../services/transfer"
import { dateConfig } from "../../utils/dateconfig"
import { fInvoiceNumber } from "../../utils/formatNumber"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ModalTransferCart from "../Transfer/ModalTransferCart"
import PrintTransfer from "../Transfer/PrintTransfer"
import ClearIcon from "@mui/icons-material/Clear";
import { useReactToPrint } from "react-to-print"
import { storeStockAdd } from "../../services/stock"
import Swal from "sweetalert2"
const ReceiptTransfer = () => {
    const componentRef = useRef();
    const [data,setData]= useState([])
    const [printData,setPrintData]= useState({})
    const getData=()=>{
        getStoreList().then(res=>{
            setData(res);
        })
    }
    useEffect(()=>{
        getData()
    },[])
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });
      const setStoreStock=()=>{
        
        storeStockAdd({transfer_id: printData.id}).then(res=>{
          getData();
          setPrintData({})
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se asigno correctamente a su stock',
            showConfirmButton: false,
            timer: 1500
          })
        })
      }
  return (
    <Page appBar={true} title="Traspasos sin asignar a mi stock">
        
    <Paper className="p-3">
    {   size(printData) >0 ? 
            <div  ref={componentRef}>
                <div className="w-full inline-flex gap-2 justify-end no-print">
                <Button
                  variant="contained"
                  color="success"
                  type="button"
                  onClick={() => setStoreStock()}                  
                  sx={{ my: 1 }}
                >
                  Registrar Ingreso a mi stock
                </Button>
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => handlePrint()}                  
                  sx={{ my: 1 }}
                >
                  Imprimir
                </Button>
                <Tooltip title="cerrar vista" followCursor>
                <IconButton
                  className="float-right no-print"
                  onClick={() => {
                    setPrintData({})
                  }}
                >
                  <ClearIcon />
                </IconButton>
				</Tooltip>
                </div>
                <Divider className="no-print"/>
                <PrintTransfer data={printData} />
            </div>
        :
        <table className="table-print">
            <thead>
                <tr>
                    <th>fecha</th>
                    <th>Traspaso No</th>
                    <th>Almacen Origen</th>
                    <th>Responsable</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    size(data)>0 && data.map((row,key)=>
                    <tr key={key}>
                        <td>{dateConfig(row.created_at)}</td>
                        <td>{fInvoiceNumber(row.voucher_number)}</td>
                        <td>{row.store.name}</td>
                        <td>{row.attendant.name}</td>
                        <td>
                           <IconButton color="primary" onClick={()=>setPrintData(row)}>
                            <RemoveRedEyeIcon/>
                           </IconButton>
                        </td>
                    </tr>
                    )
                }
            </tbody>
        </table>
    }
    </Paper>
  </Page>
  )
}
export default ReceiptTransfer