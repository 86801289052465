import { Paper } from "@mui/material"
import Page from "../../layout/Pagev1"

const ReportTransfer = () => {
  return (
    <Page appBar={true} title="Ingreso de Productos por traspaso">
      <Paper className="p-3">
          
      </Paper>
    </Page>
  )
}
export default ReportTransfer