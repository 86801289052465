import { Button, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Paper, Select, Tooltip } from "@mui/material"
import Pagev1 from "../../../layout/Pagev1"
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useFormik } from "formik";
import { find, size } from "lodash";
import { postApiService } from "../../../services/crudServices";
import ModalComponent from "../../../components/ModalComponent";
import FormularioPlanilla from "./FormularioPlanilla";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { Link } from "react-router-dom";
import PlanillaMensual from "./PlanillaMensual";
const Planilla = ()=>{
    const [data,setData] = useState([])
  const [stores, setStores] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const userData = useSelector((state) => state.userData);    
    const componentPrint = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentPrint.current,
      });
    const formik = useFormik({
        initialValues:{
            company_id:"all",
            z_company_branch_id:"all",
            store_id:"all",
        },
        onSubmit:(values)=>{
           postApiService("rrhh/planilla",values).then(res=>{
            setData(res)
           })
        }
    })
  

    const groupedData = userData.user.companies.map(company => {
        const branches = company.branches.reduce((acc, branch) => {
            const existingBranch = acc.find(b => b.sucursal === branch.sucursal);
            if (existingBranch) {
                existingBranch.store.push(branch.store);
            } else {
                acc.push({
                    id:branch.id,
                    sucursal: branch.sucursal,
                    sucursal_code: branch.sucursal_code,
                    direction: branch.direction,
                    city: branch.city,
                    phone: branch.phone,
                    store: [branch.store]
                });
            }
            return acc;
        }, []);
        return {
            ...company,
            branches: branches
        };
      });
      const handleChangeBranch = (e) => {    
        // setTargetStore("all")
         const stores = find(branchOffices,o=>{
             return o.id === e.target.value
         })
         formik.setFieldValue('z_company_branch_id',e.target.value)
         setStores(stores?.store||[])
     
       };
       const handleChangeCompany = (e) => {
        formik.setFieldValue("company_id", e.target.value);
        setData([])
        const branches = find(groupedData,o=>{
          return o.id === e.target.value
      })
        setBranchOffices(branches?.branches||[]);
      };
    return(
        <Pagev1 title="Planillas de Sueldo">
              <Paper className="p-3">
           <div className="md:inline-flex w-full gap-2 justify-between">
           <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="md:grid grid-cols-4 gap-2">

                  <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={formik.values.company_id}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.company_id && Boolean(formik.errors.company_id)
              }
            >
              <MenuItem value="all">Todas</MenuItem>
              {groupedData.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.company_id && formik.errors.company_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="z_company_branch_id">Sucursal</InputLabel>
            <Select
              labelId="empresa"
              id="z_company_branch_id"
              disabled={size(branchOffices) === 0}
              name="z_company_branch_id"
              label="Sucursal"
              value={formik.values.z_company_branch_id}
              onChange={handleChangeBranch}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.z_company_branch_id &&
                Boolean(formik.errors.z_company_branch_id)
              }
            >              
              <MenuItem value="all">Todas</MenuItem>
              {branchOffices.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.sucursal}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.z_company_branch_id &&
                formik.errors.z_company_branch_id}
            </FormHelperText>
          </FormControl>
          <FormControl  fullWidth>
          <InputLabel id="store_id">Almacen / Sala</InputLabel>
          <Select
            labelId="store_id"
            id="store_id"
            name="store_id"
            value={formik.values.store_id}
            label="Almacen / Sala"
            size="small"
            sx={{ my: 1 }}
            onChange={formik.handleChange}
          >
              <MenuItem value="all">Todas</MenuItem>
          
            {size(stores)>0 &&
            stores.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText color="error">
              {formik.touched.store_id &&
                formik.errors.store_id}
            </FormHelperText>
        </FormControl>


                <div className="mt-2">
                <Button variant="contained" type="submit" size="small" >Generar</Button>
                </div>
                
                </div>
            </form>
            <div className="md:inline-flex w-full gap-2">
            <div className="mt-2">
                <Button variant="contained" type="button" size="small" onClick={handlePrint} >Imprimir</Button>
                </div>
            <div className="mt-2">
                <ModalComponent btn="Nueva Planilla" title="Planilla" variant="contained" reload={formik.handleSubmit} >
                    <FormularioPlanilla />
                </ModalComponent>
                </div>
              
            </div>
           </div>
        </Paper>
        <Paper className="p-3 mt-3">
        <div ref={componentPrint}>
                <p className="font-xl text-center font-bold uppercase">Planilla de Sueldos</p>
                <p className="font-xl text-center font-bold uppercase">{formik.values.company_id === "all"?"Todas las Empresas":data[0].company?.name}</p>
                    <table className="table-print">
                    <thead>
                        <tr>                            
                            <th>#</th>
                            <th>Planilla</th>
                            <th>Empresa</th>
                            <th>sucursal</th>
                            <th>almacen/sala</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((row,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{row.name}</td>
                                <td>{row.company.name}</td>
                                <td>{row.z_company_branch.sucursal}</td>
                                <td>{row.store.name}</td>
                                <td>
                                  <ModalComponent title="Planilla" btn={<Tooltip title="Ver Planillas mesuales">                                  
                                    <Inventory2Icon color="success"/>                                  
                                  </Tooltip>}>
                                    <PlanillaMensual info={row} planilla={row.id} />
                                  </ModalComponent>
                                  
                                </td>
                            </tr>
                            )
                        }
                    </tbody>
                    </table>
                    </div>
        </Paper>
        </Pagev1>
    )
}
export default Planilla