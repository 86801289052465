import API from "../axios/api"

export const listProvider = async (page=null)=>{
    const {data} = await API.get('api/provider/list?page='+page)
    return data
}
export const getProvider = async (id)=>{
    const {data} = await API.get('api/provider/'+id)
    return data
}
export const searchProvider = async (val,page=null)=>{    
    const {data} = await API.post('api/provider/search?page='+page,val)
    return data
}
export const searchProviderNoPaginate = async (val)=>{    
    const {data} = await API.post('api/provider/search-provider',val)
    return data
}
export const deleteProvider = async (val)=>{    
    const {data} = await API.post('api/provider/delete',val)
    return data
}
export const setProvider = async (val)=>{    
    const {data} = await API.post('api/provider/create',val)
    return data
}
export const setProviderInfo = async (val)=>{    
    const {data} = await API.post('api/provider/info/create',val)
    return data
}
export const setProviderBank = async (val)=>{    
    const {data} = await API.post('api/bank/create',val)
    return data
}
export const delProviderBank = async (val)=>{    
    const {data} = await API.post('api/bank/delete',val)
    return data
}
export const getProviderInfo = async (val)=>{    
    const {data} = await API.post('api/provider/info/getinfo',val)
    return data
}
export const dowloadProvidersExcel = async ()=>{
    const {data} = await API.get('api/provider-download',{responseType:'blob'})
    return data
}
export const dowloadExcel = async (val)=>{
    const {data} = await API.post('api/report/download',val,{responseType:'blob'})
    return data
}
export const dowloadExcelReceiptOrder = async (val)=>{
    const {data} = await API.post('api/report/download-order',val,{responseType:'blob'})
    return data
}
export const dowloadReportSalesExcel = async (val)=>{
    const {data} = await API.post('api/report/date/download',val,{responseType:'blob'})
    return data
}
export const dowloadReportSalesExcelDet = async (val)=>{
    const {data} = await API.post('api/export/report/date',val,{responseType:'blob'})
    return data
}
export const uploadProvider= async (val)=>{
    const {data} = await API.post('api/provider-upload',val,{headers: {
        'content-type': 'multipart/form-data',
      }},)
    return data
}
