export const documentoIdentidad = [
    {
        codigoClasificador: "1",
        descripcion: "CI - CEDULA DE IDENTIDAD"
    },
    {
        codigoClasificador: "2",
        descripcion: "CEX - CEDULA DE IDENTIDAD DE EXTRANJERO"
    },
    {
        codigoClasificador: "3",
        descripcion: "PAS - PASAPORTE"
    },
    {
        codigoClasificador: "4",
        descripcion: "OD - OTRO DOCUMENTO DE IDENTIDAD"
    },
    {
        codigoClasificador: "5",
        descripcion: "NIT - NÚMERO DE IDENTIFICACIÓN TRIBUTARIA"
    }
]

export const leyendas=[
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: Puedes acceder a la reclamación cuando tus derechos han sido vulnerados."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: El proveedor debe brindar atención sin discriminación, con respeto, calidez y cordialidad a los usuarios y consumidores."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: El proveedor de productos debe habilitar medios e instrumentos para efectuar consultas y reclamaciones."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: Los contratos de adhesión deben redactarse en términos claros, comprensibles, legibles y deben informar todas las facilidades y limitaciones."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: Si se te ha vulnerado algún derecho puedes exigir la reposición o restauración."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: Tienes derecho a recibir información que te proteja de la publicidad engañosa."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: Se debe promover el consumo solidario, justo, en armonía con la Madre Tierra y precautelando el hábitat, en el marco del Vivir Bien."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: El proveedor deberá dar cumplimiento a las condiciones ofertadas."
    },
    {
        codigoActividad: "202300",
        descripcionLeyenda: "Ley N° 453: Están prohibidas las prácticas comerciales abusivas, tienes derecho a denunciarlas."
    }
]

export const tipoEmision=[
    {
        codigoClasificador: "1",
        descripcion: "EN LINEA"
    },
    {
        codigoClasificador: "2",
        descripcion: "FUERA DE LINEA"
    },
    {
        codigoClasificador: "3",
        descripcion: "MASIVO"
    }
]

export const productsServices=[
    
        {
            codigoActividad: "202300",
            codigoProducto: "35310",
            descripcionProducto: "AGENTES ORGÁNICOS TENSOACTIVOS, EXCEPTO JABÓN",
            nandina: [
                "3402.11.10.00",
                "3402.11.90.00",
                "3402.12.10.00",
                "3402.12.90.00",
                "3402.13.10.00",
                "3402.13.90.00",
                "3402.19.10.00",
                "3402.19.90.00"
            ]
        },
        {
            codigoActividad: "202300",
            codigoProducto: "35321",
            descripcionProducto: "JABÓN, PRODUCTOS ORGÁNICOS TENSOACTIVOS Y PREPARADOS PARA USAR COMO JABÓN; PAPEL, GUATA, FIELTRO Y MATERIALES TEXTILES NO TEJIDOS, IMPREGNADOS, REVESTIDOS O CUBIERTOS CON JABÓN O DETERGENTE",
            nandina: [
                "3401.11.00.00",
                "3401.19.10.00",
                "3401.19.90.00",
                "3401.20.00.00",
                "3401.30.00.00"
            ]
        },
        {
            codigoActividad: "202300",
            codigoProducto: "35322",
            descripcionProducto: "DETERGENTES Y PREPARACIONES PARA LAVAR",
            nandina: [
                "3402.20.00.00",
                "3402.90.10.00",
                "3402.90.91.00",
                "3402.90.99.00"
            ]
        },
        {
            codigoActividad: "202300",
            codigoProducto: "88426",
            descripcionProducto: "SERVICIOS DE FABRICACIÓN DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR Y PULIR; PERFUMES Y PREPARADOS DE TOCADOR"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "99100",
            descripcionProducto: "OTROS PRODUCTOS O SERVICIOS ALCANZADOS POR EL IVA"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "353109",
            descripcionProducto: "AGENTES ORGÁNICOS TENSOACTIVOS, EXCEPTO JABÓN IMPORTADO"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "353219",
            descripcionProducto: "JABÓN, PRODUCTOS ORGÁNICOS TENSOACTIVOS Y PREPARADOS PARA USAR COMO JABÓN; PAPEL, GUATA, FIELTRO Y MATERIALES TEXTILES NO TEJIDOS, IMPREGNADOS, REVESTIDOS O CUBIERTOS CON JABÓN O DETERGENTE IMPORTADO"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "353229",
            descripcionProducto: "DETERGENTES Y PREPARACIONES PARA LAVAR IMPORTADO"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "884269",
            descripcionProducto: "SERVICIOS DE FABRICACIÓN DE JABONES Y DETERGENTES, PREPARADOS PARA LIMPIAR Y PULIR; PERFUMES Y PREPARADOS DE TOCADOR IMPORTADO"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "991009",
            descripcionProducto: "OTROS PRODUCTOS O SERVICIOS ALCANZADOS POR EL IVA IMPORTADO"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "35323",
            descripcionProducto: "PERFUMES Y PREPARACIONES DE TOCADOR",
            nandina: [
                "3303.00.00.00",
                "3304.10.00.00",
                "3304.20.00.00",
                "3304.30.00.00",
                "3304.91.00.00",
                "3304.99.00.00",
                "3305.10.00.00",
                "3305.20.00.00",
                "3305.30.00.00",
                "3305.90.00.00",
                "3306.10.00.00",
                "3306.20.00.00",
                "3306.90.00.00",
                "3307.10.00.00",
                "3307.20.00.00",
                "3307.30.00.00",
                "3307.90.10.00",
                "3307.90.90.00"
            ]
        },
        {
            codigoActividad: "202300",
            codigoProducto: "35332",
            descripcionProducto: "CERAS ARTIFICIALES Y CERAS PREPARADAS",
            nandina: [
                "3404.20.00.00",
                "3404.90.30.00",
                "3404.90.40.00",
                "3404.90.90.00"
            ]
        },
        {
            codigoActividad: "202300",
            codigoProducto: "39240",
            descripcionProducto: "DESPERDICIOS Y DESECHOS DE PAPEL O CARTÓN",
            nandina: [
                "4707.10.00.00",
                "4707.20.00.00",
                "4707.30.00.00",
                "4707.90.00.00"
            ]
        },
        {
            codigoActividad: "202300",
            codigoProducto: "39270",
            descripcionProducto: "DESPERDICIOS, RECORTES Y DESECHOS DE MATERIALES PLÁSTICOS",
            nandina: [
                "3915.10.00.00",
                "3915.20.00.00",
                "3915.30.00.00",
                "3915.90.00.00"
            ]
        },
        {
            codigoActividad: "202300",
            codigoProducto: "39281",
            descripcionProducto: "PELLETS DE MADERA",
            nandina: "4401.31.00.00"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "353239",
            descripcionProducto: "PERFUMES Y PREPARACIONES DE TOCADOR IMPORTADOS"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "353109",
            descripcionProducto: "AGENTES ORGÁNICOS TENSOACTIVOS, EXCEPTO JABÓN IMPORTADOS"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "353329",
            descripcionProducto: "CERAS ARTIFICIALES Y CERAS PREPARADAS IMPORTADOS"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "392409",
            descripcionProducto: "DESPERDICIOS Y DESECHOS DE PAPEL O CARTÓN IMPORTADOS"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "392709",
            descripcionProducto: "DESPERDICIOS, RECORTES Y DESECHOS DE MATERIALES PLÁSTICOS IMPORTADOS"
        },
        {
            codigoActividad: "202300",
            codigoProducto: "392819",
            descripcionProducto: "PELLETS DE MADERA IMPORTADOS"
        }
    
]

export const unitMeasure=[
    {
        codigoClasificador: "1",
        descripcion: "BOBINAS"
    },
    {
        codigoClasificador: "2",
        descripcion: "BALDE"
    },
    {
        codigoClasificador: "3",
        descripcion: "BARRILES"
    },
    {
        codigoClasificador: "4",
        descripcion: "BOLSA"
    },
    {
        codigoClasificador: "5",
        descripcion: "BOTELLAS"
    },
    {
        codigoClasificador: "6",
        descripcion: "CAJA"
    },
    {
        codigoClasificador: "7",
        descripcion: "CARTONES"
    },
    {
        codigoClasificador: "8",
        descripcion: "CENTIMETRO CUADRADO"
    },
    {
        codigoClasificador: "9",
        descripcion: "CENTIMETRO CUBICO"
    },
    {
        codigoClasificador: "10",
        descripcion: "CENTIMETRO LINEAL"
    },
    {
        codigoClasificador: "11",
        descripcion: "CIENTO DE UNIDADES"
    },
    {
        codigoClasificador: "12",
        descripcion: "CILINDRO"
    },
    {
        codigoClasificador: "13",
        descripcion: "CONOS"
    },
    {
        codigoClasificador: "14",
        descripcion: "DOCENA"
    },
    {
        codigoClasificador: "15",
        descripcion: "FARDO"
    },
    {
        codigoClasificador: "16",
        descripcion: "GALON INGLES"
    },
    {
        codigoClasificador: "17",
        descripcion: "GRAMO"
    },
    {
        codigoClasificador: "18",
        descripcion: "GRUESA"
    },
    {
        codigoClasificador: "19",
        descripcion: "HECTOLITRO"
    },
    {
        codigoClasificador: "20",
        descripcion: "HOJA"
    },
    {
        codigoClasificador: "21",
        descripcion: "JUEGO"
    },
    {
        codigoClasificador: "22",
        descripcion: "KILOGRAMO"
    },
    {
        codigoClasificador: "23",
        descripcion: "KILOMETRO"
    },
    {
        codigoClasificador: "24",
        descripcion: "KILOVATIO HORA"
    },
    {
        codigoClasificador: "25",
        descripcion: "KIT"
    },
    {
        codigoClasificador: "26",
        descripcion: "LATAS"
    },
    {
        codigoClasificador: "27",
        descripcion: "LIBRAS"
    },
    {
        codigoClasificador: "28",
        descripcion: "LITRO"
    },
    {
        codigoClasificador: "29",
        descripcion: "MEGAWATT HORA"
    },
    {
        codigoClasificador: "30",
        descripcion: "METRO"
    },
    {
        codigoClasificador: "31",
        descripcion: "METRO CUADRADO"
    },
    {
        codigoClasificador: "32",
        descripcion: "METRO CUBICO"
    },
    {
        codigoClasificador: "33",
        descripcion: "MILIGRAMOS"
    },
    {
        codigoClasificador: "34",
        descripcion: "MILILITRO"
    },
    {
        codigoClasificador: "35",
        descripcion: "MILIMETRO"
    },
    {
        codigoClasificador: "36",
        descripcion: "MILIMETRO CUADRADO"
    },
    {
        codigoClasificador: "37",
        descripcion: "MILIMETRO CUBICO"
    },
    {
        codigoClasificador: "38",
        descripcion: "MILLARES"
    },
    {
        codigoClasificador: "39",
        descripcion: "MILLON DE UNIDADES"
    },
    {
        codigoClasificador: "40",
        descripcion: "ONZAS"
    },
    {
        codigoClasificador: "41",
        descripcion: "PALETAS"
    },
    {
        codigoClasificador: "42",
        descripcion: "PAQUETE"
    },
    {
        codigoClasificador: "43",
        descripcion: "PAR"
    },
    {
        codigoClasificador: "44",
        descripcion: "PIES"
    },
    {
        codigoClasificador: "45",
        descripcion: "PIES CUADRADOS"
    },
    {
        codigoClasificador: "46",
        descripcion: "PIES CUBICOS"
    },
    {
        codigoClasificador: "47",
        descripcion: "PIEZAS"
    },
    {
        codigoClasificador: "48",
        descripcion: "PLACAS"
    },
    {
        codigoClasificador: "49",
        descripcion: "PLIEGO"
    },
    {
        codigoClasificador: "50",
        descripcion: "PULGADAS"
    },
    {
        codigoClasificador: "51",
        descripcion: "RESMA"
    },
    {
        codigoClasificador: "52",
        descripcion: "TAMBOR"
    },
    {
        codigoClasificador: "53",
        descripcion: "TONELADA CORTA"
    },
    {
        codigoClasificador: "54",
        descripcion: "TONELADA LARGA"
    },
    {
        codigoClasificador: "55",
        descripcion: "TONELADAS"
    },
    {
        codigoClasificador: "56",
        descripcion: "TUBOS"
    },
    {
        codigoClasificador: "57",
        descripcion: "UNIDAD (BIENES)"
    },
    {
        codigoClasificador: "58",
        descripcion: "UNIDAD (SERVICIOS)"
    },
    {
        codigoClasificador: "59",
        descripcion: "US GALON (3,7843 L)"
    },
    {
        codigoClasificador: "60",
        descripcion: "YARDA"
    },
    {
        codigoClasificador: "61",
        descripcion: "YARDA CUADRADA"
    },
    {
        codigoClasificador: "62",
        descripcion: "OTRO"
    },
    {
        codigoClasificador: "63",
        descripcion: "ONZA TROY"
    },
    {
        codigoClasificador: "64",
        descripcion: "LIBRA FINA"
    },
    {
        codigoClasificador: "65",
        descripcion: "DISPLAY"
    },
    {
        codigoClasificador: "66",
        descripcion: "BULTO"
    },
    {
        codigoClasificador: "67",
        descripcion: "DIAS"
    },
    {
        codigoClasificador: "68",
        descripcion: "MESES"
    },
    {
        codigoClasificador: "69",
        descripcion: "QUINTAL"
    },
    {
        codigoClasificador: "70",
        descripcion: "ROLLO"
    },
    {
        codigoClasificador: "71",
        descripcion: "HORAS"
    },
    {
        codigoClasificador: "72",
        descripcion: "AGUJA"
    },
    {
        codigoClasificador: "73",
        descripcion: "AMPOLLA"
    },
    {
        codigoClasificador: "74",
        descripcion: "BIDÓN"
    },
    {
        codigoClasificador: "75",
        descripcion: "BOLSA"
    },
    {
        codigoClasificador: "76",
        descripcion: "CAPSULA"
    },
    {
        codigoClasificador: "77",
        descripcion: "CARTUCHO"
    },
    {
        codigoClasificador: "78",
        descripcion: "COMPRIMIDO"
    },
    {
        codigoClasificador: "79",
        descripcion: "ESTUCHE"
    },
    {
        codigoClasificador: "80",
        descripcion: "FRASCO"
    },
    {
        codigoClasificador: "81",
        descripcion: "JERINGA"
    },
    {
        codigoClasificador: "82",
        descripcion: "MINI BOTELLA"
    },
    {
        codigoClasificador: "83",
        descripcion: "SACHET"
    },
    {
        codigoClasificador: "84",
        descripcion: "TABLETA"
    },
    {
        codigoClasificador: "85",
        descripcion: "TERMO"
    },
    {
        codigoClasificador: "86",
        descripcion: "TUBO"
    },
    {
        codigoClasificador: "87",
        descripcion: "BARRIL (EEUU) 60 F"
    },
    {
        codigoClasificador: "88",
        descripcion: "BARRIL [42 GALONES(EEUU)]"
    },
    {
        codigoClasificador: "89",
        descripcion: "METRO CUBICO 68F VOL"
    },
    {
        codigoClasificador: "90",
        descripcion: "MIL PIES CUBICOS 14696 PSI"
    },
    {
        codigoClasificador: "91",
        descripcion: "MIL PIES CUBICOS 14696 PSI 68FAH"
    },
    {
        codigoClasificador: "92",
        descripcion: "MILLAR DE PIES CUBICOS (1000 PC)"
    },
    {
        codigoClasificador: "93",
        descripcion: "MILLONES DE PIES CUBICOS (1000000 PC)"
    },
    {
        codigoClasificador: "94",
        descripcion: "MILLONES DE BTU (1000000 BTU)"
    },
    {
        codigoClasificador: "95",
        descripcion: "UNIDAD TERMICA BRITANICA (TI)"
    },
    {
        codigoClasificador: "96",
        descripcion: "POMO"
    },
    {
        codigoClasificador: "97",
        descripcion: "VASO"
    },
    {
        codigoClasificador: "98",
        descripcion: "TETRAPACK"
    },
    {
        codigoClasificador: "99",
        descripcion: "CARTOLA"
    },
    {
        codigoClasificador: "100",
        descripcion: "JABA"
    },
    {
        codigoClasificador: "101",
        descripcion: "YARDA"
    },
    {
        codigoClasificador: "102",
        descripcion: "BANDEJA"
    },
    {
        codigoClasificador: "103",
        descripcion: "TURRIL"
    },
    {
        codigoClasificador: "104",
        descripcion: "BLISTER"
    },
    {
        codigoClasificador: "105",
        descripcion: "TIRA"
    },
    {
        codigoClasificador: "106",
        descripcion: "MEGAWATT"
    },
    {
        codigoClasificador: "107",
        descripcion: "KILOWATT"
    },
    {
        codigoClasificador: "108",
        descripcion: "AMORTIZACION"
    },
    {
        codigoClasificador: "109",
        descripcion: "OVULOS"
    },
    {
        codigoClasificador: "110",
        descripcion: "SUPOSITORIOS"
    },
    {
        codigoClasificador: "111",
        descripcion: "SOBRES"
    },
    {
        codigoClasificador: "112",
        descripcion: "VIAL"
    },
    {
        codigoClasificador: "113",
        descripcion: "HECTAREAS"
    },
    {
        codigoClasificador: "114",
        descripcion: "ARROBA"
    },
    {
        codigoClasificador: "115",
        descripcion: "AEROSOL"
    },
    {
        codigoClasificador: "116",
        descripcion: "BARRA"
    },
    {
        codigoClasificador: "117",
        descripcion: "CONJUNTO"
    },
    {
        codigoClasificador: "118",
        descripcion: "FANEGA"
    },
    {
        codigoClasificador: "119",
        descripcion: "PACK"
    },
    {
        codigoClasificador: "120",
        descripcion: "PIPETA"
    },
    {
        codigoClasificador: "121",
        descripcion: "POTE"
    },
    {
        codigoClasificador: "122",
        descripcion: "PASTILLA"
    },
    {
        codigoClasificador: "123",
        descripcion: "TONELADA METRICA"
    },
    {
        codigoClasificador: "124",
        descripcion: "EQUIPOS"
    }
]

export const clientType=[
    {
        code: "1",
        descripcion: "MERCADOS TRADICIONALES"
    },
    {
        code: "2",
        descripcion: "MICROMERCADOS"
    },
    {
        code: "3",
        descripcion: "GREMIALISTAS"
    },
    {
        code: "4",
        descripcion: "TIENDAS DE BARRIO"
    },
    {
        code: "5",
        descripcion: "ALMACENES"
    },
    {
        code: "6",
        descripcion: "SUPER MERCADOS"
    },
    {
        code: "7",
        descripcion: "HOTELES"
    },
    {
        code: "8",
        descripcion: "EMPRESAS"
    },
    {
        code: "9",
        descripcion: "INSTITUCIONES"
    },
    {
        code: "10",
        descripcion: "GREMIALISTAS"
    },
    {
        code: "1",
        descripcion: "PROVINCIAS Y PUEBLOS"
    }
]
export const chanels=[
    {
        code: "1",
        descripcion: "PRE-VENTAS"
    },
    {
        code: "2",
        descripcion: "INSTITUCIONALES"
    },
    {
        code: "3",
        descripcion: "HORECAS"
    },
    {
        code: "4",
        descripcion: "PROVINCIALES"
    },
    {
        code: "5",
        descripcion: "PUNTO FIJO SALA"
    },
    {
        code: "6",
        descripcion: "VENTA DIGITALES"
    }
]
export const tipoPuntoVenta=[
    {
        codigoClasificador: "1",
        descripcion: "PUNTO VENTA COMISIONISTA"
    },
    {
        codigoClasificador: "2",
        descripcion: "PUNTO VENTA VENTANILLA DE COBRANZA"
    },
    {
        codigoClasificador: "3",
        descripcion: "PUNTO DE VENTA MOVILES"
    },
    {
        codigoClasificador: "4",
        descripcion: "PUNTO DE VENTA YPFB"
    },
    {
        codigoClasificador: "5",
        descripcion: "PUNTO DE VENTA CAJEROS"
    },
    {
        codigoClasificador: "6",
        descripcion: "PUNTO DE VENTA CONJUNTA"
    }
]
