//export const baseURL = "http://192.168.1.7:8000/";
//export const baseURLFACTU = "http://apidemo.factura.com.bo/ahorasoft/v1/";///////
export const baseURL = "https://viabk.samkabolivia.com/";
//export const baseURL = "https://deltabk.samkabolivia.com/";
//export const baseURL = 'https://achumani.samkabolivia.com/';
//export const baseURL = 'https://demoviabk.samkabolivia.com/';
export const companyName = "Jiyawa";
export const companyPhone = "77790840";
export const companyDirection =
  "AVENIDA PANAMERICANA NRO.107 EDIFICIO TORRE EMPRESARIAL PISO 6 DPTO. 2 ZONA VILLA BOLIVAR D";

/*export const companyDirection =
("AVENIDA PANAMERICANA NRO.107 EDIFICIO TORRE EMPRESARIAL PISO 6 DPTO. 2 ZONA VILLA BOLIVAR D");*/
export const API_GOOGLE_MAPS = "AIzaSyBo-zH6F6VFvTh6uaQ1rZinwteCl5XspK8";
export const SOCKET_IO_URL = "https://conta.samkabolivia.com";
