import React, { useState } from 'react';
import Barcode from 'react-barcode';
import { fNumber } from '../../../utils/formatNumber';
import { baseURL } from '../../../config/AppConfig';

const AltaActivo=({data})=> {
    console.log(data)
    const fechaAlta = new Date(data.fecha_alta);
    const yearUpdate = fechaAlta.getFullYear();
    const getCurrentDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Months start from 0
        const year = currentDate.getFullYear();
    
        return { day, month, year };
      };
    
      const {day, month, year } = getCurrentDate();    

    return (
      <div className='w-full h-full '>
            <div className="bg-white text-black p-2  h-full">
              <div className="w-full h-full p-2">
                    <div className="w-full h-fit p-5">
                            <div className="flex flex-col w-full">
                              <div className="flex flex-row justify-between">
                                <div className="left-info w-3/6">
                                      <div className="flex flex-col">
                                        <span> {data.company.name}</span>
                                        <span className='text-xs'> {data.company.direction}</span>

                                        <span> LA PAZ BOLIVIA</span>
                                      </div>
                                </div>
                                <div className="right-info w-3/6 flex flex-row justify-end">
                                      <div className="flex flex-row gap-2 items-center">
                                        <div className="h-8 border-solid border-2 border-black p-2 flex flex-col align-middle justify-center">
                                                    <span>NRO. {data.id} / {yearUpdate} </span>
                                        </div>
                                        <div className="p-2">
                                        <table className="border-collapse">
                                          <thead >
                                            <tr>
                                              <th className="border-2 border-solid border-black " colSpan={"3"}>Fecha</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="border-2 border-solid border-black p-1">{day}</td>
                                              <td className="border-2 border-solid border-black p-1">{month}</td>
                                              <td className="border-2 border-solid border-black p-1">{year}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        </div>
                                      </div>
                                </div>
                              </div>
                              <div className="flex flex-col w-full justify-center gap-2">
                                <div className="w-full title flex flex-col text-center">
                                <h2 className="font-bold"> DOCUMENTO DE ALTA DE ACTIVO FIJO</h2>
                                <h4 className="font-semibold">Oficina Central</h4>
                                </div>
                               
                                <div className="flex flex-col w-full gap-2">
                                  <div className="type flex-row flex justify-center">
                                  <span className="type ">
                                        1- BIENES NUEVOS
                                  </span>
                                  </div>
                                  <div className="table-desc p-2 border-solid border-black border-2 flex flex-row justify-between">
                                      <div className="item-desc flex flex-row justify-start gap-2 ">
                                        <div className="item-detail flex flex-col font-medium">
                                              <span>
                                                UNIDAD:
                                              </span>
                                              <span>
                                                CIUDAD:
                                              </span>
                                              <span>
                                                EDIFICO:
                                              </span>
                                              <span>
                                                OFICINA:
                                              </span>
                                              <span>
                                                RESPONSABLE:
                                              </span>
                                              <span>
                                                RUBRO CONTABLE:
                                              </span>
                                              <span>
                                                TIPO DE BIEN:
                                              </span>
                                              <span>
                                                ESTADO DEL BIEN:
                                              </span>
                                             
                                        </div>
                                        <div className="item-detail flex flex-col">
                                            <span>
                                                {data.unidad.unidad}                                              </span>
                                              <span>
                                                {data.oficina.edificio.ciudad.ciudad}
                                              </span>
                                              <span>
                                              {data.oficina.edificio.edificio}
                                              </span>
                                              <span>
                                              {data.oficina.oficina}
                                              </span>
                                              <span>
                                              {data.responsable.name || " 1"}
                                              </span>
                                              <span>
                                              {data.tipo_bien.rubro_contable.nombre}
                                              </span>
                                              <span>
                                              {data.tipo_bien.tipo_bien}
                                              </span>
                                              <span>
                                              {data.estado_bien}
                                              </span>
                                             
                                        </div>

                                      </div>
                                      <div className="item-photo flex flex-col border-black border-2 border-solid">
                                        <img className="w-48 h-48" src={baseURL+"storage/"+data.file.url} alt={data.file.title} />
                                      </div>
                                  </div>
                                  <div className="table-item w-full">
                                    <table className="border-collapse w-full">
                                          <thead >
                                            <tr>
                                              <th className="border-2 border-solid border-black " >CODIGO</th>
                                              <th className="border-2 border-solid border-black " >CANT</th>
                                              <th className="border-2 border-solid border-black " >DESCRIPCIÓN</th>
                                              <th className="border-2 border-solid border-black " >Valor Bs.</th>
                                              <th className="border-2 border-solid border-black " >Tipo de cambio</th>
                                              <th className="border-2 border-solid border-black " >Valor USD</th>
                                              <th className="border-2 border-solid border-black " >Vida Util Mes</th>

                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="border-2 border-solid border-black p-1">{data.barcode}</td>
                                              <td className="border-2 border-solid border-black p-1">1</td>
                                              <td className="border-2 border-solid border-black p-1">{data.descripcion}</td>
                                              <td className="border-2 border-solid border-black p-1">  {data.valor_inicial}</td>
                                              <td className="border-2 border-solid border-black p-1">   {data.tipo_cambio}</td>
                                              <td className="border-2 border-solid border-black p-1"> { fNumber(parseFloat(data.valor_inicial) / parseFloat(data.tipo_cambio)) }</td>
                                              <td className="border-2 border-solid border-black p-1">{data.valor_recidual}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                  </div>
                                </div>
                                <div className="flex flex-col w-full gap-4">
                                <div className="table-item w-full">
                                    <table className="border-collapse w-full">
                                          <thead >
                                            <tr>
                                              <th className="border-2 border-solid border-black h-24 " > </th>
                                              <th className="border-2 border-solid border-black h-24" > </th>                                         
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="border-2 border-solid border-black p-1">
                                                <div className="flex flex-col text-center">
                                                  <span>NOMBRE DEL EMPLEADO</span>
                                                  <span>CARGO</span>
                                                  <span>Resposable de Activos fijos (acta)</span>

                                                </div>
                                              </td>
                                              <td className="border-2 border-solid border-black p-1">
                                              <div className="flex flex-col text-center">
                                                  <span>NOMBRE DEL EMPLEADO</span>
                                                  <span>CARGO</span>
                                                  <span>Resposable de Activos fijos (acta)</span>

                                                </div>
                                              </td>
                                            
                                            </tr>
                                          </tbody>
                                        </table>
                                  </div>
                                  <div className="table-item w-full">
                                    <table className="border-collapse w-full">
                                          <thead >
                                            <tr>
                                              <th className="border-2 border-solid border-black h-24 " > </th>
                                              <th className="border-2 border-solid border-black h-24" > </th>                                         
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="border-2 border-solid border-black p-1">
                                                <div className="flex flex-col text-center">
                                                  <span>ADMINISTRACIÓN</span>
                                                  <span>Vo. Bo. Gerencia Administrativa</span>

                                                </div>
                                              </td>
                                              <td className="border-2 border-solid border-black p-1">
                                              <div className="flex flex-col text-center">
                                              <span>CONTABILIDAD</span>
                                                  <span>Vo. Bo. Jefe Contabilidad</span>

                                                </div>
                                              </td>
                                            
                                            </tr>
                                          </tbody>
                                        </table>
                                  </div>
                                </div>
                              </div>                            
                            </div>
                    </div>
              </div>
            </div>
      </div>
    )

}

export default AltaActivo