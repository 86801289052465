import React, { lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import Backdrop from "../components/Backdrop";
import { AuthGuard, RoleGuard } from "../guards";
import { PublicRoutes } from "../models/routes";
import BarcodeAll from "../pages/BarcodeAll/BarcodeAll";
import SumasSaldos from "../pages/ContaSol/SumasSaldos";
import Monitor from "../pages/Sala/Monitor";
import Pos from "../pages/Sala/Pos";
import { ReceiptTransfer, ReportTransfer } from "../pages/StoreTransfer";
import { setIsAuth, setNoAuth } from "../redux/features/auth/authSlice";
import { setDataUser } from "../redux/features/config/config";
import { userInfo } from "../services/acount";
import { RoutesWithNotFound } from "../utilities";
import { Activos } from "../pages/Contabilidad/Activos";
import Objectives from "../pages/Objectives/Objectives";
import Reclamos from "../pages/Reclamos/Reclamos";
import Planilla from "../pages/RrHh/Planillas/Planilla";

const PlanillaMensual = lazy(() =>
  import("../pages/RrHh/Planillas/PlanillaMensual")
);
const GifCard = lazy(() => import("../pages/GifCard/GifCard"));
const ParametrosRrhh = lazy(() =>
  import("../pages/RrHh/Parametros/Parametros")
);
const Internacional = lazy(() =>
  import("../pages/Order/Internacional/Internacional")
);
const CajaChica = lazy(() => import("../pages/CashBox/CajaChica/CajaChica"));
const ActivosConfig = lazy(() =>
  import("../pages/Contabilidad/Activos/ActivosConfig")
);
const Personal = lazy(() => import("../pages/RrHh/Personal"));
const RegistroVenta = lazy(() =>
  import("../pages/Contabilidad/RegistroVenta/RegistroVenta")
);
const ClientMap = lazy(() => import("../pages/Client/ClientMap"));
const ProductList = lazy(() => import("../pages/Report/prices/ProductList"));
const BalanceResultado = lazy(() =>
  import("../pages/Contabilidad/BalanceResultado/BalanceResultado")
);
const BalanceComprobacion = lazy(() =>
  import("../pages/Contabilidad/Balance/BalanceComprobacion")
);
const LibroDiario = lazy(() =>
  import("../pages/Contabilidad/Diario/LibroDiario")
);
const Parametros = lazy(() =>
  import("../pages/Contabilidad/Parametros/Parametros")
);
const RecepcionNewProvider = lazy(() =>
  import("../pages/NewOrders/RecepcionNewProvider")
);
const AlertStockStore = lazy(() =>
  import("../sections/stockStatus/AlertStockStore")
);
const MapPage = lazy(() => import("../pages/Maps/MapPage"));
const Seguimiento = lazy(() => import("../pages/Seguimiento/Seguimiento"));
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const NewInvoice = lazy(() => import("../pages/Invoice/NewInvoice"));
const CustomOrders = lazy(() => import("../pages/NewOrders/CustomOrders"));
const DiscountArea = lazy(() => import("../pages/Discount/DiscountArea"));
const PayHistory = lazy(() => import("../pages/DebtsToPay/PayHistory"));
const InventoryMove = lazy(() => import("../pages/Inventory/InventoryMove"));
const AlertStockLow = lazy(() =>
  import("../sections/stockStatus/AlertStockLow")
);
const InventoryCost = lazy(() =>
  import("../pages/Inventory/Cost/InventoryCost")
);
const AdjustmentList = lazy(() => import("../pages/Inventory/AdjustmentList"));
const InventoryAdjustmentReport = lazy(() =>
  import("../pages/Inventory/InventoryAdjustmentReport")
);
const InventoryAdjustment = lazy(() =>
  import("../pages/Inventory/InventoryAdjustment")
);
const PricesList = lazy(() => import("../pages/Report/prices/PricesList"));
const Statistics = lazy(() => import("../pages/Statistics/Statistics"));
const ConsolidatedKardex = lazy(() =>
  import("../pages/Kardex/Consolidated/ConsolidatedKardex")
);
const Bajas = lazy(() => import("../pages/Bajas/Bajas"));
const ActivityLog = lazy(() => import("../pages/ActivityLog/ActivityLog"));
const MyConfigs = lazy(() => import("../pages/MyConfigs/MyConfigs"));
const ReturnReceipt = lazy(() => import("../pages/Movil/ReturnReceipt"));
const RegistrarDevolucion = lazy(() =>
  import("../pages/Movil/RegistrarDevolucion")
);
const Report = lazy(() => import("../pages/DebtsToPay/Report"));
const PaymentHistory = lazy(() => import("../pages/DebtsToPay/PaymentHistory"));
const KardexClient = lazy(() => import("../pages/Client/Kardex"));
const HitorialCotizacion = lazy(() => import("../pages/Cotizacion"));
const Cotizacion = lazy(() => import("../pages/Cotizacion/Cotizacion"));
const ConfigMenu = lazy(() => import("../pages/Config/Menu/ConfigMenu"));
const SalesV1 = lazy(() => import("../pages/Salev1/SalesV1"));
const EstadoFacturador = lazy(() => import("../pages/Factu/EstadoFacturador"));
const RequerimientoDetail = lazy(() =>
  import("../pages/Requerimiento/RequerimientoDetail")
);
const StockSala = lazy(() => import("../pages/Sala/StockSala"));
const ReceiptTransfers = lazy(() =>
  import("../pages/Transfer/ReceiptTransfers")
);
const EstadosFinancieros = lazy(() =>
  import("../pages/ContaSol/EstadosFinancieros")
);
const Historial = lazy(() => import("../pages/Factu/Historial"));
const FactuConfig = lazy(() => import("../pages/Factu/FactuConfig"));
const RequerimientoFilter = lazy(() =>
  import("../pages/Report/itemsFilter/RequerimientoFilter")
);
const LibroMayor = lazy(() => import("../pages/ContaSol/LibroMayor"));
const Offer = lazy(() => import("../pages/PromotionOffer/Offer"));
const Promotion = lazy(() => import("../pages/PromotionOffer/Promotion"));
const DiaryList = lazy(() => import("../pages/ContaSol/DiaryList"));
const DiaryRegister = lazy(() => import("../pages/ContaSol/DiaryRegister"));
const Solicitudes = lazy(() => import("../pages/ContaSol/Solicitudes"));
const NewSol = lazy(() => import("../pages/ContaSol/NewSol"));
const Kpi = lazy(() => import("../pages/Kpi/Kpi"));
const ConfigItem = lazy(() => import("../pages/Products/ConfigItem"));
const StockStore = lazy(() => import("../pages/Sala/StockStore"));
const KardexReport = lazy(() =>
  import("../pages/Kardex/ReportKardex/KardexReport")
);
const Login = lazy(() => import("../pages/Login"));
const Error = lazy(() => import("../pages/Error"));
const StoreStockFilter = lazy(() =>
  import("../pages/Transfer/StoreStockFilter")
);
const BarcodeGenerator = lazy(() =>
  import("../pages/BarcodeGenerator/BarcodeGenerator")
);
const Package = lazy(() => import("../pages/Packages/Package"));
const Home = lazy(() => import("../pages/Home"));
const GeneralReport = lazy(() =>
  import("../pages/Report/generalReports/GeneralReport")
);

const CashBoxList = lazy(() => import("../pages/CashBox/CashBoxList"));
const Providers = lazy(() => import("../pages/Providers"));
const Products = lazy(() => import("../pages/Products/Products"));
const PreKardex = lazy(() => import("../pages/Kardex/Prekardex"));
const Kardex = lazy(() => import("../pages/Kardex/Kardex"));
const KardexPro = lazy(() => import("../pages/Kardex/KardexPro/KardexPro"));
const Request = lazy(() => import("../pages/RequestProvider"));
const RequestDetail = lazy(() => import("../pages/RequestDetail"));
const ClientArea = lazy(() => import("../pages/Acount"));
const Sales = lazy(() => import("../pages/Sales"));
const SalesStore = lazy(() => import("../pages/SalesStore/SalesStore"));
const SalesCart = lazy(() => import("../pages/SalesStore/SalesCart"));
const SaleOrder = lazy(() => import("../pages/SaleOrder/SaleOrder"));
const SalesReport = lazy(() => import("../pages/Report/sales"));
const Users = lazy(() => import("../pages/Users"));
const CashBox = lazy(() => import("../pages/CashBox/CashBox"));
const BranchOffice = lazy(() => import("../pages/BranchOffice/BranchOffice"));
const StoreHouse = lazy(() => import("../pages/BranchOffice/StoreHouse"));
const Order = lazy(() => import("../pages/Order/Order"));
const ReceiptOrders = lazy(() => import("../pages/Order/ReceiptOrders"));
const OrdersList = lazy(() => import("../pages/Order/OrdersList"));
const Client = lazy(() => import("../pages/Client/Client"));

const StockPrint = lazy(() => import("../pages/Report/stockStatus/StockPrint"));
const Delivery = lazy(() => import("../pages/Delivery/Delivery"));
const PendingOrdes = lazy(() =>
  import("../pages/Report/pendingOrders/PendingOrders")
);
const PurchaseDetail = lazy(() =>
  import("../sections/stockStatus/PurchaseDetail")
);
const Accounts = lazy(() => import("../pages/DebtsToPay/Accounts"));

const Groups = lazy(() => import("../pages/Config/Groups/Groups"));
const ProductConfig = lazy(() =>
  import("../pages/Config/Products/ProductConfig")
);

const ItemsFilter = lazy(() =>
  import("../pages/Report/itemsFilter/ItemsFilter")
);

const RequestReport = lazy(() =>
  import("../pages/Report/request/RequestReport")
);

const Conta = lazy(() => import("../pages/Conta/Conta"));

const Register = lazy(() => import("../pages/Conta/Register"));

function RoutesApp() {
  const authState = useSelector((state) => state.isAuth.auth);

  const dispatch = useDispatch();
  userInfo()
    .then((res) => {
      dispatch(setIsAuth());
      dispatch(setDataUser(res));
    })
    .catch((er) => {
      dispatch(setNoAuth());
    }, authState);

  return (
    <Suspense fallback={<Backdrop />}>
      {authState !== null ? (
        <BrowserRouter>
          <RoutesWithNotFound>
            <Route path={PublicRoutes.LOGIN} element={<Login />} />
            <Route element={<AuthGuard isAuth={authState} />}>
              <Route path="/" element={<Dashboard />} />
              <Route
                element={<RoleGuard roles={["super-admin", "gerencia"]} />}
              >
                <Route path="/users" element={<Users />} />
              </Route>
              <Route path="/home" element={<Dashboard />} />
              <Route path="/clientarea" element={<ClientArea />} />
              <Route path="/providers" element={<Providers />} />
              <Route path="/providers/products/:id" element={<Products />} />
              <Route
                path="/providers/products/prekardex/:id"
                element={<PreKardex />}
              />
              <Route
                path="/providers/products/kardex/:store_id/:item_id"
                element={<Kardex />}
              />
              <Route
                path="/providers/products/kardexpro/:store_id/:price_id"
                element={<KardexPro />}
              />
              <Route path="/promotions" element={<Promotion />} />{" "}
              {/* //sin determinar */}
              <Route path="/offers" element={<Offer />} />{" "}
              {/* //sin determinar */}
              <Route path="/product/config" element={<ConfigItem />} />{" "}
              {/* //sin determinar */}
              <Route path="/request" element={<Request />} />
              <Route path="/request/:id" element={<RequestDetail />} />
              <Route path="/sales" element={<Sales />} />
              <Route path="/sale-pos" element={<Pos />} />
              <Route path="/store-sales" element={<SalesStore />} />
              <Route path="/store-sales/cart" element={<SalesCart />} />
              <Route path="/sales-report" element={<SalesReport />} />
              <Route path="/report/pending" element={<PendingOrdes />} />
              <Route path="/report/:name" element={<KardexReport />} />
              <Route path="/branch/:id" element={<BranchOffice />} />
              <Route path="/store/:id" element={<StoreHouse />} />
              <Route path="/requerimiento" element={<RequerimientoFilter />} />
              <Route
                path="/requerimiento/detail"
                element={<RequerimientoDetail />}
              />
              <Route path="/stock-status" element={<ItemsFilter />} />
              <Route path="/stock-status/low" element={<AlertStockLow />} />
              <Route path="/stock-status/store" element={<AlertStockStore />} />
              <Route path="/stock-status/detail" element={<PurchaseDetail />} />
              <Route path="/stock/report" element={<StockPrint />} />
              <Route path="/config-gruop-classes" element={<Groups />} />
              <Route
                path="/config-provider-product"
                element={<ProductConfig />}
              />
              <Route path="/order" element={<Order />} />
              <Route path="/receipt-orders/:id" element={<ReceiptOrders />} />
              <Route path="/receipt-orders" element={<OrdersList />} />
              <Route path="/cash-box" element={<CashBox />} />
              <Route path="/cash-box/list" element={<CashBoxList />} />
              <Route path="/records/request" element={<RequestReport />} />
              <Route path="/debts-to-pay" element={<Accounts />} />
              <Route path="/payment-history/:id" element={<PaymentHistory />} />
              <Route path="/payment-history-cxp/:id" element={<PayHistory />} />
              <Route path="/payment-history" element={<Report />} />
              <Route path="/sale-orders" element={<SaleOrder />} />
              <Route path="/client" element={<Client />} />
              <Route path="/client/map" element={<ClientMap />} />
              <Route path="/client/kardex/:id" element={<KardexClient />} />
              <Route path="/contabilidad/registros" element={<Conta />} />
              <Route
                path="/contabilidad/registro-caja/:id"
                element={<RegistroVenta />}
              />
              <Route
                path="/contabilidad/registros/:id"
                element={<Register />}
              />
              <Route path="/contabilidad/receipt-orders" element={<Conta />} />
              <Route
                path="/contabilidad/solicitudes"
                element={<Solicitudes />}
              />
              <Route path="/contabilidad/new-sol" element={<NewSol />} />
              <Route
                path="/contabilidad/new-diary"
                element={<DiaryRegister />}
              />
              <Route
                path="/contabilidad/daily-records"
                element={<DiaryList />}
              />
              <Route
                path="/contabilidad/libro-mayor"
                element={<LibroMayor />}
              />
              <Route
                path="/contabilidad/balance"
                element={<BalanceComprobacion />}
              />
              <Route
                path="/contabilidad/balance/sumas-saldos"
                element={<SumasSaldos />}
              />
              <Route
                path="/contabilidad/estados-financieros"
                element={<EstadosFinancieros />}
              />
              <Route
                path="/contabilidad/balance-general"
                element={<BalanceResultado />}
              />
              <Route path="/contabilidad/activos" element={<Activos />} />
              <Route
                path="/contabilidad/activos/config"
                element={<ActivosConfig />}
              />
              <Route path="/contabilidad/parametros" element={<Parametros />} />
              <Route path="/contabilidad/diario" element={<LibroDiario />} />
              <Route path="/delivery" element={<Delivery />} />
              <Route path="/informe/:name" element={<GeneralReport />} />
              <Route path="/packages" element={<Package />} />
              <Route
                path="/barcode-generator/:code/:number"
                element={<BarcodeGenerator />}
              />
              <Route path="/barcode-all" element={<BarcodeAll />} />
              <Route path="/store-stock" element={<StoreStockFilter />} />
              <Route path="/stock/store" element={<StockStore />} />
              <Route path="/stock/sala" element={<StockSala />} />
              <Route path="/store/transfers" element={<ReceiptTransfer />} />
              <Route
                path="/receipt/transfers/:id"
                element={<ReceiptTransfers />}
              />
              <Route
                path="/store/historial/transfers"
                element={<ReportTransfer />}
              />
              <Route path="/kpi/kpisales" element={<Kpi />} />
              <Route path="/invoice/config" element={<FactuConfig />} />
              <Route path="/invoice/report" element={<Historial />} />
              <Route path="/invoice/new" element={<NewInvoice />} />
              <Route path="/invoice/state" element={<EstadoFacturador />} />
              <Route path="/config/menu" element={<ConfigMenu />} />
              <Route path="/salesv1" element={<SalesV1 />} />
              <Route path="/monitor" element={<Monitor />} />
              <Route path="/cotizacion" element={<Cotizacion />} />
              <Route
                path="/cotizacion/historial"
                element={<HitorialCotizacion />}
              />
              <Route
                path="/cotizacion/historial"
                element={<HitorialCotizacion />}
              />
              <Route path="/devolucion" element={<RegistrarDevolucion />} />
              <Route
                path="/devolucion/receipt/:id"
                element={<ReturnReceipt />}
              />
              <Route path="/bank/config" element={<MyConfigs />} />
              <Route path="/activity" element={<ActivityLog />} />
              <Route
                path="/consolidated/kardex"
                element={<ConsolidatedKardex />}
              />
              <Route path="/bajas" element={<Bajas />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route
                path="/inventory-adjustment/:id"
                element={<AdjustmentList />}
              />
              <Route
                path="/inventory-adjustment"
                element={<InventoryAdjustment />}
              />
              <Route
                path="/inventory/adjustment"
                element={<InventoryAdjustmentReport />}
              />
              <Route path="/inventory/cost" element={<InventoryCost />} />
              <Route path="/inventory/activity" element={<InventoryMove />} />
              <Route path="/discount/area" element={<DiscountArea />} />
              <Route path="/prices/list" element={<PricesList />} />
              <Route path="/product/list" element={<ProductList />} />
              <Route path="/custom-orders/:type" element={<CustomOrders />} />
              <Route
                path="/custom-receipt/:id"
                element={<RecepcionNewProvider />}
              />
              <Route path="/objective" element={<Objectives />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/seguimiento" element={<Seguimiento />} />
              <Route
                path="/compras-internacionales"
                element={<Internacional />}
              />
              <Route path="/caja-chica" element={<CajaChica />} />
              <Route path="/rrhh/personal" element={<Personal />} />
              <Route path="/rrhh/parametros" element={<ParametrosRrhh />} />
              <Route path="/rrhh/planillas" element={<Planilla />} />
              <Route path="/rrhh/planillas/:id" element={<PlanillaMensual />} />
              <Route path="/ticket" element={<Reclamos />} />
              <Route path="/mapa/:type" element={<MapPage />} />
              <Route path="/gif-card" element={<GifCard />} />
            </Route>

            <Route path="/error-404" element={<Error />} />
          </RoutesWithNotFound>
        </BrowserRouter>
      ) : (
        <Backdrop />
      )}
    </Suspense>
  );
}

export default RoutesApp;
