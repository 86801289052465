import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { uploadDocument } from "../../services/document";

const UploadDocument = ({dataId,documents,type="OrderHead",description=null,doc=null,label="Seleccionar Doc. Respaldo"}) => {
    
    const [file,setFile]= useState(null);
    const [title,setTitle]= useState("");
    const handleSubmit=(e)=>{
       // e.preventDefault();
      
       if( file !== null && title !== ""){
        uploadDocument({
            document:file,
            document_id:dataId,
            document_type:type,
            title:title,
            doc:doc
        }).then(res=>{
          setFile(null)
          setTitle("")
            documents(res)
            toast.success("Se registro correctamente el documento")           
        })
       }else{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:"Debe seleccionar el documento y registrar el titulo",
          })
       }
    }
    function handleChangeDocument(event) {
        setFile(event.target.files[0]);
      }
    function handleChange(event) {
       setTitle(event.target.value)
      }
      useEffect(()=>{
        if(description){
          setTitle(description)
         }
      },[])
    return (
      <form>
       <div className="inline-flex gap-2 my-3">
     {<TextField
            label={label}
            size="small"
            type="file"
            InputLabelProps={{
                shrink: true,
              }}
            onChange={handleChangeDocument}
        />}
     {!description &&   <TextField
            label="Nombre o titulo del Respaldo"
            size="small"
            name="title"
            onChange={handleChange}
        />}
        {doc? <p>{doc}</p>: ""}
        <Button onClick={handleSubmit} variant="contained">
            Registrar
        </Button>
       </div>
      </form>
    );
}
export default UploadDocument