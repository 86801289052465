import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';



import { useEffect, useState } from "react";
/* import 'leaflet-draw/dist/leaflet.draw.css';
import L from 'leaflet';
import 'leaflet-draw'; */
const MapSelect2 = ({onPointSelect,setCoords,coords, newClient=false }) => {
  const defaultProps = {
    center: {
      lat:coords?.latitude || -16.49336973634751,
      lng:coords?.longitude || -68.14418799875067
    },
    zoom: 15
  };
  const [location,setLocation] = useState({
    lat:coords?.latitude || -16.49336973634751,
      lng:coords?.longitude ||-68.14418799875067
  })
  function getLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // La ubicación del usuario se ha obtenido con éxito
        const { latitude, longitude } = position.coords;
        setCoords(position.coords)
        setLocation({
          lat:latitude,
          lng:longitude
        }          
        )
      },
      (error) => {
        // Hubo un error obteniendo la ubicación del usuario
        console.log(error);
      }
    );
  }


  const handleMarkerDragEnd = (event) => {
    // Obtener las coordenadas después de arrastrar el marcador
    
    const { lat, lng } = event.target.getLatLng();
    setCoords({
      latitude:lat,
      longitude:lng,
    })
    setLocation({
      lat: lat,
      lng: lng
    })
    
  };
  const customIcon = L.divIcon({
    className: "custom-icon",
    html: `<div><svg xmlns="http://www.w3.org/2000/svg" width="30" height="42" viewBox="0 0 30 42">
    <path
      fill="#fc0303"
      d="M15 0C9.43 0 5 4.48 5 10.02c0 7.3 8.26 17.5 9.22 18.48l.78.72.78-.72C16.74 27.52 25 17.32 25 10.02 25 4.48 20.57 0 15 0zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"
    />
  </svg></div>`,
    iconSize: [24, 24],
    iconAnchor: [12, 24],
  });
  useEffect(()=>{
    getLocation()
  },[])
    return (
      <div style={{height:"50vh"}} id="map">
       
        <MapContainer center={defaultProps.center} zoom={15} style={{ height: '50vh', width: '100%' }} scrollWheelZoom={false}>
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    id="mapbox/streets-v11" // Puedes cambiar este estilo según tus preferencias
  />
 <Marker position={[location.lat||0,location.lng||0]}  draggable={true}
       //   onDragEnd={handleMarkerDragEnd}
          eventHandlers={{
            dragend: (event) => {
              handleMarkerDragEnd(event);
            },
          }}
          icon={customIcon}
          >
    <Popup >
      Ubicacion del cliente
    </Popup>
  </Marker> 
</MapContainer>
      </div>
    );
}

export default MapSelect2