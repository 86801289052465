import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useFormik } from "formik";
import { find, size } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { postApiService } from "../../../services/crudServices";

const FormularioPlanilla =({closeModal})=>{
    const [stores, setStores] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const userData = useSelector((state) => state.userData);

  const groupedData = userData.user.companies.map(company => {
    const branches = company.branches.reduce((acc, branch) => {
        const existingBranch = acc.find(b => b.sucursal === branch.sucursal);
        if (existingBranch) {
            existingBranch.store.push(branch.store);
        } else {
            acc.push({
                id:branch.id,
                sucursal: branch.sucursal,
                sucursal_code: branch.sucursal_code,
                direction: branch.direction,
                city: branch.city,
                phone: branch.phone,
                store: [branch.store]
            });
        }
        return acc;
    }, []);
    return {
        ...company,
        branches: branches
    };
  });

  const formik = useFormik({
    initialValues:{
        company_id:'',
        store_id:'',
        z_company_branch_id:'',
        name:'',
    },
    onSubmit:(values)=>{
        postApiService("rrhh/new-planilla",values).then(res=>{
            closeModal()
        })
    }
  })
  const handleChangeCompany = (e) => {
    formik.setFieldValue("company_id", e.target.value);
    const branches = find(groupedData,o=>{
      return o.id === e.target.value
  })
    setBranchOffices(branches?.branches||[]);
  };
  const handleChangeBranch = (e) => {    
    // setTargetStore("all")
     const stores = find(branchOffices,o=>{
         return o.id === e.target.value
     })
     formik.setFieldValue('z_company_branch_id',e.target.value)
     setStores(stores?.store||[])
 
   };
    return(
        <div>
            <form onSubmit={formik.handleSubmit}>
        <div className="">
          <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={formik.values.company_id}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.company_id && Boolean(formik.errors.company_id)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              {groupedData.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.company_id && formik.errors.company_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="z_company_branch_id">Sucursal</InputLabel>
            <Select
              labelId="empresa"
              id="z_company_branch_id"
              disabled={size(branchOffices) === 0}
              name="z_company_branch_id"
              label="Sucursal"
              value={formik.values.z_company_branch_id}
              onChange={handleChangeBranch}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.z_company_branch_id &&
                Boolean(formik.errors.z_company_branch_id)
              }
            >              
              {branchOffices.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.sucursal}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.z_company_branch_id &&
                formik.errors.z_company_branch_id}
            </FormHelperText>
          </FormControl>
          <FormControl  fullWidth>
          <InputLabel id="store_id">Almacen / Sala</InputLabel>
          <Select
            labelId="store_id"
            id="store_id"
            name="store_id"
            value={formik.values.store_id}
            label="Almacen / Sala"
            size="small"
            sx={{ my: 1 }}
            onChange={formik.handleChange}
          >
          
            {size(stores)>0 &&
            stores.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText color="error">
              {formik.touched.store_id &&
                formik.errors.store_id}
            </FormHelperText>
        </FormControl>
        <TextField
            value={formik.values.name}
            onChange={formik.handleChange}
            name="name"
            fullWidth
            sx={{ my: 1 }}
            label="Planilla"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
            size="small"
          />
             <Button variant="contained" type="submit">
            Registrar
          </Button>
        </div>
        </form>
        </div>
    )
}

export default FormularioPlanilla