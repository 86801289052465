import { useEffect, useState } from "react"
import { postApiService } from "../../../services/crudServices"
import Encabezados from "../../../sections/contabilidad/documentPrint/Encabezados"
import { size } from "lodash"
import ModalComponent from "../../../components/ModalComponent"
import ArticleIcon from '@mui/icons-material/Article';
import PrintComprobante from "../Diario/PrintComprobante"
import DiaryRegisterV2 from "../Diario/DiaryRegisterV2"
const PrintDepreciacionActualizacion=({year,company})=>{
    const[data,setData] = useState([])
    const loadData=()=>{
        postApiService("activo-fijo/depreciacion",{year:year,company_id:company}).then(res=>{
            setData(res)
        })
    }
    useEffect(()=>{
        loadData()
    },[])
    return(
        <div>
            {
                size(data) > 0 &&
                <div  className="mt-3 text-xs uppercase">
                <Encabezados
                  title="Cuadro de Actualizacion y depreciacion"
                  subtitle={"Al 31-12-"+year}
                  company={data[0].company}
                  nit={data[0].head.company.nit}
                  nro=""
                  />
              <table className="border border-gray-400 bg-white mt-2 rounded-lg  w-full shadow-md ">
               <thead>
                   <tr>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>sucursal</th>
                    <th className='p-2 font-bold text-center'>Categoria</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>valor contabilizado</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>factor de actualizacion</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>valor actualizado</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>incremento por actualizacion</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>depreciacion acumulada anterior</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>incremento depreciacion acumulada</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>depreciacion del periodo</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>depreciacion acumulada actualizada</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center'>valor neto</th>
                    <th style={{width:"5%"}} className='p-2 font-bold text-center no-print'>comprobante</th>
                   </tr>
               </thead>
               <tbody>
                   {
                       data.map((row,key)=>
                       <tr key={key}>
                           <td className='p-2 text-left  border border-gray-300'>{row.head.z_company_branch.sucursal}</td>
                           <td className='p-2 text-left  border border-gray-300'>{row.rubro_contable}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_valor_contabilizado}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_factor_actualizacion}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_valor_actualizado}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_incremento_act}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_dep_acumulado_ant}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_incremento_dep}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_dep_periodo}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_dep_acumulado_act}</td>
                           <td className='p-2 text-right border border-gray-300'>{row.suma_valor_final}</td>
                           <td className='p-2 border border-gray-300 no-print'>
                           <ModalComponent btn={<ArticleIcon color={row.head.comprobante? "success":"error"}/>} fullScreenx={true}  >
                                { 
                                    row.head.comprobante? 
                                    <PrintComprobante id={row.head.comprobante?.id}/> 
                                    :
                                    <DiaryRegisterV2
                                        type="traspaso"
                                        company_id={row.company_id}
                                        branch_id={"1"}
                                        model_type= "DepreciacionHead"
                                        model_id= {row.head.id}
                                        data= {{
                                            glosa: "Actualizacion y depreciacion de activos fijos "+row.rubro_contable,
                                            date: row.head.year+"-12-31",
                                            total: 0,
                                        }}
                                        detail={
                                            <table className="border border-gray-400 bg-white mt-2 rounded-lg shadow-md ">
                                                <tbody>
                                                       <tr> <td className='p-2 font-bold text-right'>sucursal</td>
                                                        <td className='p-2 text-left  border border-gray-300'>{row.head.z_company_branch.sucursal}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>Categoria</td>
                                                        <td className='p-2 text-left  border border-gray-300'>{row.rubro_contable}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>valor contabilizado</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_valor_contabilizado}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>factor de actualizacion</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_factor_actualizacion}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>valor actualizado</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_valor_actualizado}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>incremento por actualizacion</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_incremento_act}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>depreciacion acumulada anterior</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_dep_acumulado_ant}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>incremento depreciacion acumulada</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_incremento_dep}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>depreciacion del periodo</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_dep_periodo}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>depreciacion acumulada actualizada</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_dep_acumulado_act}</td>
                                                        </tr>
                                                       <tr> <td className='p-2 font-bold text-right'>valor neto</td>
                                                        <td className='p-2 border border-gray-300'>{row.suma_valor_final}</td>
                                                        </tr>
                                                    
                                                </tbody>
                                            </table>
                                        }
                                        company= {row.company}
                                        store= {row.store}
                                        store_id= {row.store_id}
                                        reloadData= {loadData}
                                        invoice={true}
                                        invoiceOption={true}
                                    />                                            
                                }
                            </ModalComponent>
                               
                           </td>
                       </tr>
                       )
                   }
               </tbody>
              </table>
           </div>
            }
        </div>
    )
}
export default PrintDepreciacionActualizacion