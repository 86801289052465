import { Box, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { sumBy } from "lodash";
import Firma from "../../components/Firma";
import Logo from "../../components/Logo";
import { companyDirection, companyName, companyPhone } from "../../config/AppConfig";
import { dateConfig } from "../../utils/dateconfig";
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber";
import { numeroALetras } from "../../utils/NumeroALetras";
import { calculeTotalAndDiscount, calculeTotalDiscount } from "../../utils/operations";

const PrintFull = ({ data }) => {
  return (
    <div className='uppercase text-xs'>
    <Box>

<div  className='flex flex-wrap bg-white overflow-hidden relative'>
            <div className='w-full h-full gap-2 flex flex-col'>
                <div className='logos print-heading overflow-hidden w-fulljustify-center content-center'>
                    <div className='flex flex-wrap  justify-between w-full gap-4'>
                        <div className='w-5/12 flex flex-col justify-center text-center'>
                            <div className='box-border'>
                            <Logo/>
                            </div>
                        </div>
                        <div className='w-6/12 flex flex-col'>
                        <h3 className='font-bold text-printa uppercase'>{companyName}</h3>
                                <Typography
                                    component="span"                                         
                                    color="text.secondary"
                                    variant="body3"
                                    className='font-semibold text-xs'
                                >
                               {companyDirection}
                                </Typography>
    
                                <Typography
                                    sx={{ display: 'inline' }}
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                <strong>TELEFONO: </strong> {companyPhone}
                                </Typography>
                        </div>

                    </div>
                  
                </div>
                <div className='print-tittle  relative overflow-hidden w-full print-logos flex flex-wrap justify-center text-center items-center'>
                    <div className='bg-printa rounded-md'>
                    <h2 className='tittle px-11 text-center text-xl font-bold text-white py-2'> 
                    RECIBO
                    {data.anticipo_id && " DE ANTICIPO"}
                    {data.account_id && " DE CUENTA POR COBRAR"}
                    </h2>                            
                    </div>
                </div>
                <div className='print-qr relative overflow-hidden w-full bg-white flex flex-wrap justify-center text-center items-center gap-3'>
                    <div className='qr w-full border border-solid rounded-lg border-black'>
                        <div className='w-full flex flex-wrap justify-between'>
                            <div className='w-6/12 flex flex-col text-left gap-2 py-2 px-4'>
                                <div className='w-full flex flex-row justify-between  text-left gap-2'>
                                <h3 className='font-bold text-printa text-lg'> Cliente: {data.client.business_name}</h3>
                                <span  className='font-base text-md text-gray-500'>
                                <strong> NIT/CI:</strong>   {data.client.identification_number}
                                </span>
                                </div>
                                <div className='w-full flex flex-row text-left gap-2'>
                                <span  className='font-base text-md text-gray-500'>
                                <strong> FECHA:</strong>  {dateConfig(data.recibo.created_at)}
                                </span>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className='w-5/12 flex flex-col text-center gap-2 p-2'>
                            <h3 className='font-bold text-printa text-lg'> Nro Recibo: {fInvoiceNumber(data.recibo.number)}</h3>
                            <span  className='font-base text-md text-gray-500'>
                            <strong> Número de orden</strong>  {fInvoiceNumber(data.voucher_number)}
                                </span>
                            </div>
                                
                        </div>
                    </div>
                    <div className='qr w-full border border-solid overflow-hidden rounded-lg border-black'>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>DETALLE</TableCell>
                        <TableCell>CTN</TableCell>
                        <TableCell>P. UNIT</TableCell>
                        <TableCell>DESC</TableCell>
                        <TableCell>TOTAL</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                            
                    {data.voucher_detail.map((row, key) => (
                      <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell component="th" scope="row">
                      {row.item}
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {row.qty}
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {row.price}
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {fNumber(parseFloat(row.discount))}
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {fNumber( (parseFloat(row.price)*parseFloat(row.qty))- (parseFloat(row.discount)))}
                      </TableCell>
             
                      </TableRow>
                 
                  ))}
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell component="th" scope="row">
                     
                      </TableCell>
                      <TableCell component="th" scope="row">
                     
                      </TableCell>
                      <TableCell component="th" scope="row" className="text-right">
                     DESC. GLOBAL
                      </TableCell>
                      <TableCell component="th" scope="row">
                      {fNumber(data.discount.discount_amount)}
                      </TableCell>             
                      </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
                        <div className='w-full flex flex-wrap justify-between'>
                            <div className='w-6/12 flex flex-col text-left gap-2 p-4'>
                                <div className='w-full flex flex-row justify-between  text-left gap-2'>
                                <h3 className='font-bold text-printa text-lg'> Transacción en {data.recibo.currency}</h3>
                                </div>
                                {data.recibo.currency==="sus" && <div className='w-full flex flex-row text-left gap-2'>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong>TOTAL {data.recibo.currency}:</strong>  {fNumber(parseFloat(data.recibo.total)/ parseFloat(data.recibo.exchange_rate))}
                                </span>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong>TIPO DE CAMBIO Bs:</strong>  {fNumber(data.recibo.exchange_rate)}
                                </span>
                                </div>}
                                <div className='w-full flex flex-row text-left justify-between gap-2'>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong>TOTAL  {data.recibo.currency}:</strong>   {fNumber(data.recibo.total)}
                                </span>
                                <span  className='font-base text-lg text-gray-500'>
                                <strong> A CUENTA Bs:</strong>   {fNumber(data.recibo.a_cuenta)}
                                </span>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className='w-5/12 flex flex-col text-center justify-end gap-2 p-2'>
                            <h3 className='font-bold text-red-400 text-lg'> SALDO Bs: {fNumber(parseFloat(data.recibo.total) - parseFloat(data.recibo.a_cuenta))}</h3>                                   
                            </div>                                
                        </div>
                    </div>
                    <div className='qr w-full borderRadius h-32 '>
                        <div className='w-full h-full flex flex-wrap justify-evenly align-bottom'>
                            <div className='w-3/12 flex flex-col text-center justify-end  gap-2 p-4'>
                            <Firma user={data.recibo.user?.name} title="Recibí conforme" url={data.recibo.user?.firma?.firma} />
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div className='w-3/12 flex flex-col text-center justify-end gap-2 p-4'>
                            <Firma user="____________________" title="Entregué conforme" />
                            </div>
                                
                        </div>
                    </div>
                </div>

                
            </div>
        </div> 
</Box>      
</div>
  );
};
export default PrintFull;
