import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { fNumber } from "../../utils/formatNumber";
import { size, sumBy } from "lodash";
import { verificarAnticipo } from "../../services/accountReceivable";
import { toast } from "react-toastify";
import { postApiService } from "../../services/crudServices";
const validationSchema = yup.object({
  aditional: yup.string().required("Ingrese este campo"),
});
const Hibrido = ({ data, saveToPay }) => {
  const userData = useSelector((state) => state.userData);
  const [payMethod, setPayMethod] = useState("1");
  const [pagos, setPagos] = useState([]);
  const handleChangePay = (e) => {
    setPayMethod(e.target.value);
  };
  const setMethodPagos = (val) => {
    setPagos([...pagos, val]);
  };

  const typos = {
    1: "Efectivo",
    7: "QR",
    4: "Transferencia",
    3: "Deposito",
    5: "Cheque",
    6: "Anticipos",
    8: "Tarjeta",
    30: "GiftCard",
  };
  const deletePay = (index) => {
    const newData = [...pagos];
    newData.splice(index, 1);
    setPagos(newData);
  };
  useEffect(() => {
    if (data.code) {
      setMethodPagos({
        type: "6",
        code: data.code,
        currency_a: "bs",
        exchange_rate: "1",
        monto_sus: "0",
        monto: data.saldo,
      });
    }
  }, [data]);

  return (
    <div>
      <div className="flex flex-col items-center ">
        <p className="text-lg font-semibold">TOTAL Bs:</p>
        <p className="text-4xl font-semibold text-gray-500">
          {fNumber(data.total)}
        </p>
        <div className="my-3">
          <ul className="">
            {size(pagos) > 0 &&
              pagos.map((row, key) => (
                <li className="text-right" key={key}>
                  <div className="inline-flex w-full justify-between gap-3">
                    <span>
                      <Button
                        onClick={() => deletePay(key)}
                        size="small"
                        color="error"
                        variant="text"
                      >
                        X
                      </Button>
                    </span>{" "}
                    <span>{typos[parseInt(row.type)]}</span>{" "}
                    <span className="pl-3 text-blue-500">
                      {fNumber(
                        parseFloat(row.monto) *
                          parseFloat(row.exchange_rate || 1)
                      )}
                    </span>
                  </div>
                </li>
              ))}
            <li className="border-t border-blue-500"></li>
            <li className="text-right">
              Total
              <span className="pl-3 text-blue-500 font-bold">
                {fNumber(
                  sumBy(pagos, (o) => {
                    return (
                      parseFloat(o.monto) * parseFloat(o.exchange_rate || 1)
                    );
                  })
                )}
              </span>
            </li>
            <li className="py-3">
              <Button
                size="small"
                onClick={() => saveToPay(pagos)}
                disabled={
                  Math.abs(parseFloat(data.total).toFixed(2)) !==
                  Math.abs(
                    sumBy(
                      pagos,
                      (o) =>
                        parseFloat(o.monto) * parseFloat(o.exchange_rate || 1)
                    )
                  )
                }
              >
                Registrar{" "}
              </Button>
            </li>
          </ul>
        </div>
      </div>
      <FormControl fullWidth>
        <InputLabel id="pay_method">Metodo de pago</InputLabel>
        <Select labelId="pay_method" onChange={handleChangePay} size="small">
          <MenuItem>Seleccionar</MenuItem>
          <MenuItem value="1">Efectivo</MenuItem>
          <MenuItem value="2">QR</MenuItem>
          <MenuItem value="3">Transferencia</MenuItem>
          <MenuItem value="4">Deposito</MenuItem>
          <MenuItem value="5">Cheque</MenuItem>
          <MenuItem value="6">Tarjeta</MenuItem>
          <MenuItem value="7">Anticipos</MenuItem>
          <MenuItem value="8">Gift Card</MenuItem>
        </Select>
      </FormControl>
      {payMethod === "1" && <Efectivo setPagos={setMethodPagos} />}
      {payMethod === "2" && (
        <QrPay setPagos={setMethodPagos} bank={userData.bank} />
      )}
      {payMethod === "3" && (
        <Transferencia setPagos={setMethodPagos} bank={userData.bank} />
      )}
      {payMethod === "4" && (
        <Deposito setPagos={setMethodPagos} bank={userData.bank} />
      )}
      {payMethod === "5" && (
        <Cheque setPagos={setMethodPagos} bank={userData.bank} />
      )}
      {payMethod === "6" && (
        <Tarjeta setPagos={setMethodPagos} bank={userData.bank} />
      )}
      {payMethod === "7" && (
        <Anticipos setPagos={setMethodPagos} bank={userData.bank} />
      )}
      {payMethod === "8" && (
        <GiftCard setPagos={setMethodPagos} bank={userData.bank} />
      )}
    </div>
  );
};
export default Hibrido;
const GiftCard = ({ setPagos }) => {
  const [code, setCode] = useState("");

  const verifyCode = () => {
    postApiService("gift-card/verify", { code: code }).then((res) => {
      setPagos({
        type: "30",
        code: code,
        currency_a: "bs",
        exchange_rate: "1",
        monto_sus: "0",
        monto: res.monto,
      });
    });
  };
  return (
    <div className="w-full inline-flex gap-2 mt-2 p-2 bg-green-200">
      <TextField
        value={code}
        onChange={(e) => setCode(e.target.value)}
        name="code"
        fullWidth
        sx={{ my: 1 }}
        label="Codigo"
        variant="outlined"
        size="small"
      />
      <Button variant="contained" size="small" onClick={verifyCode}>
        Verificar codigo
      </Button>
    </div>
  );
};
const Anticipos = ({ setPagos, bank, code, saldo }) => {
  const [client, setClient] = useState({
    type: "6",
    code: "",
    currency_a: "bs",
    exchange_rate: "1",
    monto_sus: "0",
    monto: "0",
  });
  const setMethodPago = () => {
    setPagos(client);
    setClient({
      type: "1",
      currency_a: "bs",
      code: "",
      exchange_rate: "1",
      monto_sus: "0",
      monto: "0",
    });
  };
  const verifyCode = () => {
    verificarAnticipo({ code: client.code, client_id: client.id }).then(
      (res) => {
        if (parseFloat(res) >= parseFloat(client.monto)) {
          setPagos(client);
          setClient({
            type: "1",
            currency_a: "bs",
            code: "",
            exchange_rate: "1",
            monto_sus: "0",
            monto: "0",
          });
        } else {
          toast.error(
            "El monto del Codigo es de " +
              fNumber(res) +
              ", el monto ingresado no puede ser mayor!"
          );
        }
      }
    );
  };
  const handleChange = (e) => {
    setClient({
      ...client,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="w-full inline-flex gap-2 mt-2 p-2 bg-green-200">
      <TextField
        value={client.code}
        onChange={handleChange}
        name="code"
        fullWidth
        sx={{ my: 1 }}
        label="Codigo"
        variant="outlined"
        size="small"
      />
      <TextField
        value={client.monto}
        onChange={handleChange}
        name="monto"
        fullWidth
        sx={{ my: 1 }}
        label="Monto"
        type="number"
        variant="outlined"
        inputProps={{ step: "0.01" }}
        size="small"
      />

      <Button variant="contained" size="small" onClick={verifyCode}>
        Verificar codigo y agragar codigos
      </Button>
    </div>
  );
};
const Efectivo = ({ setPagos }) => {
  const [client, setClient] = useState({
    type: "1",
    currency_a: "bs",
    exchange_rate: "1",
    monto_sus: "0",
    monto: "0",
  });
  const setMethodPago = () => {
    setPagos(client);
    setClient({
      type: "1",
      currency_a: "bs",
      exchange_rate: "1",
      monto_sus: "0",
      monto: "0",
    });
  };
  const handleChange = (e) => {
    setClient({
      ...client,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="w-full inline-flex gap-2 mt-2 p-2 bg-green-200">
      <FormControl fullWidth>
        <InputLabel id="currency">Moneda</InputLabel>
        <Select
          labelId="currency"
          className="col-span-2"
          name="currency_a"
          onChange={handleChange}
          value={client.currency_a}
          size="small"
          sx={{ my: 1 }}
        >
          <MenuItem value="bs">bs</MenuItem>
          <MenuItem value="sus">sus</MenuItem>
        </Select>
      </FormControl>
      {client.currency_a === "sus" && (
        <TextField
          value={client.exchange_rate}
          onChange={handleChange}
          name="exchange_rate"
          fullWidth
          sx={{ my: 1 }}
          label="Tipo de cambio"
          type="number"
          variant="outlined"
          size="small"
          inputProps={{ step: "0.01" }}
        />
      )}

      <TextField
        value={client.monto}
        onChange={handleChange}
        name="monto"
        fullWidth
        sx={{ my: 1 }}
        label="Monto"
        type="number"
        variant="outlined"
        inputProps={{ step: "0.01" }}
        size="small"
      />

      <Button variant="contained" size="small" onClick={setMethodPago}>
        Agregar
      </Button>
    </div>
  );
};
const QrPay = ({ bank, setPagos }) => {
  const formik = useFormik({
    initialValues: {
      a_cuenta: "",
      aditional: "",
      bank: "",
      type: "7",
      origin_bank: "",
      bank_id: "",
      destination_bank: "",
      voucher_number: "",
      owner_name: "",
      paid_by: "",
      monto: "0",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPagos(values);
      formik.resetForm();
    },
  });
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full inline-flex gap-2 mt-2 p-2 bg-indigo-200"
      >
        <TextField
          value={formik.values.aditional}
          onChange={formik.handleChange}
          name="aditional"
          fullWidth
          sx={{ my: 1 }}
          label="información de transacción"
          multiline={true}
          variant="outlined"
          size="small"
        />
        <FormControl fullWidth>
          <InputLabel id="bank">Banco de destino</InputLabel>
          <Select
            labelId="bank"
            className="col-span-2"
            name="bank"
            value={formik.values.bank}
            onChange={formik.handleChange}
            size="small"
            sx={{ my: 1 }}
          >
            {bank.map((row, ke) => (
              <MenuItem key={ke + "-bank"} value={row.id}>
                {row.name + " - " + row.account}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={formik.values.paid_by}
          onChange={formik.handleChange}
          name="paid_by"
          fullWidth
          sx={{ my: 1 }}
          label="Transaccion realizada por"
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.monto}
          onChange={formik.handleChange}
          name="monto"
          fullWidth
          sx={{ my: 1 }}
          label="Monto"
          type="number"
          variant="outlined"
          inputProps={{ step: "0.01" }}
          size="small"
        />

        <Button variant="contained" size="small" type="submit">
          Agregar
        </Button>
      </form>
    </div>
  );
};
const Transferencia = ({ bank, setPagos }) => {
  const formik = useFormik({
    initialValues: {
      a_cuenta: "",
      aditional: "",
      bank: "",
      type: "4",
      origin_bank: "",
      bank_id: "",
      destination_bank: "",
      voucher_number: "",
      owner_name: "",
      paid_by: "",
      monto: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPagos(values);
      formik.resetForm();
    },
  });
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full md:grid grid-cols-3 gap-2 mt-2 p-2 bg-pink-200"
      >
        <TextField
          value={formik.values.aditional}
          onChange={formik.handleChange}
          name="aditional"
          fullWidth
          sx={{ my: 1 }}
          label="información de transacción"
          multiline={true}
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.voucher_number}
          onChange={formik.handleChange}
          name="voucher_number"
          fullWidth
          sx={{ my: 1 }}
          label={"Nro de transaccion / Voucher"}
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.origin_bank}
          onChange={formik.handleChange}
          name="origin_bank"
          fullWidth
          sx={{ my: 1 }}
          label="Banco de origen"
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.owner_name}
          onChange={formik.handleChange}
          name="owner_name"
          fullWidth
          sx={{ my: 1 }}
          label="Nombre titular"
          variant="outlined"
          size="small"
        />

        <FormControl fullWidth>
          <InputLabel id="bank">Banco de destino</InputLabel>
          <Select
            labelId="bank"
            className="col-span-2"
            name="bank"
            value={formik.values.bank}
            onChange={formik.handleChange}
            size="small"
            sx={{ my: 1 }}
          >
            {bank.map((row, ke) => (
              <MenuItem key={ke + "-bank"} value={row.id}>
                {row.name + " - " + row.account}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={formik.values.paid_by}
          onChange={formik.handleChange}
          name="paid_by"
          fullWidth
          sx={{ my: 1 }}
          label="Transaccion realizada por"
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.monto}
          onChange={formik.handleChange}
          name="monto"
          fullWidth
          sx={{ my: 1 }}
          label="Monto"
          type="number"
          inputProps={{ step: "0.01" }}
          variant="outlined"
          size="small"
        />
        <Button variant="contained" type="submit" size="small">
          Agregar
        </Button>
      </form>
    </div>
  );
};
const Deposito = ({ bank, setPagos }) => {
  const formik = useFormik({
    initialValues: {
      a_cuenta: "",
      aditional: "",
      bank: "",
      type: "3",
      origin_bank: "",
      bank_id: "",
      destination_bank: "",
      voucher_number: "",
      owner_name: "",
      paid_by: "",
      monto: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPagos(values);
      formik.resetForm();
    },
  });
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full md:grid grid-cols-3 gap-2 mt-2 p-2 bg-blue-200"
      >
        <TextField
          value={formik.values.aditional}
          onChange={formik.handleChange}
          name="aditional"
          fullWidth
          sx={{ my: 1 }}
          label="información de transacción"
          multiline={true}
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.voucher_number}
          onChange={formik.handleChange}
          name="voucher_number"
          fullWidth
          sx={{ my: 1 }}
          label={"Nro de transaccion / Voucher"}
          variant="outlined"
          size="small"
        />

        <FormControl fullWidth>
          <InputLabel id="bank">Banco de destino</InputLabel>
          <Select
            labelId="bank"
            className="col-span-2"
            name="bank"
            value={formik.values.bank}
            onChange={formik.handleChange}
            size="small"
            sx={{ my: 1 }}
          >
            {bank.map((row, ke) => (
              <MenuItem key={ke + "-bank"} value={row.id}>
                {row.name + " - " + row.account}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={formik.values.paid_by}
          onChange={formik.handleChange}
          name="paid_by"
          fullWidth
          sx={{ my: 1 }}
          label="Transaccion realizada por"
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.monto}
          onChange={formik.handleChange}
          name="monto"
          fullWidth
          sx={{ my: 1 }}
          label="Monto"
          inputProps={{ step: "0.01" }}
          type="number"
          variant="outlined"
          size="small"
        />

        <Button variant="contained" type="submit" size="small">
          Agregar
        </Button>
      </form>
    </div>
  );
};
const Cheque = ({ bank, setPagos }) => {
  const formik = useFormik({
    initialValues: {
      a_cuenta: "",
      aditional: "",
      bank: "",
      type: "5",
      origin_bank: "",
      bank_id: "",
      destination_bank: "",
      voucher_number: "",
      owner_name: "",
      paid_by: "",
      monto: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPagos(values);
      formik.resetForm();
    },
  });
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full md:grid grid-cols-3 gap-2 mt-2 p-2 bg-green-300"
      >
        <TextField
          value={formik.values.aditional}
          onChange={formik.handleChange}
          name="aditional"
          fullWidth
          sx={{ my: 1 }}
          label="información de transacción"
          multiline={true}
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.voucher_number}
          onChange={formik.handleChange}
          name="voucher_number"
          fullWidth
          sx={{ my: 1 }}
          label={"Nro de cheque"}
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.origin_bank}
          onChange={formik.handleChange}
          name="origin_bank"
          fullWidth
          sx={{ my: 1 }}
          label="Banco de origen"
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.values.owner_name}
          onChange={formik.handleChange}
          name="owner_name"
          fullWidth
          sx={{ my: 1 }}
          label="Nombre titular"
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.paid_by}
          onChange={formik.handleChange}
          name="paid_by"
          fullWidth
          sx={{ my: 1 }}
          label="Transaccion realizada por"
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.monto}
          onChange={formik.handleChange}
          name="monto"
          fullWidth
          sx={{ my: 1 }}
          label="Monto"
          inputProps={{ step: "0.01" }}
          type="number"
          variant="outlined"
          size="small"
        />
        <Button variant="contained" type="submit" size="small">
          Agregar
        </Button>
      </form>
    </div>
  );
};
const Tarjeta = ({ bank, setPagos }) => {
  const formik = useFormik({
    initialValues: {
      a_cuenta: "",
      aditional: "",
      bank: "",
      type: "8",
      origin_bank: "",
      bank_id: "",
      destination_bank: "",
      voucher_number: "",
      owner_name: "",
      paid_by: "",
      monto: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPagos(values);
      formik.resetForm();
    },
  });
  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="w-full md:grid grid-cols-3 gap-2 mt-2 p-2 bg-blue-200"
      >
        <TextField
          value={formik.values.aditional}
          onChange={formik.handleChange}
          name="aditional"
          fullWidth
          sx={{ my: 1 }}
          label="información de transacción"
          multiline={true}
          variant="outlined"
          size="small"
        />

        <TextField
          value={formik.values.voucher_number}
          onChange={formik.handleChange}
          name="voucher_number"
          fullWidth
          sx={{ my: 1 }}
          label={"Nro de transaccion / Voucher"}
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.values.origin_bank}
          onChange={formik.handleChange}
          name="origin_bank"
          fullWidth
          sx={{ my: 1 }}
          label="Banco de origen"
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.values.owner_name}
          onChange={formik.handleChange}
          name="owner_name"
          fullWidth
          sx={{ my: 1 }}
          label="Nombre titular"
          variant="outlined"
          size="small"
        />
        <FormControl fullWidth>
          <InputLabel id="bank">Banco de destino</InputLabel>
          <Select
            labelId="bank"
            className="col-span-2"
            name="bank"
            value={formik.values.bank}
            onChange={formik.handleChange}
            size="small"
            sx={{ my: 1 }}
          >
            {bank.map((row, ke) => (
              <MenuItem key={ke + "-bank"} value={row.id}>
                {row.name + " - " + row.account}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          value={formik.values.paid_by}
          onChange={formik.handleChange}
          name="paid_by"
          fullWidth
          sx={{ my: 1 }}
          label="Transaccion realizada por"
          variant="outlined"
          size="small"
        />
        <TextField
          value={formik.monto}
          onChange={formik.handleChange}
          name="monto"
          fullWidth
          sx={{ my: 1 }}
          label="Monto"
          type="number"
          inputProps={{ step: "0.01" }}
          variant="outlined"
          size="small"
        />
        <Button variant="contained" type="submit" size="small">
          Agregar
        </Button>
      </form>
    </div>
  );
};
