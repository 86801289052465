import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { size, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import ClientFormModal from "../../sections/client/ClientFormModal";
import { verificarAnticipo } from "../../services/accountReceivable";
import { getClient } from "../../services/client";
import { documentoIdentidad } from "../../utils/factu/catalogos";
import { fNumber } from "../../utils/formatNumber";

const validationSchema = yup.object({
  nit: yup.string().required("Ingrese este campo"),
  name: yup.string().required("Ingrese este campo"),
  direction: yup.string().required("Ingrese este campo"),
  monto: yup.number(),
});

const Client = ({ client, code=null, setCliente, saldo=null, setSaldo=null, pay=true }) => {
  const [codeUse,setCodeUse]=useState();
  const handleChange = (ev) => {
    setCliente({
      ...client,
      id: "",
      [ev.target.name]: ev.target.value,
    
    });
  };
  const handleChangeDocument = (ev) => {
    setCliente({
      ...client,
      [ev.target.name]: ev.target.value,
    });
  };
  
  const getClientData = () => {
    getClient({ nit: client.identification_number }).then((res) => {
      if (size(res) > 0) {
        setCliente(res);
      }
    });
  };
  const verifyAnticipo = () => {
    verificarAnticipo({code:code,client_id:client.id}).then((res) => {
      setSaldo(res);
      
    });
  };
useEffect(()=>{
  if(code){
    verifyAnticipo()
  }
  if(!client.currency_a){
    setCliente({
      ...client,
      currency_a: "bs",
      exchange_rate: "1"
    });

  }
},[client.monto_sus,client.exchange_rate])
useEffect(()=>{
  if(client.currency_a === "sus"){
    setCliente({
      ...client,
      monto: parseFloat(client.exchange_rate) * parseFloat(client.monto_sus)
    });
  }
},[client])
  return (
    <div>
      <div className="md:grid grid-cols-6 gap-3">
      <ClientFormModal setClient={setCliente} />
      <TextField
        value={client.identification_number}
        onChange={handleChange}
        onBlur={() => getClientData()}
        name="identification_number"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ my: 1 }}
        label="CI / NIT"
        variant="outlined"
        size="small"
        type="number"
        className="col-span-1"
      />
      <TextField
        value={client.complement}
        onChange={handleChange}
        name="complement"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ my: 1 }}
        label="Complemento"
        variant="outlined"
        size="small"
        className="col-span-1"
      />
      <FormControl fullWidth>
        <InputLabel id="tipo_documento">Tipo de documento</InputLabel>
        <Select
          labelId="tipo_documento"
          name="document_type"
          value={client.document_type + ""}
          label="Tipo de documento"
          onChange={handleChangeDocument}
          size="small"
          sx={{ my: 1 }}
        >
          {documentoIdentidad.map((row, key) => (
            <MenuItem key={key} value={row.codigoClasificador}>
              {row.descripcion}
            </MenuItem>
          ))}
          
        </Select>
      </FormControl>
      <TextField
        value={client.business_name}
        onChange={handleChange}
        name="business_name"
        fullWidth
        sx={{ my: 1 }}
        InputLabelProps={{
          shrink: true,
        }}
        label="Nombre Razon Social"
        variant="outlined"
        size="small"
        className="col-span-2"
      />
      
    </div>
    { pay &&
      <div className="md:grid grid-cols-6 gap-3">
      <div className="">
        <Button onClick={() => verifyAnticipo()}>
          Verificar Saldo de anticipo
        </Button>
        <p className="text-green-500 font-semibold uppercase">
          {parseFloat(saldo) > 0 &&
            "Saldo de anticipo de: bs " + fNumber(saldo)}
        </p>
      </div>
      
      <FormControl fullWidth>
                    <InputLabel id="currency">Moneda</InputLabel>
                    <Select
                      labelId="currency"
                      className="col-span-2"
                      name="currency_a"
                      onChange={handleChange}
                      value={client.currency_a}
                      size="small"
                      sx={{ my: 1 }}
                    >
                      <MenuItem value="bs">bs</MenuItem>
                      <MenuItem value="sus">sus</MenuItem>
                    </Select>
                  </FormControl>
      {client.currency_a === "sus" &&<TextField
        value={client.exchange_rate }
        onChange={handleChange}
        name="exchange_rate"
        fullWidth
        sx={{ my: 1 }}
        label="Tipo de cambio"
        type="number"
        variant="outlined"
        size="small"
      />}
      {client.currency_a === "sus" &&<TextField
        value={client.monto_sus}
        onChange={handleChange}
        name="monto_sus"
        fullWidth
        sx={{ my: 1 }}
        label="Monto sus"
        type="number"
        variant="outlined"
        size="small"
      />}
    
      <TextField
        value={client.monto || 0}
        onChange={handleChange}
        name="monto"
        fullWidth
        sx={{ my: 1 }}
        label="Monto"
        type="number"
        variant="outlined"
        size="small"
      />
      </div>
    }
    </div>
  );
};

export default Client;
