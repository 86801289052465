import { Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, Tooltip } from "@mui/material"
import Pagev1 from "../../../layout/Pagev1"
import { useRef, useState } from "react"
import { useFormik } from "formik"
import ModalComponent from "../../../components/ModalComponent"
import NewActivo from "./NewActivo"
import { getActivosList } from "../../../services/conta"
import { fNumber } from "../../../utils/formatNumber"
import { dateConfigBirth } from "../../../utils/dateconfig"
import { find, size } from "lodash"
import { useReactToPrint } from "react-to-print"
import Tarjeta from "./Tarjeta"
import BadgeIcon from '@mui/icons-material/Badge';
import ArticleIcon from '@mui/icons-material/Article';
import DiaryRegisterV2 from "../Diario/DiaryRegisterV2"
import DepreciarActualizar from "./DepreciarActualizar"
import { useSelector } from "react-redux"
import PrintComprobante from "../Diario/PrintComprobante"
const Activos = () => {
  const [data,setData] = useState([])
  const [stores, setStores] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const userData = useSelector((state) => state.userData);

    
    const componentPrint = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentPrint.current,
      });
    const formik = useFormik({
        initialValues:{
            company_id:"all",
            z_company_branch_id:"all",
            store_id:"all",
        },
        onSubmit:(values)=>{
            getActivosList(values).then(res=>{
                setData(res)
            })
        }
    })
  

    const groupedData = userData.user.companies.map(company => {
        const branches = company.branches.reduce((acc, branch) => {
            const existingBranch = acc.find(b => b.sucursal === branch.sucursal);
            if (existingBranch) {
                existingBranch.store.push(branch.store);
            } else {
                acc.push({
                    id:branch.id,
                    sucursal: branch.sucursal,
                    sucursal_code: branch.sucursal_code,
                    direction: branch.direction,
                    city: branch.city,
                    phone: branch.phone,
                    store: [branch.store]
                });
            }
            return acc;
        }, []);
        return {
            ...company,
            branches: branches
        };
      });
      const handleChangeBranch = (e) => {    
        // setTargetStore("all")
         const stores = find(branchOffices,o=>{
             return o.id === e.target.value
         })
         formik.setFieldValue('z_company_branch_id',e.target.value)
         setStores(stores?.store||[])
     
       };
       const handleChangeCompany = (e) => {
        formik.setFieldValue("company_id", e.target.value);
        setData([])
        const branches = find(groupedData,o=>{
          return o.id === e.target.value
      })
        setBranchOffices(branches?.branches||[]);
      };
   
    
  return (
    <Pagev1 appBar={true} title="Activos fijos">
        <Paper className="p-3">
           <div className="md:inline-flex w-full gap-2 justify-between">
           <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="md:grid grid-cols-4 gap-2">

                  <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={formik.values.company_id}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.company_id && Boolean(formik.errors.company_id)
              }
            >
              <MenuItem value="all">Todas</MenuItem>
              {groupedData.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.company_id && formik.errors.company_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="z_company_branch_id">Sucursal</InputLabel>
            <Select
              labelId="empresa"
              id="z_company_branch_id"
              disabled={size(branchOffices) === 0}
              name="z_company_branch_id"
              label="Sucursal"
              value={formik.values.z_company_branch_id}
              onChange={handleChangeBranch}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.z_company_branch_id &&
                Boolean(formik.errors.z_company_branch_id)
              }
            >              
              <MenuItem value="all">Todas</MenuItem>
              {branchOffices.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.sucursal}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.z_company_branch_id &&
                formik.errors.z_company_branch_id}
            </FormHelperText>
          </FormControl>
          <FormControl  fullWidth>
          <InputLabel id="store_id">Almacen / Sala</InputLabel>
          <Select
            labelId="store_id"
            id="store_id"
            name="store_id"
            value={formik.values.store_id}
            label="Almacen / Sala"
            size="small"
            sx={{ my: 1 }}
            onChange={formik.handleChange}
          >
              <MenuItem value="all">Todas</MenuItem>
          
            {size(stores)>0 &&
            stores.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText color="error">
              {formik.touched.store_id &&
                formik.errors.store_id}
            </FormHelperText>
        </FormControl>


                <div className="mt-2">
                <Button variant="contained" type="submit" >Generar</Button>
                </div>
                
                </div>
            </form>
            <div className="md:inline-flex w-full gap-2">
            <div className="mt-2">
                <Button variant="contained" type="button" onClick={handlePrint} >Imprimir</Button>
                </div>
                <div className="mt-2">
                <ModalComponent btn={"Act. y/o Dep."} variant={"contained"} title={"Actualizacion y/o Depreciacion"}>
                    <DepreciarActualizar/>
                </ModalComponent>
                </div>
                <div className="mt-2">
                    <ModalComponent title="Registro de activo" btn="Nuevo" variant="contained">
                        <NewActivo /> 
                    </ModalComponent>                
                </div>
            </div>
           </div>
        </Paper>
        <Paper className="p-3 mt-3">
                {
                    size(data) > 0 ?
            <div ref={componentPrint}>
                <p className="font-xl text-center font-bold uppercase">Inventario de activos Fijos</p>
                <p className="font-xl text-center font-bold uppercase">{formik.values.company_id === "all"?"Todas las Empresas":data[0].company?.name}</p>
                    <table className="table-print">
                    <thead>
                        <tr>                            
                            <th>#</th>
                            {formik.values.company_id === "all" && <th>empresa</th>}
                            <th>codigo</th>
                            <th>tipo de bien</th>
                            <th>descripcion</th>
                            <th>fecha compra o revaluo</th>
                            <th>valor inicial</th>
                            <th>categoria <Divider/> (DS 24051)</th>
                            <th>años de vida util</th>
                            <th>depreciacion %</th>                            
                            <th>depreciacion acumulada</th>
                            <th>valor residual</th>
                            <th>oficina</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            size(data) > 0 &&
                            data.map((row,key)=>
                           <tr key={key}>
                                <td>{key+1}</td>
                                {formik.values.company_id === "all" && <td>{row.company.name}</td>}
                                <td>{row.barcode}</td>
                                <td>{row.tipo_bien.tipo_bien}</td>
                                <td>{row.descripcion}</td>
                                <td>{dateConfigBirth(row.fecha_alta)}</td>
                                <td className="text-right">{fNumber(row.valor_inicial)}</td>                                
                                <td>{row.tipo_bien.rubro_contable.nombre}</td>
                                <td className="text-right">{row.tipo_bien.rubro_contable.vida_util}</td>
                                <td className="text-right">{fNumber(row.tipo_bien.rubro_contable.coeficiente)}</td>
                                <td className="text-right">{fNumber(row.dep_acumulada)}</td>
                                <td className="text-right">{fNumber(row.valor_recidual)}</td>
                                <td>{row.oficina.oficina}</td>
                                <td>
                                   <div className="inline-flex gap-2">
                                    {
                                      size(row.comprobante) > 0 ?
                                      row.comprobante.map((ra,key)=>
                                        ra.tipo_transaccion_id === 7?
                                      <ModalComponent key={key} btn={<ArticleIcon color={"success"}/>} fullScreenx={true}  >
                                        <PrintComprobante id={ra.id}/>
                                      </ModalComponent>
                                      :
                                      <ModalComponent key={key} btn={<ArticleIcon color={"success"}/>} fullScreenx={true}  >
                                      <DiaryRegisterV2
                                          type="egreso"
                                          company_id={row.company_id}
                                          branch_id={"1"}
                                          model_type= "ActivoFijo"
                                          model_id= {row.id}
                                          data= {{
                                              glosa: "Compra de activo fijo",
                                              date: row.fecha_alta,
                                              total: row.valor_inicial,
                                          }}
                                          company= {row.company.name}
                                          store= {row.store.name}
                                          store_id= {row.store_id}
                                          reloadData= {formik.handleSubmit}
                                          invoice={true}
                                          invoiceOption={true}
                                          transaccion={7}
                                      />
                                    </ModalComponent>
                                      ) :
                                      <ModalComponent btn={<ArticleIcon color={"error"}/>} fullScreenx={true}  >                                     
                                           <DiaryRegisterV2
                                               type="egreso"
                                               company_id={row.company_id}
                                               branch_id={"1"}
                                               model_type= "ActivoFijo"
                                               model_id= {row.id}
                                               data= {{
                                                   glosa: "Compra de activo fijo",
                                                   date: row.fecha_alta,
                                                   total: row.valor_inicial,
                                               }}
                                               company= {row.company.name}
                                               store= {row.store.name}
                                               store_id= {row.store_id}
                                               reloadData= {formik.handleSubmit}
                                               invoice={true}
                                               invoiceOption={true}
                                               transaccion={7}
                                           />
                                       </ModalComponent>
                                    }
                                  
                                    <ModalComponent btn={<BadgeIcon/>} title={"Tarjeta de activo"} reloadData={formik.handleSubmit} >
                                        <Tarjeta activo={row.id} />
                                    </ModalComponent>
                                   </div>
                                </td>
                           </tr>                            
                            )
                        }
                       
                    </tbody>
                </table>
            </div>
            : <p className="font-semibold">No existen registros para la empresa seleccionada</p>
        }
        </Paper>
    </Pagev1>
  )
}
export default Activos