import {
  Button,
  ButtonGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { blue, green,indigo,orange, pink, purple, teal} from "@mui/material/colors";
import { Fragment, useEffect, useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import { applyDiscount, collectV1, collectV2, getCash, initCash } from "../../services/cashBox";
import PaynemetModal from "../../components/PaynementModal";
import Client from "./Client";
import Credito from "../../sections/paymentMethods/Credito";
import { fNumber } from "../../utils/formatNumber";
import {
  calculeTotalAndDiscount,
  calculeTotalDiscount,
} from "../../utils/operations";
import { useDispatch, useSelector } from "react-redux";
import { size } from "lodash";
import { toast } from "react-toastify";
import { resetCart } from "../../redux/features/cart/cart";
import { setLoadOff, setLoadOn } from "../../redux/features/config/load";
import { Anticipo, TarjetaTransferencia } from "../../sections/paymentMethods";
import { storage } from "../../store/storage";
import Hibrido from "../../sections/paymentMethods/Hibrido";

const Cajas = ({ code,total,clientg=null,setPaymentMethod, paymentMethod,setSales,setDataPrint,setCashDet,voucher_id=null,discountCode, setInfoPay,setDiscountCode,discount, setDiscount }) => {
  
  const [client, setClient] = useState({});  
  const cart = useSelector((state) => state.cartSales);
  const [cash,setCash] = useState([])
  const [saldo,setSaldo]= useState(0)
  //console.log("-->",code,saldo);
  const userData = useSelector(state=>state.userData)
  const dispatch = useDispatch();
 const [btnDisabled,setBtnDisabled] = useState(false)
  const handleChangePayment = () => {
    setPaymentMethod(!paymentMethod);
  };
 
  const handleMouseSearch = (event) => {
    event.preventDefault();
  };
  const handleSubmitDiscount = (event) => {
    event.preventDefault();
    applyDiscount({ barcode: discountCode}).then((res) => {
      if (size(res) > 0) {
        let discount = res.discount;
        if(userData.store_type === "almacen"){
          discount = res.discount_almacen;
        }
        setDiscount(discount);
        setClient(res.client);
      }
    });
  };
  const saleEfectivo = (type) => {
    if(voucher_id == null){
      if (size(cart) <= 0) {
        toast.error("Debe seleccionar al menos un item en su carrito!");
        return;
      }
    }
    
    if (size(client) <= 0) {
      toast.error("Registre datos de cliente!");
      return;
    }
    const val = {
      voucher_id: voucher_id,
      client:client,
      cart: cart,
      discount: discount,
      subtotal: total,
      total: calculeTotalAndDiscount(discount, total),
      totalDiscount: calculeTotalDiscount(discount, total),
      tarjeta: null,
      banco: null,
      cxc: null,
      type: type,
      paymentMethod:paymentMethod,
    };
    setBtnDisabled(true)
    collectV1(val).then(res=>{
        setDataPrint(res);
       setInfoPay({total:val.total,monto:client.monto})
        setSales(true);
        dispatch(resetCart())
        storage.remove("client")
    }).finally(()=>{
      setBtnDisabled(false)
    })
    
  };
  const loadCash = () => {
    getCash()
      .then((res) => {
        setCash(res);
        setCashDet(res);
      })
      .finally(() => {
       
      });
  };
  useEffect(()=>{
    loadCash()
  },[])
  useEffect(()=>{
    if(clientg){
      setClient(clientg)
    }
  },[total,voucher_id])
  useEffect(()=>{
    setSaldo()
  },[client,voucher_id,total])
  const saveHibrido=(values)=>{
    if (size(client) <= 0) {
      toast.error("Registre datos de cliente!");
      return;
    }
    const val = {
      voucher_id: voucher_id,
      client: client,
      cart: cart,
      discount: discount,
      subtotal: total,
      total: calculeTotalAndDiscount(discount, total),
      totalDiscount: calculeTotalDiscount(discount, total),
      paynnemt: values,
      type:200,
      paymentMethod:paymentMethod,
    };
      setBtnDisabled(true)
    collectV1(val).then(res=>{
        setDataPrint(res);
        setSales(true);
        dispatch(resetCart())
    }).finally(()=>{
      setBtnDisabled(false)
    })
  }
  const saveCredito=(values)=>{
    
   /*  if (size(cart) <= 0) {
        toast.error("Debe seleccionar al menos un item en su carrito!");
        return;
      } */
      if (size(client) <= 0) {
        toast.error("Registre datos de cliente!");
        return;
      }
      const val = {
        voucher_id: voucher_id,
        client: client,
        cart: cart,
        discount: discount,
        subtotal: total,
        total: calculeTotalAndDiscount(discount, total),
        totalDiscount: calculeTotalDiscount(discount, total),
        tarjeta: null,
        banco: null,
        a_cuenta: values.a_cuenta,
        observation: values.aditional,
        confirm: values.confirm,
        user_confirm:values.user_confirm,
        cxc: true,
        type: 6,
        paymentMethod:paymentMethod,
      };
        setBtnDisabled(true)
      collectV1(val).then(res=>{
          setDataPrint(res);
         setInfoPay({total:val.total,monto:client.monto})
          setSales(true);
          dispatch(resetCart())
      }).finally(()=>{
      setBtnDisabled(false)
    })
  }
  const saveAnticipo=(values)=>{
    if (size(client) <= 0) {
      toast.error("Registre datos de cliente!");
      return;
    }
    const val = {
      voucher_id: voucher_id,
      client: client,
      cart: cart,
      discount: discount,
      subtotal: total,
      total: calculeTotalAndDiscount(discount, total),
      totalDiscount: calculeTotalDiscount(discount, total),
      tarjeta: null,
      banco: null,
      observation: values.aditional,
      cxc: null,
      type: 1,
      paymentMethod:paymentMethod,
    };
      setBtnDisabled(true)
    collectV2(val).then(res=>{
        
        setDataPrint(res);
       setInfoPay({total:val.total,monto:client.monto})
        setSales(true);
        dispatch(resetCart())
    }).finally(()=>{
      setBtnDisabled(false)
    })
  }
  const saveQr=(values)=>{
    if (size(client) <= 0) {
      toast.error("Registre datos de cliente!");
      return;
    }
    const val = {
      voucher_id: voucher_id,
      client: client,
      cart: cart,
      discount: discount,
      subtotal: total,
      total: calculeTotalAndDiscount(discount, total),
      totalDiscount: calculeTotalDiscount(discount, total),
      tarjeta: null,
      banco: null,
      paid_by:values.paid_by,
      bank: values.bank,
      a_cuenta: values.a_cuenta,
      observation: values.aditional,
      origin_bank:values.origin_bank ,
      bank_id:values.bank_id ,
      destination_bank:values.destination_bank ,
      voucher_number:values.voucher_number ,
      owner_name:values.owner_name ,
      cxc: true,
      type: values.type,      
      method: "qr",
      paymentMethod:paymentMethod,
    };
      setBtnDisabled(true)
    collectV1(val).then(res=>{
        setDataPrint(res);
        setInfoPay({total:val.total,monto:client.monto})
        setSales(true);
        dispatch(resetCart())
    }).finally(()=>{
    setBtnDisabled(false)
  })
  }
  useEffect(()=>{
      const client = storage.get("client")
      storage.set("client",{...client, total:total, discount:discount})
  },[client,total,discount])
  useEffect(()=>{
    const cliente = storage.get("client")
    storage.set("client",{...cliente, identification_number: client.identification_number,business_name:client.business_name})
},[client])
  
  return (
    <Paper>
        {
            size(cash) > 0 ?       
            <div>
        <div className="p-3">
        <div className="lg:grid grid-cols-5 bg-white border-2 p-6 rounded-md">
          <div className="flex flex-col items-center">
            <FormControlLabel
              control={
                <Switch
                  name="payment_method"
                  value={paymentMethod}
                  checked={paymentMethod}
                  onChange={handleChangePayment}
                />
              }
              label="Precio Facturado"
            />
            <form onSubmit={handleSubmitDiscount}>
              <TextField
                size="small"
                id="input-with-icon-textfield"
                placeholder="Tarjeta de descuento"
                onChange={(e) => setDiscountCode(e.target.value)}                
                value={discountCode}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search"
                        type="submit"
                        onMouseDown={handleMouseSearch}
                        edge="end"
                      >
                        <PercentIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">SUBTOTAL Bs:</p>
            <p className="text-4xl font-semibold text-gray-500">
              {fNumber(total)}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">DESCUENTO %:</p>
            <p className="text-4xl font-semibold text-yellow-500">
              {fNumber(discount)}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">TOTAL Bs:</p>
            <p className="text-4xl font-semibold text-green-500">
              {fNumber(calculeTotalAndDiscount(discount, total))}
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">CAMBIO Bs:</p>
            <p className={ parseFloat(client.monto || 0) -
                  calculeTotalAndDiscount(discount, total) < 0 ?"text-4xl font-semibold text-red-500":"text-4xl font-semibold text-green-500"} >
              {fNumber(
                parseFloat(client.monto || 0) -
                  calculeTotalAndDiscount(discount, total)
              )}
            </p>
          </div>
        </div>
        <Client saldo={saldo} code={code} setSaldo={setSaldo} client={client} setCliente={setClient} />
      </div>

      <div className="">
        <Buttons>
         { !code && 
         <Fragment>
         <Button
            style={{ background: green[600] }}
            onClick={() => saleEfectivo(1)}
            disabled={btnDisabled}
          >
            efectivo
          </Button>
         
          <PaynemetModal 
          disabled={btnDisabled}
          savePay={saveQr}
            btn={"QR"}
            title="Cobro por QR"
            color={purple[800]}
          >
            <TarjetaTransferencia
              type="7"
              destination_bank={true}
              data={{
                discount: discount,
                total: total,
                client:client,
                paymentMethod: paymentMethod,
                cart: true,
              }}
            />
          </PaynemetModal>
        
          <PaynemetModal 
          disabled={btnDisabled}
          savePay={saveQr}
            btn={"Transferencia"}
            title="Cobro por transferencia"
            color={pink[800]}
          >
            <TarjetaTransferencia
              type="4"
              origin_bank={true}
              destination_bank={true}
              voucher_number={true}
              data={{
                discount: discount,
                total: total,
                client:client,
                paymentMethod: paymentMethod,
                cart: true,
              }}
            />
          </PaynemetModal>
          <PaynemetModal 
          disabled={btnDisabled}
          savePay={saveQr}
            btn={"Deposito"}
            title="Cobro por deposito"
            color={blue[800]}
          >
            <TarjetaTransferencia
              type="3"
              origin_bank={false}
              destination_bank={true}
              voucher_number={true}
              data={{
                discount: discount,
                total: total,
                client:client,
                paymentMethod: paymentMethod,
                cart: true,
              }}
            />
          </PaynemetModal>
          <PaynemetModal 
          disabled={btnDisabled}
          savePay={saveQr}
            btn={"Cheque"}
            title="Cobro por cheque"
            color={teal[800]}
          >
            <TarjetaTransferencia
              type="5"
              origin_bank={true}
              voucher_number={true}
              owner_name={true}              
              title="cheque"
              data={{
                discount: discount,
                total: total,
                client:client,
                paymentMethod: paymentMethod,
                cart: true,
              }}
            />
          </PaynemetModal>
          <PaynemetModal 
          disabled={btnDisabled}
          savePay={saveQr}
            btn={"Tarjeta"}
            title="Tarjeta"
            color={blue[800]}
          >
            <TarjetaTransferencia
              type="8"
              destination_bank={true}
              voucher_number={true}
              data={{
                discount: discount,
                total: total,
                client:client,
                paymentMethod: paymentMethod,
                cart: true,
              }}
            />
          </PaynemetModal>
              
       
          <PaynemetModal disabled={btnDisabled} btn={"Credito"} savePay={saveCredito} title="Credito" color={orange[600]}>
            <Credito
              type="6"
              data={{               
                discount: discount,
                total: total,
                client:client,
                paymentMethod: paymentMethod,
                voucher_id:voucher_id,
                cart: true,
              }}
            />
          </PaynemetModal>
         </Fragment>}
          <PaynemetModal disabled={btnDisabled} btn={"Hibrido"} savePay={saveHibrido} title="Hibrido" color={green[900]}>
            <Hibrido              
              data={{               
                discount: discount,
                total: total,
                saldo:saldo,
                code:code,
                client:client,
                paymentMethod: paymentMethod,
                voucher_id:voucher_id,
                cart: true,
              }}
            />
          </PaynemetModal>
        { parseFloat(saldo) > 0 &&  <PaynemetModal disabled={btnDisabled} btn={"Anticipo"} savePay={saveAnticipo} title="usar_anticipo" color={blue[600]}>
            <Anticipo
              type="6"
              data={{
                saldo:saldo,
                discount: discount,
                total: total,
                client:client,
                paymentMethod: paymentMethod,
                voucher_id:voucher_id,
                cart: true,
              }}
            />
          </PaynemetModal>}
        </Buttons>
      </div>
        </div>
        :
        <FormNewCashbox setCash={setCash} cash={cash} />
        }
    </Paper>
  );
};

export default Cajas;

const Buttons = ({children})=>{
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if(isMobile){
    return(
      <Stack spacing={1}>
        {children}
      </Stack>
    )

  }else{
    return(
      <ButtonGroup variant="contained" fullWidth>
        {children}
      </ButtonGroup>
    )

  }
}
const FormNewCashbox = ({ setCash, cash }) => {
    const [mount, setMount] = useState(0);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const dispatch = useDispatch()
    const handleSubmit = (e) => {
      dispatch(setLoadOn())
      setBtnDisabled(true);
      e.preventDefault();
      initCash({ initial_amount: mount })
        .then((res) => {
          setCash(res);
        })
        .finally(() => {
          dispatch(setLoadOff())
          setBtnDisabled(false);
        });
    };
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div className="inline-flex gap-3">
            <TextField
              value={mount}
              onChange={(e) => setMount(e.target.value)}
              name="mount"
              sx={{ my: 1 }}
              type="number"
              label="Monto Inicial"
              variant="outlined"
              size="small"
            />
            <Button
              sx={{ my: 1 }}
              variant="contained"
              type="submit"
              disabled={btnDisabled}
            >
              abrir caja
            </Button>
          </div>
        </form>
      </div>
    );
  };
