import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";

import { useTheme } from "@mui/material/styles";
import { newClient } from "../../services/client";
import CloseIcon from "@mui/icons-material/Close";
import MapSelect from "../../components/MapSelect";
import EditIcon from "@mui/icons-material/Edit";
import { chanels, clientType, documentoIdentidad } from "../../utils/factu/catalogos";
import * as yup from "yup";
import MapSelect2 from "../../components/MapSelect2";
const validationSchema = yup.object({
  business_name: yup.string().required("Ingrese este campo"),
  identification_number: yup
    .string()
    .required("Ingrese este campo"),
});

const ClientFormModal = (props) => {
  const { client ,setClient } = props
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const getLocation = (location) => {
    if (location?.location) {
      const coords = location.location.coords;
      return coords ? coords : location.location;
    }
  
    return "";
  };
  const formik = useFormik({
    initialValues: {
      business_name: client?.business_name || "",
      identification_number: client?.identification_number || "",
      document_type:client?.document_type || "1",
      complement:client?.complement || "",
      company_name: client?.company_name || "",     
      city: client?.city || "",
      direction: client?.direction || "",
      macrodistrito: client?.macrodistrito || "",
      zona: client?.zona || "",
      phone: client?.phone || "",
      whatsapp: client?.whatsapp || "",
      email: client?.email || "",
      subscription: client?.subscription || "",
      birthday: client?.birthday || "",
      sales_chanel: client?.sales_chanel ||"1", 
      business_type: client?.business_type ||"1", 
      location: getLocation(client?.location),
      id:client?.id,
      fecha_aniversario:client?.fecha_aniversario||"",
      mascota_a:client?.macota_a||"",
      tipo_mascota_a:client?.tipo_mascota_a||"",
      mascota_b:client?.macota_b||"",
      tipo_mascota_b:client?.tipo_mascota_b||""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      newClient(values).then((res) => {
        setClient(res)
        setOpen(false)
      });
    },
  });
const setLocation=(location)=>{
  
  formik.setFieldValue("location",location)
} 
const [selectedPoint, setSelectedPoint] = useState(null);

  const handlePointSelect = (point) => {
    setSelectedPoint(point);
  };
  return (
    <div>
      {client?.id ? <IconButton color="success"  onClick={() => setOpen(true)}> 
        <EditIcon/>
      </IconButton>:<Button onClick={() => setOpen(true)}>Agregar Cliente</Button>}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        scroll="paper"
        maxWidth="md"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Registro de cliente
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          
          <form onSubmit={formik.handleSubmit}>
            <TextField
              value={formik.values.business_name}
              onChange={formik.handleChange}
              name="business_name"
              fullWidth
              sx={{ my: 1 }}
              label="Nombre razon social"
              error={
                formik.touched.business_name &&
                Boolean(formik.errors.business_name)
              }
              helperText={
                formik.touched.business_name && formik.errors.business_name
              }
              variant="outlined"
              size="small"
            />

            <div className="w-full inline-flex gap-2">
            <FormControl fullWidth>
            <InputLabel id="tipo_documento">Tipo de documento</InputLabel>
            <Select
              labelId="tipo_documento"
              name="document_type"
              value={formik.values.document_type}
              label="Tipo de documento"
              onChange={formik.handleChange}
              size="small"
              sx={{ my: 1 }}
            >
              {
                documentoIdentidad.map((row,key)=>
                <MenuItem key={key} value={row.codigoClasificador}>{row.descripcion}</MenuItem>                
                )
            }
            </Select>
            </FormControl>
            <TextField
              value={formik.values.identification_number}
              onChange={formik.handleChange}
              name="identification_number"
              fullWidth
              sx={{ my: 1 }}
              label="Nit / CI"
              error={
                formik.touched.identification_number &&
                Boolean(formik.errors.identification_number)
              }
              helperText={
                formik.touched.identification_number && formik.errors.identification_number
              }
              variant="outlined"
              size="small"
            />  
            <TextField
              value={formik.values.complement}
              onChange={formik.handleChange}
              name="complement"
              fullWidth
              sx={{ my: 1 }}
              label="Complemento"
              error={
                formik.touched.complement &&
                Boolean(formik.errors.complement)
              }
              helperText={
                formik.touched.complement && formik.errors.complement
              }
              variant="outlined"
              size="small"
            />  
            </div>          


            <TextField
              value={formik.values.company_name}
              onChange={formik.handleChange}
              name="company_name"
              fullWidth
              sx={{ my: 1 }}
              label="Nombre del cliente"
              error={
                formik.touched.company_name &&
                Boolean(formik.errors.company_name)
              }
              helperText={
                formik.touched.company_name && formik.errors.company_name
              }
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.email}
              onChange={formik.handleChange}
              name="email"
              fullWidth
              sx={{ my: 1 }}
              label="E-mail"
              type="email"
              error={
                formik.touched.email &&
                Boolean(formik.errors.email)
              }
              helperText={
                formik.touched.email && formik.errors.email
              }
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.birthday}
              onChange={formik.handleChange}
              name="birthday"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ my: 1 }}
              label="Fecha Nacimiento"
              type="date"
              error={
                formik.touched.birthday &&
                Boolean(formik.errors.birthday)
              }
              helperText={
                formik.touched.birthday && formik.errors.birthday
              }
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.city}
              onChange={formik.handleChange}
              name="city"
              fullWidth
              sx={{ my: 1 }}
              label="Ciudad"
              error={
                formik.touched.city &&
                Boolean(formik.errors.city)
              }
              helperText={
                formik.touched.city && formik.errors.city
              }
              variant="outlined"
              size="small"
            />
            
            <TextField
              value={formik.values.macrodistrito}
              onChange={formik.handleChange}
              name="macrodistrito"
              fullWidth
              sx={{ my: 1 }}
              label="Makrodistrito"
              error={
                formik.touched.macrodistrito &&
                Boolean(formik.errors.macrodistrito)
              }
              helperText={
                formik.touched.macrodistrito && formik.errors.macrodistrito
              }
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.zona}
              onChange={formik.handleChange}
              name="zona"
              fullWidth
              sx={{ my: 1 }}
              label="Zona"
              error={
                formik.touched.zona &&
                Boolean(formik.errors.zona)
              }
              helperText={
                formik.touched.zona && formik.errors.zona
              }
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.direction}
              onChange={formik.handleChange}
              name="direction"
              fullWidth
              sx={{ my: 1 }}
              label="Dirección"
              error={
                formik.touched.direction &&
                Boolean(formik.errors.direction)
              }
              helperText={
                formik.touched.direction && formik.errors.direction
              }
              variant="outlined"
              size="small"
            />
              <div className="w-full inline-flex gap-2">
            <TextField
              value={formik.values.phone}
              onChange={formik.handleChange}
              name="phone"
              fullWidth
              sx={{ my: 1 }}
              label="Teléfono"
              error={
                formik.touched.phone &&
                Boolean(formik.errors.phone)
              }
              helperText={
                formik.touched.phone && formik.errors.phone
              }
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.whatsapp}
              onChange={formik.handleChange}
              name="whatsapp"
              fullWidth
              sx={{ my: 1 }}
              label="Whatsapp"
              error={
                formik.touched.whatsapp &&
                Boolean(formik.errors.whatsapp)
              }
              helperText={
                formik.touched.whatsapp && formik.errors.whatsapp
              }
              variant="outlined"
              size="small"
            />
            </div>
            <div className="w-full inline-flex gap-2">
            <FormControl fullWidth>
            <InputLabel id="sales_chanel">Canal de venta</InputLabel>
            <Select
              labelId="sales_chanel"
              name="sales_chanel"
              value={formik.values.sales_chanel}
              label="Canal de Venta"
              onChange={formik.handleChange}
              size="small"
              sx={{ my: 1 }}
            >
              {
                chanels.map((row,key)=>
                <MenuItem key={key} value={row.code}>{row.descripcion}</MenuItem>                
                )
            }
            </Select>
            </FormControl>
            <FormControl fullWidth>
            <InputLabel id="business_type">Tipo de negocio</InputLabel>
            <Select
              labelId="business_type"
              name="business_type"
              value={formik.values.business_type}
              label="Tipo de Cliente"
              onChange={formik.handleChange}
              size="small"
              sx={{ my: 1 }}
            >
              {
                clientType.map((row,key)=>
                <MenuItem key={key} value={row.code}>{row.descripcion}</MenuItem>                
                )
            }
            </Select>
            </FormControl>
            </div>
            <div>
            <TextField
              value={formik.values.fecha_aniversario}
              onChange={formik.handleChange}
              name="fecha_aniversario"
              fullWidth
              sx={{ my: 1 }}
              label="Aniversario"
              error={
                formik.touched.fecha_aniversario &&
                Boolean(formik.errors.fecha_aniversario)
              }
              helperText={
                formik.touched.fecha_aniversario && formik.errors.fecha_aniversario
              }
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              variant="outlined"
              size="small"
            />
            </div>
            <div className="w-full inline-flex gap-2">
            <TextField
              value={formik.values.mascota_a}
              onChange={formik.handleChange}
              name="mascota_a"
              fullWidth
              sx={{ my: 1 }}
              label="Mascota"
              error={
                formik.touched.mascota_a &&
                Boolean(formik.errors.mascota_a)
              }
              helperText={
                formik.touched.mascota_a && formik.errors.mascota_a
              }
              
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.tipo_mascota_a}
              onChange={formik.handleChange}
              name="tipo_mascota_a"
              fullWidth
              sx={{ my: 1 }}
              label="Tipo de Mascota"
              error={
                formik.touched.tipo_mascota_a &&
                Boolean(formik.errors.tipo_mascota_a)
              }
              helperText={
                formik.touched.tipo_mascota_a && formik.errors.tipo_mascota_a
              }
              
              variant="outlined"
              size="small"
            />
            </div>
            <div className="w-full inline-flex gap-2">
            <TextField
              value={formik.values.mascota_b}
              onChange={formik.handleChange}
              name="mascota_b"
              fullWidth
              sx={{ my: 1 }}
              label="Mascota"
              error={
                formik.touched.mascota_b &&
                Boolean(formik.errors.mascota_b)
              }
              helperText={
                formik.touched.mascota_b && formik.errors.mascota_b
              }
              
              variant="outlined"
              size="small"
            />
            <TextField
              value={formik.values.tipo_mascota_b}
              onChange={formik.handleChange}
              name="tipo_mascota_b"
              fullWidth
              sx={{ my: 1 }}
              label="Tipo de Mascota"
              error={
                formik.touched.tipo_mascota_b &&
                Boolean(formik.errors.tipo_mascota_b)
              }
              helperText={
                formik.touched.tipo_mascota_b && formik.errors.tipo_mascota_b
              }
              
              variant="outlined"
              size="small"
            />
            </div>
           {/*<MapSelect setCoords={setLocation}  coords={client?.location?.location}/> */}
             <MapSelect2 onPointSelect={handlePointSelect} setCoords={setLocation}  coords={getLocation(client?.location)}/> 
            <Button
        className="no-print"
        fullWidth
        variant="contained"
        type="submit"
        
      >
        Registrar
      </Button>
              
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ClientFormModal;
