import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Tooltip
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import PercentIcon from "@mui/icons-material/Percent";
import Swal from "sweetalert2";
import { applyDiscount, collectVoucher } from "../../services/cashBox";
import { calculeTotalAndDiscount } from "../../utils/operations";
import { fNumber } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";
import { setLoadOff, setLoadOn } from "../../redux/features/config/load";
import { uniqBy } from "lodash";
const validationSchema = yup.object({
  business_name: yup.string().required("Ingrese este campo"),
  identification_number: yup.number().min(1).required(),
});
const PayForm = ({ data, total,setPrintData,setFacturadores }) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: {
      business_name: data?.business_name || "",
      identification_number: data?.identification_number || "",
      tipo_identidad: data.client.document_type || "5",
      mount: 0,
      invoice: parseInt(data.invoice) === 0 ? false:true,
      discount: 0,
      voucher_id: data.id,
      total:total,
      cart: false,
      payment_type:'efectivo'
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        dispatch(setLoadOn())
        setBtnDisabled(true)
        collectVoucher(values).then(res=>{
          if(res.invoice === "yes"){
            setFacturadores(res.data)
          }else{
            setPrintData(res.data)
          }
         
        }).finally(()=>{
            dispatch(setLoadOff())
            setBtnDisabled(false)
        })
    },
  });

  const discountAdd = () => {
    Swal.fire({
      title: "Anote el codigo de tarjeta para aplicar el descuento",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      input: "number",
      inputLabel: "codido",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, aplicar!",
    }).then((result) => {
      if (result.isConfirmed) {
        applyDiscount({ barcode: result.value }).then((res) => {
          if (!res) {
            formik.setFieldValue("discount", 0);
            formik.setFieldValue("cart", false);
            Swal.fire(
              "Error!",
              "No se encontro registros para la targeta, si se repite el error dar parte a Administracion.",
              "error"
            );
          } else {
            formik.setFieldValue("discount", res.discount);
             formik.setFieldValue("cart", res.discount>0 ?true:false);
          }
        });
      }
    });
  };

  return (    
    <form onSubmit={formik.handleSubmit}>
      <TextField
        value={formik.values.business_name}
        onChange={formik.handleChange}
        name="business_name"
        fullWidth
        sx={{ my: 1 }}
        label="Nombre Razon Social"
        error={
          formik.touched.business_name && Boolean(formik.errors.business_name)
        }
        helperText={formik.touched.business_name && formik.errors.business_name}
        variant="outlined"
        size="small"
      />
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Tipo de identificacion:</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={formik.values.tipo_identidad}
          onChange={formik.handleChange}
          name="tipo_identidad"
        >
          <FormControlLabel value="1" control={<Radio />} label="CI - CEDULA DE IDENTIDAD" />
          <FormControlLabel value="5" control={<Radio />} label="NIT - NÚMERO DE IDENTIFICACIÓN TRIBUTARIA" />
        </RadioGroup>
      </FormControl>
      <TextField
        value={formik.values.identification_number}
        onChange={formik.handleChange}
        name="identification_number"
        fullWidth
        sx={{ my: 1 }}
        label="NIT"
        type="number"
        error={
          formik.touched.identification_number &&
          Boolean(formik.errors.identification_number)
        }
        helperText={
          formik.touched.identification_number &&
          formik.errors.identification_number
        }
        variant="outlined"
        size="small"
      />
      <TextField
        value={formik.values.mount}
        onChange={formik.handleChange}
        name="mount"
        fullWidth
        sx={{ my: 1 }}
        label="Monto"
        type="number"
        error={formik.touched.mount && Boolean(formik.errors.mount)}
        helperText={formik.touched.mount && formik.errors.mount}
        variant="outlined"
        size="small"
      />

      <div className="w-full inline-flex">
        <TextField
          value={formik.values.discount}
          disabled
          name="mount"
          fullWidth
          sx={{ my: 1 }}
          label="Descuento %"
          type="number"
          variant="outlined"
          size="small"
        />
		<Tooltip title="Tarjeta Cliente" followCursor >
        <IconButton type="button" color="primary" onClick={() => discountAdd()}>
          <PercentIcon />
        </IconButton>
		</Tooltip>
      </div>

      <div className="w-full inline-flex gap-5  justify-between">
        <h3
          className={
            "text-green-500 my-3 text-2xl font-semibold text-center w-full"
          }
        >
          Total:{" "}
          {fNumber(calculeTotalAndDiscount(formik.values.discount, total))}
        </h3>
        <h3
          className={
            calculeTotalAndDiscount(formik.values.discount, total) < total
              ? "text-red-500 text-2xl font-semibold my-3 text-center w-full"
              : "text-green-500 my-3 text-2xl font-semibold text-center w-full"
          }
        >
          Vuelto:{" "}
          {fNumber(
            formik.values.mount -
              calculeTotalAndDiscount(formik.values.discount, total)
          )}
        </h3>
      </div>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Facturado</FormLabel>
        <RadioGroup
        
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={formik.values.invoice}
          name="invoice"
        >
          <FormControlLabel value={true} disabled control={<Radio />} label="Si" />
          <FormControlLabel value={false} disabled control={<Radio />} label="No" />
        </RadioGroup>
      </FormControl>
      <Divider/>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">Conbran en:</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={formik.values.payment_type}
          onChange={formik.handleChange}
          name="payment_type"
        >
          <FormControlLabel value="efectivo" control={<Radio />} label="Efectivo" />
          <FormControlLabel value="transferencia" control={<Radio />} label="Transferencia" />
          <FormControlLabel value="qr" control={<Radio />} label="QR" />
          <FormControlLabel value="deposito" control={<Radio />} label="Deposito" />
          <FormControlLabel value="cheque" control={<Radio />} label="Cheque" />
        </RadioGroup>
      </FormControl>
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={btnDisabled}
      >
        Cobrar
      </Button>
    </form>
  );
};
export default PayForm;
