import { includes } from "lodash"
import { findKey } from "lodash"
import { useSelector } from "react-redux"
import { Navigate, Outlet, Route } from "react-router-dom"

const RoleGuard = ({ component: Component, roles, ...rest }) => {
  // Obtenemos el rol del usuario actual (asumiendo que se almacena en una variable global)
  
  const {role} = useSelector(state=>state.userData)
  // Verificamos si el usuario actual está en la lista de roles permitidos

  const isAllowed = includes(roles,role);

  // Si el usuario tiene acceso, renderizamos la ruta normalmente
  if (isAllowed) {
    return <Outlet/>;
  }
  
  // Si no tiene acceso, renderizamos un componente que indique que no tiene permisos
  return  <Navigate replace to={'error-404'}/>;
}
export default RoleGuard

// ...

//<ProtectedRoute path="/admin" roles={['admin']} component={AdminPage} />