import API from "../axios/api"

export const reportToday = async ()=>{
    const {data} = await API.get('api/report/today')
    return data
}
export const getPendingOrders = async (page)=>{
    const {data} = await API.get('api/report/pending-orders?page='+page)
    return data
}
export const setMarkShipped = async (val)=>{
    const {data} = await API.post('api/order/mark-to-shipped',val)
    return data
}
export const reportDate = async (val)=>{
    const {data} = await API.post('api/report/date',val)
    return data
}
export const recordsRequest = async (val)=>{
    const {data} = await API.post('api/records/request',val)
    return data
}

////
export const reportStock = async (values)=>{
    const {data} = await API.post('api/report/stock',values)
    return data;
}
export const reportStockExist = async (values)=>{
    const {data} = await API.post('api/report/stock-exist',values)
    return data;
}

export const reportPro = async (values,page)=>{
    const {data} = await API.post('api/report/pro?=page='+page,values)
    return data;
}
export const getDocumentPrint = async (values)=>{
    const {data} = await API.post('api/get-document',values)
    return data;
}
export const getSaleRequest = async ()=>{
    const {data} = await API.get('api/report/home')
    return data;
}
export const masVendidos = async (val)=>{
    const {data} = await API.post('api/estadistica/mas-vendidos',val)
    return data;
}
export const clientesTop = async (val)=>{
    const {data} = await API.post('api/estadistica/clientes',val)
    return data;
}
export const frequentItemsets = async (val)=>{
    const {data} = await API.post('api/estadistica/frequent-itemsets',val)
    return data;
}
export const getSuggestion = async (val)=>{
    const {data} = await API.post('api/estadistica/suggestion',val)
    return data;
}
export const uploadInventory= async (val)=>{
    const {data} = await API.post('api/inventory-upload',val,{headers: {
        'content-type': 'multipart/form-data',
      }},)
    return data
}
export const getMapData= async (val)=>{
    const {data} = await API.post('api/mapa',val)
    return data
}
