import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search';

function SearchForm({searchFunction, fullWith=false}) {
  const [searchInput,setSearchValue]=useState();
    const handleSubmit=(event)=>{
      event.preventDefault();
      searchFunction(searchInput)      
    }
  const handleMouseSearch=(event)=>{
    event.preventDefault();
  }
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  return (
    <form onSubmit={handleSubmit}>
     <TextField
        size="small"
        id="input-with-icon-textfield"
        placeholder="Buscar..."
        onChange={handleChange}
        fullWidth={fullWith}
        autoFocus
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="search"
                type="submit"
                onMouseDown={handleMouseSearch}
                edge="end"
              >
              <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
     </form>
  )
}

export default SearchForm