import { Button, Paper } from "@mui/material";
import { size } from "lodash";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";

import Page from "../../layout/Pagev1";
import { allProducts } from "../../services/product";
import { fNumber } from "../../utils/formatNumber";

const BarcodeAll = () => {
  const componentRef = useRef();
  const [data, setData] = useState([]);
  const allData = () => {
    allProducts().then((res) => {
      setData(res);
    });
  };
  useEffect(() => {
    allData();
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Page appBar={true} title={"codigo de barras de todos los productos"}>
      <Paper className="p-3">
        <Button onClick={() => handlePrint()}>imprimir</Button>
        <div ref={componentRef}>
          <table className="table-print">
            <thead>
              <th>codigo de barras</th>
              <th>codigo</th>
              <th>descripcion</th>
              <th>packing</th>
              <th>precio venta</th>
            </thead>
            {size(data) > 0 &&
              data.map((row, key) => (
                <tr key={key}>
                  <td>
                    {" "}
                    <Barcode
                      key={key}
                      value={row.barcode}
                      width={2}
                      height={60}
                      format={"CODE128"}
                      displayValue={false}
                      fontOptions={""}
                      font={""}
                      textAlign={"center"}
                      textPosition={"bottom"}
                      textMargin={2}
                      fontSize={20}
                      background={"#ffffff"}
                      lineColor={"#000000"}
                      margin={10}
                    />
                  </td>
                  <td>{row.barcode}</td>
                  <td>{row.detail}</td>
                  <td>{row.sale_presentation + " x " + row.units}</td>
                  <td>
                    <table className="w-full">
                      <thead>
                        <tr>
                            <th>Desde</th>
                            <th>Hasta</th>
                            <th>P F Bs</th>
                            <th>P S/F Bs</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          row.price.sale_prices.map((ro,key1)=>
                          <tr key={key1+"-"+row.id}>
                          <td>{ro.max_units}</td>
                          <td>{ro.type}</td>
                          <td className="text-right">{fNumber(ro.price_a)}</td>
                          <td className="text-right">{fNumber(ro.price_b)}</td>
                        </tr>
                        )
                        }
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </Paper>
    </Page>
  );
};
export default BarcodeAll;
