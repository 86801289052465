import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { find, size } from "lodash";
import * as yup from "yup";
import { listApiService, postApiService } from "../../../services/crudServices";
import { useSelector } from "react-redux";

const validationSchema = yup.object({
  company_id: yup.string().required("Ingrese este campo"),
  z_company_branch_id: yup.string().required("Ingrese este campo"),
  unidad_id: yup.string().required("Ingrese este campo"),
  ciudad_id: yup.string().required("Ingrese este campo"),
  oficina_id: yup.string().required("Ingrese este campo"),
  nro_alta: yup.string().required("Ingrese este campo"),
  tipo_alta: yup.string().required("Ingrese este campo"),
});
const NewActivo = ({ closeModal }) => {
  const [stores, setStores] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [cuadroActivos, setCuadroActivos] = useState([]);
  const [unidad,setUnidad]= useState([])
  const [ciudad,setCiudad] = useState([])
  const [edificio,setEdificio] = useState([])
  const [oficina,setOficina] = useState([])
  const [rubros,setRubros] = useState([])
  const [tipos,setTipos] = useState([])
  const [personal,setPersonal] = useState([])
  const userData = useSelector((state) => state.userData);
  const formik = useFormik({
    initialValues: {
      company_id:"",
      z_company_branch_id:"",
      store_id:"",
      unidad_id:"",
      ciudad_id:"",
      oficina_id:"",
      division_id:"",
      nro_alta:"0",
      tipo_alta:"",
      fecha_alta:"",
      fecha_baja:"",
      ufv:"",
      rubro_id: "",
      tipo_cambio:"",
      moneda:"",
      valor_inicial:"",
      dep_acumulada:"",
      valor_recidual:"",
      tipo_bien_id:"",
      estado_bien:"",
      placa:"",
      descripcion:"",
      responsable:"",
      autorizado_por:"",
      barcode:"",
      motivo_baja:"",
    },
    
    onSubmit: (values) => {
      postApiService("activo-fijo",values).then((res) => {
        closeModal();
      });
    },
  });
 

  const loadUnidad=()=>{
    listApiService('unidad').then(res=>{
        setUnidad(res)
    })
}
const loadRubroContable=()=>{
  listApiService('rubro-contable').then(res=>{
      setRubros(res)
  })
}
const loadPersonal=()=>{
  listApiService('personal-list').then(res=>{
      setPersonal(res)
  })
}
const groupedData = userData.user.companies.map(company => {
  const branches = company.branches.reduce((acc, branch) => {
      const existingBranch = acc.find(b => b.sucursal === branch.sucursal);
      if (existingBranch) {
          existingBranch.store.push(branch.store);
      } else {
          acc.push({
              id:branch.id,
              sucursal: branch.sucursal,
              sucursal_code: branch.sucursal_code,
              direction: branch.direction,
              city: branch.city,
              phone: branch.phone,
              store: [branch.store]
          });
      }
      return acc;
  }, []);
  return {
      ...company,
      branches: branches
  };
});
  useMemo(() => {
    loadPersonal();
    loadUnidad();
    loadRubroContable();
  }, []);
  const handleChangeCompany = (e) => {
    formik.setFieldValue("company_id", e.target.value);
    const branches = find(groupedData,o=>{
      return o.id === e.target.value
  })
    setBranchOffices(branches?.branches||[]);
  };
  const handleChangeUnidad=(e)=>{
    formik.setFieldValue("unidad_id", e.target.value);
    const unidadSe = find(unidad, (o) => {
      return o.id === e.target.value;
    });
    setCiudad(unidadSe?.ciudad);
  }  
  const handleChangeCiudad=(e)=>{
    formik.setFieldValue("ciudad_id", e.target.value);
    const ciudadOf = find(ciudad, (o) => {
      return o.id === e.target.value;
    });
    setEdificio(ciudadOf?.edificio);
  }
  const handleChangeEdificio=(e)=>{
    formik.setFieldValue("edificio_id", e.target.value);
    const edifOf = find(edificio, (o) => {
      return o.id === e.target.value;
    });
    setOficina(edifOf?.oficina);
  }
  const handleChangeRubro=(e)=>{
    formik.setFieldValue("rubro_id", e.target.value);
    const rubroTi = find(rubros, (o) => {
      return o.id === e.target.value;
    });
    setTipos(rubroTi?.tipo_bien);
  }

  const handleChangeBranch = (e) => {    
   // setTargetStore("all")
    const stores = find(branchOffices,o=>{
        return o.id === e.target.value
    })
    formik.setFieldValue('z_company_branch_id',e.target.value)
    setStores(stores?.store||[])

  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="md:grid grid-cols-2 gap-2">
          <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={formik.values.company_id}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.company_id && Boolean(formik.errors.company_id)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              {groupedData.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.company_id && formik.errors.company_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="z_company_branch_id">Sucursal</InputLabel>
            <Select
              labelId="empresa"
              id="z_company_branch_id"
              disabled={size(branchOffices) === 0}
              name="z_company_branch_id"
              label="Sucursal"
              value={formik.values.z_company_branch_id}
              onChange={handleChangeBranch}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.z_company_branch_id &&
                Boolean(formik.errors.z_company_branch_id)
              }
            >              
              {branchOffices.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.sucursal}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.z_company_branch_id &&
                formik.errors.z_company_branch_id}
            </FormHelperText>
          </FormControl>
          <FormControl  fullWidth>
          <InputLabel id="store_id">Almacen / Sala</InputLabel>
          <Select
            labelId="store_id"
            id="store_id"
            name="store_id"
            value={formik.values.store_id}
            label="Almacen / Sala"
            size="small"
            sx={{ my: 1 }}
            onChange={formik.handleChange}
          >
          
            {size(stores)>0 &&
            stores.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText color="error">
              {formik.touched.store_id &&
                formik.errors.store_id}
            </FormHelperText>
        </FormControl>

          <FormControl fullWidth>
            <InputLabel id="unidad_id">Unidad</InputLabel>
            <Select
              labelId="unidad_id"
              id="unidad_id"              
              name="unidad_id"
              label="Sucursal"
              value={formik.values.unidad_id}
              onChange={handleChangeUnidad}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.unidad_id &&
                Boolean(formik.errors.unidad_id)
              }
            >
              
              {unidad.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.unidad}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.unidad_id &&
                formik.errors.unidad_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="ciudad_id">Ciudad</InputLabel>
            <Select
              labelId="ciudad_id"
              id="ciudad_id"              
              name="ciudad_id"
              label="Ciudad"
              value={formik.values.ciudad_id}
              onChange={handleChangeCiudad}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.ciudad_id &&
                Boolean(formik.errors.ciudad_id)
              }
            >
              {ciudad.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.ciudad}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.ciudad_id &&
                formik.errors.ciudad_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="edificio_id">Edificio</InputLabel>
            <Select
              labelId="edificio_id"
              id="edificio_id"              
              name="edificio_id"
              label="Edificio"
              value={formik.values.edificio_id}
              onChange={handleChangeEdificio}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.edificio_id &&
                Boolean(formik.errors.edificio_id)
              }
            >              
              {edificio.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.edificio}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.edificio_id &&
                formik.errors.edificio_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="oficina_id">Oficina</InputLabel>
            <Select
              labelId="oficina_id"
              id="oficina_id"              
              name="oficina_id"
              label="Oficina"
              value={formik.values.oficina_id}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.oficina_id &&
                Boolean(formik.errors.oficina_id)
              }
            >
              <MenuItem value="">Seleccionar</MenuItem>
              
              {oficina.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.oficina}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.oficina_id &&
                formik.errors.oficina_id}
            </FormHelperText>
          </FormControl>

      

          <FormControl fullWidth>
            <InputLabel id="tipo_alta">
            Tipo Alta
            </InputLabel>
            <Select
              labelId="tipo_alta"
              id="tipo_alta"
              name="tipo_alta"
              label="Tipo Alta"
              value={formik.values.tipo_alta}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.tipo_alta &&
                Boolean(formik.errors.tipo_alta)
              }
            >
              <MenuItem value="nuevos">nuevos</MenuItem>
              <MenuItem value="usados">usados</MenuItem>
            
            </Select>
            <FormHelperText color="error">
              {formik.touched.tipo_alta &&
                formik.errors.tipo_alta}
            </FormHelperText>
          </FormControl>

          <TextField
            value={formik.values.fecha_alta}
            onChange={formik.handleChange}
            name="fecha_alta"
            fullWidth
            sx={{ my: 1 }}
            label="Fecha de Alta"
            error={formik.touched.fecha_alta && Boolean(formik.errors.fecha_alta)}
            helperText={formik.touched.fecha_alta && formik.errors.fecha_alta}
            variant="outlined"
            type="date"
            size="small"
          />
          <TextField
            value={formik.values.valor_inicial}
            onChange={formik.handleChange}
            className="col-span-2"
            name="valor_inicial"
            fullWidth
            sx={{ my: 1 }}
            label="Valor Inicial"
            error={formik.touched.valor_inicial && Boolean(formik.errors.valor_inicial)}
            helperText={formik.touched.valor_inicial && formik.errors.valor_inicial}
            variant="outlined"
            type="number"
            size="small"
          />
          <TextField
            value={formik.values.dep_acumulada}
            onChange={formik.handleChange}
            className="col-span-2"
            name="dep_acumulada"
            fullWidth
            sx={{ my: 1 }}
            label="Dep Acumulada"
            error={formik.touched.dep_acumulada && Boolean(formik.errors.dep_acumulada)}
            helperText={formik.touched.dep_acumulada && formik.errors.dep_acumulada}
            variant="outlined"
            type="number"
            size="small"
          />
          <TextField
            value={formik.values.valor_recidual}
            onChange={formik.handleChange}
            className="col-span-2"
            name="valor_recidual"
            fullWidth
            sx={{ my: 1 }}
            label="Valor Residual"
            error={formik.touched.valor_recidual && Boolean(formik.errors.valor_recidual)}
            helperText={formik.touched.valor_recidual && formik.errors.valor_recidual}
            variant="outlined"
            type="number"
            size="small"
          />

          <div className="col-span-2 text-xs font-semibold">
          <p>CLASIFICACION</p>
          </div>
          <FormControl fullWidth>
            <InputLabel id="rubro_id">Rubro Contable</InputLabel>
            <Select
              labelId="rubro_id"
              id="rubro_id"              
              name="rubro_id"
              label="Rubro Contable"
              value={formik.values.rubro_id}
              onChange={handleChangeRubro}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.rubro_id &&
                Boolean(formik.errors.rubro_id)
              }
            >
              <MenuItem value="">Seleccionar</MenuItem>
              
              {rubros.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.nombre}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.rubro_id &&
                formik.errors.rubro_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="tipo_bien_id">Tipo Activo</InputLabel>
            <Select
              labelId="tipo_bien_id"
              id="tipo_bien_id"              
              name="tipo_bien_id"
              label="Tipo Activo"
              value={formik.values.tipo_bien_id}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.tipo_bien_id &&
                Boolean(formik.errors.tipo_bien_id)
              }
            >
              <MenuItem value="">Seleccionar</MenuItem>
              
              {tipos.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.tipo_bien}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.tipo_bien_id &&
                formik.errors.tipo_bien_id}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="estado_bien">Estado</InputLabel>
            <Select
              labelId="estado_bien"
              id="estado_bien"
              name="estado_bien"
              label="Estado"
              value={formik.values.estado_bien}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.estado_bien &&
                Boolean(formik.errors.estado_bien)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              <MenuItem value="nuevo">nuevo</MenuItem>
              <MenuItem value="bueno">bueno</MenuItem>
              <MenuItem value="regular">regular</MenuItem>
              <MenuItem value="malo">malo</MenuItem>
              <MenuItem value="obsoleto">obsoleto</MenuItem>
              <MenuItem value="pesimo">pesimo</MenuItem>
              
            </Select>
            <FormHelperText color="error">
              {formik.touched.estado_bien && formik.errors.estado_bien}
            </FormHelperText>
          </FormControl>

          <TextField
            value={formik.values.placa}
            onChange={formik.handleChange}
            name="placa"
            fullWidth
            sx={{ my: 1 }}
            label="Otro dato (Placa)"
            error={
              formik.touched.placa && Boolean(formik.errors.placa)
            }
            helperText={formik.touched.placa && formik.errors.placa}
            variant="outlined"
            size="small"
          />

          <TextField
            value={formik.values.barcode}
            onChange={formik.handleChange}
            name="barcode"
            fullWidth
            sx={{ my: 1 }}
            label="Codigo"
            error={formik.touched.barcode && Boolean(formik.errors.barcode)}
            helperText={formik.touched.barcode && formik.errors.barcode}
            variant="outlined"
            size="small"
          />
         
          <TextField
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            name="descripcion"
            className="col-span-2"
            fullWidth
            sx={{ my: 1 }}
            label="Descripcion"
            error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
            helperText={formik.touched.descripcion && formik.errors.descripcion}
            variant="outlined"
            size="small"
          />

<FormControl fullWidth>
            <InputLabel id="responsable">Responsable</InputLabel>
            <Select
              labelId="responsable"
              id="responsable"
              name="responsable"
              label="Responsable"
              value={formik.values.responsable}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.responsable &&
                Boolean(formik.errors.responsable)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              {
                personal.map((row,key)=>
                <MenuItem value={row.id} key={key}>{ row.people.nombres + " "+ row.people.apellido_p}</MenuItem>                
                )
              }
              
            </Select>
            <FormHelperText color="error">
              {formik.touched.responsable && formik.errors.responsable}
            </FormHelperText>
          </FormControl>
<FormControl fullWidth>
            <InputLabel id="autorizado_por">Autorizado por</InputLabel>
            <Select
              labelId="autorizado_por"
              id="autorizado_por"
              name="autorizado_por"
              label="Autorizado por"
              value={formik.values.autorizado_por}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.autorizado_por &&
                Boolean(formik.errors.autorizado_por)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              {
                personal.map((row,key)=>
                <MenuItem value={row.id} key={key}>{ row.people.nombres + " "+ row.people.apellido_p}</MenuItem>                
                )
              }
              
            </Select>
            <FormHelperText color="error">
              {formik.touched.autorizado_por && formik.errors.autorizado_por}
            </FormHelperText>
          </FormControl>

          <Button variant="contained" type="submit">
            Registrar
          </Button>
        </div>
      </form>
    </div>
  );
};
export default NewActivo;
