import { useEffect, useRef } from 'react';
import { useState } from 'react';
import Barcode from 'react-barcode';
import { fNumber } from '../../../utils/formatNumber';
import UploadDocument from '../../../sections/uploadDocument/UploadDocument';
import { baseURL } from '../../../config/AppConfig';
import ModalComponent from '../../../components/ModalComponent';
import { Button, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { postApiService } from '../../../services/crudServices';
import { filter, find, size } from 'lodash';
import DiaryRegisterV2 from '../Diario/DiaryRegisterV2';
import PrintComprobante from '../Diario/PrintComprobante';
import ActaActivo from './ActaActivo';
import AltaActivo from './AltaActivo'

const Tarjeta =({activo})=>{
   const [observation,setObservation] = useState("")
   const [data,setData] = useState({})
   const [date,setDate] = useState("")
   const childRef = useRef()
   const handleClick=()=>{
      if(observation==="" || date === ""){
        toast.error("Debe ingresar una observacion y la fecha de baja!!")
      }
      postApiService("activo-fijo/baja",{ activo_id: data.id, fecha:date,observation:observation}).then(res=>{
        setData(res)
        toast.success("Se registro correctamente")
      })
    }
    const reloadData=()=>{
      childRef.current.closeModalChild()
    }
    const loadData=()=>{
      postApiService("activo-fijo/get",{activo_id:activo}).then(res=>{
        setData(res)
      })
    }
  useEffect(()=>{
   loadData()
  },[])
  return (
    <div className='text-xs uppercase'>
      {
        size(data) > 0 && 
        <div>
          <div className="inline-flex w-full justify-between">
                <div>
                    <p className="font-bold uppercase">{data.company.name}</p>
                    <p className="font-semibold uppercase">La Paz Bolivia</p>
                </div>
                <div>
                    {data.barcode &&
                    <Barcode
                      value={data.barcode}
                      width={2}
                      height={60}
                      format={"CODE128"}
                      displayValue={true}
                      fontOptions={""}
                      font={""}
                      textAlign={"center"}
                      textPosition={"bottom"}
                      textMargin={2}
                      fontSize={20}
                      background={"#ffffff"}
                      lineColor={"#000000"}
                      margin={10}
                    />
                    }                    
                    {data.id && <p> <span className="font-semibold">Nro: </span>{data.id}</p>}
                </div>
                
            </div>
            <p className="uppercase text-lg font-bold text-center">{"Activo Fijo"}</p>
            
            <div className="m-3 border-y border-y-gray-700 p-3">
                <div className="inline-flex w-full gap-3 justify-between">
                    <div>
                        <p><span className="font-semibold">UNIDAD: </span> {data.unidad.unidad}</p>
                        <p><span className="font-semibold">CIUDAD: </span> {data.oficina.edificio.ciudad.ciudad}</p>
                        <p><span className="font-semibold">EDIFICIO: </span> {data.oficina.edificio.edificio}</p>
                        <p><span className="font-semibold">OFICINA: </span> {data.oficina.oficina}</p>
                        <p><span className="font-semibold">RESPONSABLE: </span> {data.responsable.name}</p>
                        <p><span className="font-semibold">RUBRO CONTABLE: </span> {data.tipo_bien.rubro_contable.nombre}</p>
                        <p><span className="font-semibold">TIPO DE BIEN: </span> {data.tipo_bien.tipo_bien}</p>
                        <p><span className="font-semibold">ESTADO DEL BIEN: </span> {data.estado_bien}</p>
                        <p><span className="font-semibold">DESCRIPCION: </span> {data.descripcion}</p>
                        <p><span className="font-semibold">VALOR Bs: </span> {data.valor_inicial}</p>
                        <p><span className="font-semibold">T.C.: </span> {data.tipo_cambio}</p>
                        <p><span className="font-semibold">VALOR $US: </span> { fNumber(parseFloat(data.valor_inicial) / parseFloat(data.tipo_cambio)) }</p>
                        <p><span className="font-semibold">VALOR RECIDUAL: </span> {data.valor_recidual}</p>
                        <div className='mt-3'>
                        {
                          !data.fecha_baja ?
                          <ModalComponent color='error' variant={"outlined"} btn={"Dar de baja"} title={"Dar de baja activo fijo"}>
                          <div>
                          <div className='p-2'>
                          <p className='text-xs'>* Ingrese la razon por la que se da de baja el activo.</p>
                        </div>
                        <TextField
                          name="date"
                          fullWidth
                          size="small"
                          label="Fecha de baja"
                          value={date}
                          onChange={(e)=>setDate(e.target.value)}
                          type='date'
                        />
                        <div className='mt-2'>
                        <TextField
                          multiline
                          rows={3}
                          name="code"                          
                          fullWidth
                          size="small"
                          label="Observacion"
                          value={observation}
                          onChange={(e)=>setObservation(e.target.value)}
                        />
                        </div>
                        <div className='my-3'>                          
                            <Button variant='contained' color='error' onClick={handleClick} >dar de baja</Button>                          
                        </div>
                          </div>
                        </ModalComponent>
                        :
                        <ModalComponent
                          btn={"Comprobante de baja"}
                          variant="outlined"
                          color={size(data.comprobante) > 0 ? "success" : "error"}
                          fullScreenx={true}
                          ref={childRef}
                        >
                          {size(data.comprobante) > 0 &&
                            filter(data.comprobante, (o) => parseInt(o.tipo_transaccion_id) === 8)?.map(
                              (ra, key) => <PrintComprobante key={key} id={ra.id} />
                            )}

                          <div>
                            {size(
                              find(data.comprobante, (o) => parseInt(o.tipo_transaccion_id) === 8)
                            ) === 0 && (
                              <DiaryRegisterV2
                                type="traspaso"
                                company_id={data.company_id}
                                branch_id={"1"}
                                model_type="ActivoFijo"
                                model_id={data.id}
                                data={{
                                  glosa: "Baja de activo fijo",
                                  date: data.fecha_baja,
                                  total: data.valor_recidual,
                                }}
                                company={data.company.name}
                                store={data.store.name}
                                store_id={data.store_id}
                                reloadData={reloadData}
                                invoice={true}
                                invoiceOption={true}
                                transaccion={8}
                               
                                detail={
                                  <div>
                                    <p><span className="font-semibold">UNIDAD: </span> {data.unidad.unidad}</p>
                                    <p><span className="font-semibold">CIUDAD: </span> {data.oficina.edificio.ciudad.ciudad}</p>
                                    <p><span className="font-semibold">EDIFICIO: </span> {data.oficina.edificio.edificio}</p>
                                    <p><span className="font-semibold">OFICINA: </span> {data.oficina.oficina}</p>
                                    <p><span className="font-semibold">RESPONSABLE: </span> {data.responsable.name}</p>
                                    <p><span className="font-semibold">RUBRO CONTABLE: </span> {data.tipo_bien.rubro_contable.nombre}</p>
                                    <p><span className="font-semibold">TIPO DE BIEN: </span> {data.tipo_bien.tipo_bien}</p>
                                    <p><span className="font-semibold">ESTADO DEL BIEN: </span> {data.estado_bien}</p>
                                    <p><span className="font-semibold">DESCRIPCION: </span> {data.descripcion}</p>
                                    <p><span className="font-semibold">VALOR Bs: </span> {data.valor_inicial}</p>
                                    <p><span className="font-semibold">T.C.: </span> {data.tipo_cambio}</p>
                                    <p><span className="font-semibold">VALOR $US: </span> { fNumber(parseFloat(data.valor_inicial) / parseFloat(data.tipo_cambio)) }</p>
                                    <p><span className="font-semibold">VALOR RECIDUAL: </span> {data.valor_recidual}</p>
                                  </div>
                                }
                              />
                            )}
                          </div>
</ModalComponent>
                        }
                        </div>
                    </div>
                    <div className='border border-gray-600 p-2'>
                      {
                        data.file?
                        <div className=" max-h-24 mx-auto">
                          <img src={baseURL+"storage/"+data.file.url} alt=""  className='h-48' />
                        </div>
                        :
                        <UploadDocument
                          dataId={data.id}
                          type='ActivoFijo'
                          label='Seleccionar Fotografia del activo'
                          description={"Fotografia del activo"}
                        />
                      }
                    </div>
                </div>
            </div>
                    <div className='p-3 w-full inline-flex justify-between'>
                        <ModalComponent title="Acta de entrega" btn={"Acta de entrega"} fullScreenx={true}>
                          <ActaActivo data={data}></ActaActivo>
                        </ModalComponent>
                        <ModalComponent title="Alta Activo" btn={"Alta Activo"} fullScreenx={true}>
                          <AltaActivo data={data}></AltaActivo>
                        </ModalComponent>
                    </div>
        </div>
      }        
    </div>
  );
}
export default Tarjeta