import { Button, TextField, TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import { fNumber } from "../../utils/formatNumber";
import { toast } from "react-toastify";

const MoneyBoxCalcule = ({handleClickCloseBox,efectivo,efectivo_sus,closeModal, isCommitCash,cashBoxClose=false}) => {
  const [observation, setObservation] = useState("");
  const [totalBs,setTotalBs]=useState(0)
  const [totalSus,setTotalSus]=useState(0)
  const [bs, setBs] = useState({    
    bs_010: 0,
    bs_020: 0,
    bs_050: 0,
    bs_1: 0,
    bs_2: 0,
    bs_5: 0,
    bs_10: 0,
    bs_20: 0,
    bs_50: 0,
    bs_100: 0,
    bs_200: 0,
  });
  const [sus, setSus] = useState({
    sus_1: 0,
    sus_2: 0,
    sus_5: 0,
    sus_10: 0,
    sus_20: 0,
    sus_50: 0,
    sus_100: 0,
  });
  const handleChangeBs = (e) => {
    const { name, value } = e.target;
    setBs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeSus = (e) => {
    const { name, value } = e.target;
    setSus((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(()=>{
    const total =
    parseFloat(bs.bs_010)*0.10+
    parseFloat(bs.bs_020)*0.20+
    parseFloat(bs.bs_050)*0.50+
    parseFloat(bs.bs_1)*1+
    parseFloat(bs.bs_2)*2+
    parseFloat(bs.bs_5)*5+
    parseFloat(bs.bs_10)*10+
    parseFloat(bs.bs_20)*20+
    parseFloat(bs.bs_50)*50+
    parseFloat(bs.bs_100)*100+
    parseFloat(bs.bs_200)*200;
    setTotalBs(total);
    const totalSus = 
    parseFloat(sus.sus_1)*1+
    parseFloat(sus.sus_2)*2+
    parseFloat(sus.sus_5)*5+
    parseFloat(sus.sus_10)*10+
    parseFloat(sus.sus_20)*20+
    parseFloat(sus.sus_50)*50+
    parseFloat(sus.sus_100)*100;
    setTotalSus(totalSus);
  },[sus,bs])
  const closeCashBox=()=>{
    const diffBs = parseFloat(efectivo)-parseFloat(totalBs);
    const diffSus = parseFloat(totalSus)-parseFloat(efectivo_sus);
           console.log(bs,sus,observation);
        handleClickCloseBox(bs,sus,observation)
       
  /*   
        toast.error("Para cerrar la caja debe cuadrar el conteo de dinero y el total del sistema");
    } */
  }
  useEffect(()=>{
    if(isCommitCash === true){
        closeModal()
    }
  },[isCommitCash])
  return (
    <div className="md:grid grid-cols-2 gap-3">
      <div className="md:grid grid-cols-2 gap-2 bg-blue-200 p-2">
        <div className="col-span-2">
            <p className="font-bold text-2xl text-center">Bolivianos</p>
        </div>
        <p>Moneda 10 Ctvs de Boliviano</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_010"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_010}
        />

        <p>Moneda 20 Ctvs de Boliviano</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_020"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_020}
        />

        <p>Moneda 50 Ctvs de Boliviano</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_050"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_050}
        />

        <p>Moneda de 1 Boliviano</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_1"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_1}
        />

        <p>Moneda de 2 Bolivianos</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_2"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_2}
        />

        <p>Moneda de 5 Bolivianos</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_5"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_5}
        />

        <p>Billete de 10 Bolivianos</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_10"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_10}
        />
        <p>Billete de 20 Bolivianos</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_20"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_20}
        />

        <p>Billete de 50 Bolivianos</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_50"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_50}
        />

        <p>Billete de 100 Bolivianos</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_100"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_100}
        />

        <p>Billete de 200 Bolivianos</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="bs_200"
          onChange={handleChangeBs}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={bs.bs_200}
        />
           <div className="col-span-2">
            <p className="font-bold text-2xl text-center">Total Bs: {fNumber(totalBs)}</p>
            <p className="font-bold text-xl text-center text-green-500">Total Bs Sistema: {fNumber(efectivo)}</p>
        </div>
      </div>

      <div className="md:grid grid-cols-2 gap-2 bg-green-200 p-2">
      <div className="col-span-2">
            <p className="font-bold text-2xl text-center">Dolares</p>
        </div>
        <p>Billete de 1 Dólar Estadounidense</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="sus_1"
          onChange={handleChangeSus}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={sus.sus_1}
        />

        <p>Billete de 2 Dólares Estadounidenses</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="sus_2"
          onChange={handleChangeSus}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={sus.sus_2}
        />

        <p>Billete de 5 Dólares Estadounidenses</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="sus_5"
          onChange={handleChangeSus}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={sus.sus_5}
        />

        <p>Billete de 10 Dólares Estadounidenses</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="sus_10"
          onChange={handleChangeSus}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={sus.sus_10}
        />

        <p>Billete de 20 Dólares Estadounidenses</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="sus_20"
          onChange={handleChangeSus}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={sus.sus_20}
        />

        <p>Billete de 50 Dólares Estadounidenses</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="sus_50"
          onChange={handleChangeSus}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={sus.sus_50}
        />

        <p>Billete de 100 Dólares Estadounidenses</p>
        <TextField disabled={cashBoxClose} 
          size="small"
          name="sus_100"
          onChange={handleChangeSus}
          variant="outlined"
          type="number"
          min="1"
          className="w-full p-2 border border-blue-300"
          value={sus.sus_100}
        />
         <div className="col-span-2">
            <p className="font-bold text-2xl text-center">Total Dolares: {fNumber(totalSus)}</p>
            <p className="font-bold text-xl text-center text-green-500">Total Dolares Sistema: {fNumber(efectivo_sus)}</p>
        </div>

      </div>

      <TextareaAutosize
      disabled={cashBoxClose} 
        name="observations"
        onChange={(e) => setObservation(e.target.value)}
        variant="outlined"
        minRows={3}
        label="Observaciones"
        placeholder="Observaciones"
        className="w-full p-2 border border-blue-300"
        value={observation}
      />
      <Button disabled={cashBoxClose}  variant="contained" onClick={()=>closeCashBox()}>Cerrar Caja</Button>
    </div>
  );
};
export default MoneyBoxCalcule;
