

import API from "../axios/api"
export const registerCompEgre = async (val)=>{
    const {data} = await API.post('api/conta/registro',val)
    return data
}
export const getAcount = async (val)=>{
    const {data} = await API.post('api/conta/getacount',val)
    return data
}
export const getSolicitudes = async (val,page)=>{
    const {data} = await API.post('api/solicitud/list?page='+page,val)
    return data
}
export const newSolicitudes = async (val)=>{
    const {data} = await API.post('api/solicitud/create',val)
    return data
}
export const changeStateSolicitud = async (val)=>{
    const {data} = await API.post('api/solicitud/change-state',val)
    return data
}
export const getMayor = async (val)=>{
    const {data} = await API.post('api/libro-mayor',val)
    return data
}
export const searchAcount = async (val)=>{
    const {data} = await API.post('api/account/search',val)
    return data
}
export const getParametros = async ()=>{
    const {data} = await API.get('api/conta/parametros')
    return data
}
export const newAccountConta = async (val)=>{
    const {data} = await API.post('api/conta/cuentas/new',val)
    return data
}
export const delAccountConta = async (val)=>{
    const {data} = await API.post('api/conta/cuentas/delete',val)
    return data
}


export const createAdjustmentInventory = async (val)=>{
    const {data} = await API.post('api/inventory-adjustment-create',val)
    return data
}
export const getAdjustmentInventory = async (val)=>{
    const {data} = await API.post('api/get-inventory-adjustment',val)
    return data
}
export const addItemAdjustmentInventory = async (val)=>{
    const {data} = await API.post('api/add-inventory-adjustment',val)
    return data
}
export const generateItemAdjustmentInventory = async (val)=>{
    const {data} = await API.post('api/inventory-adjustment/generate',val)
    return data
}
export const stateItemAdjustmentInventory = async (val)=>{
    const {data} = await API.post('api/inventory-adjustment/state',val)
    return data
}
export const getCoeficienteActivos = async ()=>{
    const {data} = await API.get('api/conta/coeficiente-activos')
    return data
}
export const getActivosList = async (val)=>{
    const {data} = await API.post('api/conta/activos-list',val)
    return data
}
export const setActivosList = async (val)=>{
    const {data} = await API.post('api/conta/set-activo',val)
    return data
}

export const getListDiary = async (val)=>{
    const {data} = await API.post('api/conta/diary',val)
    return data
}
export const getComprobante = async (val)=>{
    const {data} = await API.get('api/comprobante/'+val)
    return data
}
export const getPlanCuentas = async ()=>{
    const {data} = await API.get('api/conta/cuentas')
    return data
}

export const getParametrosConta = async (val)=>{
    const {data} = await API.get('api/parametros/contabilidad/'+val)
    return data
}
export const setParametrosConta = async (val)=>{
    const {data} = await API.post('api/parametros/contabilidad',val)
    return data
}
export const newComprovanteInvoice = async (val)=>{
    const {data} = await API.post('api/conta/registro/venta',val)
    return data
}
export const newInvoiceR = async (val)=>{
    const {data} = await API.post('api/conta/registro/invoice',val)
    return data
}


