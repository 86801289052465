import { Divider } from "@mui/material"
import Logo from "../../components/Logo"
import { dateConfig, dateConfigTitle } from "../../utils/dateconfig"
import { fInvoiceNumber } from "../../utils/formatNumber"
import { useEffect, useState } from "react"
import { size } from "lodash"
import { getTransferHead } from "../../services/transfer"

const PrintTransfer = ({data=null,id=null}) => {
  const [datas,setDatas] = useState({});
  const loadData=()=>{
    getTransferHead({id:id}).then(res=>{
      setDatas(res.data)
    })
  }
  useEffect(()=>{
    if(data){
      setDatas(data)
    }else{
      loadData()
    }
    
  },[])
  return (
    <div>
      { 
        size(datas) > 0 &&
        <div>
          <div>

<div className="grid grid-cols-8">
        <div className="col-span-2">
          <Logo />
        </div>
        <div className="col-span-4 w-full flex items-center">
            <div className="w-full">
            <h2 className="uppercase text-center w-full font-bold text-2xl">
           {"Nota de Traspaso"}
          </h2>
          <p className=" text-center text-red-500 font-semibold">
            {datas.current_state === "anulado"&&"ANULADO"}
          </p>
            </div>
        </div>
        <div className="col-span-2 text-sm">
        
          <p className="text-right text-red-500 font-bold text-xl">
              <span>Nro: </span> {fInvoiceNumber(datas.voucher_number) }
            </p>            
            <p className=" text-right">
            <span className="font-semibold">Sucursal:</span>{" "}
            {datas.store?.name}
          </p>
            <p className=" text-right">
            <span className="font-semibold">Solicitud de Traspaso:</span>{" "}
            {fInvoiceNumber(datas.requerimiento?.voucher_number)}
          </p>
        </div>
        <div className="col-span-4 text-xs mt-3">
        
          <p>
            <span className="font-semibold">Almacen Destino :</span>{" "}
            {datas.store_dest?.name}{" "}
          </p>
          <p>
            <span className="font-semibold">Responsable:</span>{" "}
            {datas.attendant.name}{" "}
          </p>
          <p>
            <span className="font-semibold">Fecha y hr:</span>{" "}
            {dateConfig(datas.created_at)}{" "}
          </p>
        </div>
        <div className="col-span-4 text-xs"></div>
      </div>
    <table className="table-print">
            <thead>
        <tr>
                <th>cod de barra</th>
                <th>DESCRIPCIÓN DEL PRODUCTO</th>
                <th>packing</th>
                <th>lote</th>
                <th>vencimiento</th>
                <th>cantidad</th>
        </tr>
            </thead>
            <tbody>
                {
                    datas.detail.map((row,key)=> 
                    row.prices?
                    <tr key={key}>
                        <td>-</td>
                        <td>{row.prices.price.name}</td>
                        <td>{row.prices.sale_presentation +" x "+row.prices.units}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{row.qty}</td>
                    </tr>
                    :
                    <tr key={key}>
                        <td>{row.stock.barcode}</td>
                        <td>{row.stock.item.name}</td>
                        <td>{row.stock.pricesx?.sale_presentation+" x "+row.stock.pricesx?.units}</td>
                        <td>{row.stock.lot}</td>
                        <td>{dateConfigTitle(row.stock.expiration)}</td>
                        <td>{row.qty}</td>
                    </tr>

                    )
                }
            </tbody>
    </table>
    <div className="my-3 p-2 text-xs">
      <div className=" p-2 border border-gray-800 text-xs mb-2">
        <p>
          <span className="font-semibold">Observaciones: </span>
          {datas.observations.observation}
        </p>
      </div>
      </div>

    <div className="my-3 p-2 text-xs grid grid-cols-4 gap-3 ">
    <div className="mt-9">
            <Divider/>
            <p className="text-center">{datas.store.name}</p>
            <p className="text-center font-semibold">Jefe de almacen</p>
        </div>
        <div className="mt-9">
            <Divider/>
            <p className="text-center"> {datas.store_dest.name}</p>
            <p className="text-center font-semibold">Jefe de almacen</p>
        </div>
        <div className="mt-9">
            <Divider/>
            <p className="text-center"> Aprobado por</p>
            <p className="text-center font-semibold">Gerencia</p>
        </div>
        </div>
    </div>
        </div>
      }
    </div>
  )
}
export default PrintTransfer