import { Paper } from "@mui/material"
import Page from "../../layout/Pagev1"
import { fNumber } from "../../utils/formatNumber"

const SumasSaldos = () => {
  return (
    <Page appBar={true} title="Balance de comprobacion de sumas y saldos">
        <Paper className="p-3 text-xs">
            <h3 className="uppercase font-bold text-md text-center">Balance de comprobacion de sumas y saldos</h3>
            <p className="text-center ">(Al 31 de Diciembre de 2023)</p>
            <p className="text-center font-semibold">Expresado en Bolivianos</p>
            <table className="table-print">
                <thead>
                    <tr>
                    <th rowSpan={2}>No</th>
                    <th rowSpan={2}>codigo</th>
                    <th rowSpan={2}>cuentas</th>
                    <th colSpan={2}>sumas</th>
                    <th colSpan={2}>saldos</th>
                    </tr>
                    <tr className="bg-blue-100">
                        <td>debe</td>
                        <td>haber</td>
                        <td>deudor</td>
                        <td>acreedor</td>
                    </tr>
                </thead>
                <tbody>
                    <th colSpan={3} className="text-right">Total</th>
                    <td className="text-right">{fNumber()}</td>
                    <td className="text-right">{fNumber()}</td>
                    <td className="text-right">{fNumber()}</td>
                    <td className="text-right">{fNumber()}</td>
                </tbody>

            </table>
        </Paper>
    </Page>
  )
}
export default SumasSaldos