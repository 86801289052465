import AddBoxIcon from "@mui/icons-material/AddBox";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from "react-to-print";
import { useRef, useState } from "react";
import SearchForm from "../../sections/search/SearchForm";
import { searchAcount } from "../../services/conta";
import { size } from "lodash";
import { toast } from "react-toastify";
import { postApiService } from "../../services/crudServices";
const SelectAcount = ({addRowItem,index,company=null}) => {
  const [open,setOpen] = useState(false)
  const [data,setData]= useState([])
  const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const componentRef = useRef();
    const handleClose = () => {
      setOpen(false);
    };
    const handlePrint = useReactToPrint({
      content: ()=>componentRef.current,
    })
    const searchFunction=(val)=>{
      console.log(val);
      if(!company){
        toast.error('Debe seleccionar empresa!!')
      }
      postApiService("account/search",{search: val}).then(res=>{
        setData(res)
      })
        
    }
    const setDataAcount=(row)=>{
        addRowItem(row,index)
        setOpen(false)
    }
    return (
    <div>
        <IconButton                  
                  color="success"
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  <AddBoxIcon />
                </IconButton>
                <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Buscador de Cuentas
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handlePrint}
          >
            <PrintIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent ref={componentRef} >
        <div className="">
            <SearchForm searchFunction={searchFunction} />
        </div>
        <ul>
          {
                 size(data)>0 &&
                 data.map((row,key)=>
                 <li key={key}>
                     <div className="inline-flex w-full">
                     <span className="w-full">{row.codigo}</span>
                     <span className="w-full">{row.name}</span>
                     <span>
                         <IconButton color="primary" onClick={()=>setDataAcount(row)}>
                             <SendIcon />
                         </IconButton>
                     </span>
                     </div>
                 </li>                    
                 )
             }
          
        </ul>
     
      </DialogContent>
    </Dialog>
    </div>
  )
}
export default SelectAcount