import {
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { fInvoiceNumber, fNumber } from "../utils/formatNumber";
import React, { forwardRef, useEffect, useState } from "react";
import { size, sumBy } from "lodash";
import Logo from "./../components/Logo"
import { calculeTotalAndDiscount, calculeTotalDiscount } from "../utils/operations";
import { numeroALetras } from "../utils/NumeroALetras";
import { useSelector } from "react-redux";
import { includes } from "lodash";
import { anulateSaleOrderAll, getSaleVoucherPrint } from "../services/order";
import Swal from "sweetalert2";
import { dateConfig } from "../utils/dateconfig";

const SalesDetailPrint = forwardRef(({ data, titulo=null,subtitle=null,deeliveryNote={},btn=true, firma=false }, ref) => {
 
 const date =  new Date(data.created_at);
  const userData = useSelector(state=>state.userData)
  const [groupedData, setGroupedData] = useState([]);
  const [print,setPrint]= useState(true);
  const [dataPrint,setDataPrint] = useState(data)
  const anulateOrder=()=>{
    Swal.fire({
      title: "Anulara la orden, Está seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,    
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, anular!",
    }).then((result) => {
      if (result.isConfirmed) {
       anulateSaleOrderAll({id:dataPrint.id,observation: result.value,})
          .then((res) => { 
            Swal.fire("Anulado!", "Se anulo la orden.", "success");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  
  }

  const getVoucher=()=>{
    getSaleVoucherPrint({id:data.id||data.voucher_head_id}).then(res=>{
      setDataPrint(res);
    })
  }
  useEffect(()=>{
    if(!dataPrint.voucher_detail){
      getVoucher()
    }
  },[])

  useEffect(() => {
    // Agrupar los datos por stock_pro_id y calcular la suma de qty * price
    if(size(dataPrint.voucher_detail)>0){
     
      const grouped = dataPrint.voucher_detail.reduce((acc, itemx) => {
        const { stock_pro_id, qty, price,item,stock_pro,discount} = itemx;
      const sum = parseInt(qty) * parseFloat(price);

      if (acc[stock_pro_id]) {
        acc[stock_pro_id].qty = parseInt(acc[stock_pro_id].qty) + parseInt(qty);
        acc[stock_pro_id].discount = parseInt(acc[stock_pro_id].discount) + parseInt(discount);
        acc[stock_pro_id].total += sum;
      } else {
        acc[stock_pro_id] = {
          stock_pro_id,      
          stock_pro,
          sale_presentation: itemx.stock_pro.sale_presentation, 
          item,    
          qty,
          discount,
          total: sum,
          price
        };
      }
      
      return acc;
    }, {});
    
    setGroupedData(Object.values(grouped));
  }
  }, [dataPrint]);

  return (
    <div ref={ref}>
      {
        includes(["super-admin","gerencia","jefe-sucursal","jefe-sala"],userData.role) && dataPrint.current_state !== "anulado" &&
        <div className="w-full inline-flex justify-between no-print">
          <div></div>
          <div className="inline-flex gap-2">
          <Button onClick={()=>setPrint(!print)} className="" color="success" variant="contained" size="small" >{print? "Nota con lote":"Nota sin lote"}</Button>
       {btn && <Button onClick={()=>anulateOrder()} className="" color="error" variant="contained" size="small" >Anular orden</Button>}
          </div>
      </div>
      }
      <div>
      {
      size(deeliveryNote) === 0 ? 
      <div className="grid grid-cols-8 uppercase">
        <div className="col-span-2 ">
          <Logo />
        </div>
        <div className="col-span-4 w-full flex items-center">
          <div className="w-full">
          <h2 className="uppercase text-center w-full font-bold text-2xl">
           {titulo}
          </h2>
          <h2 className="uppercase text-center w-full font-bold">
           {subtitle}
          </h2>
         {dataPrint.statu?.state === "anulado" && <p className="uppercase text-red-500 font-bold text-xl text-center">             
              {dataPrint.statu?.state}
          </p>}
          </div>
        </div>
        <div className="col-span-2 text-sm">
        
          <p className="text-right text-red-500 font-bold text-xl">
              <span>Nro: </span> {fInvoiceNumber(dataPrint.voucher_number)}               
          </p>            
        
          {dataPrint.transfer && <p className="text-right text-red-500 font-bold text-xl">
              <span>NT: </span> {fInvoiceNumber(dataPrint.transfer.voucher_number)}               
          </p>}
            <p className=" text-right">
            <span className="font-semibold">Sucursal:</span>{" "}
            { dataPrint.store?.name }
          </p>
            <p className=" text-right">
            <span className="font-semibold">fecha:</span>{" "}
            { date.toLocaleDateString() }
          </p>
            <p className=" text-right">
            <span className="font-semibold">hora:</span>{" "}
            { date.toLocaleTimeString() }
          </p>
          <p className=" text-right">
          <span className="font-semibold">{parseInt(dataPrint.invoice)===1? "FACTURADO":"SIN FACTURA"}</span> 
          </p>
        </div>

      </div>:
      <div className="grid grid-cols-8 uppercase">
      <div className="col-span-2 ">
        <Logo />
      </div>
      <div className="col-span-4 w-full flex items-center">
        <div className="w-full">
        <h2 className="uppercase text-center w-full font-bold text-2xl">
          Nota de Entrega
        </h2>
       {deeliveryNote.statu?.state === "anulado" && <p className="uppercase text-red-500 font-bold text-xl text-center">             
            {deeliveryNote.statu?.state}
        </p>}
        </div>
      </div>
      <div className="col-span-2 text-sm">
      
        <p className="text-right text-red-500 font-bold text-xl">
            <span>Nro: </span> {fInvoiceNumber(deeliveryNote.voucher_number)} 
        </p>            
          <p className=" text-right">
          <span className="font-semibold">Sucursal:</span>{" "}
          { data.store?.name }
        </p>
          <p className=" text-right">
          <span className="font-semibold">fecha:</span>{" "}
          { date.toLocaleDateString() }
        </p>
          <p className=" text-right">
          <span className="font-semibold">hora:</span>{" "}
          { date.toLocaleTimeString() }
        </p>
        <p className=" text-right">
          <span className="font-semibold">{data.invoice===1? "FACTURADO":"SIN FACTURA"}</span> </p>
      </div>

    </div>
      }

       <div className="my-3 p-2 text-xs grid grid-cols-2 gap-3">
        <fieldset className="w-full border border-gray-400 p-2">
          <legend className="mx-2 text-sm font-bold">Información del cliente</legend>
          <p className="m-2"><b>NOMBRE O RAZÓN SOCIAL:</b> {dataPrint.business_name}</p>
          <p className="m-2"><b>NIT / CI:</b> {dataPrint.identification_number || "S/NIT"}</p>
          <p className="m-2"><b>TELÉFONO:</b> {dataPrint.client?.phone || "-"  +" - "+dataPrint.client?.whatsapp}</p>
          <p className="m-2"><b>ZONA:</b> {dataPrint.client?.macrodistrito}</p>
          <p className="m-2"><b>DIRECCIÓN:</b> {dataPrint.client?.direction}</p>
          {dataPrint.client?.location && <p className="m-2"><b>UBICACIÓN:</b> {"Lat: "+dataPrint.client?.location?.location.latitude + "-" +" Lng"+dataPrint.client?.location?.location.longitude}</p>}
        </fieldset>

        <fieldset className="w-full border border-gray-400 p-2">
          <legend className="mx-2 text-sm font-bold">Información del asesor comercial</legend>
          <p className="m-2"><b>NOMBRE:</b> {dataPrint.user?.name}</p>
          <p className="m-2"><b>TELÉFONO:</b> {dataPrint.user?.phone}</p>
        </fieldset>
      </div>

        <Table size="small" className="table-print">
          <TableHead className="bg-gray-300">
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Descripción de Producto</TableCell>   
              <TableCell>U.M.</TableCell>
            {!print &&  <TableCell>Lote</TableCell>}
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio U</TableCell>
              <TableCell>Descuento</TableCell>              
              <TableCell>Precio Total Bs.-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {size(dataPrint.voucher_detail) > 0 && (
              <>
                {!print && dataPrint.voucher_detail.map((val, key) => parseInt(val.qty) > 0 && (
                  <TableRow key={key}>
                    <TableCell>{key+1}</TableCell>
                    <TableCell>{val.item}</TableCell>                     
                    <TableCell>{val.stock_pro.sale_presentation}</TableCell>
                    <TableCell>{val.lot}</TableCell>
                    <TableCell align="right">{val.qty}</TableCell>
                    <TableCell align="right">{fNumber(val.price)} Bs.-</TableCell>
                    <TableCell align="right">{fNumber(parseFloat(val.discount))} </TableCell>
                    <TableCell align="right">{fNumber((parseFloat(val.price) * parseFloat(val.qty))-parseFloat(val.discount))} Bs.-</TableCell>
                  </TableRow>
                ))}

                 {print && groupedData.map((item,key) => (
                    <TableRow key={key}>
                    <TableCell>{key+1}</TableCell>
                    <TableCell>{item.item}</TableCell>                     
                    <TableCell>{item.sale_presentation}</TableCell>            
                    <TableCell align="right">{item.qty}</TableCell>
                    <TableCell align="right">{fNumber(item.price)} Bs.-</TableCell>
                    <TableCell align="right">{fNumber(parseFloat(item.discount))} </TableCell>
                    <TableCell align="right">{fNumber(parseFloat(item.total)-parseFloat(item.discount))} Bs.-</TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={!print ?6:5}></TableCell>
                  <TableCell>SubTotal</TableCell>
                  <TableCell align="right">
                    {fNumber(
                      sumBy(dataPrint.voucher_detail, (o) => {
                        return (parseFloat(o.price) * parseInt(o.qty))-parseFloat(o.discount);
                      })
                    )} Bs.-
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={!print ?6:5}></TableCell>
                  {/* <TableCell>Descuento {dataPrint.discount?.discount_amount || 0}%</TableCell> */}
                  <TableCell>Descuento</TableCell>
                  <TableCell align="right">
                    {fNumber(dataPrint.discount?.discount_amount || 0
                     
                    )} Bs.-
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={!print ?6:5}></TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell align="right">
                  {fNumber(
                     sumBy(dataPrint.voucher_detail, (o) => {
                      return (parseFloat(o.price) * parseInt(o.qty))-parseFloat(o.discount);
                    }) - parseFloat(dataPrint.discount?.discount_amount || 0)
                    )} Bs.-
                  </TableCell>
                </TableRow>
                <TableRow>
                  
                  <TableCell colSpan={!print?8:7} > <span className="font-semibold">SON: </span> 
                    {numeroALetras( 
                     sumBy(dataPrint.voucher_detail, (o) => {
                      return (parseFloat(o.price) * parseInt(o.qty))-parseFloat(o.discount);
                    }) - parseFloat(dataPrint.discount?.discount_amount || 0)
                    )} 
                  </TableCell>
              
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
   
                      <p className="text-sm"><span className="font-semibold">Nota: </span> Una vez que reciba su pedido, no se aceptarán cambios ni devoluciones. Por favor asegúrese de revisar cuidadosamente su pedido antes de aceptarlo. Si tiene algún problema o pregunta, no dude en ponerse en contacto con nosotros antes de la entrega. Gracias por su comprensión</p>
           
      <div>
       <div className="my-3 p-2 text-xs grid grid-cols-1 gap-3">
        <fieldset className="w-full border">
          <legend className="mx-2 text-sm font-bold">Observaciones</legend>
          <p className="m-2">{dataPrint.observations?.observation}</p>
        </fieldset>
      </div>
      {
       dataPrint.statu?.state === "anulado" &&
       <div className="my-3 p-2 text-xs grid grid-cols-1 gap-3">
        <fieldset className="w-full border border-red-600">
          <legend className="mx-2 text-sm font-bold">Anulado por: {dataPrint.statu.user?.name}</legend>
          <p className="m-2 text-red-500">{dataPrint.statu.observation}</p>
          <p className="m-2 text-red-500">{dateConfig(dataPrint.statu.created_at)}</p>
        </fieldset>
      </div>
      }

    {dataPrint.contact_person &&   <div className="my-3 p-2 text-xs grid grid-cols-1 gap-3">
        <fieldset className="w-full border">
          <legend className="mx-2 text-sm font-bold">Persona de contacto</legend>
          <p className="m-2">{dataPrint.contact_person}</p>
        </fieldset>
      </div>}


       { firma ? 
         <div className="my-3 p-2 text-xs grid grid-cols-4 gap-3">
          <div className="mt-9">
              <Divider/>
              <p className="text-center font-semibold">ENCARGADO DE VENTAS</p>
          </div>
          <div className="mt-9">
              <Divider/>
              <p className="text-center  font-semibold">CONTABILIDAD</p>
          </div>
          <div className="mt-9">
              <Divider/>
              <p className="text-center  font-semibold">CAJA</p>
          </div>
          <div className="mt-9">
              <Divider/>
              <p className="text-center  font-semibold">CLIENTE</p>
          </div>
        </div>
        :
       <div className="my-3 p-2 text-xs grid grid-cols-4 gap-3">
          <div className="mt-9">
            
          </div>
          <div className="mt-9">
              <Divider/>
              <p className="text-center font-semibold">EJECUTIVO DE VENTAS</p>
          </div>
          <div className="mt-9">
              <Divider/>
              <p className="text-center  font-semibold">ENCARGADO DE VENTAS</p>
          </div>
          <div className="mt-9">
             
          </div>
        </div>
        }

       </div>
        
      </div>
    </div>
  );
});

export default SalesDetailPrint;
