import { Button, TextField } from "@mui/material";
import { useFormik } from "formik"
import { useState } from "react"
import * as Yup from "yup";
import { createReclamos } from "../../services/utility";
const validationSchema = Yup.object({
    motivo: Yup.string().required("Este campo es requerido"),
    descripcion: Yup.string().required("Este campo es requerido"),
  });
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(',')[1]; // Elimina el encabezado "data:image/jpeg;base64,"
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
const Formulario =({closeModal})=>{
    const [file,setFile]= useState()

    const formik = useFormik({
        initialValues:{
            motivo:"",
            descripcion:"",
            file:""
        },
        validationSchema: validationSchema,
        onSubmit:(values)=>{
            createReclamos(values).then(res=>{
                closeModal();
            })
        }
    })
    const handleChangeDocument= async (event)=>{
        try {
            const base64String = await convertFileToBase64(event.target.files[0]);
          //console.log(); // Aquí tienes la representación en Base64 del archivo seleccionado
            setFile(base64String)
          } catch (error) {
            console.error(error);
          }

    }

    return(
        <form onSubmit={formik.handleSubmit}>
            <TextField
                value={formik.values.motivo}
                onChange={formik.handleChange}
                name="motivo"
                fullWidth
                sx={{ my: 1 }}
                label="Motivo"
                error={
                  formik.touched.motivo && Boolean(formik.errors.motivo)
                }
                helperText={
                  formik.touched.motivo && formik.errors.motivo
                }
                variant="outlined"
                size="small"
             />
            <TextField
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                name="descripcion"
                fullWidth
                sx={{ my: 1 }}
                label="Descripcion"
                error={
                  formik.touched.descripcion && Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
                multiline
                rows={4}
                maxRows={5}
                variant="outlined"
                size="small"
             />
         
            <TextField
                className="col-span-2"
                label="Seleccionar Doc. Respaldo"
                size="small"
                type="file"
                InputLabelProps={{
                    shrink: true,
                  }}
                onChange={handleChangeDocument}
            />
            <div className="py-3">
            <Button variant="contained" type="submit">
                      Enviar
                    </Button>
            </div>
        </form>
    )
}

export default Formulario