import { Fragment, useEffect, useState } from "react"
import { postApiService } from "../../../services/crudServices"
import Encabezados from "../../../sections/contabilidad/documentPrint/Encabezados"
import { find, findIndex, size, sumBy } from "lodash"
import { dateConfigBirth, dateConfigNumber, dateMonthYear } from "../../../utils/dateconfig"
import { fNumber } from "../../../utils/formatNumber"
import { Button, TextField } from "@mui/material"
import { useFormik } from "formik"
import { differenceInYears } from "date-fns"
import ModalComponent from "../../../components/ModalComponent"
import ArticleIcon from '@mui/icons-material/Article';
import DiaryRegisterV2 from "../../Contabilidad/Diario/DiaryRegisterV2"
import PrintComprobante from "../../Contabilidad/Diario/PrintComprobante"

const GenerarPlanilla=({planilla,fecha,info,mes,anio, id =null})=>{
    const [data,setData] = useState([])
    const [newData,setNewData] = useState([])
    const loadData=()=>{
        if(id){
            postApiService("rrhh/planilla/get",{id:id}).then(res=>{
                setData(res)
                setNewData(res)
            })
        }else{
            postApiService("rrhh/planilla/gen",{planilla_id:planilla,fecha:fecha}).then(res=>{
                setData(res)
            })
        }
    }
    const upRow=(index,data)=>{
        const oldDataIndex = findIndex(newData, (o) => {
            return parseInt(o.personal_id) === parseInt(data.personal_id);
        });    
        if (oldDataIndex !== -1) {
            const newDataCopy = [...newData];
            newDataCopy[oldDataIndex] = data;
            setNewData(newDataCopy);
        } else {
            const newDataCopy = [...newData, data];
            setNewData(newDataCopy);
        }
    }

    const generarPlanilla=()=>{
        const headData = {
            planilla_id: planilla,
            month: mes,
            year: anio,
            fecha: fecha,
            det: newData
        }
        postApiService("rrhh/planilla/generar",headData).then(res=>{
            loadData()
        })
    }

    useEffect(()=>{
        loadData()
    },[])
    if(id){
        return(
            <div>
                 <Encabezados
                    title={"Planilla de sueldos correspondiente al mes de " + mes + " - "+anio}
                    subtitle={""}
                    company={info.company.name}
                    branch={info?.z_company_branch.sucursal}
                    nit={info.company.nit}
                    nro={""}
                    />  
                    <table className="table-print">
                        <thead>
                            <tr>
                            <th rowSpan={2} className='p-2 text-left font-bold'>#</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>CI</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>Nombres y apellidos</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>cargo</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>fecha ingreso</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>haber basico</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>dias trab</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>bono antiguedad</th>
                            <th colSpan={2} className='p-2 text-left font-bold'>hr extras</th>
                            <th colSpan={2} className='p-2 text-left font-bold'>bonos</th>
                            <th colSpan={2} className='p-2 text-left font-bold'>dominicales</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>total ganado</th>
                            <th colSpan={5} className='p-2 text-left font-bold'>descuentos</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>liquido pagable</th>
                            <th rowSpan={2} className='p-2 text-left font-bold w-32'>firma</th>                        
                            </tr>
                            <tr>                            
                            
                            <th className='p-2 text-left font-bold'>Nro</th>
                            <th className='p-2 text-left font-bold'>Bs</th>
                            <th className='p-2 text-left font-bold'>Prod</th>
                            <th className='p-2 text-left font-bold'>Otros</th>
                            <th className='p-2 text-left font-bold'>Dias</th>
                            <th className='p-2 text-left font-bold'>Bs</th>
                            <th className='p-2 text-left font-bold'>12.71%</th>
                            <th className='p-2 text-left font-bold'>RC-iva</th>
                            <th className='p-2 text-left font-bold'>Antic.</th>
                            <th className='p-2 text-left font-bold'>Otro</th>
                            <th className='p-2 text-left font-bold'>Totales</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                size(data?.detail) > 0 &&
                                data.detail.map((row,key)=>
                                <tr key={key}>
                                    <td>{key+1}</td>
                                    <td>{row.personal.people.ci}</td>
                                    <td>{row.personal.people.nombres+" "+row.personal.people.apellido_p+" "+row.personal.people.apellido_m}</td>
                                    <td>{row.personal.cargo.cargo}</td>
                                    <td>{dateConfigBirth(row.personal.inicio)}</td>
                                    <td>{row.haber_basico}</td>
                                    <td>{row.dias_traa}</td>
                                    <td>{row.bono_antiguedad}</td>
                                    <td>{row.hr_extra}</td>
                                    <td>{row.hr_extra_price}</td>
                                    <td>{row.bono_prod}</td>
                                    <td>{row.bono_otro}</td>
                                    <td>{row.dominicales}</td>
                                    <td>{row.dominicales_price}</td>
                                    <td>{row.total_ganado}</td>
                                    <td>{row.iva}</td>
                                    <td>{row.rc_iva}</td>
                                    <td>{row.anticipos}</td>
                                    <td>{row.otro}</td>
                                    <td>{row.total_decuentos}</td>
                                <td>{row.liquido_pagable}</td>
                                <td></td>
                                </tr>
                                )
                            }
                            <tr>
                                <th colSpan={5}>Totales</th>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.haber_basico)
                                        }
                                    ) } </td>
                                <td></td>
                                
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.bono_antiguedad)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.hr_extra)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.hr_extra_price)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.bono_prod)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.bono_otro)
                                        }
                                    ) } </td>
                                <td> </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.dominicales_price)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.total_ganado)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.iva)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.rc_iva)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.anticipos)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.otro)
                                        }
                                    ) } 
                                </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.total_descuento)
                                        }
                                    ) } 
                                </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.liquido_pagable)
                                        }
                                    ) } </td>
                            </tr>
                        </tbody>
                        </table>
                    <div className="no-print mt-3">
                    {
                        data.comprobante ?                                                 
                      <ModalComponent  btn={<ArticleIcon color={"success"}/>} fullScreenx={true}  >
                      <PrintComprobante id={data.comprobante.id}/>
                    </ModalComponent>
                      :
                      <ModalComponent btn={<ArticleIcon color={"error"}/>} fullScreenx={true}  >
                        <DiaryRegisterV2
                            type="egreso"
                            company_id={info.company.id}
                            branch_id={"1"}
                            model_type= "PlanillaMonth"
                            model_id= {data.id}
                            detail={
                                <div>
                                    <table>
                                    <thead>
                                            <tr>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>#</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>CI</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>Nombres y apellidos</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>cargo</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>fecha ingreso</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>haber basico</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>dias trab</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>bono antiguedad</th>
                                            <th colSpan={2} className='p-2 text-left font-bold'>hr extras</th>
                                            <th colSpan={2} className='p-2 text-left font-bold'>bonos</th>
                                            <th colSpan={2} className='p-2 text-left font-bold'>dominicales</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>total ganado</th>
                                            <th colSpan={5} className='p-2 text-left font-bold'>descuentos</th>
                                            <th rowSpan={2} className='p-2 text-left font-bold'>liquido pagable</th>                    
                                            </tr>
                                            <tr>                            
                                            
                                            <th className='p-2 text-left font-bold'>Nro</th>
                                            <th className='p-2 text-left font-bold'>Bs</th>
                                            <th className='p-2 text-left font-bold'>Prod</th>
                                            <th className='p-2 text-left font-bold'>Otros</th>
                                            <th className='p-2 text-left font-bold'>Dias</th>
                                            <th className='p-2 text-left font-bold'>Bs</th>
                                            <th className='p-2 text-left font-bold'>12.71%</th>
                                            <th className='p-2 text-left font-bold'>RC-iva</th>
                                            <th className='p-2 text-left font-bold'>Antic.</th>
                                            <th className='p-2 text-left font-bold'>Otro</th>
                                            <th className='p-2 text-left font-bold'>Totales</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                <th colSpan={5}>Totales</th>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.haber_basico)
                                        }
                                    ) } </td>
                                <td></td>
                                
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.bono_antiguedad)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.hr_extra)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.hr_extra_price)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.bono_prod)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.bono_otro)
                                        }
                                    ) } </td>
                                <td> </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.dominicales_price)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.total_ganado)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.iva)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.rc_iva)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.anticipos)
                                        }
                                    ) } </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.otro)
                                        }
                                    ) } 
                                </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.total_descuento)
                                        }
                                    ) } 
                                </td>
                                <td> { sumBy(data.detail,o=>
                                        {
                                            return parseFloat(o.liquido_pagable)
                                        }
                                    ) } </td>
                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                            data= {{
                                glosa: "Registro de planilla de sueldos "+info.company.name+" "+ mes + " - "+anio,
                                date: fecha,
                                total: sumBy(data.detail,o=>
                                    {
                                        return parseFloat(o.liquido_pagable)
                                    }
                                ) ,
                            }}
                            company= {info.company.name}
                            store= {info.store.name}
                            store_id= {info.store.id}
                            reloadData= {loadData}
                            invoice={false}
                            invoiceOption={false}
                        />
                      </ModalComponent>
                    }
                    </div>
            </div>
        )

    }else{
        return(
            <div>
                 <Encabezados
                    title={"Planilla de sueldos correspondiente al mes de " + mes + " - "+anio}
                    subtitle={""}
                    company={info.company.name}
                    branch={info?.z_company_branch.sucursal}
                    nit={info.company.nit}
                    nro={""}
                    />  
                    <table className="table-print">
                        <thead>
                            <tr>
                            <th rowSpan={2} className='p-2 text-left font-bold'>#</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>CI</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>Nombres y apellidos</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>cargo</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>fecha ingreso</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>haber basico</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>dias trab</th>
                            <th colSpan={2} className='p-2 text-left font-bold'>bono antiguedad</th>
                            <th colSpan={2} className='p-2 text-left font-bold'>hr extras</th>
                            <th colSpan={2} className='p-2 text-left font-bold'>bonos</th>
                            <th colSpan={2} className='p-2 text-left font-bold'>dominicales</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>total ganado</th>
                            <th colSpan={5} className='p-2 text-left font-bold'>descuentos</th>
                            <th rowSpan={2} className='p-2 text-left font-bold'>liquido pagable</th>
                            <th rowSpan={2} className='p-2 text-left font-bold w-32'>firma</th>                        
                            </tr>
                            <tr>
                            <th className='p-2 text-left font-bold'>%</th>
                            <th className='p-2 text-left font-bold'>Bs</th>
                            <th className='p-2 text-left font-bold'>Nro</th>
                            <th className='p-2 text-left font-bold'>Bs</th>
                            <th className='p-2 text-left font-bold'>Prod</th>
                            <th className='p-2 text-left font-bold'>Otros</th>
                            <th className='p-2 text-left font-bold'>Dias</th>
                            <th className='p-2 text-left font-bold'>Bs</th>
                            <th className='p-2 text-left font-bold'>12.71%</th>
                            <th className='p-2 text-left font-bold'>RC-iva</th>
                            <th className='p-2 text-left font-bold'>Antic.</th>
                            <th className='p-2 text-left font-bold'>Otro</th>
                            <th className='p-2 text-left font-bold'>Totales</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                size(data) > 0 &&
                                data.map((row,key)=>
                                <Fragment key={key}>
                                  <Detail dat={row} nro={key+1} fecha={fecha} upRow={upRow} index={key} />
                                </Fragment>
                                )
                            }
                            <tr>
                                <th colSpan={5}>Totales</th>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.haber_basico)
                                        }
                                    ) } </td>
                                <td></td>
                                <td> </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.bono_antiguedad)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.hr_extra)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.hr_extra_price)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.bono_prod)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.bono_otro)
                                        }
                                    ) } </td>
                                <td> </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.dominicales_price)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.total_ganado)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.iva)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.rc_iva)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.anticipos)
                                        }
                                    ) } </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.otro)
                                        }
                                    ) } 
                                </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.total_descuento)
                                        }
                                    ) } 
                                </td>
                                <td> { sumBy(newData,o=>
                                        {
                                            return parseFloat(o.liquido_pagable)
                                        }
                                    ) } </td>
                            </tr>
                        </tbody>
                        </table>
                    <div className="no-print mt-3">
                    <Button variant="contained" onClick={generarPlanilla} disabled={size(newData)>0 ? false:true} >Generar Planilla</Button>
                    </div>
            </div>
        )
    }
}
const Detail=({dat,nro,fecha,upRow,index})=>{
    const [oldData,setOldData] = useState({})
    const formik = useFormik({
        initialValues:{
            personal_id:"",
            haber_basico:"",
            dias_traa:"",
            total_basico:"",
            bono_antiguedad:"",
            hr_extra:"",
            hr_extra_price:"",
            bono_prod:"",
            bono_otro:"",
            dominicales:"",
            dominicales_price:"",
            iva:"",
            rc_iva:"",
            anticipos:"",
            otro:"",
            total_descuento:"0",
            total_ganado:"0",
            liquido_pagable:"",
        },
        onSubmit: (val)=>{
            upRow(index,val)
            setOldData(val)
        }
    })
    
    useEffect(()=>{
        const total_ganado =  parseFloat(formik.values.haber_basico)+
        parseFloat(formik.values.bono_antiguedad)+
        parseFloat(formik.values.hr_extra_price)+
        parseFloat(formik.values.bono_prod)+
        parseFloat(formik.values.bono_otro)+
        parseFloat(formik.values.dominicales_price);
        formik.setFieldValue("total_ganado",total_ganado)
        
        const total_decuentos = parseFloat(formik.values.iva)+
        parseFloat(formik.values.rc_iva)+
        parseFloat(formik.values.anticipos)+
        parseFloat(formik.values.otro)
        formik.setFieldValue("total_descuento",total_decuentos)
        formik.setFieldValue("liquido_pagable",total_ganado-total_decuentos)


    },[formik.values])
    useEffect(()=>{
        const antiguedad = calcularAntiguedad(dat.inicio,fecha,dat.sueldo);
        const values =  {
            personal_id:dat.id,
            haber_basico:dat.sueldo,
            dias_traa:"0",
            total_basico:dat.sueldo,
            porcentaje_antiguedad: antiguedad.porcentaje,
            bono_antiguedad: antiguedad.bono,
            hr_extra:"0",
            hr_extra_price:"0",
            bono_prod:"0",
            bono_otro:"0",
            dominicales:"0",
            dominicales_price:"0",
            iva: dat.sueldo*0.1271,
            rc_iva:"0",
            anticipos: sumBy(dat.histo,o=>{
                if(o.type === "anticipo"){
                    return parseFloat(o.monto);
                }else{
                    return 0;
                }
            }) ,
            otro: sumBy(dat.histo,o=>{
                if(o.type === "descuento"){
                    return parseFloat(o.monto);
                }else{
                    return 0;
                }
            }),
            liquido_pagable:"",
        }
       // setOldData(values)
        formik.setValues(
           values
        )
    },[])
    
    return(
        <Fragment>
            <tr className={formik.values === oldData?"":"bg-red-300"}>
              <td>{nro}</td>
              <td>{dat.people.ci}</td>
              <td>{dat.people.nombres+" "+dat.people.apellido_p+" "+dat.people.apellido_m}</td>
              <td>{dat.cargo.cargo}</td>
              <td>{dateConfigBirth(dat.inicio)}</td>
              <td>{fNumber(formik.values.total_basico)}</td>
              <td>
                <input
                 value={formik.values.dias_traa}
                 onChange={formik.handleChange}
                 name="dias_traa"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
              {formik.values.porcentaje_antiguedad}           
              </td>
              <td>
              {formik.values.bono_antiguedad}           
              </td>
              <td>
                <input
                 value={formik.values.hr_extra}
                 onChange={formik.handleChange}
                 name="hr_extra"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
                <input
                 value={formik.values.hr_extra_price}
                 onChange={formik.handleChange}
                 name="hr_extra_price"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
                <input
                 value={formik.values.bono_prod}
                 onChange={formik.handleChange}
                 name="bono_prod"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
                <input
                 value={formik.values.bono_otro}
                 onChange={formik.handleChange}
                 name="bono_otro"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
                <input
                 value={formik.values.dominicales}
                 onChange={formik.handleChange}
                 name="dominicales"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
                <input
                 value={formik.values.dominicales_price}
                 onChange={formik.handleChange}
                 name="dominicales_price"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
                {
                   formik.values.total_ganado                   
                }
              </td>
              <td>
                <input
                 value={formik.values.iva}
                 onChange={formik.handleChange}
                 name="iva"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
                <input
                 value={formik.values.rc_iva}
                 onChange={formik.handleChange}
                 name="rc_iva"
                 className="w-16"             
                 variant="outlined"
                 size="small"
                 type="number"
                 />
           
              </td>
              <td>
              {formik.values.anticipos}           
              </td>
              <td>
              {formik.values.otro}           
              </td>
              <td>
                {
                     formik.values.total_descuento
                }
              </td>
              <td>
                {
                   formik.values.liquido_pagable
                }
              </td>
                <td>
                    {formik.values !== oldData &&
                        <div className="">
                        <Button onClick={formik.handleSubmit}>✅</Button>
                    </div>}
                </td>
                </tr>
        </Fragment>
    )
}
export default GenerarPlanilla

const rangos =[
    { inicio: 2, fin: 4, porcentaje: 5 },
    { inicio: 5, fin: 7, porcentaje: 11 },
    { inicio: 8, fin: 10, porcentaje: 18 },
    { inicio: 11, fin: 14, porcentaje: 26 },
    { inicio: 15, fin: 19, porcentaje: 34 },
    { inicio: 20, fin: 24, porcentaje: 42 },
    { inicio: 25, fin: 100, porcentaje: 50 },
]
const calcularAntiguedad=(inicio,aFecha,salarioBase)=>{
    const fechaInicio = new Date(inicio);
const fechaFin = new Date(aFecha);
const antiguedadEnAnios  = differenceInYears(fechaFin, fechaInicio);
let porcentajeIncremento = 0;

for (const rango of rangos) {
    if (antiguedadEnAnios >= rango.inicio && antiguedadEnAnios <= rango.fin) {
        porcentajeIncremento = rango.porcentaje;
        break; // Romper el bucle si se encuentra el rango correspondiente
    }
}
const bono = (porcentajeIncremento / 100) * salarioBase;

return {bono:bono,porcentaje:porcentajeIncremento};
}