import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from "react-to-print";
import { cloneElement, Fragment, useRef, useState } from "react";
import { Children } from "react";

const PaynemetModal = ({children,btn,title,color,savePay,disabled=false}) => {
    const [open,setOpen] = useState(false)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const componentRef = useRef();
    const handleClose = () => {
      setOpen(false);
    };
    const handlePrint = useReactToPrint({
      content: ()=>componentRef.current,
    })
    const saveToPay=(val)=>{
        savePay(val)
        handleClose()
    }
  return (
    <Fragment>
         
        <Button disabled={disabled} onClick={()=>setOpen(true)} style={{background: color}}>{btn}</Button>
        <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handlePrint}
          >
            <PrintIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent ref={componentRef} >

        <div className="">
          {
            Children.map(children,child=>cloneElement(child,{
                saveToPay:saveToPay
            }))
          }
        </div>
      </DialogContent>
    </Dialog>
    </Fragment>
  )
}
export default PaynemetModal