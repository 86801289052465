import { fNumber } from "../../utils/formatNumber"
import { toast } from "react-toastify";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { size } from "lodash";
import { Button, TextField } from "@mui/material";
const validationSchema = yup.object({
    a_cuenta: yup.number().integer()
  });
const Anticipo = ({data,saveToPay}) => {
    const cart = useSelector((state) => state.cartSales);  
  const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            a_cuenta: "",
            aditional:""
        },
        validationSchema: validationSchema,
        onSubmit:(values)=>{
          /*   if (size(cart) <= 0) {
                toast.error("Debe seleccionar al menos un item en su carrito!");
                return;
              } */
              if (size(data.client) <= 0) {
                toast.error("Registre datos de cliente!");
                return;
              }
              saveToPay(values)
        }
    })
    return (
    <div>
         
    <div className="flex flex-col items-center ">
       <p className="text-lg font-semibold">TOTAL Bs:</p>
       <p className="text-4xl font-semibold text-gray-500">
         {fNumber(data.total)}
       </p>
     </div>
    <div className="flex flex-col items-center ">
       <p className="text-lg font-semibold">SALDO ANTICIPO Bs:</p>
       <p className="text-4xl font-semibold text-green-500">
         {fNumber(data.saldo)}
       </p>
     </div>
    
    
   <form  onSubmit={formik.handleSubmit}>

 <div className="flex flex-col items-center">
       <p className="text-lg font-semibold">SALDO Bs:</p>
       <p className="text-xl font-semibold text-gray-500">
         {fNumber(parseFloat(data.saldo) - parseFloat(data.total))}
       </p>
     </div>
   <TextField
     value={formik.values.aditional}
     onChange={formik.handleChange}
     name="aditional"
     fullWidth
     sx={{ my: 1 }}
     label="Observaciones"
     multiline={true}          
     variant="outlined"
     size="small"       
   />
   {
    parseFloat(data.saldo) - parseFloat(data.total) >= 0 ?
    <Button  variant="contained" type="submit">Registrar Venta</Button>:
    <p className="text-red-500 font-semibold uppercase">No tiene saldo suficiente para realizar esta compra</p>
   }
   </form>
</div>
  )
}
export default Anticipo