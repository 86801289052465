import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import Page from "../../layout/Pagev1";
import { facturate, getCash, getSales, initCash } from "../../services/cashBox";
import Grid from "@mui/material/Unstable_Grid2";
import { size, uniqBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import ReplayIcon from "@mui/icons-material/Replay";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { sumBy } from "lodash";
import { fNumber } from "../../utils/formatNumber";
import SalesDetailPrint from "../../components/SalesDetailPrint";
import { useReactToPrint } from "react-to-print";
import { setLoadOff, setLoadOn } from "../../redux/features/config/load";

import { toast } from "react-toastify";
import InvoicePrint from "./InvoicePrint";
import Cajas from "../Salev1/Cajas";
import PrintConfig from "../Salev1/PrintConfig";
import CashDetailV1 from "./CashDetailV1";
const FormNewCashbox = ({ setCash, cash }) => {
  const [mount, setMount] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch()
  const handleSubmit = (e) => {
    dispatch(setLoadOn())
    setBtnDisabled(true);
    e.preventDefault();
    initCash({ initial_amount: mount })
      .then((res) => {
        setCash(res);
      })
      .finally(() => {
        dispatch(setLoadOff())
        setBtnDisabled(false);
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="inline-flex gap-3">
          <TextField
            value={mount}
            onChange={(e) => setMount(e.target.value)}
            name="mount"
            sx={{ my: 1 }}
            type="number"
            label="Monto Inicial"
            variant="outlined"
            size="small"
          />
          <Button
            sx={{ my: 1 }}
            variant="contained"
            type="submit"
            disabled={btnDisabled}
          >
            abrir caja
          </Button>
        </div>
      </form>
    </div>
  );
};

const CashBox = () => {
  const [cash, setCash] = useState();
  const userData = useSelector((state) => state.userData);
  const [vouchers, setVouchers] = useState();
  const [voucher, setVoucher] = useState();
  const [print, setPrints] = useState();
  const [invoice,setInvoice] = useState([])
  const [load, setLoad] = useState(true);
  const [facturadores,setFacturadores] = useState([])
  const [paymentMethod, setPaymentMethod] = useState(true);
  const [sales,setSales] = useState(false)
  const [cashdet,setCashDet] = useState([])
  const [client,setClient] = useState({})
  const [dataPrint,setDataPrint] = useState(false)
  const [discountCode,setDiscountCode] = useState()
  const [discount,setDiscoun] = useState(0)
  const [infoPay, setInfoPay] = useState({});
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const loadCash = () => {
    getCash()
      .then((res) => {
        setCash(res);
      })
      .finally(() => {
        setLoad(false);
      });
  };
  const setPrint = async (val) => {
    setPrints(val);
    await sleep(1000);
    handlePrint();
  };
  const loadVouchers = () => {
    setVoucher();
    loadCash();
    setPrint();
    getSales().then((res) => {
      setVouchers(res);
      
    });
  };
  useEffect(() => {
    loadVouchers();
  }, []);

  const setVoucherData = (row) => {    
    
    const dat = row;    
    setClient(dat.client)
    setVoucher(dat);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => loadVouchers(),
  });
  const printInvoice=useReactToPrint({
    content: () => componentRef.current,
  })
  const setFactu = (res)=>{
      setFacturadores(() => {
        return uniqBy(res.detail, "factu");
      })
    }
    const toastId = useRef(null)
    const facturar= async (facturar)=>{      
      toastId.current =  toast.loading("cargando...")
      await facturate({id:facturar.factu, voucher_id: facturar.voucher_id}).then(res=>{        
        setInvoice(res)
        setVoucher(res)        
        
      }).finally(()=>{
        toastId.current =  toast.dismiss(toastId.current)
      })
      await sleep(1000);
      printInvoice();

    } 
  return (
    <Page appBar={true} title={"Caja / " + userData.user.name}>
      {size(cash) > 0 ? (
        <div>
          {
            sales?
            <PrintConfig dataPrint={dataPrint} infoPay={infoPay}/>
            :
            <Grid container spacing={3}>
            <Grid md={4}>
              <Paper className="px-3 overflow-y-auto" style={{ maxHeight: "90vh" }}>
                <List >
                  <ListItem>
                    <div className="w-full inline-flex justify-between items-center">
                      <p className="font-semibold text-primary uppercase">
                        Clientes en espera{" "}
                      </p>
                      <IconButton color="primary" onClick={() => loadVouchers()}>
                        <ReplayIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                  <Divider />
                  {size(vouchers) > 0 && (
                    <>
                      {vouchers.map((row, key) => (
                        <ListItemButton
                          key={key}
                          selected={row.id === voucher?.id}
                          onClick={() => setVoucherData(row)}
                          className="bg-blue-200"
                        >
                          <ListItemIcon>
                            <PointOfSaleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              "Nro: " +
                              row.voucher_number +
                              " - " +
                              row.business_name
                            }
                            secondary={
                              "Bs.-" + fNumber(calculeTotal(row.voucher_detail))
                            }
                          />
                        </ListItemButton>
                      ))}
                    </>
                  )}
                  <Divider />
                  <ListItem>
                    <div className="w-full inline-flex justify-between items-center">
                      <p className="font-semibold text-green-500">
                        En caja: Bs.-{" "}                    
                        <span className="text-xl">
                          {fNumber(parseFloat(cash.detail_sum_total)+parseFloat(cash.initial_amount))}
                        </span>
                      </p>
                      <CashDetailV1 box={cash} reloadData={()=>console.log("g")}/>
                    </div>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid md={8}>
              {size(voucher) > 0 && (
                <Paper className="p-4">
                  {size(print) > 0 ? (
                    <Button
                      variant="contained"
                      onClick={() => handlePrint()}
                      sx={{ my: 1 }}
                    >
                      Imprimir
                    </Button>
                  ) : (
                    <div>
                       {
                        size(facturadores) > 0 ?
                        <div>
                          {
                            facturadores.map((row,key)=>
                            <Button key={key} disabled={row.factu===null} fullWidth onClick={()=>facturar(row)}>{row.provider}</Button>
                            
                            )
                          }  
                        </div>
                        
                        :
                       <div>
                        <p className="text-2xl text-primary font-semibold my-3">
                        Monto Total a cobrar :{" "}
                        <span>
                          {fNumber(calculeTotal(voucher.voucher_detail))}
                        </span>
                      </p>
                      <Cajas 
                        total={calculeTotal(voucher.voucher_detail)}
                        paymentMethod={parseInt(voucher.invoice)}
                        voucher_id={voucher.id}
                        code={voucher.anticipo_code}
                        clientg={client}
                        setSales={setSales} 
                        setCashDet={setCashDet}
                        setDataPrint={setDataPrint}           
                        setInfoPay={setInfoPay}           
                        setPaymentMethod={setPaymentMethod} 
                        discountCode={discountCode}
                        setDiscountCode={setDiscountCode}
                        discount={discount}
                        setDiscoun={setDiscoun}
                        />
              
                        </div>
                        }
                    </div>
                  )}
                  <div className="" ref={componentRef}>                
                     <div>
                      {
                       size(invoice)> 0?
                       "" :
                        <div>
                           {print &&
                        <SalesDetailPrint data={print} />
                        }
                        </div>
                      }
                      {
                         size(invoice)> 0 &&
                         <div>
                           <InvoicePrint invoice={invoice}/> 
                          <Button className="no-print" onClick={()=>printInvoice()}>Imprimir</Button>
                         </div>
                      }
                      </div>
                  </div>
                </Paper>
              )}
            </Grid>
          </Grid>
          }
        </div>
      
      ) : (
        <Paper className="p-3">
          {!load ? (
            <FormNewCashbox setCash={setCash} cash={cash}  />
          ) : (
            <p>cargando...</p>
          )}
        </Paper>
      )}
    </Page>
  );
};



const calculeTotal = (voucher) => {
  return sumBy(voucher, (o) => {
    return (parseInt(o.qty) * parseFloat(o.price))-parseFloat(o.discount);
  });
};
export default CashBox;
