import React from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { searchAcount } from '../services/conta';
const AutocompleteAccount = ({setAccount,company}) => {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
  
    React.useEffect(() => {
      let active = true;
  
      if (!loading) {
        return undefined;
      }
  
      (() => {
        if (active) {
          searchAcount({ search: "1" }).then((res) => {
            setOptions(res);
          });
        }
      })();
  
      return () => {
        active = false;
      };
    }, [loading]);
    const handleChange = (event) => {
      setOptions([]);
        searchAcount({ search: event.target.value }).then((res) => {
        setOptions(res);
      });
    };
    React.useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);
  return (
    <Autocomplete
    
    sx={{ my: 1 }}
    open={open}
    onOpen={() => {
      setOpen(true);
    }}
    onClose={() => {
      setOpen(false);
    }}
    isOptionEqualToValue={(option, value) => option.name === value.name}
    getOptionLabel={(option) =>  option.codigo +" - "+ option.name}
    filterOptions={(x)=>{return x}}
    options={options}
    loading={loading}
    onChange={(event, value) => {
        setAccount(value);
    }}
    renderInput={(params) => (
      <TextField
        
        {...params}
        size="small"        
        label="Buscar cuenta"
        onChange={handleChange}       
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    )}
  />
  )
}
export default AutocompleteAccount