import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import {
  AppBar,
  Badge,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  Tooltip
} from "@mui/material";
import { size } from "lodash";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import { fNumber } from "../../utils/formatNumber";
import DeleteIcon from "@mui/icons-material/Delete";
import { delItem, resetCart, upItem } from "../../redux/features/cart/transferCart";
import Swal from "sweetalert2";
import { setTransfer } from "../../services/transfer";
import PrintTransfer from "./PrintTransfer";
import { useReactToPrint } from "react-to-print";
/* 
import socketIOClient from "socket.io-client"
import { SOCKET_IO_URL } from "../../config/AppConfig"; */
const ModalTransferCart = ({stores,printData=null,callData=null}) => {
  const cart = useSelector((state) => state.transferCart);

 //   let socketio = socketIOClient(SOCKET_IO_URL)
    
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({});
  const [print,setPrint] = useState({})
  const [disabled,setDisabled] = useState(false)
  const dispatch= useDispatch()
  const theme = useTheme();
  const userData = useSelector(state=>state.userData)
  const componentRef = useRef();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));  
  const handlePrint = useReactToPrint({
    content: ()=>componentRef.current,
  })
  const handleChange = (e,row) => {
    dispatch(upItem({
        stock_id: row.stock_id,
        sale_presentation: row.sale_presentation,
        qty: e.target.value
    }))
  };
  
  const deleteItem = (row) => {
        dispatch(delItem({stock_id:row.stock_id}))
  };
  const generateTrasnfer=()=>{
    setDisabled(true)
    if(options.store && options.observation){
        const val ={
            head:options,
            cart:cart
        }
        setTransfer(val).then(res=>{
            setPrint(res)
            setOptions({})
            dispatch(resetCart())
           /*  
           socketio.emit("alertsk",{
              titulo: "Traspaso Directo",
              message: res.attendant.name +" creo un traspaso para "+ res.store?.name,
              user: userData.name
            }) */
            
        }).finally(()=>{
          setDisabled(false)
        })
    }else{
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Almacen y observaciones son datos requeridos para esta operación!",
          });
    }
  }
  const handleClose=()=>{
    callData()
    setPrint({})
    setOpen(false)
  }
  
  return (
    <div>
	<Tooltip title="Productos seleccionados para traspasos" followCursor>
      <IconButton onClick={() => setOpen(true)}>
        <Badge badgeContent={size(cart)} color="success">
          <ShoppingCartCheckoutIcon color="primary" />
        </Badge>
      </IconButton>
	 </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => handleClose()}
        scroll="paper"
        maxWidth="2xl"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
		  <Tooltip title="cerrar" followCursor>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
			</Tooltip>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Traspaso de Productos
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handlePrint}>
              <PrintIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent  ref={componentRef}>
        <div>
        {size(print)>0?
        <div>
            <PrintTransfer data={print}/>
        </div>
            :
        <div>
          <Table size="small" className="text-xs table-print">
            <TableHead>
              <TableRow>
                <TableCell>Proveedor</TableCell>
                <TableCell>Producto</TableCell>
                <TableCell>Presentación</TableCell>
                <TableCell>Packing</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell className="no-print"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {size(cart) > 0 &&
                cart.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell>{row.provider}</TableCell>
                    <TableCell>{row.item_name}</TableCell>
                    <TableCell>{row.item_presentation}</TableCell>
                    <TableCell>
                      {row.sale_presentation + " x " + row.sale_units}
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="price"
                        sx={{ maxWidth: 100 }}
                        size="small"
                        type="number"
                        inputProps={{ max: row.stock_pro?.qty, min: 1 }}
                        value={row.qty}
                        onChange={(e) => handleChange(e, row)}
                      />
                    </TableCell>
                    <TableCell>
					<Tooltip title="eliminar" followCursor>
                      <IconButton
                        onClick={() => deleteItem(row)}
                        color="error"
                        aria-label="eliminar"
                        component="label"
                      >
                        <DeleteIcon />
                      </IconButton>
					  </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className="mt-3">
            <p className="text-sm my-2 text-gray-600">selecciona la sucursal a la que quieres traspasar los prouctos seleccionados</p>
          <FormControl className="w-60">
              <InputLabel id="store">Almacen</InputLabel>
              <Select                
                labelId="store"
                id="store"
                name="store"
                label="Almacen/Sala"
                value={options.store}
                onChange={(e) =>
                  setOptions({ ...options, store: e.target.value })
                }
                sx={{ my: 1 }}
                size="small"               
              >
                {size(stores) > 0 &&
                  stores.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.name}
                    </MenuItem>
                  )
                  )}
              </Select>               
            </FormControl>
           
          </div>
          <div>
          <FormControl fullWidth >        
            <TextareaAutosize  
                  className="border border-gray-400 rounded-md p-2 w-full mt-3"
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Observación"
                  name="observation"
                  onChange={(e) =>
                    setOptions({ ...options, observation: e.target.value })}
                  defaultValue={options.observation}                
                /> 
            </FormControl>
          </div>
        <div className="mt-3">
        <Button disabled={size(cart)===0 || disabled  } variant="contained" onClick={()=>generateTrasnfer()}>
            Finalizar traspaso
          </Button>
        </div>
        </div>}
        </div>
              
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalTransferCart;
