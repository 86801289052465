import API from "../axios/api"

export const getRoles = async ()=>{
    const {data} = await API.get('api/roles/list',)    
    return data
}
export const getBranchOffice = async ()=>{
    const {data} = await API.get('api/branch-office/all',)    
    return data
}
export const getRoleMenu = async (val)=>{
    const {data} = await API.post('api/menu/list',val)    
    return data
}
export const addRoleMenu = async (val)=>{
    const {data} = await API.post('api/menu/asign-menu',val)    
    return data
}
export const delRoleMenu = async (val)=>{
    const {data} = await API.post('api/menu/del-menu',val)    
    return data
}
export const listRole = async ()=>{
    const {data} = await API.get('api/menu/list-rol')    
    return data
}

export const listReclamos = async ()=>{
    const {data} = await API.get('api/reclamo/list')    
    return data
}
export const createReclamos = async (val)=>{
    const {data} = await API.post('api/reclamo/create',val)    
    return data
}
export const updateReclamos = async (val)=>{
    const {data} = await API.post('api/reclamo/update',val)    
    return data
}

export const listUfv = async ()=>{
    const {data} = await API.get('api/utility/ufv/list')    
    return data
}
export const newUfv = async (val)=>{
    const {data} = await API.post('api/utility/ufv/new',val)    
    return data
}
export const downloadUfv = async ()=>{
    const {data} = await API.get('api/utility/ufv/export',{responseType:'blob'})    
    return data
}
export const importUfv = async (val)=>{
    const {data} = await API.post('api/utility/ufv/import',val,{headers: {
        'content-type': 'multipart/form-data',
      }})    
    return data
}
export const listExchangeRate = async ()=>{
    const {data} = await API.get('api/utility/exchange-rate/list')    
    return data
}
export const newExchangeRate = async (val)=>{
    const {data} = await API.post('api/utility/exchange-rate/new',val)    
    return data
}
export const downloadExchangeRate = async ()=>{
    const {data} = await API.get('api/utility/exchange-rate/export',{responseType:'blob'})    
    return data
}
export const importExchangeRate = async (val)=>{
    const {data} = await API.post('api/utility/exchange-rate/import',val,{headers: {
        'content-type': 'multipart/form-data',
      }})    
    return data
}

export const searchPais = async (val)=>{
    const {data} = await API.post('api/pais',val)    
    return data
}
export const searchEstado = async (val)=>{
    const {data} = await API.post('api/estado',val)    
    return data
}