import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useFormik } from "formik"
import { find, size } from "lodash";
import { useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { listApiService, postApiService } from "../../../services/crudServices";
import { toast } from "react-toastify";

const Apertura =({closeModal})=>{
    const userData = useSelector((state) => state.userData);
    const [branchOffices, setBranchOffices] = useState([]);
    const [stores, setStores] = useState([]);
    const [personal,setPersonal] = useState([])
  

    const formik = useFormik({
        initialValues:{
            company_id:"",
            z_company_branch_id:"",
            store_id:"",
            responsable:"",
            monto_apertura:"",
            monto_cierre:"",
            cierre:"",
            user_id:"",
        },
        onSubmit:(values)=>{
            postApiService('caja-chica/create',values).then(res=>{
                toast.success("Se registro correctamente!!");
                closeModal()
            })
        }
    })
    const loadPersonal=()=>{
        listApiService('personal-list').then(res=>{
            setPersonal(res)
        })
      }
    
    const groupedData = userData.user.companies.map(company => {
        const branches = company.branches.reduce((acc, branch) => {
            const existingBranch = acc.find(b => b.sucursal === branch.sucursal);
            if (existingBranch) {
                existingBranch.store.push(branch.store);
            } else {
                acc.push({
                    id:branch.id,
                    sucursal: branch.sucursal,
                    sucursal_code: branch.sucursal_code,
                    direction: branch.direction,
                    city: branch.city,
                    phone: branch.phone,
                    store: [branch.store]
                });
            }
            return acc;
        }, []);
        return {
            ...company,
            branches: branches
        };
      });
      const handleChangeCompany = (e) => {
        formik.setFieldValue("company_id", e.target.value);
        const branches = find(groupedData,o=>{
          return o.id === e.target.value
      })
        setBranchOffices(branches?.branches||[]);
      };
      const handleChangeBranch = (e) => {    
        // setTargetStore("all")
         const stores = find(branchOffices,o=>{
             return o.id === e.target.value
         })
         formik.setFieldValue('z_company_branch_id',e.target.value)
         setStores(stores?.store||[])
     
       };
       useMemo(() => {
        loadPersonal();
      }, []);
    return(
        <form onSubmit={formik.handleSubmit}>
            <div className="md:grid grid-cols-2 gap-2">
            <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={formik.values.company_id}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.company_id && Boolean(formik.errors.company_id)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              {groupedData.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.company_id && formik.errors.company_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="z_company_branch_id">Sucursal</InputLabel>
            <Select
              labelId="empresa"
              id="z_company_branch_id"
              disabled={size(branchOffices) === 0}
              name="z_company_branch_id"
              label="Sucursal"
              value={formik.values.z_company_branch_id}
              onChange={handleChangeBranch}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.z_company_branch_id &&
                Boolean(formik.errors.z_company_branch_id)
              }
            >              
              {branchOffices.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.sucursal}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.z_company_branch_id &&
                formik.errors.z_company_branch_id}
            </FormHelperText>
          </FormControl>
          <FormControl  fullWidth>
          <InputLabel id="store_id">Almacen / Sala</InputLabel>
          <Select
            labelId="store_id"
            id="store_id"
            name="store_id"
            value={formik.values.store_id}
            label="Almacen / Sala"
            size="small"
            sx={{ my: 1 }}
            onChange={formik.handleChange}
          >
          
            {size(stores)>0 &&
            stores.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText color="error">
              {formik.touched.store_id &&
                formik.errors.store_id}
            </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
            <InputLabel id="responsable">Responsable</InputLabel>
            <Select
              labelId="responsable"
              id="responsable"
              name="responsable"
              label="Responsable"
              value={formik.values.responsable}
              onChange={formik.handleChange}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.responsable &&
                Boolean(formik.errors.responsable)
              }
            >
              <MenuItem value="">seleccionar</MenuItem>
              {
                personal.map((row,key)=>
                <MenuItem value={row.id} key={key}>{ row.people.nombres + " "+ row.people.apellido_p}</MenuItem>                
                )
              }
              
            </Select>
            <FormHelperText color="error">
              {formik.touched.responsable && formik.errors.responsable}
            </FormHelperText>
          </FormControl>
          <TextField
            value={formik.values.monto_apertura}
            onChange={formik.handleChange}
            name="monto_apertura"
            className="col-span-2"
            fullWidth
            sx={{ my: 1 }}
            label="Monto Apertura (Bs)"
            error={formik.touched.monto_apertura && Boolean(formik.errors.monto_apertura)}
            helperText={formik.touched.monto_apertura && formik.errors.monto_apertura}
            variant="outlined"
            type="number"
            size="small"
          />
           <Button variant="contained" type="submit">
            Registrar
          </Button>
            </div>
        </form>
    )
}

export default Apertura