import API from "../axios/api"

export const uploadDocument= async (val)=>{
    const {data} = await API.post('api/document/upload',val,{headers: {
        'content-type': 'multipart/form-data',
      }},)
    return data
}
export const downloadDocument = async (val)=>{
    const {data} = await API.post('api/document/download',val,{responseType:'blob'})
    return data
}