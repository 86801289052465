import { useEffect, useState } from "react";
import { getDocumentPrint } from "../../services/report";
import { size } from "lodash";
import { Divider } from "@mui/material";
import { dateConfig } from "../../utils/dateconfig";
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber";
import { numeroALetras } from "../../utils/NumeroALetras";
import QRCode from "react-qr-code";

const InvoicePrintV2 = ({ invoice, doc, id }) => {
    let head1 = invoice?.factura.invoice_init;
    let detail1 = invoice?.factura.invoice_init?.items;
    let status1 = invoice?.status;
    const [head, setHead] = useState(head1);
    const [detail, setDetail] = useState(detail1);
    const [user,setUser] = useState(invoice?.user)
    const [status, setStatus] = useState(status1);
    const [nota,setNota] = useState(invoice?.nota)
    const codigo = head?.cuf;
    const primeraParte = codigo?.substring(0, 34);
    const segundaParte = codigo?.substring(34);
    useEffect(() => {
      if (size(doc) > 0) {
        getDocumentPrint({ doc: doc, id: id }).then((res) => {
          setHead(res.factura);
          setUser(res.user)
          setNota(res.nota)
          setStatus(res.status || "");
          if (res.factura.items[0]) {
            setDetail(res.factura.items);
          } else {
            setDetail([res.factura.items]);
          }
        });
      }
    }, []);

    return (
      <div>
       {/*  <style>
        @page {
          margin: 20px;
        }

        * {
          font-size: 12px;
          font-family: Arial, Verdana, Helvetica;
          font-family: 'Inconsolata', monospace
        }
      </style> */}
        {size(detail) > 0 && (
          <div style={{fontFamily:"Arial, Verdana, Helvetica",color:"#000"}}>
            <div className="text-center font-bold " style={{ fontSize: "10px" }}>
              <div className="text-center font-bold">FACTURA</div>
              <p className="font-semibold">CON DERECHO A CRÉDITO FISCAL</p>
  
              <div className="text-center">
              <p>{head.razonSocial}</p>
              <p>
                {head.codigo_sucursal === "0"
                  ? "Casa Matriz"
                  : "Sucursal " + head.codigo_sucursal}
              </p>
              <p>No. Punto de Venta {head.punto_venta}</p>
              <p>
                DIRECCION: <span>{head.direccion}</span>
              </p>
              <p>
                TELEFONO: <span>{head.telefono}</span>
              </p>
              <p>
                <span>{head.ciudad||'LA PAZ'}</span>
              </p>
  
              </div>
  
              <div className="text-center text-xs font-bold mb-1">
                ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
              </div>
              <p>
                <span className="font-bold">NIT: </span>
                {head.nit_emisor}
              </p>
              <p>
                <span className="font-bold">FACTURA No: </span>
                {head.invoice_number}
              </p>
              <p>CÓD AUTORIZACIÓN: </p>
              <p>{primeraParte}</p>
              <p>{segundaParte}</p>
            </div>
            <Divider/>
            <div className="uppercase" style={{ fontSize: "11px" }}>
              <p>
                <span className="font-semibold">NOMBRE/RAZÓN SOCIAL: </span>{" "}
                {head.customer}{" "}
              </p>
              <p>
                <span className="font-semibold">NIT/CI/CEX: </span>{" "}
                {head.nit_ruc_nif}{" "}
              </p>
              <p>
                <span className="font-semibold">COD. CLIENTE: </span>{" "}
                {head.nit_ruc_nif}{" "}
              </p>
              <p>
                <span className="font-semibold">FECHA DE EMISION: </span>{" "}
                {dateConfig(head.creation_date)}{" "}
              </p>
            </div>
            <Divider />
            <div className="text-xs" style={{ fontSize: "10px" }}>             
                <table style={{width:'100%'}} className="">
                  <thead>
                    <tr>
                      <th>CANT.</th>
                      <th>P.UNIT.</th>
                      <th className="text-left">DETALLE</th>
                      <th>DESCT.</th>
                      <th>SUBTOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                  {size(detail) > 0 &&
                detail.map((row, key) => (
                 <tr key={key} >
                    <td className="text-right">{fNumber(row.quantity)}</td>
                    <td className="text-right">  {fNumber(
                          parseFloat(row.price) 
                        )}</td>
                    <td className="">{ row.product_name.slice(0,33)}</td>
                    <td className="text-right">{ fNumber(row.discount)}</td>
                    <td className="text-right"> {fNumber(
                        row.total
                        )}</td>
                 </tr>                
                  ))}
                  </tbody>
                </table>
                  <Divider />
              <div
                className="w-full grid grid-cols-2"
                style={{ fontSize: "10px" }}
              >
                <p className="text-right">SUTOTAL Bs</p>
                <p className="text-right">
                  {fNumber(
                    head.subtotal
                  )}
                </p>
                <p className="text-right">DESCUENTO Bs</p>
                <p className="text-right">{fNumber(head.discount)}</p>
                <p className="text-right">
                  TOTAL Bs. <span></span>
                </p>
                <p className="text-right">
                  {fNumber(head.total)}
                </p>
                <p className="text-right">
                  MONTO GIFT CARD Bs. <span></span>
                </p>
                <p className="text-right">
                  {fNumber(head.monto_giftcard)}
                </p>
                <p className="text-right">
                  MONTO A PAGAR Bs. <span></span>
                </p>
                <p className="text-right">
                  {fNumber(parseFloat(head.total) - parseFloat(head.monto_giftcard))}
                </p>
              </div>
              <div>Son: {numeroALetras(parseFloat(parseFloat(head.total) - parseFloat(head.monto_giftcard)))}</div>
              <Divider />
              <p
                className="font-semibold text-xs text-center"
                style={{ fontSize: "10px" }}
              >
                ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS, EL USO ILÍCITO
                SERÁ SANCIONADO PENALMENTE DE ACUERDO A LEY.
              </p>
              <p
                className="font-semibold text-center"
                style={{ fontSize: "0.7rem" }}
              >
               {head.leyenda}
              </p>
              <p
                className="font-semibold text-center"
                style={{ fontSize: "10px" }}
              >
                {status === "VALIDADA"
                  ? "Este documento es la Representación Gráfica de un Documento Fiscal Digital emitido en una modalidad de facturación en línea"
                  : "Este documento es la Representación Gráfica de un Documento Fiscal Digital emitido fuera de línea, verifique su envío con su proveedor o en la página web www.impuestos.gob.bo"}
              </p>
  
              <div>
                <QRCode
                  className="mx-auto"
                  size={100}
                  value={head.siat_url }
                  viewBox={`0 0 256 256`}
                />
              </div>
              <div className="text-center text-xs font-bold mb-1">
                ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
              </div>
              <p>
                <span className="font-bold"># Nota: </span>
                {fInvoiceNumber(nota)}
              </p>
              <p>
                <span className="font-bold">Caja: </span>
                {user}
              </p>
            </div>
          </div>
        )}
      </div>
    );
}
export default InvoicePrintV2