import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { size, sumBy, uniqBy } from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../layout/Pagev1";
import { searchProdBarcode } from "../../services/sales";
import {
  addCartItem,
  delCartItem,
  upCartItem,
} from "../../redux/features/cart/cart";
import Swal from "sweetalert2";
import SalesDetailPrint from "../../components/SalesDetailPrint";
import { fNumber } from "../../utils/formatNumber";

import PayPos from "../CashBox/PayPos";
import { facturate, getCash, initCash } from "../../services/cashBox";
import { setLoadOff, setLoadOn } from "../../redux/features/config/load";
import CashDetail from "../CashBox/CashDetail";
import InvoicePrint from "../CashBox/InvoicePrint";
import { toast } from "react-toastify";

const Pos = () => {
  const [searchInput, setSearchValue] = useState("");
  const [printData, setPrintData] = useState();
  const [paymentMethod, setPaymentMethod] = useState(true);
  const cart = useSelector((state) => state.cartSales);
  const [cash, setCash] = useState();
  const componentRef1 = useRef();
  const componentRef = useRef();
  const [voucher, setVoucher] = useState();
  const [vouchers, setVouchers] = useState();
  const [invoice,setInvoice] = useState([])
  const [facturadores,setFacturadores] = useState([])
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef1.current,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    searchProdBarcode({ barcode: searchInput })
      .then((data) => {
        dispatch(
          addCartItem({
            item: data.detail,
            provider: data.item.provider.company_name,
            item_id: data.item_id,
            promotion_id: data.promotion_id,
            store_id: data.store_id,
            package_id: data.package_id,
            stock_id: data.id,
            qty: 1,
            units: data.units,
            sale_presentation: data.sale_presentation,
            pricex: data.prices,
            price: 0,
            prices: data.price.sale_prices,
            price_a: selectPrice(data.price.sale_prices, 1).price_a,
            price_b: selectPrice(data.price.sale_prices, 1).price_b,
            id: data.id,
            stock_qty: data.qty,
            barcode: searchInput,
          })
        );
      })
      .finally(() => {
        setSearchValue("");
      });
  };
  const handleMouseSearch = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleChangeQty = (event, val) => {
    if (
      parseInt(event.target.value) > 0 &&
      selectPrice(val.prices, event.target.value).price_a > 0 &&
      selectPrice(val.prices, event.target.value).price_b
    ) {
      dispatch(
        upCartItem({
          qty: parseInt(event.target.value),
          stock_id: val.stock_id,
          sale_presentation: val.sale_presentation,
          price: 0,
          price_a: selectPrice(val.prices, event.target.value).price_a,
          price_b: selectPrice(val.prices, event.target.value).price_b,
        })
      );
    }
  };
  const print = (res) => {
    setPrintData(res);
    handlePrint();
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const delItem = (item) => {
    Swal.fire({
      title: "Está seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(delCartItem({ stock_id: item.stock_id }));
      }
    });
  };
  const loadCash = () => {
    getCash()
      .then((res) => {
        setCash(res);
      })
      .finally(() => {
       
      });
  };
  useEffect(()=>{
    loadCash()
  },[])
  const setFactu = (res)=>{
    setFacturadores(() => {
      return uniqBy(res.detail, "factu");
    })
  }
  const toastId = useRef(null)
  const facturar= async (facturar)=>{      
    toastId.current =  toast.loading("cargando...")
    await facturate({id:facturar.factu, voucher_id: facturar.voucher_id}).then(res=>{        
      setInvoice(res)
      setVoucher(res)        
      
    }).finally(()=>{
      toastId.current =  toast.dismiss(toastId.current)
    })
    await sleep(1000);
    printData();

  } 
  const printInvoice=useReactToPrint({
    content: () => componentRef1.current,
  })
  return (
    <Page title="Ventas" appBar={true}>
      {size(printData) > 0 ? (
        <Paper className="p-3 ">
          {
            printData &&
            <SalesDetailPrint ref={componentRef} data={printData} />
            }
          {
                       size(invoice)> 0 &&
                       <div ref={componentRef}>
                         <InvoicePrint invoice={invoice}/> 
                       
                       </div>
                    }
                <div>
                     {
                      size(facturadores) > 0 ?
                      <div>
                        {
                          facturadores.map((row,key)=>
                          <Button key={key} disabled={row.factu===null} fullWidth onClick={()=>facturar(row)}>{row.provider}</Button>
                          
                          )
                        }

                      </div>
                      
                      :
                     <div>
                      <p className="text-2xl text-primary font-semibold my-3">
                      Monto Total a cobrar :{" "}
                      <span>
                        {fNumber(calculeTotal(voucher.voucher_detail))}
                      </span>
                    </p>
                   
                      </div>
                      }
                  </div>
          <Button
            variant="contained"
            onClick={() => handlePrint()}
            sx={{ my: 1 }}
          >
            Imprimir
          </Button>
          <Button
            variant="contained"
            className="mx-2"
            onClick={() => setPrintData(null)}
          >
            Nueva Venta
          </Button>
        </Paper>
      ) : (
        <>
          <Paper className="p-3">
            <form onSubmit={handleSubmit}>
              <TextField
                size="small"
                id="input-with-icon-textfield"
                placeholder="Buscar..."
                onChange={handleChange}
                autoFocus
                value={searchInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="search"
                        type="submit"
                        onMouseDown={handleMouseSearch}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          </Paper>
          
        {
        
        size(facturadores) > 0 ?
        <Paper className="p-3 mt-3">
          <div  ref={componentRef1}>
          {
            facturadores.map((row,key)=>
            <Button key={key} className="no-print" disabled={row.factu===null} fullWidth onClick={()=>facturar(row)}>{row.provider}</Button>
            
            )
          }
 {
                       size(invoice)> 0 &&
                       <div>
                         <InvoicePrint invoice={invoice}/> 
                        <Button className="no-print" onClick={()=>printInvoice()}>Imprimir</Button>
                        <Button className="no-print" onClick={()=>setInvoice([])}>cerrar</Button>
                       </div>
                    }
          </div>
        </Paper>
        
        :
        <div>
          <Paper className="p-3 my-3">
            <TableContainer>
              <Table className="table-print">
                <TableHead>
                  <TableRow>
                    <TableCell>Proveedor</TableCell>
                    <TableCell>detalle</TableCell>
                    <TableCell>Unidades</TableCell>
                    <TableCell>Cantidad</TableCell>
                    <TableCell>Precio C/F</TableCell>
                    <TableCell>Precio S/F</TableCell>
                    <TableCell>Subtotal</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {size(cart) > 0 &&
                    cart.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell>{row.provider}</TableCell>
                        <TableCell>{row.item}</TableCell>
                        <TableCell>
                          <p className="text-center font-semibold text-lg">
                            {row.units}
                          </p>
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="price"
                            sx={{ maxWidth: 100 }}
                            inputProps={{ min: 1 }}
                            size="small"
                            type="number"
                            value={row.qty}
                            onChange={(e) => handleChangeQty(e, row)}
                          />
                        </TableCell>

                        <TableCell
                          className={paymentMethod ? "bg-green-200" : ""}
                        >
                          {row.price_a}
                        </TableCell>
                        <TableCell
                          className={paymentMethod ? "" : "bg-green-200"}
                        >
                          {row.price_b}
                        </TableCell>
                        {paymentMethod ? (
                          <TableCell>
                            {fNumber(parseInt(row.qty )* parseFloat(row.price_a))}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {fNumber(parseInt(row.qty) * parseFloat(row.price_b))}
                          </TableCell>
                        )}

                        <TableCell>
                          <Tooltip title="Eliminar" followCursor>
                            <IconButton
                              onClick={() => delItem(row)}
                              color="error"
                              aria-label="eliminar"
                              title="eliminar"
                              component="label"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  {size(cart) > 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        {" "}
                        <p className="text-right font-bold">Total: Bs.</p>
                      </TableCell>
                      <TableCell>
                        {paymentMethod
                          ? fNumber(
                              sumBy(cart, (o) => {
                                return parseInt(o.qty )* parseFloat(o.price_a);
                              })
                            )
                          : fNumber(
                              sumBy(cart, (o) => {
                                return parseInt(o.qty )* parseFloat(o.price_b);
                              })
                            )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Paper className="p-3 my-3">
          
            {
              size(cash) > 0 ?
            <div>
                <PayPos
              data={cart}
              setFacturadores={setFactu}
              total={ sumBy(cart, (o) => {
                return parseInt(o.qty )* parseFloat(o.price_a);
              })}
              setPrintData={setPrintData}
            />
            <div className="w-full inline-flex justify-between items-center">
                    <p className="font-semibold text-green-500">
                      En caja: Bs.-{" "}                    
                      <span className="text-xl">
                        {fNumber(parseFloat(cash.detail_sum_total)+parseFloat(cash.initial_amount))}
                      </span>
                    </p>
                    <CashDetail box={cash} />
                  </div>
            </div>
              :
               <FormNewCashbox setCash={setCash} cash={cash} />
            }
           
          </Paper>
          </div>}
        
        </>
      )}
    </Page>
  );
};
const FormNewCashbox = ({ setCash, cash }) => {
  const [mount, setMount] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const dispatch = useDispatch()
  const handleSubmit = (e) => {
    dispatch(setLoadOn())
    setBtnDisabled(true);
    e.preventDefault();
    initCash({ initial_amount: mount })
      .then((res) => {
        setCash(res);
      })
      .finally(() => {
        dispatch(setLoadOff())
        setBtnDisabled(false);
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="inline-flex gap-3">
          <TextField
            value={mount}
            onChange={(e) => setMount(e.target.value)}
            name="mount"
            sx={{ my: 1 }}
            type="number"
            label="Monto Inicial"
            variant="outlined"
            size="small"
          />
          <Button
            sx={{ my: 1 }}
            variant="contained"
            type="submit"
            disabled={btnDisabled}
          >
            abrir caja
          </Button>
        </div>
      </form>
    </div>
  );
};
const selectPrice = (prices, qty) => {
  let price = 0;
  for (let index = 0; index < prices.length; index++) {
    if (
      qty >= parseInt(prices[index]["min_units"]) &&
      qty <= parseInt(prices[index]["max_units"])
    ) {
      price = prices[index];
      break;
    }
  }

  return price;
};
export default Pos;
const calculeTotal = (voucher) => {
  return sumBy(voucher, (o) => {
    return o.qty * o.price;
  });
};