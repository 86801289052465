import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
    Button,
    IconButton,
    TextareaAutosize,
    TextField,
  } from "@mui/material";
  import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from '@mui/icons-material/Clear';
import { concat,  size, sumBy } from "lodash";
import { fNumber } from "../../../utils/formatNumber";
import { getAcount, registerCompEgre } from "../../../services/conta";
import ModalComponent from "../../../components/ModalComponent";
import RegistroCompra from "../RegistroCompra/RegistroCompra";
import SelectAcount from "../../ContaSol/SelectAcount";

const DiaryRegisterV2=({
    type,
    transaccion=null,
    company_id,
    branch_id,
    model_type,
    model_id,
    data,
    state=false,
    invoice= false,
    company,
    store,
    store_id,
    reloadData,
    invoiceOption=false,
    detail=null
})=>{
  const [detailCont, setDetailCont] = useState([]);
  const [factura,setFactura] = useState([])
  
  const [comprobante, setComprobante] = useState({
    glosa:"",
    date: "",
    comprobante: "",
    sol_id: data?.id,
    type:""
  });

  const handleChange = (e, key) => {
    let alldata = [...detailCont];
    alldata[key][e.target.name] = e.target.value;
    setDetailCont(alldata);
  };
  const addRow = () => {
    setDetailCont(
      concat(detailCont, {
        id: size(detailCont) + 1,
        code: "",
        acount_id: "",
        title: "",
        parcial: 0,
        debe: 0,
        haber: 0,
        comment: "",
      })
    );
  };
  const delRow=(index)=>{
    const newArray = [...detailCont]; // Crea una copia del array
    newArray.splice(index, 1); // Elimina el elemento en el índice especificado
    setDetailCont(newArray); 
  }
  const addRowItem=(row,index)=>{
    let alldata = [...detailCont];
    alldata[index]["code"] = row.codigo;
    alldata[index]["title"] = row.name;
    alldata[index]["acount_id"] = row.id;
    setDetailCont(alldata);
  }
  const handleChangeMain = (e) => {
    setComprobante({ 
      ...comprobante,
      [e.target.name]: e.target.value,
    });
  };
  const handleGetAcount = (val, key) => {
    getAcount({ code: val }).then((res) => {
      let alldata = [...detailCont];
      alldata[key]["code"] = res.codigo;
      alldata[key]["title"] = res.name;
      setDetailCont(alldata);
    });
  };
  const handleClickSend = () => {
    if (
      sumBy(detailCont, (o) => {
        return parseFloat(o.haber);
      }) !==
        sumBy(detailCont, (o) => {
          return parseFloat(o.debe);
        }) ||
      sumBy(detailCont, (o) => {
        return parseFloat(o.debe);
      }) === 0
    ) {
      toast.error("Hay un error en sus registros");
    } else {
      const val = {
        comprobante: comprobante,
        detailCont: detailCont,
        model_id: model_id,
        model_type: model_type,        
        company_id: company_id,
        store_id: store_id,
        store_branch: branch_id,
        type:type,
        transaccion: transaccion,
        total:  sumBy(detailCont, (o) => {
          return parseFloat(o.haber);
        }) 
        ,
        factura:factura
      };      
      registerCompEgre(val).then((res) => {
        toast.success("Se registro corretamente")
        reloadData()

      });
    }
  };

  useEffect(()=>{
    setComprobante({
        ...comprobante,
        glosa: data?.glosa,
        date: data?.date,
    })
  },[data])
  
    return(
        <div>
        <h3 className="font-semibold uppercase text-center">
          {company}
        </h3>
        <h3 className="font-bold uppercase text-center">
          {store}
        </h3>
        <h3 className="font-semibold uppercase text-center">Registro Contable</h3>
        <h3 className="font-semibold uppercase text-center">
          Comprobante de diario de {type} 
        </h3>
     
        <form>
          <table className="table-print">
            <thead>
              <tr>
                <td colSpan={7} className="py-3">
                  <div
                    className={size(data) > 0 ? "pt-3 mb-3 hidden" : "pt-3 mb-3"}
                  >
                     <div className="md:grid grid-cols-4 gap-2">
            <div>
           {
            branch_id &&
            type === "egreso" &&
          <ModalComponent title={"Registro de Factura"} btn={"Registrar Factura"}  variant={"contained"} color={size(factura) > 0 ? "success":'error'}  >            
            {
                invoice?
                <RegistroCompra factura={factura} data={detailCont} setFactura={setFactura} />
                :
                <p className="text-red-500 text-center font-bold text-2xl">La compra no se registro con factura</p>
            }
          </ModalComponent>
        }
           </div>
              
          </div>
                    
                  </div>
                  {
                    invoiceOption &&
                    <div className="pt-3 mb-3">
    {
            type === "egreso" &&
          <ModalComponent title={"Registro de Factura"} btn={"Registrar Factura"}  variant={"contained"} color={size(factura) > 0 ? "success":'error'}  >            
            {
                invoice?
                <RegistroCompra factura={factura} data={detailCont} setFactura={setFactura} />
                :
                <p className="text-red-500 text-center font-bold text-2xl">La compra no se registro con factura</p>
            }
          </ModalComponent>
        }
                    </div>
                  }
                  <div className="mt-3">
                    {detail}
                   { data.date && <p>Fecha: <span className="font-bold">{data.date}</span></p> }
                   { parseFloat(data.total)>0 && <p>Total: <span className="font-bold">{fNumber(data.total)}</span></p> }
                   { parseFloat(data.base_credito_fiscal)>0 && <p>Importe base para credito fiscal: <span className="font-bold">{fNumber(data.base_credito_fiscal)}</span></p> }
                   { parseFloat(data.descuento)>0 && <p>Descuento: <span className="font-bold">{fNumber(data.descuento)}</span></p> }
                   { parseFloat(data.ice_especifico)>0 && <p>Ice especifico: <span className="font-bold">{fNumber(data.ice_especifico)}</span></p> }
                   { parseFloat(data.ice_porcentual)>0 && <p>Ice porcentual: <span className="font-bold">{fNumber(data.ice_porcentual)}</span></p> }
                  </div>
                  <TextareaAutosize
                    className="border border-gray-400 rounded-md p-2 w-full mt-3"
                    row={4}
                    aria-label="maximum height"
                    placeholder="Glosa"
                    name="glosa"
                    onChange={handleChangeMain}
                    defaultValue={comprobante.glosa}
                  />
                </td>
              </tr>
              <tr>
                <th width="20%">codigo</th>
                <th width="30%">cuenta</th>                
                <th width="10%">debe</th>
                <th width="10%">haber</th>
                <th width="30%">glosa</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {size(detailCont) > 0 &&
                detailCont.map((row, key) => (
                  <tr key={key}>
                    <td>
                    <div className="inline-flex gap-2">
                    <SelectAcount company={company_id} addRowItem={addRowItem} index={key}/>                    
                      <TextField
                        value={row.code}
                        onBlur={(e) => handleGetAcount(e.target.value, key)}
                        onChange={(e) => handleChange(e, key)}
                        name="code"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </div>
                    </td>
                    <td className="uppercase">
                    {row.title}
                    </td>                    
                    <td className="text-right">
                      <TextField
                        disabled={state}
                        value={row.debe}
                        onChange={(e) => handleChange(e, key)}
                        name="debe"
                        fullWidth
                        type="number"
                        variant="standard"
                        size="small"
                      />
                    </td>
                    <td className="text-right">
                      <TextField
                        disabled={state}
                        value={row.haber}
                        onChange={(e) => handleChange(e, key)}
                        name="haber"
                        fullWidth
                        type="number"
                        variant="standard"
                        size="small"
                      />
                    </td>
                    <td>
                      <TextField
                        disabled={state}
                        value={row.comment}
                        onChange={(e) => handleChange(e, key)}
                        name="comment"
                        fullWidth
                        variant="standard"
                        size="small"
                      />
                    </td>
                    <td>
                    <IconButton
                    disabled={state}
                    color="error"
                    size="small"
                    onClick={() => delRow(key)}
                  >
                    <ClearIcon />
                  </IconButton> 
                    </td>
                  </tr>
                ))}
              <tr
                className={
                  sumBy(detailCont, (o) => {
                    return parseFloat(o.haber);
                  }) !==
                  sumBy(detailCont, (o) => {
                    return parseFloat(o.debe);
                  })
                    ? "bg-red-500 text-white"
                    : "bg-green-500 text-white"
                }
              >
                <td colSpan={2} className="text-right font-semibold">
                  totales
                </td>
                <td>
                  {fNumber(
                    sumBy(detailCont, (o) => {
                      return parseFloat(o.debe);
                    })
                  )}
                </td>
                <td>
                  {fNumber(
                    sumBy(detailCont, (o) => {
                      return parseFloat(o.haber);
                    })
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <p className="text-red-500 font-semibold">{company_id !== "" ? "" : "¡Debes seleccionar la empresa!"}
  </p>
                </td>
  
                <td>
                
        <IconButton
                    disabled={state}
                    color="success"
                    size="small"
                    onClick={() => addRow()}
                  >
                    <AddBoxIcon />
                  </IconButton> 
                </td>
              </tr>
              <tr>
                <td colSpan={7}>
                  <Button
                    disabled={state}
                    variant="contained"
                    onClick={() => handleClickSend()}
                  >
                    Registrar
                  </Button>
                  
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    )
}
export default DiaryRegisterV2