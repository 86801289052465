import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { size } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { fNumber } from "../../utils/formatNumber";
import { userConfirm } from "../../services/users";
const validationSchema = yup.object({
    a_cuenta: yup.number().required("Ingrese este campo"),
  });
const Credito = ({data,setOpen,saveToPay,voucher_id}) => {

    const formik = useFormik({
        initialValues: {
            a_cuenta: "",
            aditional:"",
            confirm:false,
            password:"",
            email:"",
            user_confirm: ""
        },
        validationSchema: validationSchema,
        onSubmit:(values)=>{
          /*   if (size(cart) <= 0) {
                toast.error("Debe seleccionar al menos un item en su carrito!");
                return;
              } */
              if (size(data.client) <= 0) {
                toast.error("Registre datos de cliente!");
                return;
              }
              saveToPay(values)
        }
    })
    const confirmarPassword =()=>{
      if(formik.values.password == "" || formik.values.email == ""){
        toast.error("Debe ingresar su email y contraseña")
      }
      userConfirm({password:formik.values.password,email:formik.values.email}).then(res=>{        
        formik.setFieldValue('password',"");
        formik.setFieldValue("user_confirm",res);
        formik.setFieldValue("confirm",true);
      })
    }
  return (
    <div>
         
         <div className="flex flex-col items-center ">
            <p className="text-lg font-semibold">TOTAL Bs:</p>
            <p className="text-4xl font-semibold text-gray-500">
              {fNumber(data.total)}
            </p>
          </div>
         
         
        <form  onSubmit={formik.handleSubmit}>
     
        <TextField
        fullWidth
        value={formik.values.a_cuenta}
        onChange={formik.handleChange}
        name="a_cuenta"
        sx={{ my: 0, minWidth:80 }}
        label="A cuenta"
        type="number"
        inputProps={{max:data.total}}        
        error={
          formik.touched.a_cuenta &&
          Boolean(formik.errors.a_cuenta)
        }
        helperText={
          formik.touched.a_cuenta && formik.errors.a_cuenta
        }
        variant="outlined"
        size="small"
      />
      <div className="flex flex-col items-center">
            <p className="text-lg font-semibold">SALDO Bs:</p>
            <p className="text-xl font-semibold text-gray-500">
              {fNumber(parseFloat(data.total) - parseFloat(formik.values.a_cuenta))}
            </p>
          </div>
        <TextField
          value={formik.values.aditional}
          onChange={formik.handleChange}
          name="aditional"
          fullWidth
          sx={{ my: 1 }}
          label="Observaciones"
          multiline={true}          
          variant="outlined"
          size="small"       
        />
       <div>
       { formik.values.confirm ?
       <div>
          <p className="text-center font-bold text-green-500 text-xl">Autorizado</p>
       </div>
       : <div >
        <div>
        <p className="text-red-500 text-xs my-1 ">* Esta operacion requiere la confirmacion de gerencia o jefe de sucursal</p>
        <TextField
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            name="email"
            sx={{ minWidth:80 }}
            label="Email"   
            error={true}
            type="email"
            helperText={
              formik.touched.email && formik.errors.email
            }
            variant="outlined"
            size="small"
          />
           <div className="inline-flex w-full gap-2 mt-2">
           <TextField
            fullWidth
            value={formik.values.password}
            onChange={formik.handleChange}
            name="password"
            sx={{ minWidth:80 }}
            label="Confirmacion"   
            error={true}
            type="password"
            helperText={
              formik.touched.password && formik.errors.password
            }
            variant="outlined"
            size="small"
          />
          <Button variant="contained" color="success" size="small" type="button" onClick={()=>confirmarPassword()} >Confirmar</Button>
           </div>
        </div>
        </div>}
       </div>
        <div className="mt-3">
        <Button variant="contained" disabled={!formik.values.confirm} type="submit">Registrar Venta</Button>
        </div>
        </form>
    </div>
  )
}
export default Credito