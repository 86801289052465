import API from "../axios/api"

export const getCash = async ()=>{
    const {data} = await API.get('api/cashbox')    
    return data
}

export const getCashDetail = async (val)=>{
    const {data} = await API.post('api/cashbox/detail',val)    
    return data
}
export const getCashDetailAll = async (val)=>{
    const {data} = await API.post('api/cashbox/detail-all',val)    
    return data
}
export const initCash = async (val)=>{
    const {data} = await API.post('api/cashbox/create',val)    
    return data
}
export const closeCash = async (val)=>{
    const {data} = await API.post('api/stock/sales',val)    
    return data
}
export const applyDiscount = async (val)=>{
    const {data} = await API.post('api/cashbox/add-discount',val)    
    return data
}
export const collectVoucher = async (val)=>{
    const {data} = await API.post('api/cashbox/collec-voucher',val)    
    return data
}
export const collectVoucherPos = async (val)=>{
    const {data} = await API.post('api/cashbox/collec-voucher-pos',val)    
    return data
}

export const getSales = async ()=>{
    const {data} = await API.get('api/cashbox/list-vouchers')    
    return data
}

export const closeBox = async (val)=>{
    const {data} = await API.post('api/cashbox/close-box',val)    
    return data
}
export const getCashBoxDetv1= async (val)=>{
    const {data} = await API.post('api/cashbox/detailv1',val)    
    return data
}
export const getCashBoxDetv2= async (val)=>{
    const {data} = await API.post('api/cashbox/detailv2',val)    
    return data
}
export const getCashBoxDetBrand= async (val)=>{
    const {data} = await API.post('api/cashbox/getcashbox-brand',val)    
    return data
}

export const facturate = async (val)=>{
    const {data} = await API.post('api/cashbox/facturar',val)    
    return data
}

export const getCatalogoItems = async (val)=>{
    const {data} = await API.post('api/cashbox/catalogo',val)    
    return data
}
export const collectV1 = async (val)=>{
    const {data} = await API.post('api/cashbox/collect-v1',val)    
    return data
}
export const collectV2 = async (val)=>{
    const {data} = await API.post('api/cashbox/collect-v2',val)    
    return data
}
export const setPay = async (val)=>{
    const {data} = await API.post('api/set-pay',val)    
    return data
}




