
import { useSelector } from "react-redux"

import ComprobantePrint from "./ComprobantePrint"
import ComprobantePrintTicket from "./ComprobantePrintTicket"

const ComprobanteInicial = ({data}) => {
    const printNormal = useSelector((state) => state.eventButton);
    if(!printNormal ){
   return <ComprobantePrint data={data} />
    }else{
    return <ComprobantePrintTicket data={data} />
    }

}
export default ComprobanteInicial