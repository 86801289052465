import API from "../axios/api"

export const searchProdBarcode = async (val)=>{
    const {data} = await API.post('api/product/search/barcode',val)
    return data
}
export const searchProduct = async (val)=>{
    const {data} = await API.post('api/sales/search',val)
    return data
}
export const searchProductEsp = async (val)=>{
    const {data} = await API.post('api/sales/search/esp',val)
    return data
}
export const salesSend = async (val)=>{
    const {data} = await API.post('api/sales/register',val)
    return data
}
export const salesSendStore = async (val)=>{
    const {data} = await API.post('api/sales/register-store',val)
    return data
}
export const salesSuggestPrice = async (val)=>{
    const {data} = await API.post('api/aprobe-action',val)
    return data
}
export const salesSuggestPriceAction = async (val)=>{
    const {data} = await API.post('api/aprobe-action/action',val)
    return data
}
export const getSuggestPriceAction = async (val)=>{
    const {data} = await API.post('api/aprobe-action/get',val)
    return data
}
export const bajaSend = async (val)=>{
    const {data} = await API.post('api/baja/create',val)
    return data
}
export const dashboardInfo = async (val)=>{
    const {data} = await API.post('api/dashboard/info',val)
    return data
}
