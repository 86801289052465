import { Button, Divider, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux"
import { postApiService } from "../../../services/crudServices";
import ModalComponent from "../../../components/ModalComponent";
import PrintDepreciacionActualizacion from "./PrintDepreciacionActualizacion";

const DepreciarActualizar=()=>{
    const [company,setCompany] = useState("")
    const userData = useSelector(state=>state.userData)
    const [data,setData] = useState([])
    const groupedData = userData.user.companies.map(company => {
        const branches = company.branches.reduce((acc, branch) => {
            const existingBranch = acc.find(b => b.sucursal === branch.sucursal);
            if (existingBranch) {
                existingBranch.store.push(branch.store);
            } else {
                acc.push({
                    id:branch.id,
                    sucursal: branch.sucursal,
                    sucursal_code: branch.sucursal_code,
                    direction: branch.direction,
                    city: branch.city,
                    phone: branch.phone,
                    store: [branch.store]
                });
            }
            return acc;
        }, []);
        return {
            ...company,
            branches: branches
        };
      });
      const verificarRegistros=(val)=>{
        postApiService('activo-fijo/verificar',{company_id:val}).then(res=>{
          setData(res)
        })
      }
      const generarActDep=(val)=>{
        postApiService('activo-fijo/actualizar-depreciar',val).then(res=>{
          verificarRegistros(company)          
        })
      }
      const handleChangeCompany=(e)=>{
        setCompany(e.target.value)
      }
    return(
        <div>
            <div className="inline-flex w-full justify-between items-center gap-2">
            <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={company}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
            >
              <MenuItem value="">seleccionar</MenuItem>
              {groupedData.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
          <Button variant="contained" onClick={()=>verificarRegistros(company)}>Verificar</Button>
          </div>
            </div>
            <div>
                <ul>
                  {
                    data.map((row,key)=>
                    <li key={key}> 
                      <div className="inline-flex w-full justify-between">
                        <p className="font-bold text-xl">{row.year}</p>
                        {
                          parseInt(row.state) === 0 ? 
                          <Button onClick={()=>generarActDep(row)}>Generar</Button>
                          :
                          <ModalComponent btn={"VER"} title={"Actualizacion y Depreciacion"} fullScreenx={true} >
                                <PrintDepreciacionActualizacion year={row.year} company={row.company_id} />
                          </ModalComponent>
                        }
                      </div>
                      <Divider/>
                     </li>
                    )
                  }
                </ul>
            </div>
        </div>
    )
}

export default DepreciarActualizar