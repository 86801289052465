import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select } from "@mui/material"
import Pagev1 from "../../../layout/Pagev1"
import ModalComponent from "../../../components/ModalComponent"
import { useState } from "react"
import Apertura from "./Apertura"
import { postApiService } from "../../../services/crudServices"
import ArticleIcon from '@mui/icons-material/Article';
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import { find, size } from "lodash"
import { useRef } from "react"
import { useReactToPrint } from "react-to-print"
import { dateConfig } from "../../../utils/dateconfig"
import PrintComprobante from "../../Contabilidad/Diario/PrintComprobante"
import DiaryRegisterV2 from "../../Contabilidad/Diario/DiaryRegisterV2"
import Caja from "./Caja"
const CajaChica =()=>{
    const [data,setData] = useState([])
    const[isModal,setIsModal]= useState(false)
    const [stores, setStores] = useState([]);
    const [branchOffices, setBranchOffices] = useState([]);
    const userData = useSelector((state) => state.userData);
    const componentPrint = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentPrint.current,
      });
    const formik = useFormik({
        initialValues:{
            company_id:"all",
            z_company_branch_id:"all",
            store_id:"all",
        },
        onSubmit:(values)=>{
            postApiService('caja-chica',values).then(res=>{
                setData(res)
            })
        }
    })

    const groupedData = userData.user.companies.map(company => {
        const branches = company.branches.reduce((acc, branch) => {
            const existingBranch = acc.find(b => b.sucursal === branch.sucursal);
            if (existingBranch) {
                existingBranch.store.push(branch.store);
            } else {
                acc.push({
                    id:branch.id,
                    sucursal: branch.sucursal,
                    sucursal_code: branch.sucursal_code,
                    direction: branch.direction,
                    city: branch.city,
                    phone: branch.phone,
                    store: [branch.store]
                });
            }
            return acc;
        }, []);
        return {
            ...company,
            branches: branches
        };
      });
      const handleChangeBranch = (e) => {    
        // setTargetStore("all")
         const stores = find(branchOffices,o=>{
             return o.id === e.target.value
         })
         formik.setFieldValue('z_company_branch_id',e.target.value)
         setStores(stores?.store||[])
     
       };
       const handleChangeCompany = (e) => {
        formik.setFieldValue("company_id", e.target.value);
        setData([])
        const branches = find(groupedData,o=>{
          return o.id === e.target.value
      })
        setBranchOffices(branches?.branches||[]);
      };

    return(
        <Pagev1 title="Listado Caja Chica">
            <Paper className="p-3">
            <div className="md:inline-flex w-full gap-2 justify-between">
           <form className="w-full" onSubmit={formik.handleSubmit}>
                <div className="md:grid grid-cols-4 gap-2">

                  <FormControl fullWidth>
            <InputLabel id="company_id">Empresa</InputLabel>
            <Select
              labelId="empresa"
              id="company_id"
              name="company_id"
              label="Empresa"
              value={formik.values.company_id}
              onChange={handleChangeCompany}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.company_id && Boolean(formik.errors.company_id)
              }
            >
              <MenuItem value="all">Todas</MenuItem>
              {groupedData.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.company_id && formik.errors.company_id}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="z_company_branch_id">Sucursal</InputLabel>
            <Select
              labelId="empresa"
              id="z_company_branch_id"
              disabled={size(branchOffices) === 0}
              name="z_company_branch_id"
              label="Sucursal"
              value={formik.values.z_company_branch_id}
              onChange={handleChangeBranch}
              sx={{ my: 1 }}
              size="small"
              error={
                formik.touched.z_company_branch_id &&
                Boolean(formik.errors.z_company_branch_id)
              }
            >              
              <MenuItem value="all">Todas</MenuItem>
              {branchOffices.map((row, key) => (
                <MenuItem key={key} value={row.id}>
                  {row.sucursal}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText color="error">
              {formik.touched.z_company_branch_id &&
                formik.errors.z_company_branch_id}
            </FormHelperText>
          </FormControl>
          <FormControl  fullWidth>
          <InputLabel id="store_id">Almacen / Sala</InputLabel>
          <Select
            labelId="store_id"
            id="store_id"
            name="store_id"
            value={formik.values.store_id}
            label="Almacen / Sala"
            size="small"
            sx={{ my: 1 }}
            onChange={formik.handleChange}
          >
              <MenuItem value="all">Todas</MenuItem>
          
            {size(stores)>0 &&
            stores.map((row, key) => (
              <MenuItem key={key} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText color="error">
              {formik.touched.store_id &&
                formik.errors.store_id}
            </FormHelperText>
        </FormControl>


                <div className="mt-2">
                <Button variant="contained" type="submit" >Generar</Button>
                </div>
                
                </div>
            </form>
            <div className="md:inline-flex w-full gap-2">
            <div className="mt-2">
                <Button variant="contained" type="button" onClick={handlePrint} >Imprimir</Button>
                </div>
               
                <div className="mt-2">
                <ModalComponent title={"Registrar Apertura de Caja"} btn={"Nuevo"} variant={"contained"} reload={formik.handleSubmit} >
                    <Apertura/>
                </ModalComponent>              
                </div>
            </div>
           </div>
                
            </Paper>
            <Paper className="p-3 mt-3">
                {
                    size(data) > 0 &&
                    <div ref={componentPrint}>
                <p className="font-xl text-center font-bold uppercase">Reporte de Cajas Chicas</p>
                <p className="font-xl text-center font-bold uppercase">{formik.values.company_id === "all"?"Todas las Empresas":data[0].company?.name}</p>
                    <table className="table-print">
                    <thead>
                        <tr>                            
                            <th>#</th>
                            {formik.values.company_id === "all" && <th>empresa</th>}                            
                            <th>Almacen/Sala</th>
                            <th>Monto Apertura</th>
                            <th>Monto cierre</th>
                            <th>responsable</th>
                            <th>registrado por</th>
                            <th>fecha apertura</th>
                            <th>fecha cierre</th>
                            <th>registro contable</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((row,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                {formik.values.company_id === "all" && <td>{row.company.name}</td>}
                                <td>{row.store.name}</td>
                                <td>{row.monto_apertura}</td>
                                <td>{row.cierre ?row.monto_cierre:""}</td>
                                <td>{row.responsable.people.nombres+" "+row.responsable.people.apellido_p+" "+row.responsable.people.apellido_m}</td>
                                <td>{row.user.name}</td>
                                <td>{dateConfig(row.created_at)}</td>
                                <td>{row.cierre ? dateConfig(row.cierre):""}</td>
                                <td >
                                    <div className="inline-flex w-full">
                                       { 
                                    
                                 
                                <ModalComponent btn={<ArticleIcon color={size(row.comprobante)>0? "success":"error"}/>} fullScreenx={true}  >
                                       
                                       {
                                        row.comprobante[0] ?
                                       <PrintComprobante id={row.comprobante[0].id}/> 
                                       :
                                       <DiaryRegisterV2
                                       type="traspaso"
                                       company_id={row.company_id}
                                       branch_id={"1"}
                                       model_type= "CajaChica"
                                       model_id= {row.id}
                                       data= {{
                                           glosa: "Apertura de caja chica",
                                           date: row.created_at,
                                           total: row.monto_apertura,
                                       }}
                                       company= {row.company.name}
                                       store= {row.store.name}
                                       store_id= {row.store_id}
                                       reloadData= {formik.handleSubmit}
                                       invoice={true}
                                       invoiceOption={true}
                                   />
                                                    }                                   
                                </ModalComponent> 

                                }
                                 {row.comprobante[1]&&
                                  <ModalComponent btn={<ArticleIcon color={size(row.comprobante)>0? "success":"error"}/>} fullScreenx={true}  >
                                      <PrintComprobante id={row.comprobante[1].id}/> 
                                  </ModalComponent>
                                      }
                                      </div>
                                </td>
                                <td>
                                    <ModalComponent fullScreenx={true} title={"Caja chica"} btn={"Ver Caja"}>
                                            <Caja id={row.id} responsable={row.responsable.id}/>
                                    </ModalComponent>
                                </td>
                            </tr>
                            )
                        }
                    </tbody>
                    </table>
                    </div>
                    
                }
            </Paper>
        </Pagev1>
    )
}
export default CajaChica