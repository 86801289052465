import API from "../axios/api"

export const setStore = async (values)=>{
    const {data} = await API.post('api/store/create',values)
    return data
}


export const listStore = async ()=>{
    const {data} = await API.post('api/store/list',{})
    return data
}
export const listStores = async ()=>{
    const {data} = await API.get('api/store/list-stores')
    return data
}
export const listAllStores = async ()=>{
    const {data} = await API.get('api/store/list-all')
    return data
}
export const listStoreType = async (val)=>{
    const {data} = await API.post('api/store/list',val)
    return data
}