import { Button, FormControl, FormLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useFormik } from "formik"
import { useEffect } from "react";
import * as yup from "yup";
import { newInvoiceR } from "../../../services/conta";
import { size } from "lodash";
const validationSchema = yup.object({
    importe_total: yup.number().required("Ingrese este campo"),
    nit_proveedor: yup.number().required("Ingrese este campo"),
    razon_social: yup.string().required("Ingrese este campo"),
  });
const tipoCompra = [
    { cod:"1", name:"Compras para mercado interno con destino a actividades gravadas"},
    { cod:"2", name:" Compras para mercado interno con destino a actividades no gravadas"},
    { cod:"3", name:"Compras sujetas a proporcionalidad"},
    { cod:"4", name:"Compras para exportaciones"},
    { cod:"5", name:" Compras tanto para el mercado interno como para exportaciones"}
]
const RegistroCompra = ({closeModal,factura, setFactura, form=false})=>{
    const formik = useFormik({
        initialValues:{
            especificacion:"1",
            nit_proveedor:"",
            razon_social:"",
            codigo_autorizacion:"",
            numero_factura:"",
            numero_dui:"0",
            fecha_factura:"",
            importe_total:"",
            importe_ice:"0",
            importe_iehd:"0",
            importe_ipj:"0",
            tasas:"0",
            no_sujeto_creditof:"0",
            importe_exentos:"0",
            compras_grav_tasacero:"0",
            subtotal:"",
            descuentos:"0",
            gif_card:"0",
            tipo_compra:"",
            codigo_control:"",
        }, 
        validationSchema: validationSchema,
        onSubmit: (values)=>{
            setFactura(values)
            closeModal()
        }

    })
    useEffect(()=>{
        formik.setValues(factura)
    },[])
    return (
        <div>
             <form onSubmit={formik.handleSubmit}>
                <div>
                <TextField
                    value={formik.values.nit_proveedor}
                    onChange={formik.handleChange}
                    name="nit_proveedor"
                    fullWidth
                    sx={{ my: 1 }}
                    label="NIT PROVEEDOR"
                    error={
                    formik.touched.nit_proveedor && Boolean(formik.errors.nit_proveedor)
                    }
                    helperText={formik.touched.nit_proveedor && formik.errors.nit_proveedor}
                    variant="outlined"
                    type="number"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.razon_social}
                    onChange={formik.handleChange}
                    name="razon_social"
                    fullWidth
                    sx={{ my: 1 }}
                    label="RAZON SOCIAL PROVEEDOR"
                    error={
                    formik.touched.razon_social && Boolean(formik.errors.razon_social)
                    }
                    helperText={formik.touched.razon_social && formik.errors.razon_social}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.codigo_autorizacion}
                    onChange={formik.handleChange}
                    name="codigo_autorizacion"
                    fullWidth
                    sx={{ my: 1 }}
                    label="CÓDIGO DE AUTORIZACIÓN"
                    error={
                    formik.touched.codigo_autorizacion && Boolean(formik.errors.codigo_autorizacion)
                    }
                    type="number"
                    helperText={formik.touched.codigo_autorizacion && formik.errors.codigo_autorizacion}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.numero_factura}
                    onChange={formik.handleChange}
                    name="numero_factura"
                    fullWidth
                    sx={{ my: 1 }}
                    label="NÚMERO FACTURA"
                    error={
                    formik.touched.numero_factura && Boolean(formik.errors.numero_factura)
                    }
                    type="number"
                    helperText={formik.touched.numero_factura && formik.errors.numero_factura}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.numero_dui}
                    onChange={formik.handleChange}
                    name="numero_dui"
                    fullWidth
                    sx={{ my: 1 }}
                    label="NÚMERO DUI/DIM"
                    error={
                    formik.touched.numero_dui && Boolean(formik.errors.numero_dui)
                    }
                    type="number"
                    helperText={formik.touched.numero_dui && formik.errors.numero_dui}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.fecha_factura}
                    onChange={formik.handleChange}
                    name="fecha_factura"
                    fullWidth
                    sx={{ my: 1 }}
                    label="FECHA DE FACTURA/DUI/DIM"
                    error={
                    formik.touched.fecha_factura && Boolean(formik.errors.fecha_factura)
                    }
                    type="date"
                    disabled={form}
                    helperText={formik.touched.fecha_factura && formik.errors.fecha_factura}
                    variant="outlined"
                    size="small"
                />
                <TextField
                    value={formik.values.importe_total}
                    onChange={formik.handleChange}
                    name="importe_total"
                    fullWidth
                    sx={{ my: 1 }}
                    label="IMPORTE TOTAL COMPRA"
                    error={
                    formik.touched.importe_total && Boolean(formik.errors.importe_total)
                    }
                    type="number"
                    helperText={formik.touched.importe_total && formik.errors.importe_total}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.importe_ice}
                    onChange={formik.handleChange}
                    name="importe_ice"
                    fullWidth
                    sx={{ my: 1 }}
                    label="IMPORTE ICE"
                    error={
                    formik.touched.importe_ice && Boolean(formik.errors.importe_ice)
                    }
                    type="number"
                    helperText={formik.touched.importe_ice && formik.errors.importe_ice}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.importe_iehd}
                    onChange={formik.handleChange}
                    name="importe_iehd"
                    fullWidth
                    sx={{ my: 1 }}
                    label="IMPORTE IEHD"
                    error={
                    formik.touched.importe_iehd && Boolean(formik.errors.importe_iehd)
                    }
                    type="number"
                    helperText={formik.touched.importe_iehd && formik.errors.importe_iehd}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.importe_ipj}
                    onChange={formik.handleChange}
                    name="importe_ipj"
                    fullWidth
                    sx={{ my: 1 }}
                    label="IMPORTE IPJ"
                    error={
                    formik.touched.importe_ipj && Boolean(formik.errors.importe_ipj)
                    }
                    type="number"
                    helperText={formik.touched.importe_ipj && formik.errors.importe_ipj}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.tasas}
                    onChange={formik.handleChange}
                    name="tasas"
                    fullWidth
                    sx={{ my: 1 }}
                    label="TASAS"
                    error={
                    formik.touched.tasas && Boolean(formik.errors.tasas)
                    }
                    type="number"
                    helperText={formik.touched.tasas && formik.errors.tasas}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.no_sujeto_creditof}
                    onChange={formik.handleChange}
                    name="no_sujeto_creditof"
                    fullWidth
                    sx={{ my: 1 }}
                    label="OTRO NO SUJETO A CRÉDITO FISCAL"
                    error={
                    formik.touched.no_sujeto_creditof && Boolean(formik.errors.no_sujeto_creditof)
                    }
                    type="number"
                    helperText={formik.touched.no_sujeto_creditof && formik.errors.no_sujeto_creditof}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.importe_exentos}
                    onChange={formik.handleChange}
                    name="importe_exentos"
                    fullWidth
                    sx={{ my: 1 }}
                    label="IMPORTES EXENTOS"
                    error={
                    formik.touched.importe_exentos && Boolean(formik.errors.importe_exentos)
                    }
                    type="number"
                    helperText={formik.touched.importe_exentos && formik.errors.importe_exentos}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.compras_grav_tasacero}
                    onChange={formik.handleChange}
                    name="compras_grav_tasacero"
                    fullWidth
                    sx={{ my: 1 }}
                    label="IMPORTE COMPRAS GRAVADAS A TASA CERO"
                    error={
                    formik.touched.compras_grav_tasacero && Boolean(formik.errors.compras_grav_tasacero)
                    }
                    type="number"
                    helperText={formik.touched.compras_grav_tasacero && formik.errors.compras_grav_tasacero}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.descuentos}
                    onChange={formik.handleChange}
                    name="descuentos"
                    fullWidth
                    sx={{ my: 1 }}
                    label="DESCUENTOS/BONIFICACIONES /REBAJAS SUJETAS AL IVA"
                    error={
                    formik.touched.descuentos && Boolean(formik.errors.descuentos)
                    }
                    type="number"
                    helperText={formik.touched.descuentos && formik.errors.descuentos}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <TextField
                    value={formik.values.gif_card}
                    onChange={formik.handleChange}
                    name="gif_card"
                    fullWidth
                    sx={{ my: 1 }}
                    label="IMPORTE GIFT CARD"
                    error={
                    formik.touched.gif_card && Boolean(formik.errors.gif_card)
                    }
                    type="number"
                    helperText={formik.touched.gif_card && formik.errors.gif_card}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                 <FormControl size="small" fullWidth>
          <InputLabel id="tipo_compra">TIPO COMPRA</InputLabel>
          <Select
            labelId="tipo_compra"
            id="tipo_compra"
            name="tipo_compra"
            label="TIPO COMPRA"
            disabled={form}
            value={formik.values.tipo_compra}
            onChange={formik.handleChange}
          >
            {
                tipoCompra.map((row,key)=>
                <MenuItem key={key} value={row.cod}>{row.name}</MenuItem>                
                )
            }
           
          </Select>
        </FormControl>
                <TextField
                    value={formik.values.codigo_control}
                    onChange={formik.handleChange}
                    name="codigo_control"
                    fullWidth
                    sx={{ my: 1 }}
                    label="CÓDIGO DE CONTROL"
                    error={
                    formik.touched.codigo_control && Boolean(formik.errors.codigo_control)
                    }
                    type="number"
                    helperText={formik.touched.codigo_control && formik.errors.codigo_control}
                    variant="outlined"
                    disabled={form}
                    size="small"
                />
                <div className="mt-3">
                    <Button variant="contained" type="submit" 
                    disabled={form}>Registrar</Button>
                </div>
                </div>
            </form>
        </div>
    )
}
export default RegistroCompra