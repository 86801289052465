import { useEffect } from "react"
import { useState } from "react"
import Pagev1 from "../../../layout/Pagev1"
import { useSelector } from "react-redux"
import ModalComponent from "../../../components/ModalComponent"
import { listApiService, postApiService } from "../../../services/crudServices"
import Encabezados from "../../../sections/contabilidad/documentPrint/Encabezados"
import { dateConfig, dateConfigNumber } from "../../../utils/dateconfig"
import { size, sumBy } from "lodash"
import FormularioIp from "../../../components/FormularioIp"
import { fNumber } from "../../../utils/formatNumber"
import DiaryRegisterV2 from "../../Contabilidad/Diario/DiaryRegisterV2"
import ArticleIcon from '@mui/icons-material/Article';
import PrintComprobante from "../../Contabilidad/Diario/PrintComprobante"
import { Button, Typography } from "@mui/material"


const Caja =({id,responsable})=>{
    const [data,setData] = useState({})
    const [personal,setPersonal] = useState([])
    const [isModal,setIsModal] = useState(false)
    const userData = useSelector(state=>state.userData)
    const loadData=()=>{
        postApiService("caja-chica/detail",{caja_chica_id:id}).then(res=>{
            setData(res)
            setIsModal(false)
        })
    }
    const loadPersonal=()=>{
        listApiService('personal-list').then(res=>{
            const values = res.map((row,key)=>{
                return {key:row.id,option:row.people.nombres+" "+row.people.apellido_p}
              })
                setPersonal(values)
        })
      }
    const registrarSalida=(val)=>{
        postApiService('caja-chica/salida',val).then(res=>{
            setIsModal(true)
        })
    }
    const cerrarCaja=()=>{
        postApiService('caja-chica/cerrar',{caja_chica_id:id}).then(res=>{
            loadData()
        })
    }
    useEffect(()=>{
        loadData()
        loadPersonal()
    },[])
    return (
        <div className='diario'>
            {
                size(data) > 0 &&
                <div>
                <Encabezados
               title="Caja Chica"
               subtitle={""}
               company={data.company.name}
               branch={data.z_company_branch.sucursal}
               nit={data.company.nit}
               nro={dateConfigNumber(data.id,data.created_at)}
               />
              <div className="no-print">
              {
                data.cierre?
                <div>cerrado</div>
                :
                <ModalComponent variant={"contained"} reload={loadData} title={"Agregar Salida a la caja"} btn={"Registrar Salida"}>
                    <FormularioIp
                        isModal={isModal}
                        formSubmit={registrarSalida}
                        dataForm={[
                            {
                                name: "caja_chica_id",
                                label: "caja_chica_id",
                                type: "hidden",
                                value: data.id,
                                hidden: true
                            },
                            {
                                name: "responsable",
                                type: "select",
                                label: "Responsable",
                                value: "",
                                options: personal
                            },
                            {
                                name: "monto",
                                type: "number",
                                label: "Monto",
                                value: ""
                            },
                            {
                                name: "descripcion",
                                type: "text",
                                label: "Descripcion",
                                value: ""
                            },
                        ]}
            />
                </ModalComponent>
               }
              </div>
              <div className="mt-3 text-xs uppercase">
                   <p>Fecha: <span className="font-bold">{dateConfig(data.created_at)}</span></p> 
                   <p>Responsable: <span className="font-bold">{data.responsable.people.nombres+" "+data.responsable.people.apellido_p}</span></p> 
                   <p>Monto de Apertura Bs: <span className="font-bold">{fNumber(data.monto_apertura)}</span></p> 
                   {data.cierre && <p>Monto de Cierre Bs: <span className="font-bold">{fNumber(data.monto_cierre)}</span></p> }
                   {data.cierre && <p>Fecha de Cierre Bs: <span className="font-bold">{dateConfig(data.cierre)}</span></p> }
              </div>
                <table className="border border-gray-400 bg-white mt-2 rounded-lg overflow-hidden w-full shadow-md ">
        <thead>
          <tr>
            <th className='p-2 text-left font-bold'>No</th>
            <th className='p-2 text-left font-bold'>RESPONSABLE</th>
            <th className='p-2 text-left font-bold'>DESCRIPCION DE TRANSACCION</th>
            <th className='p-2 text-right font-bold'>MONTO</th>
            <th className='p-2 text-left font-bold'>FECHA</th>
            <th className='p-2 text-left font-bold no-print'>COMPROBANTE</th>
          </tr>
        </thead>
        <tbody>       
           {
            size(data) > 0 &&
            data.detail.map((row,key)=>
            <tr key={key}>
            <td className='p-2 text-left border-b border-gray-300'>{key+1}</td>
            <td className='p-2 text-left border-b border-gray-300'>{row.responsable.people.nombres+" "+row.responsable.people.apellido_p}</td>
            <td className='p-2 text-left border-b border-gray-300'>{row.descripcion}</td>
            <td className='p-2 text-right border-b border-gray-300'>{fNumber(row.monto)}</td>
            <td className='p-2 text-left border-b border-gray-300'>{dateConfig(row.created_at)}</td>
            <td className='p-2 text-left border-b border-gray-300  no-print'>
            <ModalComponent btn={<ArticleIcon color={size(row.comprobante)>0? "success":"error"}/>} fullScreenx={true}>
           {
            size(row.comprobante)>0?             
            <PrintComprobante id={row.comprobante.id}/> 
            :
           <DiaryRegisterV2
                type="egreso"
                company_id={data.company_id}
                branch_id={"1"}
                model_type= "CajaChicaDetail"
                model_id= {row.id}
                data= {{
                    glosa: row.descripcion,
                    date: row.created_at,
                    total: row.monto,
                }}
                company= {data.company.name}
                store= {data.store.name}
                store_id= {data.store_id}
                reloadData= {loadData}
                invoice={true}
                invoiceOption={true}
            />      
        }
            </ModalComponent>
            </td>

            </tr>
            )
           }
           <tr>
                <td colSpan={3} className='p-2 text-right font-bold'> Total</td>
                <td className='p-2 text-right font-bold '> {fNumber(sumBy(data.detail,o=>{
                    return parseFloat(o.monto)
                }))}</td>
           </tr>
            </tbody>
            </table>
               {
                   !data.cierre?                   
                   <div className="mt-3 no-print">
                       <Button variant="contained" onClick={cerrarCaja}>Cerrar Caja</Button>
                   </div>
                   :
                   <ModalComponent  btn={<Typography color={size(data.comprobante[1])>0? "success":"error"}>Comprobante de Cierre</Typography>} fullScreenx={true}>
                  {
                  size(data.comprobante[1])>0?             
                  <PrintComprobante id={data.comprobante[1].id}/> 
                  :                  
                  <DiaryRegisterV2
                   type="traspaso"
                   company_id={data.company_id}
                   branch_id={"1"}
                   model_type= "CajaChica"
                   model_id= {data.id}
                   data= {{
                       glosa: "cierre de caja chica",
                       date: data.cierre,
                       total: parseFloat(data.monto_apertura) - sumBy(data.detail,o=>{
                        return parseFloat(o.monto)
                    }),
                   }}
                   reload={loadData}
                   company= {data.company.name}
                    store= {data.store.name}
                    store_id= {data.store_id}
                    invoice={true}
                    invoiceOption={true}
               />}
               </ModalComponent>
               }
           </div>
            }
        </div>
      
    )
}
export default Caja