import { useEffect, useState } from "react"
import Logo from "../../components/Logo"
import { dateConfig } from "../../utils/dateconfig"
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber"
import { getCashBoxDetBrand } from "../../services/cashBox"

const CashboxDetailBrand = ({encabezado,cashbox}) => {
    const [data,setData] = useState([])
    useEffect(()=>{
        getCashBoxDetBrand({cashbox_id:cashbox}).then(res=>{
            setData(res)
        })
    },[])  
    const dataByCompanyName = data.reduce((acc, row) => {
        (acc[row.company_name] = acc[row.company_name] || []).push(row);
        return acc;
    }, {});
 console.log(dataByCompanyName)
  return (
    <div>
            <div className="grid grid-cols-8">
                <div className="col-span-2">
                    <Logo />
                </div>
                <div className="col-span-4 w-full flex items-center">
                    <h2 className="uppercase text-center w-full font-bold">
                        {"Detalle de Caja"}
                    </h2>
                </div>
                <div className="col-span-2 text-sm">
                    <p className="text-right text-red-500 font-bold text-lg">
                        <span>Nro </span> {fInvoiceNumber(encabezado.nro)}
                    </p>
                    <p className="text-right text-red-500 font-bold">
                        <span> </span> {encabezado.store}
                    </p>
                </div>
                <div className="col-span-4 text-xs mt-3">
                    <p>
                        <span className="font-semibold">Responsable Caja:</span>{" "}
                        {encabezado.usuario}
                    </p>
                    <p>
                        <span className="font-semibold">Fecha y hr Apertura:</span>{" "}
                        {dateConfig(encabezado.f_apertura)}
                    </p>
                    <p>
                        <span className="font-semibold">Fecha y hr Cierre:</span>{" "}
                        {dateConfig(encabezado.f_cierre)}
                    </p>
                </div>
                <div className="col-span-4 text-xs">
                    
                </div>
            </div>
            <div>
            {Object.entries(dataByCompanyName).map(([companyName, rows]) => (
                <div key={companyName} className="mt-3">
                    <h2>{companyName}</h2>
                    <table className="table-print"> 
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr key={row.voucher_detail_id}>
                                    <td>{row.item}</td>
                                    <td>{row.qty}</td>
                                    <td>{row.price}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div>
                        <h3>Totales</h3>
                        <p>Cantidad Total: {fNumber(rows.reduce((acc, row) => acc +parseFloat(row.qty), 0))}</p>
                        <p>Precio Total: {fNumber(rows.reduce((acc, row) => acc + parseFloat(row.price), 0))}</p>
                    </div>
                </div>
            ))}
        </div>
        </div>
  )
}
export default CashboxDetailBrand