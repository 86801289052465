import { useEffect, useState } from "react";
import { getCashBoxDetv1 } from "../../services/cashBox";
import Logo from "../../components/Logo";
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber";
import { dateConfig } from "../../utils/dateconfig";
import ModalComponent from "../../components/ModalComponent";
import InvoicePrint from "./InvoicePrint";
import InvoicePrintV2 from "./InvoicePrintV2";

const CashBoxDetail = ({ cashbox, encabezado }) => {
  const [data, setData] = useState([]);
  const [companySums, setCompanySums] = useState({});

  useEffect(() => {
    getCashBoxDetv1({ cashbox: cashbox }).then((res) => {
      setData(res);
    });
  }, [cashbox]);

  useEffect(() => {
    let newCompanySums = { ...companySums };
    data.forEach((row) => {
      if (!newCompanySums[row.company_name]) {
        newCompanySums[row.company_name] = {
          totalMonto: 0,
          totalIva: 0,
          totalIt: 0,
          totalRedEnlace: 0,
        };
      }
      newCompanySums[row.company_name].totalMonto += parseFloat(row.total);
      newCompanySums[row.company_name].totalIva += parseFloat(row.iva_amount);
      newCompanySums[row.company_name].totalIt += parseFloat(row.it_amount);
      newCompanySums[row.company_name].totalRedEnlace += parseFloat(
        row.re_amount
      );
    });
    setCompanySums(newCompanySums);
  }, [data]);
  const renderTables = () => {
    const companyNames = [...new Set(data.map((row) => row.company_name))];
    return companyNames.map((companyName) => {
      const companyRows = data.filter(
        (row) => row.company_name === companyName
      );
      const rows = companyRows.map((row) => (
        <tr key={row.invoice_id}>
          <td>
            <ModalComponent
              btn={
                "Factura No " +
                (parseFloat(row.invoice_number) > 0
                  ? row.invoice_number
                  : row.invoice_numbers)
              }
            >
              {parseInt(row.version) === 1 ? (
                <InvoicePrint doc="factura" id={row.invoice_id} />
              ) : (
                <InvoicePrintV2 doc="facturav2" id={row.invoice_id} />
              )}
            </ModalComponent>
          </td>
          <td>{row.payment_type_label}</td>
          <td>{row.total}</td>
          <td>{row.iva_amount}</td>
          <td>{row.it_amount}</td>
          {/*  <td>{row.payment_type+""==="8"?row.re_amount:0}</td> */}
        </tr>
      ));

      return (
        <div key={companyName}>
          <h2 className="mt-3 font-bold">{companyName}</h2>
          <table className="table-print">
            <thead>
              <tr>
                <th>Factura</th>
                <th>Tipo venta</th>
                <th>Monto</th>
                <th>IVa 13%</th>
                <th>it 3%</th>
                {/* <th>Red Enlace 1.8%</th> */}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          <div className="totals font-semibold">
            <h3>Totales para {companyName}</h3>
            <p>Total Monto: {fNumber(companySums[companyName]?.totalMonto)}</p>
            <p>Total IVA 13%: {fNumber(companySums[companyName]?.totalIva)}</p>
            <p>Total IT 3%: {fNumber(companySums[companyName]?.totalIt)}</p>
            <p>
              Total:{" "}
              {fNumber(
                parseFloat(companySums[companyName]?.totalMonto) -
                  parseFloat(companySums[companyName]?.totalIva) -
                  parseFloat(companySums[companyName]?.totalIt)
              )}
            </p>
            {/* <p>Total Red Enlace 1.8%: {fNumber(companySums[companyName]?.totalRedEnlace)}</p> */}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="grid grid-cols-8">
        <div className="col-span-2">
          <Logo />
        </div>
        <div className="col-span-4 w-full flex items-center">
          <h2 className="uppercase text-center w-full font-bold">
            {"Detalle de Caja"}
          </h2>
        </div>
        <div className="col-span-2 text-sm">
          <p className="text-right text-red-500 font-bold text-lg">
            <span>Nro </span> {fInvoiceNumber(encabezado.nro)}
          </p>
          <p className="text-right text-red-500 font-bold">
            <span> </span> {encabezado.store}
          </p>
        </div>
        <div className="col-span-4 text-xs mt-3">
          <p>
            <span className="font-semibold">Responsable Caja:</span>{" "}
            {encabezado.usuario}
          </p>
          <p>
            <span className="font-semibold">Fecha y hr Apertura:</span>{" "}
            {dateConfig(encabezado.f_apertura)}
          </p>
          <p>
            <span className="font-semibold">Fecha y hr Cierre:</span>{" "}
            {dateConfig(encabezado.f_cierre)}
          </p>
        </div>
        <div className="col-span-4 text-xs"></div>
      </div>
      {renderTables()}
    </div>
  );
};

export default CashBoxDetail;
