import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import AutocompleteProvider from "./AutocompleteProvider";
import { useDispatch, useSelector } from "react-redux";
import { setLoadOff, setLoadOn } from "../redux/features/config/load";
import { find, includes, size } from "lodash";
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { listStoreType } from "../services/store";
import { listRoleUsers } from "../services/users";
import { reportPro } from "../services/report";
import AutocompleteProduct from "./AutocompleteProduct";

import * as yup from "yup";
import { dowloadExcel } from "../services/provider";
import AutocompleteAccount from "./AutocompleteAccount";
import {
  listBranchOffices,
  listBranchOfficesAll,
} from "../services/branchOfice";

const validationSchema = yup.object({
  date_start: yup.date().default(function () {
    return new Date();
  }),
  date_end: yup.date().default(function () {
    return new Date();
  }),
});

const FiltersReport = forwardRef(
  (
    {
      str = false,
      account = false,
      report,
      store_type = null,
      user = false,
      roles = false,
      store = false,
      state = false,
      client = false,
      voucher = false,
      states = [],
      days = [],
      loadData,
      provider = false,
      product = false,
      handlePrint,
      setOption,
      dateRange = true,
      setKardex,
      search = false,
      searchTitle = "buscar",
      type = [],
      types = null,
      transfer = false,
      reload = false,
      inputType = "date",
      exportExcel = false,
      paginate = false,
      data = null,
      lastDate = false,
      month = false,
      companies = [],
      barcode = false,
      branch = false,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [branches, setBranches] = useState([]);
    const [stores, setStores] = useState([]);
    const [errors, setErrors] = useState([]);
    const [msg, setMsg] = useState("");
    const [users, setUsers] = useState([]);
    const [prices, setPrices] = useState([]);
    const [prevName, setPrevName] = useState("");
    const [prov, setProv] = useState({});
    const userData = useSelector((state) => state.userData);
    const evetButton = useSelector((state) => state.eventButton);
    const [datax, setDatax] = useState({});
    const date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = `0${month}`;
    }

    const formik = useFormik({
      initialValues: {
        date_start: year + "-" + month + "-" + day,
        date_end: year + "-" + month + "-" + day,
        provider: "",
        product: "",
        store: "all",
        user: "",
        statu: "all",
        client: "",
        days: "30",
        packing: "",
        report: report,
        type: "all",
        types: types,
        search: "",
        page: 1,
        company: "",
        account: {},
        barcode: "",
        branch_id: "all",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(setLoadOn());
        reportPro(values, values.page)
          .then((res) => {
            setDatax({
              per_page: res.data?.per_page || res.per_page,
              total: res.data?.total || res.total,
              current_page: res.data?.current_page - 1 || res.current_page,
            });
            setMsg("");
            loadData(res);
            setOption({
              ...values,
              stor: find(stores, (o) => o.id === values.store),
              pro: prov,
            });
          })
          .catch((err) => {
            if (err.code === "ERR_BAD_REQUEST") {
              setMsg("Todos los campos son requeridos");
              loadData([]);
            }
          })
          .finally(() => {
            dispatch(setLoadOff());
          });
      },
    });
    useImperativeHandle(ref, () => ({
      reportGenerate() {
        formik.handleSubmit();
      },
    }));

    const downloadExcel = () => {
      dowloadExcel(formik.values).then((res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Report.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    };
    const getData = (roles) => {
      Promise.all([
        listStoreType({ type: store_type, str: str }),
        listRoleUsers({ rol: roles }),
        listBranchOfficesAll(),
      ]).then((res) => {
        setStores(res[0]);
        setUsers(res[1]);
        setBranches(res[2]);
      });
    };
    const getDataStore = () => {
      /* listStoreType({ type: store_type, str: str }).then((res) => {
        setStores(res);
      }); */
      Promise.all([
        listStoreType({ type: store_type, str: str }),
        listBranchOfficesAll(),
      ]).then((res) => {
        setStores(res[0]);
        setBranches(res[1]);
      });
    };
    useEffect(() => {
      if (report !== "paynement") {
        formik.handleSubmit();
      }
    }, [evetButton]);

    useEffect(() => {
      if (size(roles) > 0) {
        getData(roles);
      } else {
        getDataStore();
      }
    }, []);
    const setProvider = (val) => {
      if (val) {
        formik.setFieldValue("provider", val?.id);
        formik.setFieldValue("product", "");
        formik.setFieldValue("page", 1);
        setErrors({ ...errors, product: true });
        setProv(val);
      } else {
        formik.setFieldValue("provider", "");
      }
    };
    const setProduct = (val) => {
      if (val) {
        formik.setFieldValue("product", val?.id);
        setPrices(val.prices);
        setKardex({ item: val, provider: prov });
        setErrors({ ...errors, product: false });
        formik.setFieldValue("page", 1);
      } else {
        formik.setFieldValue("product", "");
      }
    };
    if (report !== prevName) {
      setPrevName(report);
      formik.resetForm();
      setProvider({});
    }
    useEffect(() => {
      formik.setFieldValue("user", userData.user.id);
      formik.setFieldValue("store", userData.store);
      formik.setFieldValue("page", 1);
    }, [userData]);

    const handleChangePage = (event, newPage) => {
      formik.setFieldValue("page", newPage);
      formik.handleSubmit();
    };
    const setAccount = (val) => {
      formik.setFieldValue("account", val);
    };

    return (
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="md:grid grid-cols-9 gap-2">
            {barcode && (
              <TextField
                value={formik.values.barcode}
                onChange={formik.handleChange}
                name="barcode"
                fullWidth
                sx={{ my: 1 }}
                label={"Codigo de barras"}
                type="number"
                error={formik.touched.barcode && Boolean(formik.errors.barcode)}
                helperText={formik.touched.barcode && formik.errors.barcode}
                variant="outlined"
                size="small"
              />
            )}
            {provider && (
              <div className=" ">
                <AutocompleteProvider
                  setProvider={setProvider}
                  key={report + "a"}
                />
              </div>
            )}
            {product && (
              <div className=" ">
                <AutocompleteProduct
                  provider_id={formik.values.provider}
                  setProduct={setProduct}
                  error={errors?.product}
                  key={report + "b"}
                />
              </div>
            )}
            {size(prices) > 0 && (
              <FormControl fullWidth>
                <InputLabel id="packing">Packing</InputLabel>
                <Select
                  labelId="packing"
                  id="packing"
                  name="packing"
                  label="Estado"
                  value={formik.values.packing}
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                  size="small"
                  error={
                    formik.touched.packing && Boolean(formik.errors.packing)
                  }
                >
                  <MenuItem value="all">todos</MenuItem>
                  {prices.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.sale_presentation + " x " + row.units}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText color="error">
                  {formik.touched.packing && formik.errors.packing}
                </FormHelperText>
              </FormControl>
            )}
            {size(type) > 0 && (
              <FormControl fullWidth>
                <InputLabel id="type">Comprobante</InputLabel>
                <Select
                  labelId="type"
                  id="type"
                  name="type"
                  label="Comprobante"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                  size="small"
                  error={formik.touched.type && Boolean(formik.errors.packing)}
                >
                  <MenuItem value="all">todos</MenuItem>

                  {type.map((row, key) => (
                    <MenuItem key={key} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText color="error">
                  {formik.touched.type && formik.errors.type}
                </FormHelperText>
              </FormControl>
            )}
            {search && (
              <TextField
                value={formik.values.search}
                onChange={formik.handleChange}
                name="search"
                fullWidth
                sx={{ my: 1 }}
                label={searchTitle}
                error={formik.touched.search && Boolean(formik.errors.search)}
                helperText={formik.touched.search && formik.errors.search}
                variant="outlined"
                size="small"
              />
            )}
            {size(companies) > 0 && (
              <FormControl size="small" fullWidth>
                <InputLabel id="company">Empresa</InputLabel>
                <Select
                  labelId="company"
                  id="company"
                  name="company"
                  value={formik.values.company}
                  label="Empresa"
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                >
                  <MenuItem value="">Seleccionar</MenuItem>
                  {companies.map((row, key) => (
                    <MenuItem key={key} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {account && (
              <AutocompleteAccount
                setAccount={setAccount}
                company={formik.values.company}
              />
            )}
            {voucher && (
              <TextField
                value={formik.values.voucher}
                onChange={formik.handleChange}
                name="voucher"
                fullWidth
                sx={{ my: 1 }}
                label={"Nro de nota"}
                type="number"
                error={formik.touched.voucher && Boolean(formik.errors.voucher)}
                helperText={formik.touched.voucher && formik.errors.voucher}
                variant="outlined"
                size="small"
              />
            )}
            {client && (
              <TextField
                value={formik.values.client}
                onChange={formik.handleChange}
                name="client"
                fullWidth
                sx={{ my: 1 }}
                label="Nit del cliente"
                type="number"
                error={formik.touched.client && Boolean(formik.errors.client)}
                helperText={formik.touched.client && formik.errors.client}
                variant="outlined"
                size="small"
              />
            )}
            {transfer && (
              <Fragment>
                <FormControl fullWidth>
                  <InputLabel id="store">Almacen Salida</InputLabel>
                  <Select
                    labelId="store"
                    id="store"
                    name="store"
                    label="Almacen Salida"
                    value={formik.values.store}
                    onChange={formik.handleChange}
                    sx={{ my: 1 }}
                    size="small"
                    error={formik.touched.store && Boolean(formik.errors.store)}
                  >
                    {/*   {includes(
                      ["super-admin", "gerencia", "contabilidad-aux", "jefe-almacen"],
                      userData.role
                    ) && <MenuItem value="all">todos</MenuItem>} */}
                    <MenuItem value="all">todos</MenuItem>
                    {size(stores) > 0 &&
                      stores.map((row, key) => (
                        <MenuItem key={key} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText color="error">
                    {formik.touched.store && formik.errors.store}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="store_a">Almacen destino</InputLabel>
                  <Select
                    labelId="store_a"
                    id="store_a"
                    name="store_a"
                    label="Almacen Destino"
                    value={formik.values.store_a}
                    onChange={formik.handleChange}
                    sx={{ my: 1 }}
                    size="small"
                    error={
                      formik.touched.store_a && Boolean(formik.errors.store_a)
                    }
                  >
                    {includes(
                      [
                        "super-admin",
                        "gerencia",
                        "contabilidad-aux",
                        "jefe-almacen",
                        "jefe-sala",
                        "jefe-sucursal",
                        "contador-master",
                      ],
                      userData.role
                    ) && <MenuItem value="all">todos</MenuItem>}

                    {size(stores) > 0 &&
                      stores.map((row, key) => (
                        <MenuItem key={key} value={row.id}>
                          {row.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText color="error">
                    {formik.touched.store && formik.errors.store}
                  </FormHelperText>
                </FormControl>
              </Fragment>
            )}
            {branch && (
              <FormControl fullWidth>
                <InputLabel id="branch_id">Sucursal</InputLabel>
                <Select
                  labelId="branch_id"
                  id="branch_id"
                  name="branch_id"
                  label="Sucursal"
                  value={formik.values.branch_id}
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                  size="small"
                  error={
                    formik.touched.branch_id && Boolean(formik.errors.branch_id)
                  }
                >
                  {includes(
                    ["super-admin", "gerencia", "contabilidad-aux"],
                    userData.role
                  ) && <MenuItem value="all">todos</MenuItem>}

                  {size(branches) > 0 &&
                    branches.map((row, key) => (
                      <MenuItem key={key} value={row.id}>
                        {row.branch_office}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText color="error">
                  {formik.touched.branch_id && formik.errors.branch_id}
                </FormHelperText>
              </FormControl>
            )}
            {store && (
              <FormControl fullWidth>
                <InputLabel id="store">Almacen / Sala</InputLabel>
                <Select
                  labelId="store"
                  id="store"
                  name="store"
                  label="Almacen/Sala"
                  value={formik.values.store}
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                  size="small"
                  error={formik.touched.store && Boolean(formik.errors.store)}
                >
                  {includes(
                    ["super-admin", "gerencia", "contabilidad-aux"],
                    userData.role
                  ) && <MenuItem value="all">todos</MenuItem>}

                  {size(stores) > 0 &&
                    stores.map((row, key) => {
                      if (formik.values.branch_id !== "all") {
                        return (
                          <MenuItem
                            key={key}
                            value={row.id}
                            disabled={
                              parseInt(formik.values.branch_id) ===
                              parseInt(row.branch_office_id)
                                ? false
                                : true
                            }
                          >
                            {row.name}
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem key={key} value={row.id}>
                            {row.name}
                          </MenuItem>
                        );
                      }
                    })}
                </Select>
                <FormHelperText color="error">
                  {formik.touched.store && formik.errors.store}
                </FormHelperText>
              </FormControl>
            )}
            {size(days) > 0 && (
              <TextField
                labelId="days"
                label="Rango de Dias"
                id="days"
                name="days"
                value={formik.values.days}
                onChange={formik.handleChange}
                sx={{ my: 1 }}
                size="small"
                type="number"
                variant="outlined"
                error={formik.touched.days && Boolean(formik.errors.days)}
              />
            )}

            {user && (
              <FormControl fullWidth>
                <InputLabel id="user">Usuario</InputLabel>
                <Select
                  labelId="user"
                  id="user"
                  name="user"
                  label="Vendedor"
                  value={formik.values.user}
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                  size="small"
                  error={formik.touched.user && Boolean(formik.errors.user)}
                >
                  <MenuItem value="all">todos</MenuItem>
                  {size(users) > 0 &&
                    users.map((row, key) => (
                      <MenuItem key={key} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText color="error">
                  {formik.touched.user && formik.errors.user}
                </FormHelperText>
              </FormControl>
            )}

            {state && (
              <FormControl fullWidth>
                <InputLabel id="statu">Estado</InputLabel>
                <Select
                  labelId="statu"
                  id="statu"
                  name="statu"
                  label="Estado"
                  value={formik.values.statu}
                  onChange={formik.handleChange}
                  sx={{ my: 1 }}
                  size="small"
                  error={formik.touched.statu && Boolean(formik.errors.statu)}
                >
                  <MenuItem value="all">todos</MenuItem>
                  {states.map((row, key) => (
                    <MenuItem key={key} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText color="error">
                  {formik.touched.statu && formik.errors.statu}
                </FormHelperText>
              </FormControl>
            )}
            {dateRange && (
              <>
                <TextField
                  value={formik.values.date_start}
                  onChange={formik.handleChange}
                  name="date_start"
                  fullWidth
                  sx={{ my: 1 }}
                  type={inputType}
                  label="Del"
                  error={
                    formik.touched.date_start &&
                    Boolean(formik.errors.date_start)
                  }
                  helperText={
                    formik.touched.date_start && formik.errors.date_start
                  }
                  variant="outlined"
                  size="small"
                />
                <TextField
                  value={formik.values.date_end}
                  onChange={formik.handleChange}
                  name="date_end"
                  fullWidth
                  sx={{ my: 1 }}
                  disabled={month === false}
                  type={inputType}
                  label="Al"
                  error={
                    formik.touched.date_end && Boolean(formik.errors.date_end)
                  }
                  helperText={formik.touched.date_end && formik.errors.date_end}
                  variant="outlined"
                  size="small"
                />
              </>
            )}
            {lastDate && (
              <TextField
                value={formik.values.date_end}
                onChange={formik.handleChange}
                name="date_end"
                fullWidth
                sx={{ my: 1 }}
                disabled={month === false}
                type={inputType}
                label="Al"
                error={
                  formik.touched.date_end && Boolean(formik.errors.date_end)
                }
                helperText={formik.touched.date_end && formik.errors.date_end}
                variant="outlined"
                size="small"
              />
            )}

            <Button variant="contained" type="submit" fullWidth sx={{ my: 1 }}>
              Generar
            </Button>
            <Button
              variant="contained"
              type="button"
              onClick={() => handlePrint()}
              fullWidth
              sx={{ my: 1 }}
            >
              Imprimir
            </Button>
            {exportExcel && (
              <Button
                size="small"
                variant="contained"
                color="success"
                fullWidth
                onClick={downloadExcel}
              >
                Descargar Excel
              </Button>
            )}
          </div>
        </form>
        {msg && <p className="text-sm text-red-500">{msg}</p>}
        {paginate && (
          <TablePagination
            rowsPerPageOptions={[datax.per_page]}
            component="div"
            count={datax.total}
            rowsPerPage={datax.per_page}
            page={datax.current_page}
            onPageChange={handleChangePage}
          />
        )}
      </div>
    );
  }
);
export default FiltersReport;
