import { Button, Paper } from "@mui/material"
import Pagev1 from "../../../layout/Pagev1"
import FormularioIp from "../../../components/FormularioIp"
import { listApiService, postApiService } from "../../../services/crudServices"
import { toast } from "react-toastify"
import { useState } from "react"
import { useEffect } from "react"
import ModalComponent from "../../../components/ModalComponent"
import { size } from "lodash"

const ActivosConfig=()=>{
    const [unidad,setUnidad] = useState([])
    const [selectedUnidad,setSelectedUnidad] = useState()
    const [selectedCiudad,setSelectedCiudad] = useState()
    const [selectedEdif,setSelectedEdif] = useState()
    const [rubros,setRubros] = useState([])
    const [rubroX,setRubroX] = useState([])
    const [tipoBien,setTipoBien] = useState([])
    const regUnidad=(val)=>{
        postApiService('unidad',val).then(res=>{
            toast.success('registrado correctamente')
            loadUnidad()
        })
    }
    const loadUnidad=()=>{
        listApiService('unidad').then(res=>{
            setUnidad(res)
        })
    }
    const regCiudad=(val)=>{
        postApiService('ciudad',val).then(res=>{
            toast.success('registrado correctamente')
            loadUnidad()
        })
    }
    const regEdificio=(val)=>{
        postApiService('edificio',val).then(res=>{
            toast.success('registrado correctamente')
            loadUnidad()
        })
    }
    const regOficina=(val)=>{
        postApiService('oficina',val).then(res=>{
            toast.success('registrado correctamente')
            loadUnidad()
        })
    }
    const setRubroContable= async (val)=>{
        postApiService('rubro-contable',val).then(res=>{
            toast.success('registrado correctamente')
            loadRubroContable()
            return res;
        })
    }
    const loadRubroContable=()=>{
        listApiService('rubro-contable').then(res=>{
            setRubros(res)
        })
    }
    const regTipoBien=(val)=>{
        postApiService('tipo-bien',val).then(res=>{
            toast.success('registrado correctamente')
            loadTipoBien()
            return res;
        })
    }
    const loadTipoBien=()=>{
        listApiService('tipo-bien').then(res=>{
            setTipoBien(res)
        })
    }
    const setCuenta =(val)=>{
        postApiService("rubro-contable/cuenta",val).then(res=>{
            loadRubroContable();
            toast.success("Se registro correctamente!!")
        })
    }
    useEffect(()=>{
        const rubroX = rubros.map((field)=>({
            key: field.id,
            option: field.nombre
        }),{})
        setRubroX(rubroX)
    },[rubros])
    useEffect(()=>{
        loadUnidad()
        loadRubroContable()
        loadTipoBien()
    },[])
    useEffect(()=>{
        setSelectedCiudad()
        setSelectedEdif()
    },[selectedUnidad])
    useEffect(()=>{        
        setSelectedEdif()
    },[selectedCiudad])
    
    return(
        <Pagev1 title="Configuraciones">
            <div className="md:grid grid-cols-2 gap-3">
                <Paper className="p-3">
                    <div>
                        <div>Unidad</div>
                        <div className="p-3">
                            <ul>
                                {unidad.map((row,key)=>
                                <li key={key}>
                                    <Button onClick={()=>setSelectedUnidad(row.id)}>
                                    <p className="font-semibold">{row.unidad}</p>                                       
                                    </Button>
                                    <div className="px-3">
                                            {
                                                parseInt(selectedUnidad) === parseInt(row.id) &&
                                                <div className="border-l border-l-green-500 pl-3 bg-green-200 p-2">
                                                <p className="text-green-700 uppercase font-semibold text-xs">Ciudades</p>
                                                 { row.ciudad.map((row,key)=>
                                                <div key={key+"-"+row.id} className="pl-3">
                                                    <Button onClick={()=>setSelectedCiudad(row.id)}>{row.ciudad}</Button>
                                                    {
                                                parseInt(selectedCiudad) === parseInt(row.id) &&
                                                <div className="border-l border-l-blue-500 pl-3 bg-blue-200 p-2">
                                                <p className="text-blue-700 uppercase font-semibold text-xs">Edificios</p>
                                           
                                                 {row.edificio.map((row,key)=>
                                                <div key={key} className="pl-3">
                                                    <Button onClick={()=>setSelectedEdif(row.id)}>{row.edificio}</Button>

                                                    {
                                                        parseInt(selectedEdif) === parseInt(row.id) &&
                                                        <div className="border-l border-l-yellow-500 pl-3 bg-yellow-200 p-2">
                                                        <p className="text-yellow-700 uppercase font-semibold text-xs">Oficinas</p>
                                                          {row.oficina.map((row,key)=>
                                                         <div key={key} className="pl-3">
                                                             <Button className="text-left" onClick={()=>console.log("")}>{row.oficina}</Button>
                                                         </div>
                                                         )}
                                                          <FormularioIp dataForm={[
                                                             {name: "oficina",
                                                             label: "Nombre del oficina",
                                                             type: "text",
                                                             value: "",},
                                                             {name: "edificio_id",
                                                             label: "edificio",
                                                             type: "hidden",
                                                             hidden: true,
                                                             value: row.id,},
                                                         ]} formSubmit={regOficina} />
                                                        </div>
                                                    }

                                                </div>
                                                )}
                                                 <FormularioIp dataForm={[
                                                    {name: "edificio",
                                                    label: "Nombre del edificio",
                                                    type: "text",
                                                    value: "",},
                                                    {name: "ciudad_id",
                                                    label: "ciudad",
                                                    type: "hidden",
                                                    hidden: true,
                                                    value: row.id,},
                                                ]} formSubmit={regEdificio} />
                                               </div>
                                            }
                                                </div>
                                                )}
                                                 <FormularioIp dataForm={[
                                                    {name: "ciudad",
                                                    label: "Nombre de la ciudad",
                                                    type: "text",
                                                    value: "",},
                                                    {name: "unidad_id",
                                                    label: "unidad",
                                                    type: "hidden",
                                                    hidden: true,
                                                    value: row.id,},
                                                ]} formSubmit={regCiudad} />
                                                 
                                               </div>
                                            }
                                        </div>
                                </li>
                                )}
                            </ul>
                        </div>
                                <FormularioIp dataForm={[
                                    {name: "unidad",
                                    label: "Nombre de la unidad",
                                    type: "text",
                                    value: "",}
                                ]} formSubmit={regUnidad} />
                    </div>
                </Paper>
           
                <Paper className="p-3">
                    <div>
                        <div>Rubro contable</div>
                        <ModalComponent btn={"Nuevo"} title={"Rubro contable"}>
                            <FormularioIp dataForm={[
                                 {
                                    name: "nombre",
                                    type: "text",
                                    label: "Nombre",
                                    value: ""
                                }, 
                                 {
                                    name: "vida_util",
                                    type: "number",
                                    label: "Vida Util en años",
                                    value: ""
                                }, 
                                 {
                                    name: "coeficiente",
                                    type: "number",
                                    label: "Coeficiente %",
                                    value: ""
                                },
                                {
                                    name: "actualiza",
                                    type: "select",
                                    label: "Actualiza",
                                    value: "",
                                    options: [
                                        {key:"1",option:"SI"},
                                        {key:"0",option:"NO"}
                                    ]
                                },
                                {
                                    name: "deprecia",
                                    type: "select",
                                    label: "Deprecia",
                                    value: "",
                                    options: [
                                        {key:"1",option:"SI"},
                                        {key:"0",option:"NO"}
                                    ]
                                }
                            ]} formSubmit={setRubroContable} />
                            
                        </ModalComponent>
                        <table className="table-print">
                                <thead>
                                    <tr>
                                        <th>cod</th>
                                        <th>nombre</th>
                                        <th>vida util</th>
                                        <th>coeficiente %</th>
                                        <th>Deprecia</th>
                                        <th>actualiza</th>
                                        <th>cuentas</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        size(rubros)>0&&
                                        rubros.map((row,key)=>
                                        <tr key={key}>
                                            <td>{row.id}</td>
                                            <td>{row.nombre}</td>
                                            <td>{row.vida_util}</td>
                                            <td>{row.coeficiente}</td>
                                            <td>{row.deprecia?"SI":"NO"}</td>
                                            <td>{row.actualiza?"SI":"NO"}</td>
                                            <td>
                                                {
                                                    size(row.cuentas) > 0 &&
                                                    row.cuentas.map((row,key)=>
                                                    <p key={key}>{row.cuenta}</p>
                                                    )
                                                }
                                            </td>
                                            <td>
                                                <ModalComponent title={"Agregar Cuenta"} btn="Cuenta">
                                                <FormularioIp dataForm={[
                                                    {
                                                        name: "rubro_id",
                                                        type: "hidden",
                                                        label: "Cuenta",
                                                        value: row.id,
                                                        hidden: true
                                                    },
                                                    {
                                                        name: "cuenta",
                                                        type: "number",
                                                        label: "Cuenta",
                                                        value: ""
                                                    },
                                                    {
                                                        name: "registra",
                                                        type: "select",
                                                        label: "Registra en",
                                                        value: "debe",
                                                        options: [
                                                            {key:"debe",option:"Debe"},
                                                            {key:"haber",option:"Haber"}
                                                        ]
                                                    }
                                                ]} formSubmit={setCuenta} />
                            
                        
                                                </ModalComponent>
                                            </td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                    </div>
                </Paper>
                <Paper className="p-3">
                    <div>
                        <div>Tipo bien</div>
                        <ModalComponent btn={"Nuevo"} title={"Tipo de Bien"}>
                        <FormularioIp dataForm={[
                                    {name: "tipo_bien",
                                    label: "Tipo Bien",
                                    type: "text",
                                    value: "",},
                                    {
                                        name: "rubro_contable_id",
                                        type: "select",
                                        label: "Rubro contable",
                                        value: "",
                                        options: rubroX
                                    }

                                ]} formSubmit={regTipoBien} />
                        </ModalComponent>
                        <table className="table-print">
                                <thead>
                                    <tr>
                                        <th>cod</th>
                                        <th>Tipo bien</th>
                                        <th>Cod Rubro</th>
                                        <th>Rubro Contable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        size(tipoBien)>0&&
                                        tipoBien.map((row,key)=>
                                        <tr key={key}>
                                            <td>{row.id}</td>
                                            <td>{row.tipo_bien}</td>
                                            <td>{row.rubro_contable.id}</td>
                                            <td>{row.rubro_contable.nombre}</td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                    </div>
                </Paper>
              
            </div>
        </Pagev1>
    )

}
export default ActivosConfig