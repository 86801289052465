import { useSelector } from "react-redux"
import { dateConfig } from "../utils/dateconfig";

const PrintFooter = () => {
    const userData = useSelector((state)=>state.userData)
    const date = new Date();
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }  
    if (month < 10) {
      month = `0${month}`;
    }
  return (
    <div className="px-3 py-2">
        <p className="text-xs">
                <span className="font-bold">Generado por:</span>{" "}
                {userData.user.name}
        </p>
        <p className="text-xs">
                <span className="font-bold">Fecha emisión:</span>{" "}
                {dateConfig(date)}              
        </p>
    </div>
  )
}
export default PrintFooter