import { Fragment, useState } from "react"
import { getCashBoxDetv2 } from "../../services/cashBox";
import { find, size } from "lodash";
import { fNumber } from "../../utils/formatNumber";
import ModalComponent from "../../components/ModalComponent";
import DiaryRegisterV2 from "../Contabilidad/Diario/DiaryRegisterV2";
import { Divider } from "@mui/material";
import PrintComprobante from "../Contabilidad/Diario/PrintComprobante";
const typos = {
    1:"Efectivo",
    7:"QR",
    4:"Transferencia",
    3:"Deposito",
    5:"Cheque",
    6:"Credito",
    200:"Hibrido",
    8:"Tarjeta",
    30:"GiftCard",
  }
const CashboxV2 =({box})=>{
    const [data,setData] = useState([])
    const loadBox = () => {
        getCashBoxDetv2({ id: box.id }).then((res) => {
          setData(res);
        });
      };
    useState(()=>{
        loadBox()
    },[])

    return(
        <div>
         {
            size(data) > 0 &&
            data.companias.map((row,key)=>
                <div>
                    {
                    size(data.data[row.id]) > 0 &&
                    <div>
                        <h3 className="font-bold uppercase">{row.name}</h3>
                        <p className="font-semibold mt-3">Ventas Facturadas</p>
                        {Object.entries(typos).map(([keyx, value]) => 
                        parseFloat(data.data[row.id]?.cf?.[keyx]) > 0 &&
                        (
                            <p> <span className="font-semibold text-gray-700">{value} : </span> { fNumber(data.data[row.id]?.cf?.[keyx]) }</p>
                            ))}
                            <p> <span className="font-semibold text-gray-700">Descuento : </span> { fNumber(data.data[row.id]?.cf?.discount) }</p>
                            <p> <span className="font-semibold text-gray-700">Costo de venta : </span> { fNumber(data.data[row.id]?.cf?.cost) }</p>
                            <div className="my-2">
                            <Divider/>
                            </div>
                            {
                                 
                                  <ModalComponent btn={"Comprobante CF "+ key+1} title={"Comprobante"} fullScreenx={true} color={verifyComprobante(data.comprobantes,3,row.id) ? "success":"error"} variant={"contained"}>
                                    {
                                    verifyComprobante(data.comprobantes,3,row.id) ?
                                  <PrintComprobante id={ getComprobante(data.comprobantes,3,row.id) }/> 
                                    :
                                  <DiaryRegisterV2
                                      type="ingreso"
                                      company_id={row.id}
                                      branch_id={"1"}
                                      model_type= "Cashbox"
                                      model_id= {box.id}
                                      transaccion={3}
                                      detail={
                                          <div>
                                               {Object.entries(typos).map(([keyx, value]) => 
                                                  parseFloat(data.data[row.id]?.cf?.[keyx]) > 0 &&
                                                  (
                                                      <p> <span className="font-semibold text-gray-700">{value} : </span> { fNumber(data.data[row.id]?.cf?.[keyx]) }</p>
                                                      ))}
                                                      <p> <span className="font-semibold text-gray-700">Descuento : </span> { fNumber(data.data[row.id]?.cf?.discount) }</p>
                                                      <p> <span className="font-semibold text-gray-700">Costo de venta : </span> { fNumber(data.data[row.id]?.cf?.cost) }</p>
                                          </div>
                                      }
                                      data= {{
                                          glosa: "Ingreso por Ventas Con Factura",
                                          date: data.encabezado?.f_cierre,
                                          total: 0,
                                      }}
                                      company= {row.name}
                                      store= {data.store.name}
                                      store_id= {data.store.id}
                                      reloadData= {loadBox}
                                      invoice={true}
                                      invoiceOption={true}
                                  />}
                                  </ModalComponent> 
                            }
                           
                        <div className="my-2">
                            <Divider/>
                            </div>
                        <p className="font-semibold mt-3">Ventas Sin Facturas</p>
                        {Object.entries(typos).map(([keyx, value]) => {
                            const sfValue = data.data[row.id]?.sf?.[keyx];
                            return (
                                sfValue && parseFloat(sfValue) > 0 && (
                                    <p key={keyx}> 
                                        <span className="font-semibold text-gray-700">{value} : </span> 
                                        { fNumber(sfValue) }
                                    </p>
                                )
                            );
                        })}
                            <p> <span className="font-semibold text-gray-700">Descuento : </span> { fNumber(data.data[row.id].sf?.discount) }</p>
                            <p> <span className="font-semibold text-gray-700">Costo de venta : </span> { fNumber(data.data[row.id].sf?.cost) }</p>
                           
                              <div className="my-2">
                            <Divider/>
                            </div>
                            <ModalComponent btn={"Comprobante SF "+ key+1} title={"Comprobante"} color={verifyComprobante(data.comprobantes,4,row.id) ? "success":"error"} fullScreenx={true} variant={"contained"} >
                          {
                          verifyComprobante(data.comprobantes,4,row.id) ?
                          <PrintComprobante id={ getComprobante(data.comprobantes,4,row.id) }/> 
                          :
                          <DiaryRegisterV2
                                type="ingreso"
                                company_id={row.id}
                                branch_id={"1"}
                                model_type= "Cashbox"
                                model_id= {box.id}
                                transaccion={4}
                                data= {{
                                    glosa: "Ingreso por Ventas Con Factura",
                                    date: row.encabezado?.f_cierre,
                                    total: 0
                                }}
                                detail={
                                    <div>
                                         {Object.entries(typos).map(([keyx, value]) => {
                                            const sfValue = data.data[row.id]?.sf?.[keyx];
                                            if (sfValue && parseFloat(sfValue) > 0) {
                                                return (
                                                    <p key={keyx}> 
                                                        <span className="font-semibold text-gray-700">{value} : </span> 
                                                        { fNumber(sfValue) }
                                                    </p>
                                                );
                                            }
                                            return null; // Otra opción: return un elemento nulo si no se cumple la condición
                                        })}
                                                                                        <p> <span className="font-semibold text-gray-700">Descuento : </span> { fNumber(data.data[row.id].sf?.discount) }</p>
                                                <p> <span className="font-semibold text-gray-700">Costo de venta : </span> { fNumber(data.data[row.id].sf?.cost) }</p>
                                    </div>
                                }
                                company= {row.name}
                                store= {data.store.name}
                                store_id= {data.store.id}
                                reloadData= {loadBox}
                                invoice={true}
                                invoiceOption={true}
                            />}
                        </ModalComponent> 
                        <div className="my-2">
                            <Divider/>
                            </div>
                    </div>
                    }
                    
                </div>
            )
         }
        </div>
    )
}

const verifyComprobante = (comprobante,type,company)=>{
    if(size(comprobante) === 0){
        return false;
    }
    const compr = find(comprobante, o=>{        
            return parseInt(o.tipo_transaccion_id) === type && parseInt(o.company_id) === parseInt(company);        
    })
    if(size(compr) > 0 ){
        return true
    }
    return false;
}
const getComprobante = (comprobante,type,company)=>{
    if(size(comprobante) === 0){
        return false;
    }
    const compr = find(comprobante, o=>{        
            return parseInt(o.tipo_transaccion_id) === type && parseInt(o.company_id) === parseInt(company);        
    })
   return compr.id;
}
export default CashboxV2