import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  TextareaAutosize,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { closeBox, getCashDetail } from "../../services/cashBox";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import Logo from "../../components/Logo";
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber";
import { dateConfig } from "../../utils/dateconfig";
import { size, sumBy } from "lodash";
import ModalComponent from "../../components/ModalComponent";
import SalesDetailPrint from "../../components/SalesDetailPrint";
import InvoicePrint from "./InvoicePrint";
import UploadDocument from "../../sections/uploadDocument/UploadDocument";
import GetAppIcon from "@mui/icons-material/GetApp";
import CashBoxDetail from "./CashBoxDetail";
import CashboxDetailBrand from "./CashboxDetailBrand";
import MoneyBoxCalcule from "./MoneyBoxCalcule";
import InvoicePrintV2 from "./InvoicePrintV2";
const typos = {
  1:"Efectivo",
  7:"QR",
  4:"Transferencia",
  3:"Deposito",
  5:"Cheque",
  6:"Credito",
  200:"Hibrido",
  8:"Tarjeta",
  30:"GiftCard",
}
const CashDetailV1 = ({ box, edit = true, reloadData, downloadRes }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [totalQr, setTotalQr] = useState(0);
  const [totalHibrido, setTotalHibrido] = useState(0);
  const [totalEfectivo, setTotalEfectivo] = useState(0);
  const [totalTb, setTotalTb] = useState(0);
  const [totalAnticipos, setTotalAnticipos] = useState({
    bs_c: 0,
    sus_c: 0,
    bs_b: 0,
    sus_b: 0,
  });
  const [totalUsoAnticipos, setTotalUsoAnticipos] = useState(0);
  const [totalDevAnticipos, setTotalDevAnticipos] = useState({ bs: 0, sus: 0 });
  const [totalSus, setTotalSus] = useState({ bs: 0, sus: 0 });
  const [totalCheque, setTotalCheque] = useState(0);
  const [totalTransferencia, setTotalTransferencia] = useState(0);
  const [totalDeposito, setTotalDeposito] = useState(0);
  const [totalCredito, setTotalCredito] = useState({ total: 0, saldo: 0 });
  const [totalCxc, setTotalCxc] = useState({
    bs_c: 0,
    sus_c: 0,
    bs_b: 0,
    sus_b: 0,
  });
  const [hibridoTotals,setHibridoTotals]= useState([])
  const [isCommitCash,setIsCommitCash] = useState(false)
  const [observation, setObservation] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleClose = () => {
    if(parseFloat(data.encabezado.efectivo) === 0){
      handleClickCloseBoxev()
    }
    reloadData();
    setOpen(false);
  };
  
  const loadBox = () => {
    getCashDetail({ id: box.id }).then((res) => {
      setData(res);
    });
  };
  useEffect(() => {
    if (open) {
      loadBox();
    }
  }, [open]);
  const handleChangeInfo = (e) => {
    setObservation(e.target.value);
  };
  const handleClickCloseBoxev = () => {
   
      closeBox({
        id: box.id,
        type: "v2",        
        efectivo: parseFloat(totalEfectivo) +
          parseFloat(totalAnticipos.bs_c) +
          parseFloat(totalCxc.bs_c) +
          parseFloat(totalCredito.total) -
          parseFloat(totalSus.bs),
        efectivo_sus: parseFloat(totalAnticipos.sus_c) +
          parseFloat(totalSus.sus),
        qr:totalQr,
        trans_bank:parseFloat(totalTransferencia) +
        parseFloat(totalAnticipos.bs_b) +
        parseFloat(totalCxc.bs_b),
        trans_bank_sus:totalAnticipos.sus_b,
        tarjeta:totalTb,
        deposito:totalDeposito,
        cheque:totalCheque,
      }).then((res) => {
        loadBox();
        toast.success("caja actualizada correctamente");
      });
   
  };
  
  const handleClickCloseBox = (bs,sus,obs) => {
  
    if (obs !== "") {
      closeBox({
        id: box.id,
        bs:bs,
        sus:sus,
        type: "v1",
        observation: obs,
        efectivo: parseFloat(totalEfectivo) +
          parseFloat(totalAnticipos.bs_c) +
          parseFloat(totalCxc.bs_c) +
          parseFloat(totalCredito.total) -
          parseFloat(totalSus.bs)+
          parseFloat(hibridoTotals[1]?.bs||0)
          ,
        efectivo_sus: parseFloat(totalAnticipos.sus_c) +
          parseFloat(totalSus.sus)+
          parseFloat(hibridoTotals[1]?.sus||0),
        qr:parseFloat(totalQr)+parseFloat(hibridoTotals[7]?.bs||0),
        trans_bank:parseFloat(totalTransferencia) +
        parseFloat(totalAnticipos.bs_b) +
        parseFloat(totalCxc.bs_b)+
        +parseFloat(hibridoTotals[4]?.bs||0)
        ,
        trans_bank_sus:totalAnticipos.sus_b,
        tarjeta:parseFloat(totalTb)+parseFloat(hibridoTotals[8]?.bs||0),
        deposito: parseFloat(totalDeposito)+parseFloat(hibridoTotals[3]?.bs||0),
        cheque: parseFloat(totalCheque) + parseFloat(hibridoTotals[5]?.bs||0),
      }).then((res) => {
        loadBox();
        toast.success("caja cerrada correctamente");
       setIsCommitCash(true)
      });
    } else {
      toast.error("Debe ingresar al menos una observacion!");
    }
   
  };
  const calcularTotales =(data)=>{
    const typeCurrencyTotals  = {};

    data.forEach(item => {
      item.infopay.forEach(infopay => {
        const type = infopay.type;
        const currency = infopay.currency_a;
        const monto = parseFloat(infopay.monto);
  
        if (!typeCurrencyTotals[type]) {
          typeCurrencyTotals[type] = {};
        }
        
        if (!typeCurrencyTotals[type][currency]) {
          typeCurrencyTotals[type][currency] = 0;
        }
  
        typeCurrencyTotals[type][currency] += monto;
      });
    });
    setHibridoTotals(typeCurrencyTotals)
  
  }
  
  useEffect(() => {
    size(data.qr) > 0 &&
      setTotalQr(
        sumBy(data.qr, (o) => {
          return parseFloat(o.total);
        })
      );
      size(data.hibrido)>0&&      
      setTotalHibrido(
        sumBy(data.hibrido, (o) => {
          return parseFloat(o.total);
        })
      );

      size(data.hibrido)>0&&      
        calcularTotales(data.hibrido)
      
    size(data.cheque) > 0 &&
      setTotalCheque(
        sumBy(data.cheque, (o) => {
          return parseFloat(o.total);
        })
      );
    size(data.transferencia) > 0 &&
      setTotalTransferencia(
        sumBy(data.transferencia, (o) => {
          return parseFloat(o.total);
        })
      );
    size(data.deposito) > 0 &&
      setTotalDeposito(
        sumBy(data.deposito, (o) => {
          return parseFloat(o.total);
        })
      );
    size(data.efectivo) > 0 &&
      setTotalEfectivo(
        sumBy(data.efectivo, (o) => {
          return parseFloat(o.total);
        })
      );
    size(data.bancos) > 0 &&
      setTotalTb(
        sumBy(data.bancos, (o) => {
          return parseFloat(o.total);
        })
      );
    size(data.anticipo) > 0 &&
      setTotalAnticipos({
        bs_c: sumBy(data.anticipo, (o) => {          
            return o.disponible === "caja" && o.currency === "bs"
              ? parseFloat(o.amount)
              : 0;
          
        }),
        sus_c: sumBy(data.anticipo, (o) => {
          return o.disponible === "caja" && o.currency === "sus"
            ? parseFloat(o.amount) / parseFloat(o.exchange_rate)
            : 0;
        }),
        bs_b: sumBy(data.anticipo, (o) => {
          if(!o.voucher_head){
          return o.disponible === "banco" && o.currency === "bs"
            ? parseFloat(o.amount)
            : 0;
          }else{
            return 0
          }
        }),
        sus_b: sumBy(data.anticipo, (o) => {
          if(!o.voucher_head){
          return o.disponible === "banco" && o.currency === "sus"
            ? parseFloat(o.amount) / parseFloat(o.exchange_rate)
            : 0;
          }else{
              return 0;
            }
        }),
      });
    size(data.uso_anticipo) > 0 &&
      setTotalUsoAnticipos(
        sumBy(data.uso_anticipo, (o) => {
          return o.voucher_head ? parseFloat(o.amount) : 0;
        })
      );
    size(data.uso_anticipo) > 0 &&
      setTotalDevAnticipos({
        bs: sumBy(data.uso_anticipo, (o) => {
          if (!o.voucher_head) {
            return o.currency === "bs" ? parseFloat(o.amount) : 0;
          }
          return 0;
        }),
        sus: sumBy(data.uso_anticipo, (o) => {
          if (!o.voucher_head) {
            return o.currency === "sus" ? parseFloat(o.amount) : 0;
          }
          return 0;
        }),
      });
    size(data.sus) > 0 &&
      setTotalSus({
        bs: sumBy(data.sus, (o) => {
          return parseFloat(o.origin_amount);
        }),
        sus: sumBy(data.sus, (o) => {
          return parseFloat(o.destination_amount);
        }),
      });
    size(data.credito) > 0 &&
      setTotalCredito({
        total: sumBy(data.credito, (o) => {
          return parseFloat(o.total);
        }),
        saldo: sumBy(data.credito, (o) => {
          return parseFloat(o.cuenta.amount);
        }),
      });
    size(data.cxc) > 0 &&
      setTotalCxc({
        bs_c: sumBy(data.cxc, (o) => {
          return o.available === "cash_register" && o.currency === "bs"
            ? parseFloat(o.amount)
            : 0;
        }),
        sus_c: sumBy(data.cxc, (o) => {
          return o.available === "cash_register" && o.currency === "sus"
            ? parseFloat(o.amount) / parseFloat(o.exchange_rate)
            : 0;
        }),
        bs_b: sumBy(data.cxc, (o) => {
          return o.available === "bank" && o.currency === "bs"
            ? parseFloat(o.amount)
            : 0;
        }),
        sus_b: sumBy(data.cxc, (o) => {
          return o.available === "bank" && o.currency === "sus"
            ? parseFloat(o.amount) / parseFloat(o.exchange_rate)
            : 0;
        }),
      });
  }, [data]);

  return (
    <div>
      <Tooltip title="Cerrar Caja" placement="top-start">
        <IconButton color="success" onClick={() => setOpen(true)}>
          <PointOfSaleIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Caja
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handlePrint}>
              <PrintIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent ref={componentRef}>
          {size(data) > 0 && (
            <div>
              <div className="grid grid-cols-8">
                <div className="col-span-2 ">
                  <Logo />
                </div>
                <div className="col-span-4 w-full flex items-center">
                  <h2 className="uppercase text-center w-full font-bold">
                    {"Caja"}
                  </h2>
                </div>
                <div className="col-span-2 text-sm">
                  <p className="text-right text-red-500 font-bold text-lg">
                    <span>Nro </span> {fInvoiceNumber(data.encabezado.nro)}
                  </p>
                  <p className="text-right text-red-500 font-bold">
                    <span> </span> {data.encabezado.store}
                  </p>
                </div>
                <div className="col-span-4 text-xs mt-3">
                  <p>
                    <span className="font-semibold">Responsable Caja:</span>{" "}
                    {data.encabezado.usuario}{" "}
                  </p>
                  <p>
                    <span className="font-semibold">Fecha y hr Apertura:</span>{" "}
                    {dateConfig(data.encabezado.f_apertura)}{" "}
                  </p>
                  <p>
                    <span className="font-semibold">Fecha y hr Cierre:</span>{" "}
                    {dateConfig(data.encabezado.f_cierre)}{" "}
                  </p>
                </div>
                <div className="col-span-4 text-xs"></div>
              </div>
              <div className="no-print py-3 inline-flex gap-2">
                <ModalComponent title={"Detalle "} btn={"Detalle por Facturador"} variant={"contained"}>
                  <CashBoxDetail cashbox={box.id} encabezado={data.encabezado}/>
                </ModalComponent>
                <ModalComponent title={"Detalle "} btn={"Detalle por Brand general"} variant={"contained"}>
                  <CashboxDetailBrand cashbox={box.id} encabezado={data.encabezado}/>
                </ModalComponent>
              </div>
              {size(data.efectivo) > 0 && (
                <TablePrintEfectivo
                  data={data.efectivo}
                  total={totalEfectivo}
                />
              )}
              {size(data.qr) > 0 && (
                <TablePrintTransfer
                  data={data.qr}
                  total={totalQr}
                  title="Ingreso por ventas con QR"
                />
              )}
              {size(data.hibrido) > 0 && (
                <Hibrido
                  data={data.hibrido}
                  total={totalHibrido}
                  totals ={hibridoTotals}
                  title="Ingreso por ventas con pagos Hibridos"
                />
              )}
              {size(data.transferencia) > 0 && (
                <TablePrintTransfer
                  data={data.transferencia}
                  total={totalTransferencia}
                  title="Ingreso por ventas con transferecia bancaria"
                />
              )}
              {size(data.deposito) > 0 && (
                <TablePrintTransfer
                  data={data.deposito}
                  total={totalDeposito}
                  title="Ingreso por ventas con deposito bancario"
                />
              )}
              {size(data.bancos) > 0 && (
                <TablePrintTransfer
                  data={data.bancos}
                  total={totalTb}
                  redEnlace={true}
                  title="Ingreso por ventas con Tarjeta Bancaria"
                />
              )}
              {size(data.cheque) > 0 && (
                <TablePrintTransfer
                  data={data.cheque}
                  total={totalCheque}
                  title="Ingreso por ventas con cheque"
                />
              )}
              {size(data.uso_anticipo) > 0 && (
                <TablePrintAnticipoUso
                  data={data.uso_anticipo}
                  total={totalUsoAnticipos}
                />
              )}
              {size(data.uso_anticipo) > 0 && (
                <TablePrintAnticipoDevolucion
                  data={data.uso_anticipo}
                  total={totalDevAnticipos}
                />
              )}
              {size(data.anticipo) > 0 && (
                <TablePrintAnticipo
                  data={data.anticipo}
                  total={totalAnticipos}
                />
              )}
              {size(data.credito) > 0 && (
                <TablePrintCredito data={data.credito} total={totalCredito} />
              )}
              {size(data.cxc) > 0 && (
                <TablePrintCxc data={data.cxc} total={totalCxc} />
              )}
              {size(data.sus) > 0 && (
                <TablePrintChangeMoney data={data.sus} total={totalSus} />
              )}
              <table className="table-print">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Bolivianos</th>
                    <th>Dolares americanos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold text-ms" colSpan={2}>
                      Total Ingresos 
                    </td>
                    <td className="font-bold text-ms">
                      {fNumber(
                          parseFloat(totalEfectivo) +
                          parseFloat(hibridoTotals[1]?.bs||0)+
                          parseFloat(hibridoTotals[2]?.bs||0)+
                          parseFloat(hibridoTotals[3]?.bs||0)+
                          parseFloat(hibridoTotals[4]?.bs||0)+
                          parseFloat(hibridoTotals[5]?.bs||0)+
                          parseFloat(hibridoTotals[6]?.bs||0)+
                          parseFloat(hibridoTotals[7]?.bs||0)+
                          parseFloat(hibridoTotals[8]?.bs||0)+                          
                          parseFloat(totalQr) +
                          parseFloat(totalTransferencia) +
                          parseFloat(totalDeposito) +
                          parseFloat(totalTb) +
                          parseFloat(totalCheque) +
                          parseFloat(totalAnticipos.bs_c) +
                          parseFloat(totalAnticipos.bs_b) +
                          parseFloat(totalCxc.bs_c) +
                          parseFloat(totalCxc.bs_b) +
                          parseFloat(totalCredito.total) -
                          parseFloat(totalSus.bs)
                      )}
                    </td>
                    <td className="font-bold text-ms">
                      {fNumber(
                        parseFloat(totalAnticipos.sus_c) +
                          parseFloat(totalAnticipos.sus_b) +
                          parseFloat(hibridoTotals[1]?.sus||0)+

                          parseFloat(totalSus.sus)
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>Efectivo</td>
                    <td>
                      {fNumber(parseFloat(totalEfectivo) +
                         parseFloat(hibridoTotals[1]?.bs||0)+
                        parseFloat(totalAnticipos.bs_c) +
                        parseFloat(totalCxc.bs_c) +
                        parseFloat(totalCredito.total) -
                        parseFloat(totalSus.bs))}
                    </td>
                    <td>
                      {" "}
                      {fNumber(
                        parseFloat(totalAnticipos.sus_c) +
                        hibridoTotals[1]?.sus||0 +
                        parseFloat(hibridoTotals[1]?.sus||0)+
                          parseFloat(totalSus.sus)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>QR</td>
                    <td>{fNumber(parseFloat(totalQr) + parseFloat(hibridoTotals[7]?.bs||0) )}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Transferencia bancaria</td>
                    <td>
                      {
                        fNumber(
                          parseFloat(totalTransferencia) +
                          parseFloat(totalAnticipos.bs_b) +
                          parseFloat(totalCxc.bs_b) +
                          parseFloat(hibridoTotals[4]?.bs||0))
                      }
                    </td>
                    <td>{parseFloat(totalAnticipos.sus_b)}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Tarjeta</td>
                    <td>{fNumber(parseFloat(totalTb)+parseFloat(hibridoTotals[8]?.bs||0))}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Deposito</td>
                    <td>{fNumber(parseFloat(totalDeposito)+parseFloat(hibridoTotals[3]?.bs||0))}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Cheque</td>
                    <td>{parseFloat(totalCheque)+parseFloat(hibridoTotals[5]?.bs||0)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div>
            {edit ? (
              <div className="p-2 mt-3">
                <ModalComponent btn="Cerrar Caja" title={"Cierre de Caja"} reload={()=>console.log(1)}>
                    <MoneyBoxCalcule handleClickCloseBox={handleClickCloseBox} efectivo={parseFloat(totalEfectivo) +
          parseFloat(totalAnticipos.bs_c) +
          parseFloat(totalCxc.bs_c) +
          parseFloat(hibridoTotals[1]?.bs||0)+
          parseFloat(totalCredito.total) -
          parseFloat(totalSus.bs)} efectivo_sus={parseFloat(totalAnticipos.sus_c) +
            parseFloat(totalSus.sus)} isCommitCash={isCommitCash} />
                </ModalComponent>             
              </div>
            ) : (
              <div className="p-2 mt-3">
                <div className="w-full p-2 border border-blue-300">
                  {data?.encabezado?.observacion}
                </div>
                <div className="w-full p-2 border border-blue-300 mt-3 ">
                  {size(data.encabezado?.voucher) > 0
                    ? data.encabezado.voucher.map((img, index) => (
                        <Button
                          size="small"
                          key={index}
                          onClick={() => downloadRes(img.id)}
                        >
                          <GetAppIcon size="small" />
                          {img.title}
                        </Button>
                      ))
                    : "Sin Respaldo"}
                  <UploadDocument
                    dataId={data.encabezado?.nro}
                    documents={loadBox}
                    type={"Cashbox"}
                  />
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const TablePrintCxc = ({ data, total }) => {
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={10} className="text-center">
            INGRESO POR CUENTAS POR COBRAR
          </th>
        </tr>
        <tr>
          <th rowSpan={2}>#</th>
          <th rowSpan={2}>Fecha y Hr</th>
          <th rowSpan={2}>Detalle</th>
          <th rowSpan={2}>Moneda</th>
          <th rowSpan={2}>Tipo Cambio</th>
          <th colSpan={2}>Efectivo</th>
          <th colSpan={3}>Banco</th>
        </tr>
        <tr>
          <th width="10%">total $us</th>
          <th width="10%">total Bs</th>
          <th width="10%">total $us</th>
          <th width="10%">total Bs</th>
          <th width="10%">Banco</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{dateConfig(row.created_at)}</td>
            <td>
              {row.accounts.detail}

              <InfoPay data={row?.infopay} info={row.adicional} />
            </td>
            <td className="text-right">{row.currency}</td>
            <td className="text-right">{fNumber(row.exchange_rate)}</td>
            {row.available === "cash_register" ? (
              <Fragment>
                <td className="text-right">
                  {" "}
                  {row.currency === "sus"
                    ? fNumber(
                        parseFloat(row.amount) / parseFloat(row.exchange_rate)
                      )
                    : 0}{" "}
                </td>
                <td className="text-right">
                  {" "}
                  {row.currency === "bs" ? fNumber(row.amount) : 0}{" "}
                </td>
              </Fragment>
            ) : (
              <Fragment>
                <td className="text-right"> 0 </td>
                <td className="text-right"> 0 </td>
              </Fragment>
            )}
            {row.available === "bank" ? (
              <Fragment>
                <td className="text-right">
                  {" "}
                  {row.currency === "sus"
                    ? fNumber(
                        parseFloat(row.amount) / parseFloat(row.exchange_rate)
                      )
                    : 0}{" "}
                </td>
                <td className="text-right">
                  {" "}
                  {row.currency === "bs" ? fNumber(row.amount) : 0}{" "}
                </td>
                <td className="text-right">
                  {row.bank?.bank + " " + row.bank?.account}
                </td>
              </Fragment>
            ) : (
              <Fragment>
                <td className="text-right"> 0 </td>
                <td className="text-right"> 0 </td>
                <td className="text-right"> - </td>
              </Fragment>
            )}
          </tr>
        ))}
        <tr>
          <th rowSpan={3} colSpan={5}>
            Total ingreso por cuentas por cobrar
          </th>
          <th colSpan={2}>Efectivo</th>
          <th colSpan={2}>Banco</th>
        </tr>
        <tr>
          <th>total $us</th>
          <th>total Bs</th>
          <th>total $us</th>
          <th>total Bs</th>
        </tr>
        <tr>
          <td className="text-right">{fNumber(total.sus_c)}</td>
          <td className="text-right">{fNumber(total.bs_c)}</td>
          <td className="text-right">{fNumber(total.sus_b)}</td>
          <td className="text-right">{fNumber(total.bs_b)}</td>
        </tr>
      </tbody>
    </table>
  );
};
const TablePrintCredito = ({ data, total }) => {
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={6}>ventas a credito</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Fecha y Hr</th>
          <th>Detalle</th>
          <th width="10%">Monto Nota Bs</th>
          <th width="10%">A cuenta bs</th>
          <th width="10%">Saldo credito bs</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{dateConfig(row.fecha)}</td>
            <td>
              <DocumentsPrint data={row} />
              <InfoPay data={row?.infopay} info={row.adicional} />
            </td>
            <td className="text-right">
              {fNumber(parseFloat(row.total) + parseFloat(row.cuenta.amount))}
            </td>
            <td className="text-right">{fNumber(row.total)}</td>
            <td className="text-right">{fNumber(row.cuenta.amount)}</td>
          </tr>
        ))}
        <tr>
          <th className="text-right" colSpan={4}>
            Total Bs
          </th>
          <td className="text-right font-bold">{fNumber(total.total)}</td>
          <td className="text-right font-bold">{fNumber(total.saldo)}</td>
        </tr>
      </tbody>
    </table>
  );
};
const Hibrido = ({ data, title, total, redEnlace=false,totals }) => {
  //console.log(totals);
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={7}>{title}</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Fecha y Hr</th>
          <th>Pagos</th>
          <th>Detalle</th>
          <th width="10%">Monto Nota Bs</th>
          <th width="10%">Descuento %</th>
          <th width="10%">Total Bs</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{dateConfig(row.fecha)}</td>
            <td>
              {
                row.infopay.map((val,key)=>
                <Fragment key={key}>
                  <InfoPays data={val} info={row.adicional} />
                </Fragment>  
                )
              }
              
            </td>
            <td>
              <DocumentsPrint data={row} />
            </td>
            <td className="text-right">{fNumber(row.monto)}</td>
            <td className="text-right">{fNumber(row.descuento)}</td>
            <td className="text-right">{fNumber(row.total)}</td>
          </tr>
        ))}
        <tr>
          <th className="text-right" colSpan={6}>
           {redEnlace?"Sun Total Bs":"Total Bs"}
          </th>
          <td className="text-right font-bold">{fNumber(total)}</td>
        </tr>
        <tr>
          <td colSpan={7}>
              <table className="w-full">
                <tbody>
                  <tr>
                  <td>Totales</td>
          {totals && 
          Object.keys(totals).map(type => (
          <td key={type}>
            <span className="font-bold"> {typos[parseInt(type)]}:</span>
            {Object.keys(totals[type]).map(currency => (
              <p key={currency}>
                <span className="font-bold">Moneda {currency}:</span> {totals[parseInt(type)][currency]}
              </p>
            ))}
          </td>
        ))
          }
                  </tr>
                </tbody>
              </table>
          </td>
        </tr>
      {redEnlace &&  
    <>
      <tr>
          <th className="text-right" colSpan={6}>
            Comision red enlace 1.8%
          </th>
          <td className="text-right font-bold bg-red-200">{fNumber(total*0.018)}</td>
        </tr>
        <tr>
          <th className="text-right" colSpan={6}>
           Total Bs
          </th>
          <td className="text-right font-bold">{fNumber(total-(total*0.018))}</td>
        </tr>
       
    </>
        }
      </tbody>
    </table>
  );
};
const TablePrintTransfer = ({ data, title, total, redEnlace=false }) => {
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={7}>{title}</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Fecha y Hr</th>
          <th>Banco</th>
          <th>Detalle</th>
          <th width="10%">Monto Nota Bs</th>
          <th width="10%">Descuento %</th>
          <th width="10%">Total Bs</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{dateConfig(row.fecha)}</td>
            <td>
              {row.banco?.name + " - " + row.banco?.account}
              <InfoPay data={row?.infopay} info={row.adicional} />
            </td>
            <td>
              <DocumentsPrint data={row} />
            </td>
            <td className="text-right">{fNumber(row.monto)}</td>
            <td className="text-right">{fNumber(row.descuento)}</td>
            <td className="text-right">{fNumber(row.total)}</td>
          </tr>
        ))}
        <tr>
          <th className="text-right" colSpan={6}>
           {redEnlace?"Sun Total Bs":"Total Bs"}
          </th>
          <td className="text-right font-bold">{fNumber(total)}</td>
        </tr>
      {redEnlace &&  
    <>
      <tr>
          <th className="text-right" colSpan={6}>
            Comision red enlace 1.8%
          </th>
          <td className="text-right font-bold bg-red-200">{fNumber(total*0.018)}</td>
        </tr>
        <tr>
          <th className="text-right" colSpan={6}>
           Total Bs
          </th>
          <td className="text-right font-bold">{fNumber(total-(total*0.018))}</td>
        </tr>
    </>
        }
      </tbody>
    </table>
  );
};
const TablePrintAnticipoUso = ({ data, total }) => {
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={4}>Venta con anticipos</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Fecha y Hr</th>
          <th>Detalle</th>
          <th width="10%">Monto Bs</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          (row, key) =>
            row.voucher_head && (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{dateConfig(row.created_at)}</td>
                {row.voucher_head ? (
                  <td>
                    <DocumentsPrint data={row.voucher_head} />
                  </td>
                ) : (
                  <td>{row.observation.observation}</td>
                )}
                <td className="text-right">
                  {row.currency === "bs" ? fNumber(row.amount) : fNumber(0)}
                </td>
              </tr>
            )
        )}
        <tr>
          <th className="text-right" colSpan={3}>
            Total
          </th>
          <td className="text-right font-bold">{fNumber(total)}</td>
        </tr>
      </tbody>
    </table>
  );
};
const TablePrintAnticipoDevolucion = ({ data, total }) => {
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={5}>Uso de anticipos</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Fecha y Hr</th>
          <th>Detalle</th>
          <th width="10%">Monto Bs</th>
          <th width="10%">Monto Sus</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          (row, key) =>
            !row.voucher_head && (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{dateConfig(row.created_at)}</td>
                {row.voucher_head ? (
                  <td>
                    {"Nota No. " +
                      fInvoiceNumber(row.voucher_head.voucher_number)}
                    <DocumentsPrint data={row.voucher_head} />
                  </td>
                ) : (
                  <td>
                    <InfoPay
                      data={row?.infopay}
                      info={row.observation?.observation}
                    />
                  </td>
                )}
                <td className="text-right">
                  {row.currency === "bs" ? fNumber(row.amount) : fNumber(0)}
                </td>
                <td className="text-right">
                  {row.currency === "sus" ? fNumber(row.amount) : fNumber(0)}
                </td>
              </tr>
            )
        )}
        <tr>
          <th className="text-right" colSpan={3}>
            Total
          </th>
          <td className="text-right font-bold">{fNumber(total.bs)}</td>
          <td className="text-right font-bold">{fNumber(total.sus)}</td>
        </tr>
      </tbody>
    </table>
  );
};
const TablePrintEfectivo = ({ data, total }) => {
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={6}>ingreso por ventas en efectivo</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Fecha y Hr</th>
          <th>Detalle</th>
          <th width="10%">Monto Nota Bs</th>
          <th width="10%">Descuento %</th>
          <th width="10%">Total Bs</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{dateConfig(row.fecha)}</td>
            <td>
              <DocumentsPrint data={row} />
            </td>
            <td className="text-right">{fNumber(row.monto)}</td>
            <td className="text-right">{fNumber(row.descuento)}</td>
            <td className="text-right">{fNumber(row.total)}</td>
          </tr>
        ))}
        <tr>
          <th className="text-right" colSpan={5}>
            Total Bs
          </th>
          <td className="text-right font-bold">{fNumber(total)}</td>
        </tr>
      </tbody>
    </table>
  );
};

const TablePrintAnticipo = ({ data, total }) => {
  return (
    <table className="table-print">
      <thead>
        <tr>
          <th colSpan={10} className="text-center">
            INGRESO POR ANTICIPOS
          </th>
        </tr>
        <tr>
          <th rowSpan={2}>#</th>
          <th rowSpan={2}>Fecha y Hr</th>
          <th rowSpan={2}>Detalle</th>
          <th rowSpan={2}>Moneda</th>
          <th rowSpan={2}>Tipo Cambio</th>
          <th colSpan={2}>Efectivo</th>
          <th colSpan={3}>Banco</th>
        </tr>
        <tr>
          <th width="10%">total $us</th>
          <th width="10%">total Bs</th>
          <th width="10%">total $us</th>
          <th width="10%">total Bs</th>
          <th width="10%">Banco</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{dateConfig(row.created_at)}</td>
            <td>
              {"Anticipo cliente: " + row.client.business_name}
              <InfoPay data={row?.infopay} info={row.adicional} />
            </td>
            <td className="text-right">{row.currency}</td>
            <td className="text-right">{fNumber(row.exchange_rate)}</td>
            {row.disponible === "caja" ? (
              <Fragment>
                <td className="text-right">
                  {" "}
                  {row.currency === "sus"
                    ? fNumber(
                        parseFloat(row.amount) / parseFloat(row.exchange_rate)
                      )
                    : 0}{" "}
                </td>
                <td className="text-right">
                  {" "}
                  {row.currency === "bs" ? fNumber(row.amount) : 0}{" "}
                </td>
              </Fragment>
            ) : (
              <Fragment>
                <td className="text-right"> 0 </td>
                <td className="text-right"> 0 </td>
              </Fragment>
            )}
            {row.disponible === "banco" ? (
              <Fragment>
                <td className="text-right">
                  {" "}
                  {row.currency === "sus"
                    ? fNumber(
                        parseFloat(row.amount) / parseFloat(row.exchange_rate)
                      )
                    : 0}{" "}
                </td>
                <td className="text-right">
                  {" "}
                  {row.currency === "bs" ? fNumber(row.amount) : 0}{" "}
                </td>
                <td className="text-right">
                  {row.bank?.bank + " " + row.bank?.account}
                </td>
              </Fragment>
            ) : (
              <Fragment>
                <td className="text-right"> 0 </td>
                <td className="text-right"> 0 </td>
                <td className="text-right"> - </td>
              </Fragment>
            )}
          </tr>
        ))}
        <tr>
          <th rowSpan={3} colSpan={5}>
            Total ingreso por anticipos
          </th>
          <th colSpan={2}>Efectivo</th>
          <th colSpan={2}>Banco</th>
        </tr>
        <tr>
          <th>total $us</th>
          <th>total Bs</th>
          <th>total $us</th>
          <th>total Bs</th>
        </tr>
        <tr>
          <td className="text-right">{fNumber(total.sus_c)}</td>
          <td className="text-right">{fNumber(total.bs_c)}</td>
          <td className="text-right">{fNumber(total.sus_b)}</td>
          <td className="text-right">{fNumber(total.bs_b)}</td>
        </tr>
      </tbody>
    </table>
  );
};
const TablePrintChangeMoney = ({ data, total }) => {
  return (
    <table className="table-print-3">
      <thead>
        <tr>
          <th colSpan={6}>Compra de Dolares</th>
        </tr>
        <tr>
          <th>#</th>
          <th>fecha y hr</th>
          <th>cliente</th>
          <th width="10%">Salida Bs</th>
          <th width="10%">Tipo de cambio</th>
          <th width="10%">Ingreso Sus</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, key) => (
          <tr key={key}>
            <td>{key + 1}</td>
            <td>{dateConfig(row.created_at)}</td>
            <td>{row.client.business_name}</td>
            <td className="text-right">{fNumber(row.origin_amount)}</td>
            <td className="text-right">{fNumber(row.exchange_rate)}</td>
            <td className="text-right">{fNumber(row.destination_amount)}</td>
          </tr>
        ))}
        <tr>
          <th colSpan={3} className="text-right">
            Total Bs
          </th>
          <td className="text-right font-bold">-{fNumber(total.bs)}</td>
          <th className="text-right">Total Sus</th>

          <td className="text-right font-bold">{fNumber(total.sus)}</td>
        </tr>
      </tbody>
    </table>
  );
};
const DocumentsPrint = ({ data }) => {
  return (
    <div className=" flex gap-2">
      <div>
        <ModalComponent
          btn={"NOTA NO. " + fInvoiceNumber(data.nota || data.voucher_number)}
        >
          <SalesDetailPrint
            data={{ id: data.nota_id || data.id }}
            titulo="Orden de Pedido"
            subtitle="Original"
          />
        </ModalComponent>
      </div>
      {size(data?.invoices)> 0 && data.invoices.map((row, key) => (
        <div key={key}>
          <ModalComponent btn={"F. - " + row.company+" "+row.invoice_number}>
            { parseInt(row.version) === 1 ?
              <InvoicePrint doc="factura" id={row.id} />
            :
            <InvoicePrintV2 doc="facturav2" id={row.id} />
            }

          </ModalComponent>
        </div>
      ))}
    </div>
  );
};
const InfoPay = ({ data, info }) => {
  return (
    <div>
      {info && (
        <p className="text-gray-600 text-xs italic">
          <span className="font-bold">Información Adicional: </span> {info}{" "}
        </p>
      )}
      {size(data) > 0 && (
        <Fragment>
          {data.origin_bank && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Banco de origen: </span>{" "}
              {data.origin_bank}
            </p>
          )}
          {data.owner_name && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Nombre Titular: </span>
              {data.owner_name}
            </p>
          )}
          {data.paid_by && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Transaccion realizada por: </span>
              {data.paid_by}
            </p>
          )}
          {data.voucher_number && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Nro Voucher / Cheque: </span>
              {data.voucher_number}
            </p>
          )}
        </Fragment>
      )}
    </div>
  );
};
const InfoPays = ({ data, info }) => {
  return (
    <div>
      {info && (
        <p className="text-gray-600 text-xs italic">
          <span className="font-bold">Información Adicional: </span> {info}{" "}
        </p>
      )}
      {size(data) > 0 && (
        <Fragment>
          {data.type && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Tipo: </span>{" "}
              {typos[data.type]} {" "} {data.currency_a === "sus"?" $us":" Bs"}
            </p>
          )}
          {data.origin_bank && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Banco de origen: </span>{" "}
              {data.origin_bank}
            </p>
          )}
          {data.owner_name && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Nombre Titular: </span>
              {data.owner_name}
            </p>
          )}
          {data.paid_by && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Transaccion realizada por: </span>
              {data.paid_by}
            </p>
          )}
          {data.voucher_number && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Nro Voucher / Cheque: </span>
              {data.voucher_number}
            </p>
          )}
          {data.monto && (
            <p className="text-gray-600 text-xs italic">
              <span className="font-bold">Monto {data.currency_a === "sus"&&" $us"}: </span>
              {fNumber(parseFloat(data.monto)*parseFloat(data.exchange_rate))}
            </p>
          )}
          <Divider/>
        </Fragment>
      )}
    </div>
  );
};
export default CashDetailV1;
