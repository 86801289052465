import API from "../axios/api"

export const setTransfer = async (val)=>{
    const {data} = await API.post('api/transfer/transfer',val)
    return data
}
export const getTransfer = async (val)=>{
    const {data} = await API.post('api/requerimiento',val)
    return data
}
export const getTransferHead = async (val)=>{
    const {data} = await API.post('api/transfer',val)
    return data
}
export const getTransferReturn = async (val)=>{
    const {data} = await API.post('api/return-stock',val)
    return data
}
export const getStoreList = async ()=>{
    const {data} = await API.get('api/transfer/store-list')
    return data
}
export const transferItems = async (val)=>{
    const {data} = await API.post('api/transfer/set-items',val)
    return data
}
export const receptionGenerate = async (val)=>{
    const {data} = await API.post('api/parte/generar',val)
    return data
}
export const receptionGenerateReturn = async (val)=>{
    const {data} = await API.post('api/parte/devolucion',val)
    return data
}
export const setStateTransfer = async (val)=>{
    const {data} = await API.post('api/transfer/set-state',val)
    return data
}
export const setDeclineTransfer = async (val)=>{
    const {data} = await API.post('api/transfer/decline',val)
    return data
}
export const setAprobeTransfer = async (val)=>{
    const {data} = await API.post('api/transfer/aprobe',val)
    return data
}

export const returnStock = async (val)=>{
    const {data} = await API.post('api/return-stock/register',val)
    return data
}

