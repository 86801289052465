import { useEffect, useState } from 'react'

import './diario.css'
import { getComprobante } from '../../../services/conta'
import { dateConfig, dateConfigBirth, dateConfigNumber } from '../../../utils/dateconfig'
import { size, sumBy } from 'lodash'
import { fInvoiceNumber, fNumber } from '../../../utils/formatNumber'
import Encabezados from '../../../sections/contabilidad/documentPrint/Encabezados'
import { InvoicePrint } from '../../CashBox'
import InvoicePrintV2 from '../../CashBox/InvoicePrintV2'
import ModalComponent from '../../../components/ModalComponent'
import RegistroCompra from '../RegistroCompra/RegistroCompra'
const PrintComprobante=({id})=>{
    const [data,setDataPrint] = useState({})
    const loadData=()=>{
        getComprobante(id).then(res=>{
            setDataPrint(res)
        })
    }
    useEffect(()=>{
        loadData()
    },[])
    if(size(data) == 0){
        return ""
    }
    return(
        <div className='diario'>
         
    <Encabezados 
    data={data}
    title="libro diario"
    subtitle={"COMPROBANTE DE "+data.type}
    company={data.company.name}
    branch={data.z_company_branch.sucursal}
    nit={data.company.nit}
    nro={data.code+" "+dateConfigNumber(data.number,data.date)}
    />
    <table className="mt-2 w-full" >
    <thead>
      <tr>
        <td className="font-bold" style={{ width: '18%' }}>
          FECHA:
        </td>
        <td>{dateConfigBirth(data.date)}</td>
        <td style={{ width: '40%', textAlign: 'right', fontWeight: 'bold' }}>
          UFV:
        </td>
        <td>{data.ufv}</td>
      </tr>
      <tr>
        <td style={{ width: '18%', fontWeight: 'bold' }}>ORIGEN:</td>
        <td>
          <div className='inline-flex'>
          
        <ModalComponent btn={data.tipo_transaccion.key+" - "+data.comprobante?fInvoiceNumber(data.comprobante?.voucher_number):fInvoiceNumber(data.comprobante?.number)} title={"Documento Adjunto"} reload={console.log("")}>
        <Comprobante comprobante={data.comprobante} type={data.type}/>
        </ModalComponent>
          </div>
        </td>
        <td></td>
        <td></td>
      </tr>
    { size(data.factura) > 0 &&  <tr>
        <td style={{ width: '18%', fontWeight: 'bold' }}>FACTURA:</td>
        <td>
          <div className='inline-flex'>
          
        <ModalComponent btn={fInvoiceNumber(data.factura?.numero_factura)} title={"Documento Adjunto"} reload={console.log("")}>
        <RegistroCompra factura={data.factura} form={true} />
        </ModalComponent>
          </div>
        </td>
        <td></td>
        <td></td>
      </tr>}
      <tr>
        <td style={{ width: '18%', fontWeight: 'bold' }}>ELABORADO:</td>
        <td>{dateConfig(data.created_at)}</td>
        <td style={{ width: '40%', textAlign: 'right', fontWeight: 'bold' }}> T.C.:</td>
        <td>{data.exchange_rate}</td>
      </tr>
    </thead>
    </table>
    <table className="border border-gray-400 bg-white mt-2 rounded-lg overflow-hidden w-full shadow-md ">
        <thead>
          <tr>
            <th className='p-2 text-left font-bold'>No</th>
            <th className='p-2 text-left font-bold'>CUENTA</th>
            <th className='p-2 text-left font-bold'>DESCRIPCION DE TRANSACCION</th>
            <th className='p-2 text-left font-bold'>DEBE</th>
            <th className='p-2 text-left font-bold'>HABER</th>
          </tr>
        </thead>
        <tbody>
         {
            data.detail.map((row,key)=>
            <tr>
            <td className=' text-left border-b border-gray-300 p-2'>{key+1}</td>
            <td className=' text-left border-b border-gray-300 p-2'><span className='font-semibold'>{row.z_accounts.codigo}</span> <span>{row.z_accounts.name}</span></td>
            <td className=' text-left border-b border-gray-300 p-2'><span className='font-semibold'>Glosa:</span><span>{row.comment}</span></td>
            <td className=' text-right border-b border-gray-300 p-2'>{fNumber(row.debe)}</td>
            <td className=' text-right border-b border-gray-300 p-2'>{fNumber(row.haber)}</td>
          </tr>
            )
         }
          <tr>
            <td className='text-left border-b border-gray-300 p-2' colSpan="3" style={{ fontWeight: 'bold', textAlign: 'right' }}>
              Total:
            </td>
            <td className='text-right border-b border-gray-300 p-2 font-semibold'>{fNumber(sumBy(data.detail,o=>{
                return parseFloat(o.debe)
            }))}</td>
            <td className='text-right border-b border-gray-300 p-2 font-semibold'>{fNumber(sumBy(data.detail,o=>{
                return parseFloat(o.haber)
            }))}</td>
            
          </tr>
          <tr>
            <td colSpan={5}>
              <div className='p-2'>
                <p className='font-bold'>Glosa: </p>
                <p className='pl-3'>{data.comment}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table className="w-full text-center mt-16">
        <tbody>
          <tr>
            <th className='border-t border-gray-300 p-2 ' style={{ borderTop: 'none' }}></th>
            <th className='border-t border-gray-300 p-2 ' style={{ width: '20%' }}>REALIZADO POR</th>
            <th className='border-t border-gray-300 p-2 ' style={{ borderTop: 'none' }}></th>
            <th className='border-t border-gray-300 p-2 ' style={{ width: '20%' }}>CONTABILIDAD</th>
            <th className='border-t border-gray-300 p-2 ' style={{ borderTop: 'none' }}></th>            
            <th className='border-t border-gray-300 p-2 ' style={{ width: '20%' }}>GERENCIA</th>
            <th className='border-t border-gray-300 p-2 ' style={{ borderTop: 'none' }}></th>
          </tr>
        </tbody>
      </table>

        </div>
    )
}    

const Comprobante = ({comprobante,type})=>{
  console.log(comprobante);
  if(size(comprobante) === 0){
    return <div>EL comprobante no tiene respaldo</div>
  }
  if(type === "ingreso"){
    
     if(parseInt(comprobante.version) === 1){
       return  <InvoicePrint doc="factura" id={comprobante.id}/>

     }else{
     return  <InvoicePrintV2 doc="facturav2" id={comprobante.id}/>
     }
    }
  if(type === "egreso"){
    return <div>sa</div>
  }
 


}

export default PrintComprobante