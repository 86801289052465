import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { size } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { fNumber } from "../../utils/formatNumber";
const validationSchema = yup.object({
  aditional: yup.string().required("Ingrese este campo"),
});
const TarjetaTransferencia = ({
  data,
  setOpen,
  saveToPay,
  voucher_id,
  title,
  type,
  origin_bank = false,
  bank_id = false,
  destination_bank = false,
  voucher_number = false,
  owner_name = false,
}) => {
  const userData = useSelector((state) => state.userData);

  const formik = useFormik({
    initialValues: {
      a_cuenta: "",
      aditional: "",
      bank: "",
      type: type,
      origin_bank: "",
      bank_id: "",
      destination_bank: "",
      voucher_number: "",
      owner_name: "",
      paid_by:""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      /*   if (size(cart) <= 0) {
                toast.error("Debe seleccionar al menos un item en su carrito!");
                return;
              } */
      if (size(data.client) <= 0) {
        toast.error("Registre datos de cliente!");
        return;
      }
      saveToPay(values);
    },
  });
  return (
    <div>
      <div className="flex flex-col items-center ">
        <p className="text-lg font-semibold">TOTAL Bs:</p>
        <p className="text-4xl font-semibold text-gray-500">
          {fNumber(data.total)}
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          value={formik.values.aditional}
          onChange={formik.handleChange}
          name="aditional"
          fullWidth
          sx={{ my: 1 }}
          label="información de transacción"
          multiline={true}
          variant="outlined"
          size="small"
        />
        {voucher_number && (
          <TextField
            value={formik.values.voucher_number}
            onChange={formik.handleChange}
            name="voucher_number"
            fullWidth
            sx={{ my: 1 }}
            label={title==="cheque"?"Numero de Cheque":"Nro de transaccion / Voucher"}
            variant="outlined"
            size="small"
          />
        )}

        {origin_bank && (
          <TextField
            value={formik.values.origin_bank}
            onChange={formik.handleChange}
            name="origin_bank"
            fullWidth
            sx={{ my: 1 }}
            label="Banco de origen"
            variant="outlined"
            size="small"
          />
        )}
        {owner_name && (
          <TextField
            value={formik.values.owner_name}
            onChange={formik.handleChange}
            name="owner_name"
            fullWidth
            sx={{ my: 1 }}
            label="Nombre titular"
            variant="outlined"
            size="small"
          />
        )}
     
        {destination_bank && (
          <FormControl fullWidth>
            <InputLabel id="bank">Banco de destino</InputLabel>
            <Select
              labelId="bank"
              className="col-span-2"
              name="bank"
              value={formik.values.bank}
              onChange={formik.handleChange}
              size="small"
              sx={{ my: 1 }}
            >
              {userData.bank.map((row, ke) => (
                <MenuItem key={ke + "-bank"} value={row.id}>
                  {row.name + " - " + row.account}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
           <TextField
            value={formik.values.paid_by}
            onChange={formik.handleChange}
            name="paid_by"
            fullWidth
            sx={{ my: 1 }}
            label="Transaccion realizada por"
            variant="outlined"
            size="small"
          />

        <Button variant="contained" type="submit">
          Registrar Venta
        </Button>
      </form>
    </div>
  );
};
export default TarjetaTransferencia;
