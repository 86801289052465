import { AppBar, Box, Button, Dialog, DialogContent, IconButton, TextareaAutosize, Toolbar, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from "react-to-print";
import { useTheme } from "@mui/material/styles";
import { closeBox, closeCash, getCash, getCashDetail } from "../../services/cashBox";
import { useEffect } from "react";
import { size, sumBy } from "lodash";
import { fInvoiceNumber, fNumber } from "../../utils/formatNumber";
import Logo from "../../components/Logo";
import { dateConfig } from "../../utils/dateconfig";
import Swal from "sweetalert2";

const CashDetail = ({box,edit=true}) => {
    const [open,setOpen] = useState(false)
    const [data,setData] = useState([])
    const [observation,setObservation] = useState("")
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: ()=>componentRef.current,
      })
      const handleClose = () => {
        setOpen(false)
      };
    const loadBox=()=>{
      getCashDetail({id:box.id}).then(res=>{
        setData(res)
      })
    }
    useEffect(()=>{
      if(open){
        loadBox()
      }
    },[open])
    const handleChangeInfo=(e)=>{
      setObservation(e.target.value)
    }
    const handleClickCloseBox=()=>{
      if(observation !== ""){
        closeBox({
          id: box.id,
          observation: observation
        }).then(res=>{
          window.location.reload()
        })
      }else{
        Swal.fire(
          "Debe ingrsar al menos una observacion!",
        );
      }
    }
  return (
    <div>
      <Tooltip title="Cerrar Caja" placement="top-start">
        <IconButton color="success" onClick={()=>setOpen(true)}>
          <PointOfSaleIcon />
        </IconButton>
      </Tooltip>

      <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      scroll="paper"
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Caja
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handlePrint}
          >
            <PrintIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent ref={componentRef} >
        {size(data)>0 &&<div>
        <div className="grid grid-cols-8">
            <div className="col-span-2 ">
              <Logo />
            </div>
            <div className="col-span-4 w-full flex items-center">
              <h2 className="uppercase text-center w-full font-bold">
                { "Caja"}
              </h2>
            </div>
            <div className="col-span-2 text-sm">             
              <p className="text-right text-red-500 font-bold text-lg">
                <span>Nro </span> {fInvoiceNumber(data.id)}
              </p>
              <p className="text-right text-red-500 font-bold">
                <span> </span> {data.store.name}
              </p>
            </div>
            <div className="col-span-4 text-xs mt-3">
              <p>
                <span className="font-semibold">Usuario Caja:</span>{" "}
                {data.user.name}{" "}
              </p>
              <p>
                <span className="font-semibold">Fecha y hr Apertura:</span>{" "}
                {dateConfig(data.created_at)}{" "}
              </p>
              <p>
                <span className="font-semibold">Fecha y hr Cierre:</span>{" "}
                {data?.close_date && dateConfig(data.close_date)}{" "}
              </p>
              <p>
                <span className="font-semibold">Usuario Caja:</span>{" "}
                {data.user.name}{" "}
              </p>
             
            </div>
            <div className="col-span-4 text-xs"></div>
          </div>
          <table className="table-print">
              <thead>
                <tr>
                  <th colSpan={10} className="text-center">INGRESO POR VENTAS</th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Fecha y Hr</th>
                  <th>Detalle</th>
                  <th>Efectivo</th>
                  <th>Credito</th>
                  <th>Anticipo</th>
                  <th>Banco</th>
                  <th>sub total Bs</th>
                  <th>descuento </th>
                  <th>total Bs</th>
                </tr>
              </thead>
              <tbody>
                {
                  size(data) > 0 &&
                  data.detail.map((row,key)=>
                  <tr key={key}>
                    <td>{key+1}</td>
                    <td>{dateConfig(row.created_at)}</td>
                    <td>{" Nota Nro "+fInvoiceNumber(row.voucher_head.voucher_number)}</td>
                    <td className="bg-green-200" > {row.payment_type+"" === "1" ? formaPago(row.payment_type)
                    : "-" }
                    </td>
                    <td className="bg-yellow-200" > {row.payment_type+"" === "6" ? formaPago(row.payment_type)
                    : "-" }
                    </td>
                    <td className="bg-blue-200" > {row.payment_type+"" === "0" ? formaPago(row.payment_type)
                    : "-" }
                    </td>
                    <td className="bg-indigo-200"> {row.payment_type+"" === "7" ? formaPago(row.payment_type)
                    : "-"}</td>
                    <td className="text-right">{row.price}</td>
                    <td className="text-right">{row.discount}</td>
                    <td className="text-right">{row.total}</td>
                  </tr>
                  )
                }
                 <tr>
                  <th rowSpan={2} colSpan={3} className="text-right">Totales Ingreso por ventas Bs</th>
                  <th>Efectivo</th>
                  <th>Credito</th>
                  <th>Anticipo</th>
                  <th>Banco</th>
                  <th>total Bs</th>
                </tr>
               <tr>
             
                <td className="text-right">
                {fNumber(sumBy(data.detail, o=>{
                  return o.payment_type+"" === "1"? parseFloat(o.total) : 0
                }))}
                </td>
                <td className="text-right">
                {fNumber(sumBy(data.detail, o=>{
                  return o.payment_type+"" === "6"? parseFloat(o.total) : 0
                }))}
                </td>
                <td className="text-right">
                {fNumber(sumBy(data.detail, o=>{
                  return o.payment_type+"" === "0"? parseFloat(o.total) : 0
                }))}
                </td>
                <td className="text-right">
                {fNumber(sumBy(data.detail, o=>{
                  return o.payment_type+"" === "7"? parseFloat(o.total) : 0
                }))}
                </td>
               
                <td className="text-right">
                {fNumber(sumBy(data.detail, o=>{
                  return  o.payment_type+"" !== "0"? parseFloat(o.total) : 0
                }))}
                </td>
               </tr>
           
              </tbody>
          </table>
        {size(data.anticipo) > 0 &&  <table className="table-print">
              <thead>
              <tr>
                  <th colSpan={9} className="text-center">INGRESO POR ANTICIPOS</th>
                </tr>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th rowSpan={2}>Fecha y Hr</th>
                  <th rowSpan={2}>Detalle</th>
                  <th rowSpan={2}>Moneda</th>
                  <th rowSpan={2}>Tipo Cambio</th>
                  <th colSpan={2}>Efectivo</th>
                  <th colSpan={2}>Banco</th>
                </tr>
                <tr>
                <th >total $us</th>
                <th >total Bs</th>
                <th >total $us</th>
                <th >total Bs</th>
                </tr>
              </thead>
              <tbody>
              {                  
                  data.anticipo.map((row,key)=>
                    <tr key={key}>
                       <td>{key+1}</td>
                      <td>{dateConfig(row.created_at)}</td>
                      <td>Anticipo cliente: {row.client.business_name}</td>
                      <td>{row.currency}</td>
                      <td>{fNumber(row.exchange_rate)}</td>
                      {
                        row.disponible === "caja"?
                        <Fragment>
                          <td> {row.currency === "sus"? fNumber(parseFloat(row.amount) / parseFloat(row.exchange_rate)) :0 } </td>
                          <td> {row.currency === "bs"? fNumber(row.amount) : 0  } </td>
                        </Fragment>
                        :
                        <Fragment>
                          <td> 0 </td>
                          <td> 0 </td>
                        </Fragment>
                      }
                      {
                        row.disponible === "banco"?
                        <Fragment>
                          <td> {row.currency === "sus"? fNumber(parseFloat(row.amount)/parseFloat(row.exchange_rate)) :0 } </td>
                          <td> {row.currency === "bs"? fNumber(row.amount) : 0  } </td>
                        </Fragment>
                        :
                        <Fragment>
                          <td> 0 </td>
                          <td> 0 </td>
                        </Fragment>
                      }
                   
                      
                    </tr>   
                  )
              }
                <tr>
                  <th rowSpan={3} colSpan={5}>Total ingreso por anticipos</th>
                  <th colSpan={2}>Efectivo</th>
                  <th colSpan={2}>Banco</th>
                </tr>
                <tr>
                <th >total $us</th>
                <th >total Bs</th>
                <th >total $us</th>
                <th >total Bs</th>
                </tr>
                <tr>
                  <td>{fNumber(sumBy(data.anticipo,o=>{
                    return o.disponible === "caja" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  }))}</td>
                  <td>{fNumber(sumBy(data.anticipo,o=>{
                    return o.disponible === "caja" && o.currency === "bs" ? parseFloat(o.amount): 0
                  }))}</td>
                  <td>{fNumber(sumBy(data.anticipo,o=>{
                    return o.disponible === "banco" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  }))}</td>
                  <td>{fNumber(sumBy(data.anticipo,o=>{
                    return o.disponible === "banco" && o.currency === "bs" ? parseFloat(o.amount): 0
                  }))}</td>
                
                
                </tr>
              </tbody>
              </table>}
        {size(data.anticipo_use) > 0 &&  <table className="table-print-3">
              <thead>
              <tr>
                  <th colSpan={9} className="text-center">SALIDA DE ANTICIPOS</th>
                </tr>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th rowSpan={2}>Fecha y Hr</th>
                  <th rowSpan={2}>Detalle</th>
                  <th rowSpan={2}>Moneda</th>
                  <th rowSpan={2}>Tipo Cambio</th>
                  <th colSpan={2}>Efectivo</th>
                  <th colSpan={2}>Banco</th>
                </tr>
                <tr>
                <th >total $us</th>
                <th >total Bs</th>
                <th >total $us</th>
                <th >total Bs</th>
                </tr>
              </thead>
              <tbody>
              {                  
                  data.anticipo_use.map((row,key)=> !row.voucher_head_id &&
                    <tr key={key}>
                       <td>{key+1}</td>
                      <td>{dateConfig(row.created_at)}</td>
                      <td>cliente: {row.client.business_name}</td>
                      <td>{row.currency}</td>
                      <td>{fNumber(row.exchange_rate)}</td>
                      {
                        row.disponible === "caja"?
                        <Fragment>
                          <td> {row.currency === "sus"? fNumber(parseFloat(row.amount) / parseFloat(row.exchange_rate)) :0 } </td>
                          <td> {row.currency === "bs"? fNumber(row.amount) : 0  } </td>
                        </Fragment>
                        :
                        <Fragment>
                          <td> 0 </td>
                          <td> 0 </td>
                        </Fragment>
                      }
                      {
                        row.disponible === "banco"?
                        <Fragment>
                          <td> {row.currency === "sus"? fNumber(parseFloat(row.amount)/parseFloat(row.exchange_rate)) :0 } </td>
                          <td> {row.currency === "bs"? fNumber(row.amount) : 0  } </td>
                        </Fragment>
                        :
                        <Fragment>
                          <td> 0 </td>
                          <td> 0 </td>
                        </Fragment>
                      }
                   
                      
                    </tr>   
                  )
              }
                <tr>
                  <th rowSpan={3} colSpan={5}>Total salida de anticipos</th>
                  <th colSpan={2}>Efectivo</th>
                  <th colSpan={2}>Banco</th>
                </tr>
                <tr>
                <th >total $us</th>
                <th >total Bs</th>
                <th >total $us</th>
                <th >total Bs</th>
                </tr>
                <tr>
                  <td>{fNumber(sumBy(data.anticipo_use,o=>{
                    if(o.voucher_head_id){
                      return 0;
                    }else{
                      return o.disponible === "caja" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                    }
                  }))}</td>
                  <td>{fNumber(sumBy(data.anticipo_use,o=>{
                    if(o.voucher_head_id){
                      return 0;
                    }else{
                      return o.disponible === "caja" && o.currency === "bs" ? parseFloat(o.amount): 0
                    }
                  }))}</td>
                  <td>{fNumber(sumBy(data.anticipo_use,o=>{
                    if(o.voucher_head_id){
                      return 0;
                    }else{
                    return o.disponible === "banco" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                    }
                  }))}</td>
                  <td>{fNumber(sumBy(data.anticipo_use,o=>{
                    if(o.voucher_head_id){
                      return 0;
                    }else{
                    return o.disponible === "banco" && o.currency === "bs" ? parseFloat(o.amount): 0
                    }
                  }))}</td>
                </tr>
              </tbody>
              </table>}
              {
                size(data.paynement) > 0 &&
                <table className="table-print-2 mt-3">
              <thead>
              <tr>
                  <th colSpan={9} className="text-center">INGRESO POR CUENTAS POR COBRAR</th>
                </tr>
                <tr>
                  <th rowSpan={2}>#</th>
                  <th rowSpan={2}>Fecha y Hr</th>
                  <th rowSpan={2}>Detalle</th>
                  <th rowSpan={2}>Moneda</th>
                  <th rowSpan={2}>Tipo Cambio</th>
                  <th colSpan={2}>Efectivo</th>
                  <th colSpan={2}>Banco</th>
                </tr>
                <tr>
                <th >total $us</th>
                <th >total Bs</th>
                <th >total $us</th>
                <th >total Bs</th>
                </tr>
              </thead>
              <tbody>
                  {
                    data.paynement.map((col,key)=>
                    <tr key={key+"-"+col.id}>
                      <td>{key+1}</td>
                      <td>{dateConfig(col.created_at)}</td>
                      <td>{col.accounts.detail}</td>
                      <td>{col.currency}</td>
                      <td>{fNumber(col.exchange_rate)}</td>
                      {
                        col.available === "cash_register"?
                        <Fragment>
                          <td> {col.currency === "sus"? fNumber(parseFloat(col.amount) / parseFloat(col.exchange_rate)) :0 } </td>
                          <td> {col.currency === "bs"? fNumber(col.amount) : 0  } </td>
                        </Fragment>
                        :
                        <Fragment>
                          <td> 0 </td>
                          <td> 0 </td>
                        </Fragment>
                      }
                      {
                        col.available === "bank"?
                        <Fragment>
                          <td> {col.currency === "sus"? fNumber(parseFloat(col.amount)/parseFloat(col.exchange_rate)) :0 } </td>
                          <td> {col.currency === "bs"? fNumber(col.amount) : 0  } </td>
                        </Fragment>
                        :
                        <Fragment>
                          <td> 0 </td>
                          <td> 0 </td>
                        </Fragment>
                      }
                    </tr>
                    )
                  }
                   <tr>
                  <th rowSpan={3} colSpan={5}>Total ingreso por cuentas por cobrar</th>
                  <th colSpan={2}>Efectivo</th>
                  <th colSpan={2}>Banco</th>
                </tr>
                <tr>
                <th >total $us</th>
                <th >total Bs</th>
                <th >total $us</th>
                <th >total Bs</th>
                </tr>
                <tr>
                  <td>{fNumber(sumBy(data.paynement,o=>{
                    return o.available === "cash_register" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  }))}</td>
                  <td>{fNumber(sumBy(data.paynement,o=>{
                    return o.available === "cash_register" && o.currency === "bs" ? parseFloat(o.amount): 0
                  }))}</td>
                  <td>{fNumber(sumBy(data.paynement,o=>{
                    return o.available === "bank" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  }))}</td>
                  <td>{fNumber(sumBy(data.paynement,o=>{
                    return o.available === "bank" && o.currency === "bs" ? parseFloat(o.amount): 0
                  }))}</td>                
                
                </tr>
              </tbody>
              </table>}

              <table className="table-print">
                
                <tr>
                  <th></th>
                  <th>Bs</th>
                  <th>Sus</th>
                </tr>
                <tr>
                  <th className="text-right">Total efectivo</th>
                  <td className="text-right">
                  {fNumber(sumBy(data.detail, o=>{
                  return  o.payment_type+"" !== "0"? parseFloat(o.total) : 0
                })+sumBy(data.anticipo,o=>{
                  return o.disponible === "caja" && o.currency === "bs" ? parseFloat(o.amount): 0
                })+sumBy(data.paynement,o=>{
                  return o.available === "cash_register" && o.currency === "bs" ? parseFloat(o.amount) : 0
                })
                )}
                  </td>
                  <td className="text-right">{fNumber(sumBy(data.anticipo,o=>{
                    return o.disponible === "caja" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  })
                  + sumBy(data.paynement,o=>{
                    return o.available === "cash_register" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  })
                  )}</td>
                </tr>

                <tr>
                  <th className="text-right">Total creditos</th>
                  <td className="text-right">
                  {fNumber(sumBy(data.detail, o=>{
                  return o.voucher_head?.cxc && parseFloat(o.voucher_head.cxc?.amount)
                }))}
                  </td>
                  <td className="text-right">-</td>
                </tr>
                <tr>
                  <th className="text-right">Total anticipos</th>
                  <td className="text-right">{fNumber(sumBy(data.anticipo,o=>{
                    return  o.currency === "bs" ? parseFloat(o.amount): 0
                  }))}</td>
                  <td className="text-right">
                  {fNumber(sumBy(data.anticipo,o=>{
                    return  o.currency === "sus" ? parseFloat(o.amount)/ parseFloat(o.exchange_rate): 0
                  }))}
                  </td>
                </tr>
                <tr>
                  <th className="text-right">Total depositos</th>
                  <td className="text-right">{fNumber(sumBy(data.anticipo,o=>{
                    return o.disponible === "banco" && o.currency === "bs" ? parseFloat(o.amount): 0
                  })
                  +sumBy(data.paynement,o=>{
                    return o.available === "bank" && o.currency === "bs" ? parseFloat(o.amount): 0
                  })+ sumBy(data.detail, o=>{
                    return o.payment_type+"" === "7"? parseFloat(o.total) : 0
                  })
                  )}</td>
                  <td className="text-right">{fNumber(sumBy(data.anticipo,o=>{
                    return o.disponible === "banco" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  })
                  +sumBy(data.paynement,o=>{
                    return o.available === "bank" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  })
                  )}</td>
                </tr>
                <tr>
                  <th className="text-right">Total salidas</th>
                  <td className="text-right">-{fNumber( sumBy(data.anticipo_use,o=>{
                     if(o.voucher_head_id){
                      return 0;
                    }else{
                  return o.disponible === "caja" && o.currency === "bs" ? parseFloat(o.amount): 0
                    }
                }) )}</td>
                  <td className="text-right">-{
                    fNumber( sumBy(data.anticipo_use,o=>{
                      if(o.voucher_head_id){
                        return 0;
                      }else{
                        return o.disponible === "caja" && o.currency === "sus" ? parseFloat(o.amount): 0
                      }
                    }) )
                  }</td>
                </tr>
                <tr>
                  <th className="text-right">Monto apertura</th>
                  <td className="text-right">{fNumber(data.initial_amount)}</td>
                  <td>--</td>
                </tr>
                <tr>
                  <th className="text-right">Total Caja Cierre</th>
                  <td className="bg-green-500 font-bold text-white text-2xl text-right"> {fNumber(sumBy(data.detail, o=>{
                  return  o.payment_type+"" !== "0"? parseFloat(o.total) : 0
                })+sumBy(data.anticipo,o=>{
                  return o.disponible === "caja" && o.currency === "bs" ? parseFloat(o.amount): 0
                })+parseFloat(data.initial_amount) + sumBy(data.paynement,o=>{
                  return o.available === "cash_register" && o.currency === "bs" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                })-sumBy(data.anticipo_use,o=>{
                  if(o.voucher_head_id){
                    return 0
                  }else{
                  return o.disponible === "caja" && o.currency === "bs" ? parseFloat(o.amount): 0
                  }
                })
                )}</td>
                  <td className="bg-green-500 font-bold text-white text-2xl text-right">{fNumber(sumBy(data.anticipo,o=>{
                  return o.disponible === "caja" && o.currency === "sus" ? parseFloat(o.amount)/ parseFloat(o.exchange_rate): 0
                })+sumBy(data.paynement,o=>{
                  return o.available === "cash_register" && o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                })-sumBy(data.anticipo_use,o=>{
                  if(o.voucher_head_id){
                    return 0
                  }else{
                    return o.disponible === "caja" && o.currency === "sus" ? parseFloat(o.amount): 0
                  }
                })
                )}</td>
                </tr>
                <tr>
                  <th className="text-right">Total Ingresos</th>
                  <td className="text-right">
                      {
                        fNumber(sumBy(data.detail, o=>{
                          return  o.payment_type+"" !== "0"? parseFloat(o.total) : 0
                        })+sumBy(data.anticipo,o=>{
                          return o.currency === "bs"?parseFloat(o.amount):0
                        })
                        +sumBy(data.paynement,o=>{
                          return o.currency === "bs" ? parseFloat(o.amount): 0
                        })-sumBy(data.anticipo_use,o=>{
                          if(o.voucher_head_id){
                            return 0
                          }else{
                          return o.disponible === "caja" && o.currency === "bs" ? parseFloat(o.amount): 0
                          }
                        })+sumBy(data.detail, o=>{
                          return o.payment_type+"" === "7"? parseFloat(o.total) : 0
                        })
                        )
                      }
                  </td>
                  <td className="text-right">
                  {fNumber(sumBy(data.anticipo,o=>{
                    return o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  })
                  +sumBy(data.paynement,o=>{
                    return  o.currency === "sus" ? parseFloat(o.amount) / parseFloat(o.exchange_rate) : 0
                  })-sumBy(data.anticipo_use,o=>{
                    if(o.voucher_head_id){
                      return 0
                    }else{
                    return o.disponible === "caja" && o.currency === "sus" ? parseFloat(o.amount): 0
                    }
                  })
                
                  )}
                  </td>
                </tr>
              </table>
          <div>
            <p></p>
          </div>
          <div>
          {edit? <div className="p-2 mt-3">
              <TextareaAutosize
                onChange={handleChangeInfo}
                variant="outlined"
                minRows={3}
                label="observaciones"
                placeholder="Observaciones"
                className="w-full p-2 border border-blue-300"
              />
             <Button onClick={()=>handleClickCloseBox()}>Cerrar Caja</Button>
              </div>:
              <div className="p-2 mt-3">
                    <div className="w-full p-2 border border-blue-300">
                          {data.observation?.observation}
                      </div>
                </div>
             }
          </div>
        </div>}
      </DialogContent>
    </Dialog>
    </div>
  );
};
const formaPago =(pago)=>{
  switch (pago+"") {
    case "0":
      return "Anticipo";
      break;
    case "1":
      return "efectivo";
      break;
    case "2":
      return "Tarjeta";
      break;
    case "6":
      return "Credito";
      break;
    case "7":
      return "QR";
      break;
  
    default:
      break;
  }
}

const getColor=(pago)=>{
  switch (pago+"") {
    case "0":
      return "bg-blue-200";
      break;
    case "1":
      return "bg-green-200";
      break;
    case "2":
      return "Tarjeta";
      break;
    case "6":
      return "bg-yellow-200";
      break;
  
    default:
      break;
  }
}
export default CashDetail;
